import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from "./Components/LandingPage/Home";
import About from "./Components/LandingPage/About";
import Blog from "./Components/LandingPage/Blog";
import Navbar from "./Components/Common/Navbar/LoginNavbar";
import "./Styles.css";
import { useAuth } from "../src/Context/AuthContext";
import MainLayout from "./MainLayout";


const App = () => {
  const [firstTimeLogin, setFirstTimeLogin] = useState(true);
  const { isLogin } = useAuth(); // Assuming useAuth provides login status


  // console.log(isLogin,"isLogin");
  
  return (
    <Router>
      <ToastContainer />
      {isLogin ? (
        <MainLayout firstTimeLogin={firstTimeLogin} />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            {/* Add other routes as needed */}
          </Routes>
        </>
      )}
    </Router>
  );
};

export default App;
