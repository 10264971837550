import React, { useState, useEffect } from "react";
import { Form, Card, Stack, Image, Row, Col, Button } from "react-bootstrap";
import frel from "../../../Images/payment_icons/frel.png";
import usd from "../../../Images/payment_icons/usd.png";
import inr from "../../../Images/payment_icons/inr.png";
import euro from "../../../Images/payment_icons/euro.png";
import pound from "../../../Images/payment_icons/pound.png";
import bitcoin from "../../../Images/payment_icons/bitcoin.png";
import litecoin from "../../../Images/payment_icons/litecoin.png";
import ethereum from "../../../Images/payment_icons/ethereum.png";
import usdt from "../../../Images/payment_icons/usdt.png";
// import "./Payment.css";

const Payment = ({ onSave, updatePayloadData, type, onPaymentChange }) => {
  const [acceptedPaymentModes, setAcceptedPaymentModes] = useState({
    freelcoin: true,
    traditional: { usd: false, inr: false, euro: false, pound: false },
    other: { bitcoin: false, litecoin: false, ethereum: false, usdt: false },
    any: false,
  });

  const [selectedMode, setSelectedMode] = useState("frel");
  // console.log(
  //   type,
  //   "type",
  //   selectedMode,
  //   "selectedMode",
  //   acceptedPaymentModes,
  //   "acceptedPaymentModes"
  // );

  const handleRadioChange = (mode) => {
    console.log(mode, "mode");

    setSelectedMode(mode);

    if (mode === "frel" || mode === "any") {
      setAcceptedPaymentModes((prevState) => ({
        ...prevState,
        freelcoin: mode === "frel",
        any: mode === "any",
        traditional: { usd: false, inr: false, euro: false, pound: false },
        other: {
          bitcoin: false,
          litecoin: false,
          ethereum: false,
          usdt: false,
        },
      }));
    } else if (mode === "traditional") {
      setAcceptedPaymentModes((prevState) => ({
        ...prevState,
        freelcoin: false,
        any: false,
        other: {
          bitcoin: false,
          litecoin: false,
          ethereum: false,
          usdt: false,
        },
      }));
    } else {
      setAcceptedPaymentModes((prevState) => ({
        ...prevState,
        freelcoin: false,
        any: false,
        traditional: { usd: false, inr: false, euro: false, pound: false },
      }));
    }
  };

  const isDisabled = (mode) => selectedMode && selectedMode !== mode;

  const handleCheckboxChange = (category, subMode) => {
    setAcceptedPaymentModes((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [subMode]: !prevState[category][subMode],
      },
    }));
  };

  // console.log(acceptedPaymentModes, "acceptedPaymentModes");

  useEffect(() => {
    if (type !== "project")
      updatePayloadData({ acceptedPaymentModes: [acceptedPaymentModes] });
    else {
      onPaymentChange(selectedMode, [acceptedPaymentModes]);
    }
  }, [acceptedPaymentModes]);

  return (
    <div>
      {type !== "project" && (
        <div className="service-tilte">
          <h3>Payment</h3>
        </div>
      )}
      <Card className="border-0">
        {type !== "project" && (
          <>
            <Card.Title>Payment Mode</Card.Title>
            <Card.Text>
              Lorem ipsum dolor sit amet consectetur. Ultrices curabitur
              lobortis pharetra id nisi. Eu justo justo econsequat nisl..Lorem
              ipsum dolor sit amet consectetur. Ultrices.
            </Card.Text>
          </>
        )}
        <h5>Choose payment mode</h5>
        {/* Frel Coin */}
        <Form.Group className="mt-4 mb-2">
          <Form.Check
            inline
            name="group1"
            type="radio"
            id="freelacoin"
            defaultChecked={selectedMode === "frel"}
            onChange={() => handleRadioChange("frel")}
            label="Frel Coin"
          />

          <Stack direction="horizontal" className={`ms-4 ps-2 gap-2 ${isDisabled("frel") && "disabled opacity-50"}`}>
            <Image src={frel} alt="frel" />
            <div className="content">
              <p className="mb-0">Frel </p>
              <small className="opacity-50">No transaction fees</small>
            </div>
          </Stack>
        </Form.Group>

        {/* Other Payment Option */}
        <Form.Group className="mt-4 mb-2">
          <Form.Check
            inline
            id="OtherPaymentOption"
            label="Other Payment Option"
            name="group1"
            type="radio"
            onChange={() => handleRadioChange("other")}
          />
          <div className="ms-4 ps-2">
            <Row className="gy-3">
              <Col xs={12}>
                <p className="freela-fs-12px opacity-50 mb-0">Cryptocurrencies</p>
              </Col>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  id="bitcoin"
                  type="checkbox"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("other")}
                  checked={acceptedPaymentModes.other.bitcoin}
                  onChange={() => handleCheckboxChange("other", "bitcoin")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={bitcoin} alt="Bitcoin" />
                      <span>Bitcoin</span>
                    </Stack>
                  }
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  type="checkbox"
                  id="litecoin"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("other")}
                  checked={acceptedPaymentModes.other.litecoin}
                  onChange={() => handleCheckboxChange("other", "litecoin")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={litecoin} alt="Litecoin" />
                      <span>Litecoin</span>
                    </Stack>
                  }
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  id="ethereum"
                  type="checkbox"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("other")}
                  checked={acceptedPaymentModes.other.ethereum}
                  onChange={() => handleCheckboxChange("other", "ethereum")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={ethereum} alt="ethereum" />
                      <span>Ethereum</span>
                    </Stack>
                  }
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  type="checkbox"
                  id="USDT"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("other")}
                  checked={acceptedPaymentModes.other.usdt}
                  onChange={() => handleCheckboxChange("other", "usdt")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={usdt} alt="usdt" />
                      <span>USDT (TRC-20)</span>
                    </Stack>
                  }
                />
              </Form.Group>
            </Row>
          </div>
        </Form.Group>

        {/* Traditional Payment Mode */}
        <Form.Group className="mt-4 mb-2">
          <Form.Check
            inline
            id="TraditionalPaymentmode"
            label="Traditional Payment mode"
            name="group1"
            type="radio"
            onChange={() => handleRadioChange("traditional")}
          />
          <div className="ms-4 ps-2">
            <Row className="gy-3">
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  id="usd"
                  type="checkbox"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("traditional")}
                  checked={acceptedPaymentModes.traditional.usd}
                  onChange={() => handleCheckboxChange("traditional", "usd")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={usd} alt="usd" />
                      <span>USD</span>
                    </Stack>
                  }
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  type="checkbox"
                  id="inr"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("traditional")}
                  checked={acceptedPaymentModes.traditional.inr}
                  onChange={() => handleCheckboxChange("traditional", "inr")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={inr} alt="inr" />
                      <span>INR</span>
                    </Stack>
                  }
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  id="euro"
                  type="checkbox"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("traditional")}
                  checked={acceptedPaymentModes.traditional.euro}
                  onChange={() => handleCheckboxChange("traditional", "euro")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={euro} alt="euro" />
                      <span>Euro</span>
                    </Stack>
                  }
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Check
                  inline
                  id="pound"
                  type="checkbox"
                  className="d-flex align-items-center gap-2"
                  disabled={isDisabled("traditional")}
                  checked={acceptedPaymentModes.traditional.pound}
                  onChange={() => handleCheckboxChange("traditional", "pound")}
                  label={
                    <Stack direction="horizontal" className="gap-2">
                      <Image src={pound} alt="pound" />
                      <span>British Pound</span>
                    </Stack>
                  }
                />
              </Form.Group>
            </Row>
          </div>
        </Form.Group>
        {/* Any mode of payment */}
        <Form.Group className="mt-4">
          <Form.Check
            inline
            id="checkMore"
            label="Check here for Any mode of payment"
            name="group1"
            type="radio"
            onChange={() => handleRadioChange("any")}
          />
        </Form.Group>
        <Form.Group className="mt-4">
          <div className="d-grid w-200px mx-auto">
            <Button variant="" className="freela-btn-primary">Ok</Button>
          </div>
        </Form.Group>
      </Card>
    </div>
  );
};
export default Payment;
