// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_card .form-check-label{
    padding-left:15px
}
.ml-40{
    margin-left:40px;
}
.payment_mode p{
    line-height: 12px;
    margin-bottom: 0px;
}
.payment_mode small{
    font-size: 12px;
    color: #667483; 
}
.payment_mode .form-label{
    margin-bottom: 0px;
}
p.grey_font{
    color: #667483;
    font-size: 16px;
    font-weight: 600;
}
.payment_card h2{
    color: #F0806C;
    margin:10px 0px;
}
.payment_card{
    border-radius: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Service/Payment.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".payment_card .form-check-label{\r\n    padding-left:15px\r\n}\r\n.ml-40{\r\n    margin-left:40px;\r\n}\r\n.payment_mode p{\r\n    line-height: 12px;\r\n    margin-bottom: 0px;\r\n}\r\n.payment_mode small{\r\n    font-size: 12px;\r\n    color: #667483; \r\n}\r\n.payment_mode .form-label{\r\n    margin-bottom: 0px;\r\n}\r\np.grey_font{\r\n    color: #667483;\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n}\r\n.payment_card h2{\r\n    color: #F0806C;\r\n    margin:10px 0px;\r\n}\r\n.payment_card{\r\n    border-radius: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
