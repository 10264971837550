import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Stack,
  ButtonGroup,
} from "react-bootstrap";

import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";
import { debounce } from "lodash";
import Publish from "../Service/Publish";

import ProjectView from "./ProjectView";
import ShowModal from "./ShowModal";
import Profile from "../Profile/Profile/Profile";
import ProfilePreview from "../ProfilePreview/ProfilePreview";

import { ReactComponent as GridIcon } from "../../../Images/freela-icons/grid-icon.svg";
import { ReactComponent as UnGridIcon } from "../../../Images/freela-icons/ungrid-icon.svg";
import { ReactComponent as FilterIcon } from "../../../Images/freela-icons/filter-icon.svg";

import MyWorkFilterOffcanvas from "./MyWorkFilterOffcanvas";

import GridView from "./GridView";
import RowView from "./RowView";
import CustomModal from "../../Common/Modal/CustomModal";
import { BottomCircle, BottomShape, BottomVerticalCircle, NavigationIcon, TopLeftShape, TopRightShape } from "../../Common/utils/SVGIcons";

const MyWorks = ({ userDetails }) => {
  const [isActive, setIsActive] = useState(false);
  const [activeRole, setActiveRole] = useState("freelancer");
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showProfilePrev, setShowProfilePrev] = useState(false);
  const [prevUserDetails, setPrevUserDetails] = useState({});
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const { notify } = useToast();

  const [isGridView, setIsGridView] = useState(true);

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    if (activeRole) {
      debouncedGetActiveRoleDetails();
    }
    return () => debouncedGetActiveRoleDetails.cancel();
  }, [activeRole]);

  const getActiveRoleDetails = async (type, order) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      let endpoint = activeRole === "freelancer" ? "project" : "service";

      if (type === "sort" && order) {
        endpoint += `?order=${order}`;
      }

      // console.log(endpoint, "endpoint");
      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleCard = (Id) => {
    const isIncompleteProfile =
      activeRole === "freelancer"
        ? userDetails?.freelancerPercentage !== 100
        : userDetails?.clientPercentage !== 100;

    if (isIncompleteProfile) {
      console.log("go to profle");
      setShowPopup(true);
    } else {
      console.log("got to service/project ", Id);

      getCardDetails(Id);
    }
  };

  const handleCloseModal = () => {
    setShowPopup(false);
  };

  const handleProfile = () => {
    console.log("show profile");
    handleCloseModal();
    setShowProfile(true);
  };
  const getCardDetails = async (Id) => {
    setLoading(true);
    setCardDetails([]);

    try {
      const endpoint =
        activeRole === "freelancer" ? `project/${Id}` : `service/${Id}`;
      console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setCardDetails(response.data);
        setShowCardDetails(true);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  // console.log(cardDetails, "cardDetails");

  const handleUpdateMeta = async (itemId, saveKey, isSave, endpoint) => {
    setLoading(true);

    try {
      const payload = {
        itemType: activeRole === "freelancer" ? "projects" : "services",
        itemId: itemId,
        userId: userDetails?.userId,
        [saveKey]: isSave,
      };

      const response = await ApiCall(
        "POST",
        endpoint,
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200 || 201) {
        console.log("successful:", response.data);
        notify("success", response.message);
        getActiveRoleDetails();
      } else {
        notify("error", response.message || "saved failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleProfilePreview = async (id) => {
    setShowProfilePrev(true);
    setLoading(true);

    try {
      const response = await ApiCall(
        "GET",
        `user/getUserByUserId?userId=${id}&currentUserId=${userDetails?.userId}`,
        null,
        "application/json"
      );

      console.log(response, "Prev User response");

      // Handle success with a 200 status code
      if (response.statusCode === 200) {
        // console.log(response.data); // Log or handle the user details data
        setPrevUserDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      // Handle API call error
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const searchList = [
    {
      title: "Web Dev",
    },
    {
      title: "Mobile Dev",
    },
    {
      title: "Design",
    },
    {
      title: "Writing",
    },
    {
      title: "Admin Support",
    },
  ];

  const handleSearch = async (searchPayload) => {
    console.log(searchPayload, "searchPayload");
    setLoading(true);
    setActiveRoleDetails([]);

    try {
      const payload = {
        requiredSkills: searchPayload.requiredSkills,
        experience: searchPayload.experience,
        requiredReputations: searchPayload.requiredReputations,
        estimateBudget: searchPayload.estimateBudget,
        paymentModes: searchPayload.paymentModes,
        availability: searchPayload.availability,
      };
      const endpoint = `${activeRole === "freelancer" ? "project" : "service"
        }/searchFilter`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall(
        "POST",
        endpoint,
        payload,
        "application/json"
      );
      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      {showPopup && (
        <CustomModal isOpen={showPopup} onClose={handleCloseModal} modalSize="sm">
          <ShowModal handleProfile={handleProfile} />
        </CustomModal>
      )}

      {showProfilePrev ? (
        <ProfilePreview
          userDetails={prevUserDetails[0]}
          setUserDetails={setPrevUserDetails}
        />
      ) : showProfile ? (
        <Profile userDetails={userDetails}/>
      ) : showCardDetails ? (
        activeRole === "freelancer" ? (
          <ProjectView
            payloadData={cardDetails}
            userDetails={userDetails}
            type={"bid"}
          />
        ) : (
          <Publish
            serviceData={cardDetails}
            userDetails={userDetails}
            type={"bid"}
          />
        )
      ) : (
        <section className="find_works">
          <Row className="gy-4">
            <Col xs={12}>
              <Card as={Card.Body} className="advance_search rounded-5 py-5">
                <TopRightShape />
                <TopLeftShape />
                <BottomShape />
                <BottomCircle />
                <BottomVerticalCircle />
                <Row className="gy-3 z-1 position-relative">
                  <Col xs={12} className="text-center">
                    <div className="display-6 fw-600">Find Your Work</div>
                  </Col>
                  <Col xl={9} className="mx-auto">
                    <Row className="g-3 g-md-0 advance_search_fields">
                      <Col md={4} lg={4} xl={3}>
                        <Form.Group className="position-relative">
                          <NavigationIcon />
                          <Form.Select
                            aria-label="Default select example"
                            className="ps-5"
                          >
                            <option>Location</option>
                            <option value="1">Location-1</option>
                            <option value="2">Location-2</option>
                            <option value="3">Location-3</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} lg={4} xl={3}>
                        <Form.Select aria-label="Default select example">
                          <option>Category</option>
                          <option value="1">Category-1</option>
                          <option value="2">Category-2</option>
                          <option value="3">Category-3</option>
                        </Form.Select>
                      </Col>
                      <Col md={4} lg={4} xl={6}>
                        <Form.Group className="position-relative searchbar">
                          <Form.Control placeholder="Search here..." />
                          <Button
                            variant=""
                            size="sm"
                            className="me-1 freela-btn-primary position-absolute top-50 end-0 translate-middle-y"
                          >
                            Search <i className="bi bi-search" />
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Nav className="align-items-center justify-content-center flex-wrap gap-2 mt-4 pt-3">
                  {searchList.map((item, idx) => (
                    <Button
                      variant=""
                      size="sm"
                      className="freela-btn-primary2 bg-transparent freela-border-greaseblack"
                      key={idx}
                    >
                      {item.title}
                    </Button>
                  ))}
                </Nav>
              </Card>
            </Col>

            <Col xs={12}>
              <Card
                as={Card.Body}
                className="find_work_content section_card rounded-5"
              >
                <Row className="gy-3 mb-3">
                  <Col sm={6} md={7} lg={8} xl={8}>
                    <ButtonGroup
                      aria-label="Basic example"
                      className="rounded-3 overflow-hidden"
                    >
                      <Button
                        variant=""
                        onClick={() => setActiveRole("freelancer")}
                        className={
                          activeRole === "freelancer"
                            ? "freela-btn-primary"
                            : "freela-btn-primary-outline"
                        }
                      >
                        Freelancer
                      </Button>
                      <Button
                        variant=""
                        onClick={() => setActiveRole("client")}
                        className={
                          activeRole === "client"
                            ? "freela-btn-primary px-5"
                            : "freela-btn-primary-outline px-5"
                        }
                      >
                        Client
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col sm={6} md={5} lg={4} xl={4}>
                    <Stack direction="horizontal" className="gap-2">
                      <Form.Select
                        onChange={(e) =>
                          getActiveRoleDetails("sort", e.target.value)
                        }
                      >
                        <option value="">Select</option>
                        <option value="asc">Oldest</option>
                        <option value="desc">Newest</option>
                      </Form.Select>
                      <Stack
                        direction="horizontal"
                        className="gap-2 border border-solid freela-border-greylight13 p-05 rounded-pill"
                      >
                        <Button
                          variant=""
                          size="xs"
                          onClick={() => setIsGridView(true)}
                          className={
                            isGridView === true
                              ? "freela-btn-primary btn-rounded active"
                              : "freela-btn-primary-outline btn-outline-rounded freela-bg-greylight3 freela-border-greylight2"
                          }
                        >
                          <GridIcon width={16} height={16} />
                        </Button>
                        <Button
                          variant=""
                          size="xs"
                          onClick={() => setIsGridView(false)}
                          className={
                            isGridView === false
                              ? "freela-btn-primary btn-rounded active"
                              : "freela-btn-primary-outline btn-outline-rounded freela-bg-greylight3 freela-border-greylight2"
                          }
                        >
                          <UnGridIcon width={16} height={16} />
                        </Button>
                      </Stack>
                      <Stack direction="horizontal">
                        <Button
                          variant=""
                          className="freela-btn-primary-outline btn-outline-rounded freela-border-greylight2"
                          onClick={() => setShowFilterOffcanvas(true)}
                        >
                          <FilterIcon width={16} height={16} />
                        </Button>
                      </Stack>
                    </Stack>
                  </Col>
                  <Col xs={12}>
                    <Stack direction="horizontal">
                      <div className="freela-fs-20px fw-600">
                        Showing {activeRoleDetails?.length}{" "}
                        {activeRole === "freelancer" ? "projects" : "services"}
                      </div>
                      <Button
                        variant=""
                        className="text-decoration-none freela-fs-14px p-0 ms-auto freela-text-primary"
                      >
                        View More
                      </Button>
                    </Stack>
                  </Col>
                </Row>

                <Row className="gy-4">
                  {isGridView ? (
                    <GridView
                      activeRoleDetails={activeRoleDetails}
                      activeRole={activeRole}
                      handleProfilePreview={handleProfilePreview}
                      handleUpdateMeta={handleUpdateMeta}
                      handleCard={handleCard}
                    />
                  ) : (
                    <RowView
                      activeRoleDetails={activeRoleDetails}
                      activeRole={activeRole}
                      handleProfilePreview={handleProfilePreview}
                      handleUpdateMeta={handleUpdateMeta}
                      handleCard={handleCard}
                    />
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </section>
      )}

      {showFilterOffcanvas && (
        <MyWorkFilterOffcanvas
          show={showFilterOffcanvas}
          handleClose={() => setShowFilterOffcanvas(false)}
          handleSearch={handleSearch}
        />
      )}
    </>
  );
};

export default MyWorks;
