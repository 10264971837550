// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-tabs-row{
    display: flex;
    border-bottom: 1px solid #EEEAEA;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ClientProfile/ClientProfileCreation/ClientProfileCreation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gCAAgC;IAChC,uBAAuB;AAC3B","sourcesContent":[".client-tabs-row{\r\n    display: flex;\r\n    border-bottom: 1px solid #EEEAEA;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
