import React, { useState } from "react";
import Modal from "../Modal/CustomModal";
import { Button } from "react-bootstrap";
import "./Navbar.css";
import logo from "../../../Images/logo.png";
import Login from "../../Login/Login";
import Register from "../../Register/Register";
import RegisterVerify from "../../Register/RegisterVerify";
import RegisterCongrats from "../../Register/RegisterCongrats";
import ForgotPwd from "../../Register/ForgotPwd";
import ForgotVerify from "../../Register/ForgotVerify";
import { Navbar, Nav } from "react-bootstrap";

const LoginNavbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false); // State to track form type
  const [showVerifyModal, setShowVerifyModal] = useState(false); // New state for RegisterVerify modal
  const [showCongratsModal, setShowCongratsModal] = useState(false); // New state for RegisterCongrats modal
  const [showLoginModal, setShowLoginModal] = useState(false); // State for Login modal
  const [showRegModal, setShowRegModal] = useState(false); // State for Register modal
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false); // State for forgot password modal
  const [showForgotVerPwdModal, setShowForgotVerPwdModal] = useState(false); // State for forgot verify password modal
  const [regEmail, setRegEmail] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");

  const handleOpenModal = (isRegister) => {
    setIsRegister(isRegister); // Set the form type based on the button clicked
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowVerifyModal(false); // Close the RegisterVerify modal if it's open
    setShowCongratsModal(false); // Close the RegisterCongrats modal if it's open
    setShowLoginModal(false); // Close the Login modal if it's open
    setShowRegModal(false);
    setShowForgotPwdModal(false);
    setShowForgotVerPwdModal(false);
  };

  // Function to open the RegisterVerify modal
  const openVerifyModal = (email) => {
    handleCloseModal()
    setRegEmail(email);
    setIsModalOpen(false); // Close the registration modal
    setShowVerifyModal(true); // Open the verification modal
  };

  // console.log(showVerifyModal,"showVerifyModal");

  const openCongratsModal = () => {
    handleCloseModal()
    setShowVerifyModal(false);
    setShowCongratsModal(true);
  };

  // Function to open the Login modal
  const openLogin = () => {
    // console.log(showLoginModal, showRegModal, "openLogin");
    handleCloseModal()
    setShowForgotVerPwdModal(false);
    setShowForgotPwdModal(false);
    setShowRegModal(false);
    setShowLoginModal(true); // Open the login modal
  };

  const openRegister = () => {
    // console.log(showLoginModal, showRegModal, "openRegister");
    handleCloseModal()
    setShowLoginModal(false);
    setShowForgotPwdModal(false);
    setShowRegModal(true); // Open the register modal
  };

  const openForgotPwd = () => {
    handleCloseModal()
    setShowLoginModal(false);
    setShowRegModal(false); // Open the register modal
    setShowForgotVerPwdModal(false);
    setShowForgotPwdModal(true); // Open the login modal
  };

  const openForgotverify = (email) => {
    // console.log(email, "email");
    handleCloseModal()
    setVerifyEmail(email);
    setShowLoginModal(false);
    setShowForgotPwdModal(false);
    setShowForgotVerPwdModal(true);
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="login_navbar">
        <Navbar.Brand href="#home">
          <img src={logo} alt="Freela Logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <button
              className="secondary-btn"
              onClick={() => handleOpenModal(true)}
            >
              Sign Up
            </button>
            <button
              className="primary-btn ms-3"
              onClick={() => handleOpenModal(false)}
            >
              Sign In
            </button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Modal content for Sign Up or Sign In */}

      {isRegister ? (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <Register openVerifyModal={openVerifyModal} openLogin={openLogin} />
        </Modal>
      ) : (
        // Login Form
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <Login openRegister={openRegister} openForgotPwd={openForgotPwd} />
        </Modal>
      )}

      {/* RegisterVerify Modal */}
      {showVerifyModal && (
        <Modal isOpen={showVerifyModal} onClose={handleCloseModal}>
          <RegisterVerify
            regEmail={regEmail}
            openCongratsModal={openCongratsModal}
          />
        </Modal>
      )}

      {/* RegisterCongrats Modal */}
      {showCongratsModal && (
        <Modal isOpen={showCongratsModal} onClose={handleCloseModal}>
          <RegisterCongrats openLogin={openLogin} />
        </Modal>
      )}

      {/* Login Modal */}
      {showLoginModal && (
        <Modal isOpen={showLoginModal} onClose={handleCloseModal}>
          <Login openRegister={openRegister} openForgotPwd={openForgotPwd} />
        </Modal>
      )}

      {showRegModal && (
        <Modal isOpen={showRegModal} onClose={handleCloseModal}>
          <Register openVerifyModal={openVerifyModal} openLogin={openLogin} />
        </Modal>
      )}

      {showForgotPwdModal && (
        <Modal isOpen={showForgotPwdModal} onClose={handleCloseModal}>
          <ForgotPwd
            openLogin={openLogin}
            openForgotverify={openForgotverify}
          />
        </Modal>
      )}

      {showForgotVerPwdModal && (
        <Modal isOpen={showForgotVerPwdModal} onClose={handleCloseModal}>
          <ForgotVerify verifyEmail={verifyEmail} openLogin={openLogin} />
        </Modal>
      )}
    </>
  );
};

export default LoginNavbar;
