import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./TopNav.css"; // Import the stylesheet
import logo from "../../../Images/logo.png"; // Logo image
import notificationIcon from "../../../Images/icons/notification-bell.png"; // Notification icon
import profilePic from "../../../Images/profile.png"; // Profile picture
import cImage from "../../../Images/icons/c-switch-toggle.svg"; // 'C' toggle image
import fImage from "../../../Images/icons/f-switch-toggle.svg"; // 'F' toggle image
import dropdown from "../../../Images/icons/dropdown.png";
import { useAuth } from "../../../Context/AuthContext";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import ServiceModal from "../ServiceModal";

const TopNav = ({ userDetails, role, setRole }) => {
  // const [toggle, setToggle] = useState(cImage);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleShowModal =()=>{
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false); // Close the modal
  };

  const handleRoleSelection = (selectedRole) => {
    console.log(selectedRole,"selectedRole");
    
    setRole(selectedRole); // Update the role
    setModalOpen(false); // Close the modal

    // Navigate based on the selected role
    if (selectedRole === "freelancer") {
      navigate("/create-service");
    } else {
      navigate("/create-project");
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">
          <img src={logo} alt="Freela Logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <div className="right-side">
              {role === "freelancer" ? (
                <button className="project-button" onClick={handleShowModal}>
                  Create Service
                </button>
              ) : (
                <button className="project-button" onClick={handleShowModal}>
                  Create Project
                </button>
              )}

              {/* <div className="toggle-section">
                <span className="toggle-section-name">Switch to</span>
                <button className="toggle-btn" onClick={handleToggle}>
                  <img src={toggle} alt="Toggle" className="toggle-icon" />
                </button>
              </div> */}

              <div className="notification">
                <img
                  src={notificationIcon}
                  alt="Notifications"
                  className="icon"
                />
              </div>

              <div className="profile" ref={dropdownRef}>
                <img
                  src={userDetails?.profile.profilePic || profilePic}
                  alt="Profile"
                  className="profile-pic"
                />
                <span className="profile-name">{userDetails?.firstName}</span>
                <span
                  className={`dropdown-icon ${isDropdownOpen ? "rotate" : ""}`}
                  onMouseEnter={() => setDropdownOpen(true)}
                >
                  <img src={dropdown} alt="Dropdown Icon" />
                </span>

                {isDropdownOpen && (
                  <div className="dropdown">
                    <div className="dropdown-content">
                      <a href="profile-preview">Profile</a>
                      <a href="/settings">Settings</a>
                      <a onClick={handleLogout} href="/">
                        Logout
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* ServiceModal */}
      {isModalOpen && (
        <ServiceModal isOpen={isModalOpen} onClose={handleModalClose} onRoleSelect={handleRoleSelection}/>
      )}
    </>
  );
};

export default TopNav;
