import React from 'react'
import { Container, Row, Col, Image, Button, Stack } from 'react-bootstrap';
import hero_bg1 from '../../Images/freela-images/hero_bg_girl.png';
import hero_bg2 from '../../Images/freela-images/hero_bg_boy.png';

export const Home = () => {
  return (
    <section className='hero py-5 position-relative'>
      <Image width={350} src={require('../../Images/freela-icons/hero-bg1.svg').default} className='z-0 position-absolute end-0 top-0' />
      <Container>
        <Row className='align-items-center'>
          <Col md={6} lg={6} xl={6} xxl={6}>
            <div className="content mb-lg-0 mb-5 position-relative">
              <p className='freela-text-primary mb-0'>The only freelancing space</p>
              <h1>Find your dream jobs
                through <span className="freela-text-primary">Freela</span> easily</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut erat bibendum ornare urna, cursus eget convallis. Feugiat imperdiet posuere justo, ultrices interdum.</p>
              <Stack direction='horizontal' className='mt-2 gap-3'>
                <Button variant="" className="freela-btn-primary-outline">I Want to Work</Button>
                <Button variant="" className="freela-btn-primary">I Want to Hire</Button>
              </Stack>
            </div>
          </Col>
          <Col md={6} lg={6} xl={6} xxl={6}>
            <div className="ms-lg-5">
              <Stack direction='horizontal' className="position-relative ms-lg-5">
                <Image src={hero_bg2} width={200} className='mt-5 ms-lg-5 position-absolute' />
                <Image src={hero_bg1} width={200} style={{ marginLeft: '13rem' }} />
              </Stack>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
