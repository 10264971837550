import React, { useEffect, useState } from "react";
import ProjectPreview from "./ProjectPreview";
import { Row, Col, Card, Button, Form, Dropdown, DropdownButton, InputGroup, Nav, Stack } from "react-bootstrap";
import { Range } from "react-range";
import { formatDateToDDMMYYYYC, formatForInput } from "../../Common/utils/utils";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";
import Payment from "../Service/Payment";
import CustomModal from "../../Common/Modal/CustomModal";

const ProjectSkills = ({
  payloadData,
  setPayloadData,
  handleBack,
  videoSrc,
  userDetails,
  userId,
  projectId,
  certificateFile,
  projectData,
  type,
}) => {
  const [showProPrev, setShowProPrev] = useState(false);
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(
    "Select Payment Mode"
  );

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const [skillPayloadData, setSkillPayloadData] = useState({
    skills: "",
    skillsNeeded: [],
    tier: [],
    serviceType: [],
    estimateBudget: {
      min: 0, // Minimum budget
      max: 0, // Maximum budgets
    },
    acceptedPaymentModes: {},
    paymentType: {
      type: "Hours",
      duration: 0,
    },
    projectBidTimings: {
      start: "1991-01-01",
      end: "1991-01-01",
    },
  });
  const [skillList, setSkillList] = useState([]);

  const handleBidTimingChange = (e) => {
    const { id, value } = e.target;
    setSkillPayloadData((prevState) => ({
      ...prevState,
      projectBidTimings: {
        ...prevState.projectBidTimings,
        [id]: value,
      },
    }));
  };

  const handleProSkillNext = (boolean) => {
    setShowProPrev(boolean);
    handlePost();
  };

  const handlePost = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: payloadData?.projectName,
        description: payloadData?.description,
        category: payloadData?.category,
        subCategory: payloadData?.subCategory,
        userId: userId,
        tags: payloadData?.searchTagsList,
        skillsNeeded: skillPayloadData?.skillsNeeded,
        instruction: payloadData?.instruction,
        media: [
          {
            coverImage: payloadData?.coverPhoto,
            video: payloadData?.video,
            photo: payloadData?.profilePhoto,
            document: payloadData?.certificateFile,
          },
        ],
        paymentType: {
          type: skillPayloadData?.paymentType?.type,
          duration: skillPayloadData?.paymentType?.duration,
        },
        estimateBudget: {
          min: skillPayloadData?.estimateBudget?.min,
          max: skillPayloadData?.estimateBudget?.max,
        },
        projectBidTimings: {
          start: formatDateToDDMMYYYYC(
            skillPayloadData?.projectBidTimings?.start
          ),
          end: formatDateToDDMMYYYYC(skillPayloadData?.projectBidTimings?.end),
        },
        acceptedPaymentModes: skillPayloadData?.acceptedPaymentModes[0],
        serviceType: skillPayloadData?.serviceType,
        tier: skillPayloadData?.tier,
        status: "draft",
        projectStatus: "Open",
        projectId: type === "draft" ? projectData?.projectId : projectId,
      };

      // console.log(payload, "project post payload");

      // Make the API call
      const response = await ApiCall(
        "PUT",
        "project",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log("successful:", response.data);
        notify("success", "Project updated successfully!");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleskillChange = (e) => {
    setSkillPayloadData({ ...skillPayloadData, skills: e.target.value });
  };

  const handleDeleteSkill = (index) => {
    setSkillList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    const trimmedTag = skillPayloadData.skills.trim();

    if (e.key === "Enter" && trimmedTag) {
      e.preventDefault();

      if (!skillList.includes(trimmedTag)) {
        setSkillList((prev) => [...prev, trimmedTag]);
      }

      setSkillPayloadData({ ...skillPayloadData, skills: "" });
    }
  };

  useEffect(() => {
    setSkillPayloadData((prev) => ({ ...prev, skillsNeeded: skillList }));
  }, [skillList]);

  const handleMultiSelect = (field, value) => {
    setSkillPayloadData((prev) => {
      const isSelected = prev[field].includes(value);
      return {
        ...prev,
        [field]: isSelected
          ? prev[field].filter((item) => item !== value) // Remove if already selected
          : [...prev[field], value], // Add if not selected
      };
    });
  };
  const STEP = 10; // Increment steps
  const MIN = 0; // Minimum value
  const MAX = 1000; // Maximum value

  const handleRangeChange = (values) => {
    // console.log(values,"values");

    setSkillPayloadData((prev) => ({
      ...prev,
      estimateBudget: {
        min: values[0],
        max: values[1],
      },
    }));
  };

  const handlePaymentModeChange = (e) => {
    const selectedMode = e.target.value;

    // Update skillPayloadData with the selected payment mode
    setSkillPayloadData((prev) => ({
      ...prev,
      acceptedPaymentModes: selectedMode,
    }));
  };

  const handlePaymentTypeChange = (type) => {
    setSkillPayloadData((prev) => ({
      ...prev,
      paymentType: { ...prev.paymentType, type },
    }));
  };

  const handleDurationChange = (e) => {
    const duration = parseInt(e.target.value, 10) || 0;
    setSkillPayloadData((prev) => ({
      ...prev,
      paymentType: { ...prev.paymentType, duration },
    }));
  };

  const handlePaymentChange = (mode, acceptedModes) => {
    console.log(mode, acceptedModes, "mode, acceptedModes");
    setSkillPayloadData((prev) => ({
      ...prev,
      acceptedPaymentModes: acceptedModes,
    }));
    setSelectedPaymentMode(mode);

    // handleCloseModal(); // Close modal after selection
  };

  return (
    <>
      {!showProPrev ? (
        <>
          {isModalOpen && (
            <CustomModal
              isOpen={isModalOpen}
              modalSize="md"
              onClose={handleCloseModal}
            >
              <Payment type="project" onPaymentChange={handlePaymentChange} />
            </CustomModal>
          )}

          <section className="section_card create_project px-md-5">
            <Row className="mx-md-3 gy-4">
              <Col xs={12}>
                <div className="fs-4 fw-500 section-title">What skills are required?</div>
              </Col>

              <Form.Group as={Col} xs={12}>
                <Form.Label>Add skills that Freelancers should have to work on your project</Form.Label>
                <Form.Control type="text" placeholder="Select skills" value={skillPayloadData.skills} onChange={handleskillChange} onKeyDown={handleKeyDown} />
              </Form.Group>

              {skillList.length > 0 &&
                <Col xs={12}>
                  <Nav className="flex-row flex-wrap justify-content-center gap-2">
                    {skillList.map((tag, index) => (
                      <Button key={index} className="freela-btn-primary2" size="sm">
                        <span className="me-1">{tag}</span>
                        <i className="bi bi-x d-inline-block" onClick={() => handleDeleteSkill(index)}>
                        </i>
                      </Button>
                    ))}
                  </Nav>
                </Col>
              }

              <Col xs={12}>
                <div className="freela-fs-18px mb-2 fw-500">Select Your Tire</div>
                <Card as={Card.Body} className="rounded-5 p-4">
                  <Card.Text>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit, nesciunt nam? Dolor repudiandae nemo at autem molestias quos corporis blanditiis, dicta quam doloremque modi, quod minus assumenda architecto, possimus tempore?</Card.Text>
                  <Nav className="flex-row flex-wrap gap-2">
                    {[
                      "Beginner",
                      "Emerging",
                      "Standard",
                      "Proficient",
                      "Elite",
                    ].map((tier, idx) => (
                      <Button key={idx}
                        onClick={() => handleMultiSelect("tier", tier)}
                        className={`freela-btn-primary2 freela-bg-white ${skillPayloadData.tier.includes(tier) && 'active freela-bg-primary-200'}`}
                        size="sm">{tier}</Button>
                    ))}
                  </Nav>
                </Card>
              </Col>

              <Col xs={12}>
                <Row className="gy-3 align-items-end">
                  <Col lg={6} xl={6}>
                    <div className="freela-fs-18px mb-2 fw-500">How much do you want to pay each Freelancer to complete this task?</div>
                    <Card as={Card.Body} className="rounded-5 p-4">
                      <Card.Text>You may change this at any time.</Card.Text>
                      <Card.Title as="h6">Set Budget Range</Card.Title>

                      {/* Range Slider */}
                      <div className="py-4">
                        <Range
                          step={STEP}
                          min={MIN}
                          max={MAX}
                          values={[
                            skillPayloadData.estimateBudget.min,
                            skillPayloadData.estimateBudget.max,
                          ]}
                          onChange={handleRangeChange}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "6px",
                                width: "100%",
                                background: "#ddd",
                              }}
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props, index }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "16px",
                                width: "16px",
                                borderRadius: "50%",
                                backgroundColor: "#f0806c",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-28px",
                                  color: "#555",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  background: "white",
                                  padding: "2px 4px",
                                  borderRadius: "3px",
                                }}
                              >
                                {index === 0
                                  ? skillPayloadData.estimateBudget.min
                                  : skillPayloadData.estimateBudget.max}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <Stack direction="horizontal" className="gap-2">
                        <Form.Control className="w-100px" placeholder="" value={skillPayloadData.estimateBudget.min} />
                        <Form.Control className="w-100px ms-auto" placeholder="" value={skillPayloadData.estimateBudget.max} />
                      </Stack>
                    </Card>
                  </Col>
                  <Col lg={6} xl={6}>
                    <div className="freela-fs-18px mb-2 fw-500">Choose Payment Mode</div>
                    <Card as={Card.Body} className="rounded-5 p-4">
                      <Card.Text className="pb-lg-4 mb-lg-5">This is the currency your Freelancers will be paid in. You may change this at any time.</Card.Text>
                      <Form.Control type="text" className="mt-lg-2" placeholder="Select Payment Mode" value={selectedPaymentMode} onClick={handleOpenModal} readOnly />
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                <Row className="gy-3">
                  <Col lg={6} xl={6}>
                    <div className="freela-fs-18px mb-2 fw-500">Time to Complete</div>
                    <Card className="rounded-5 p-4">
                      <Card.Text>How much time do you want to give Freelancers to complete
                        your project? Please consider all of your requirements and
                        be sure to give your Freelancers plenty of time to work on
                        the project without being rushed.</Card.Text>
                      <Form.Group className="mt-2">
                        <Form.Label>Select</Form.Label>
                        <InputGroup>
                          <Form.Control
                            aria-label="Text input with dropdown button"
                            placeholder="Enter duration"
                            value={skillPayloadData.paymentType.duration}
                            onChange={handleDurationChange} // Update duration on input change
                          />
                          <DropdownButton
                            variant="outline-secondary rounded-5 rounded-start-0 freela-border-greylight8"
                            title={
                              skillPayloadData.paymentType.type
                                .charAt(0)
                                .toUpperCase() +
                              skillPayloadData.paymentType.type.slice(1)
                            } // Capitalize the type
                            id="input-group-dropdown-2"
                            align="end"
                            onSelect={handlePaymentTypeChange} // Update payment type on selection
                          >
                            <Dropdown.Item eventKey="Hours">Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="Days">Days</Dropdown.Item>
                            <Dropdown.Item eventKey="Weeks">Weeks</Dropdown.Item>
                            <Dropdown.Item eventKey="Months">Months</Dropdown.Item>
                            <Dropdown.Item eventKey="Years">Years</Dropdown.Item>
                          </DropdownButton>
                        </InputGroup>
                      </Form.Group>
                    </Card>
                  </Col>

                  <Col lg={6} xl={6}>
                    <div className="freela-fs-18px mb-2 fw-500">Biding Time </div>
                    <Card className="rounded-5 p-4">
                      <Card.Text className="mb-lg-5">Select bid start date and end date</Card.Text>
                      <Row className="gy-3 mt-lg-4 pt-lg-3">
                        {/* Starting from Field */}
                        <Form.Group as={Col} md={6} lg={6} xl={6}>
                          <Form.Label> Starting from <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="date" id="start"
                            placeholder="Starting from"
                            value={formatForInput(skillPayloadData.projectBidTimings.start)}
                            onChange={handleBidTimingChange}
                            required
                          />
                        </Form.Group>
                        {/* Ending Field */}
                        <Form.Group as={Col} md={6} lg={6} xl={6}>
                          <Form.Label>Ending <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="date" id="end"
                            placeholder="Ending"
                            value={formatForInput(skillPayloadData.projectBidTimings.end)}
                            onChange={handleBidTimingChange}
                            required
                          />
                        </Form.Group>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                <div className="freela-fs-18px mb-2 fw-500">Select Service Type</div>
                <Card as={Card.Body} className="rounded-5 p-4">
                  <Card.Text>
                    Lorem ipsum is a dummy or placeholder text commonly used in
                    graphic design, publishing, and web development to fill empty
                    spaces in a layout that do not yet have content.
                  </Card.Text>
                  <Nav className="flex-row flex-wrap gap-2">
                    {["Independent", "Agency", "Enterprise"].map(
                      (serviceType, idx) => (
                        <Button key={idx}
                          onClick={() =>
                            handleMultiSelect("serviceType", serviceType)
                          }
                          className={`freela-btn-primary2 freela-bg-white ${skillPayloadData.serviceType.includes(serviceType) && 'active freela-bg-primary-200'}`}
                          size="sm">{serviceType}</Button>
                      )
                    )}
                  </Nav>
                </Card>
              </Col>
              <Col xs={12}>
                <Stack direction="horizontal" className="justify-content-end gap-3 mt-4">
                  <Button className="freela-btn-primary-outline" onClick={() => handleBack(false)}>Back</Button>
                  <Button className="freela-btn-primary" onClick={() => handleProSkillNext(true)}> Save and Continue</Button>
                </Stack>
              </Col>
            </Row>
          </section>
        </>
      ) :
        <ProjectPreview
          payloadData={payloadData}
          skillPayloadData={skillPayloadData}
          handleNext={handleProSkillNext}
          videoSrc={videoSrc}
          userDetails={userDetails}
          projectId={projectId}
          userId={userId}
          certificateFile={certificateFile}
        />
      }
    </>
  );
};

export default ProjectSkills;
