import * as Yup from "yup";
const nameRegex = /^[A-Za-z]+$/; // Allows only alphabets
const phoneRegex = /^\d{10,15}$/; // Allows only 10-15 numerics 
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/; //Allows 1 uppercase, 1 lowercase, 1 numeric, and 1 special character
const otpRegex = /^\d{6}$/; // Allows only 6 numerics

export const registerValidationSchema = Yup.object({
  fname: Yup.string()
    .matches(nameRegex, "First name must contain only alphabets")
    .min(3, "First name must be at least 3 characters")
    .max(50, "First name cannot exceed 50 characters")
    .required("First name is required"),

  lname: Yup.string()
    .matches(nameRegex, "Last name must contain only alphabets")
    .min(3, "Last name must be at least 3 characters")
    .max(50, "Last name cannot exceed 50 characters")
    .required("Last name is required"),

  email: Yup.string().email("Invalid email").required("Email is required"),

  phone: Yup.string()
    .matches(phoneRegex, "Enter a valid mobile number")
    .required("Phone number is required"),

  password: Yup.string()
    .matches(passwordRegex, "Password must meet complexity requirements")
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),

  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),

  consent: Yup.boolean().oneOf([true], "You must accept the terms"),
});

export const loginValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),

  password: Yup.string()
    .matches(passwordRegex, "Password must meet complexity requirements")
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

export const registerVerifyValidationSchema = Yup.object({
  otp: Yup.string()
    .matches(otpRegex, "OTP must be exactly 6 digits")
    .required("OTP is required"),
});

export const forgotPwdValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
});
