import axios from 'axios';

// Base URL can be set up here
const API_BASE_URL = 'https://api.demo-thefreela.com/';
//const API_BASE_URL = 'http://localhost:4000/';
// const API_BASE_URL = 'http://52.0.157.137/'


const ApiCall = async (method, endpoint, data = {}, content,role, params = {} ) => {

  // console.log(role,"role");
  
  const token = localStorage.getItem("token"); 
  // console.log(token,"token");
  

  try {
    const response = await axios({
      method: method,                   // HTTP method (GET, POST, PUT, DELETE, etc.)
      url: `${API_BASE_URL}${endpoint}`, // Full URL with base URL and endpoint
      data: data,                       // Request body for POST/PUT methods
      params: params,                   // URL parameters for GET method
      headers: {
        'Content-Type': content,        
        'role':role,

        "Authorization": token
      },
    });

    return response.data; // Return the API response data
  } catch (error) {
    // Handle error
    console.error(`API call error: ${error}`);
    throw error;
  }
};

export default ApiCall;
