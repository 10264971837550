// Utility function to format date in 'DD-MM-YYYY' format
export const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
    const year = date.getFullYear();
    // return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;

  };
  
  // Utility function to format date for input in 'YYYY-MM-DD' format
  export const formatForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  

  export const formatDateToDDMMYYYY = (dateStr) => {
    console.log(dateStr,"dateStr");
    if(dateStr !== 'undefined'){

      const dateParts = dateStr?.split("-");
      // return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      // console.log(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`,"`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`");
      
      return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
    }
    
  }

  export const formatDateToDDMMYYYYC = (dateStr) => {
    console.log(dateStr, "dateStr");
    
    // Check if dateStr is a valid string and in the expected format
    if (typeof dateStr === 'string' && dateStr.includes("-")) {
      const dateParts = dateStr.split("-");
      
      // Ensure dateParts has exactly 3 elements (year, month, day)
      if (dateParts.length === 3) {
        return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Return DD-MM-YYYY format
      }
    }
  
    // Return empty string or handle invalid dateStr
    return '';
  };