// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-style {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
  
.image-style {
    width: 100%;
}
.card-height{
    height:80vh;
    margin:2rem;
}
.coming_soon{
    width: 90%;
    border-radius: 30px;  
   
    text-align: center;
    margin:auto;
    border: 0;
   /* box-shadow: 0px 20px 30px 0px #0000000D; */
    display: flex;
    align-items: center;
    justify-content:center;
    height: 100%;
}

.coming_soon h1{
    font-size: 70px;
    font-weight: 600;
    color: #181E4B;    
    font-family: Volkhov;
}
.coming_soon h1 span{
    color: #F0806C;
}

@media (max-width:1200px) {
    .card-height{
        height:45vh;
    }
}
@media (max-width:767px) {
    .card-height{
        height:auto;
    }
    .coming_soon h1{
        font-size: 50px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Components/LandingPage/Home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,UAAU;IACV,mBAAmB;;IAEnB,kBAAkB;IAClB,WAAW;IACX,SAAS;GACV,6CAA6C;IAC5C,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,oBAAoB;AACxB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;IACf;AACJ;AACA;IACI;QACI,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container-style {\r\n    width: 100%;\r\n    height: 100vh;\r\n    overflow: hidden;\r\n    position: relative;\r\n}\r\n  \r\n.image-style {\r\n    width: 100%;\r\n}\r\n.card-height{\r\n    height:80vh;\r\n    margin:2rem;\r\n}\r\n.coming_soon{\r\n    width: 90%;\r\n    border-radius: 30px;  \r\n   \r\n    text-align: center;\r\n    margin:auto;\r\n    border: 0;\r\n   /* box-shadow: 0px 20px 30px 0px #0000000D; */\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content:center;\r\n    height: 100%;\r\n}\r\n\r\n.coming_soon h1{\r\n    font-size: 70px;\r\n    font-weight: 600;\r\n    color: #181E4B;    \r\n    font-family: Volkhov;\r\n}\r\n.coming_soon h1 span{\r\n    color: #F0806C;\r\n}\r\n\r\n@media (max-width:1200px) {\r\n    .card-height{\r\n        height:45vh;\r\n    }\r\n}\r\n@media (max-width:767px) {\r\n    .card-height{\r\n        height:auto;\r\n    }\r\n    .coming_soon h1{\r\n        font-size: 50px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
