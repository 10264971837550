import React, { useState, useRef } from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import info from "../../../Images/icons/information.png";
import flag from "../../../Images/flag.png";
import love from "../../../Images/love_icon.png";
import online from "../../../Images/online_icon.png";
import refresh from "../../../Images/icons/refresh.png";
import clock from "../../../Images/icons/clock.png";
import frel from "../../../Images/payment_icons/frel.png";
import usd from "../../../Images/payment_icons/usd.png";
import inr from "../../../Images/payment_icons/inr.png";
import euro from "../../../Images/payment_icons/euro.png";
import pound from "../../../Images/payment_icons/pound.png";
import bitcoin from "../../../Images/payment_icons/bitcoin.png";
import litecoin from "../../../Images/payment_icons/litecoin.png";
import ethereum from "../../../Images/payment_icons/ethereum.png";
import usdt from "../../../Images/payment_icons/usdt.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import playBtn from "../../../Images/play_btn.png";
import "./Publish.css";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";
import ApiCall from "../../../Api/ApiCall";

const Publish = ({ serviceData, userDetails, onSave }) => {
  console.log(serviceData, "serviceData in Publish");
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    console.log("handlePlayPause");

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const userId = localStorage.getItem("userId");
  const { notify } = useToast();

  const handlePublish = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: serviceData?.serviceName,
        description: serviceData?.description,
        category: serviceData?.category,
        subCategory: serviceData?.subCategory,
        userId: userId,
        skills: serviceData?.searchTagsList,
        media: {
          video: serviceData?.video,
          image: serviceData?.images,
        },
        tiers: serviceData?.tiers,
        addons: serviceData?.addons,
        faqs: serviceData?.faqs,
        acceptedPaymentModes: serviceData?.acceptedPaymentModes[0],
        finalPrice: 0,
      };

      console.log(payload, "serive publish payload");

      // Make the API call
      const response = await ApiCall(
        "POST",
        "service/createService",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 201) {
        console.log("successful:", response.data);
        notify("success", "Service published successfully!");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div>
        <Row>
          <Col sm={12} md={12} lg={8} className="mt-5">
            {/* About service card */}
            <Card className="about_card">
              <div className="about_heading_sec">
                <h5>I will design UX and UI for your app</h5>
                <div className="d-flex align-items-center">
                  <img src={flag} alt="flag" />
                  <button type="button" className="grey-btn ms-3">
                    Save{" "}
                    <img
                      src={love}
                      alt="love"
                      width="18px"
                      height="18px"
                      className="ms-2"
                    />
                  </button>
                </div>
              </div>
              <div className="profile_sec mt-3">
                <div className="profile_img">
                  <img src={userDetails?.profile?.profilePic} alt="profile" />
                  <div className="profile_img_online">
                    <img src={online} alt="profile" />
                  </div>
                </div>
                <div className="ms-2">
                  <h6 className="mb-0">{userDetails?.firstName}</h6>
                  <label>Freelancer - UI UX Designer</label>
                </div>
              </div>
              <div className="mt-4">
                <h6>{serviceData?.serviceName}</h6>
                <p>{serviceData?.description}</p>
                <div>
                  <h6>Tags</h6>
                  <div className="tagList">
                    <ul className="justify-content-start ps-0">
                      {serviceData?.searchTagsList?.map((tag, index) => (
                        <li key={index}>{tag}</li> // Render each tag dynamically
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
            <Card className="image-caurosel mt-4">
              <div className="video-preview position-relative">
                {/* Video Preview */}
                <video
                  ref={videoRef}
                  src={serviceData?.video}
                  className="w-100" // Adjust size as needed, full width of the container
                  onClick={handlePlayPause} // Toggle play/pause on click
                  onEnded={() => setIsPlaying(false)} // Reset play state when video ends
                  style={{ cursor: "pointer" }}
                  controls={false} // Hide default controls
                />
                {/* Play/Pause Button */}
                <button
                  onClick={handlePlayPause}
                  className="play-button position-absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <div className="play_btn">
                    <img
                      src={isPlaying ? "pauseIcon" : playBtn}
                      alt={isPlaying ? "Pause" : "Play"}
                    />
                  </div>
                </button>
              </div>
            </Card>
            {/* FAQ Card */}
            <Card className="review_faq_card mt-4">
              <div>
                <h5>FAQ</h5>
              </div>

              <Accordion defaultActiveKey="0">
                {serviceData?.faqs?.map((item, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card>
            <Card className="extra_service_card">
              <div>
                <h5>Extra Service</h5>
              </div>
              {serviceData?.addons
                ?.filter((item) => item.addonStatus === true) // Filter only items with addonStatus === true
                ?.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <div className="d-flex w-50">
                        <label className="ms-2">
                          {item.addonTitle}
                          <img src={info} alt="info" className="ms-1" />
                        </label>
                      </div>
                      <div className="ms-4">
                        <InputGroup>
                          <Form.Control
                            aria-label="Dollar amount (with dot and two decimal places)"
                            value={item.addonPrice} // Use value to display the addon price
                            readOnly // To ensure the field is not editable
                          />
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup>
                      </div>
                    </li>
                  </ul>
                ))}
            </Card>
          </Col>
          <Col sm={12} md={12} lg={4} className="mt-5">
            <Card className="plan_card">
              <Tabs
                defaultActiveKey="Basic"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Basic" title="Basic" className="tab_one">
                  <div className="d-flex justify-content-between">
                    <h5>Basic Plan</h5>
                    <h5>{serviceData.tiers[0].price}</h5>
                  </div>
                  <p>{serviceData.tiers[0].description}</p>
                  <div className="mb-3">
                    <img src={clock} alt="clock" className="me-2" />
                    <label>
                      {serviceData.tiers[0].deliveryTime} Days Delivery
                    </label>
                    <img src={refresh} className="ms-3 me-2" alt="clock" />
                    <label>{serviceData.tiers[0].revisions} Revision</label>
                  </div>
                  <div>
                    <ul class="tick-list">
                      {Object.entries(serviceData.tiers[0].subSubcategory)
                        .filter(([key, value]) => value === true) // Only show items with 'true'
                        .map(([key, value]) => (
                          <li key={key} className="mb-1">
                            {key}
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="more_text">
                    <a>View Extra Services</a>
                  </div>
                </Tab>
                <Tab eventKey="Standard" title="Standard">
                  <div className="d-flex justify-content-between">
                    <h5>Standard Plan</h5>
                    <h5>{serviceData.tiers[1].price}</h5>
                  </div>
                  <p>{serviceData.tiers[1].description}</p>
                  <div className="mb-3">
                    <img src={clock} alt="clock" className="me-2" />
                    <label>
                      {serviceData.tiers[1].deliveryTime} Days Delivery
                    </label>
                    <img src={refresh} className="ms-3 me-2" alt="clock" />
                    <label>{serviceData.tiers[1].revisions} Revision</label>
                  </div>
                  <div>
                    <ul class="tick-list">
                      {Object.entries(serviceData.tiers[1].subSubcategory)
                        .filter(([key, value]) => value === true) // Only show items with 'true'
                        .map(([key, value]) => (
                          <li key={key} className="mb-1">
                            {key}
                          </li>
                        ))}
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="Premium" title="Premium" className="tab_three">
                  <div className="d-flex justify-content-between">
                    <h5>Premium Plan</h5>
                    <h5>{serviceData.tiers[2].price}</h5>
                  </div>
                  <p>{serviceData.tiers[2].description}</p>
                  <div className="mb-3">
                    <img src={clock} alt="clock" className="me-2" />
                    <label>
                      {serviceData.tiers[2].deliveryTime} Days Delivery
                    </label>
                    <img src={refresh} className="ms-3 me-2" alt="clock" />
                    <label>{serviceData.tiers[2].revisions} Revision</label>
                  </div>
                  <div>
                    <ul class="tick-list">
                      {Object.entries(serviceData.tiers[2].subSubcategory)
                        .filter(([key, value]) => value === true) // Only show items with 'true'
                        .map(([key, value]) => (
                          <li key={key} className="mb-1">
                            {key}
                          </li>
                        ))}
                    </ul>
                  </div>
                </Tab>
              </Tabs>
            </Card>
            <Card className="payment_coins_card p-4 mt-3">
              <h5>Payment Coins Accepted</h5>

              <div className="mt-3">
                {/* Render Freelcoin payment mode */}
                {serviceData?.acceptedPaymentModes[0]?.freelcoin && (
                  <img src={frel} alt="freelcoin" className="me-3" />
                )}

                {/* Render Traditional payment modes */}
                {serviceData?.acceptedPaymentModes[0]?.traditional?.usd && (
                  <img src={usd} alt="usd" className="me-3" />
                )}
                {serviceData?.acceptedPaymentModes[0]?.traditional?.inr && (
                  <img src={inr} alt="inr" className="me-3" />
                )}
                {serviceData?.acceptedPaymentModes[0]?.traditional?.euro && (
                  <img src={euro} alt="euro" className="me-3" />
                )}
                {serviceData?.acceptedPaymentModes[0]?.traditional?.pound && (
                  <img src={pound} alt="pound" className="me-3" />
                )}

                {/* Render Other payment modes */}
                {serviceData?.acceptedPaymentModes[0]?.other?.bitcoin && (
                  <img src={bitcoin} alt="bitcoin" className="me-3" />
                )}
                {serviceData?.acceptedPaymentModes[0]?.other?.litecoin && (
                  <img src={litecoin} alt="litecoin" className="me-3" />
                )}
                {serviceData?.acceptedPaymentModes[0]?.other?.ethereum && (
                  <img src={ethereum} alt="ethereum" className="me-3" />
                )}
                {serviceData?.acceptedPaymentModes[0]?.other?.usdt && (
                  <img src={usdt} alt="usdt" className="me-3" />
                )}
              </div>
            </Card>
            <div className="text-center mt-3">
              <button type="button" className="secondary-btn me-2" onClick={onSave}>
                Back
              </button>
              <button
                type="button"
                className="primary-btn ms-2"
                onClick={handlePublish}
              >
                Publish
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Publish;
