import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Card, Button, Image, Stack } from "react-bootstrap";
import MyBids from "./MyBids";
import SavedBids from "./SavedBids";
import useToast from "../../../../Hooks/useToast";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import Loader from "../../../Common/Loader/Loader";
import { formatDateToDDMMYYYYC } from "../../../Common/utils/utils";
import { ReactComponent as USDollerIcon } from "../../../../Images/freela-icons/us-doller-icon.svg";
import { ReactComponent as ProfileVerifyIcon } from "../../../../Images/freela-icons/verify-shield-icon.svg";
import DefaultProfileImg from "../../../../Images/freela-images/profile.png";

const MyProposals = ({ role }) => {
  const [activeButton, setActiveButton] = useState("myBids"); // Default to "My Bids"

  const [loading, setLoading] = useState(false);
  const [proposalDetails, setProposalDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");

  const debouncedGetProposalDetails = debounce(() => {
    getProposalDetails();
  }, 300);

  useEffect(() => {
    if (role === "freelancer") {
      debouncedGetProposalDetails();
    }
    return () => debouncedGetProposalDetails.cancel();
  }, [role]);

  const getProposalDetails = async () => {
    setLoading(true);
    setProposalDetails([]);
    try {
      const endpoint = role === "freelancer" ? `bid/user?userId=${userId}&role=${role} ` : ``;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setProposalDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const getProposalDetailsByStage = async (stage) => {
    setLoading(true);
    setProposalDetails([]);
    try {
      const endpoint = `bid/filterByStatus?freelancerId=${userId}&stage=${stage}`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setProposalDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  // console.log(proposalDetails, "proposalDetails");

  const renderClientContent = () => {
    switch (activeButton) {
      case "myBids":
        return (
          <div>
            <MyBids />
          </div>
        );
      case "published":
        return (
          <div>
            <SavedBids />
          </div>
        );
      default:
        return <div>No content available</div>;
    }
  };

  const renderFreelancerContent = () => {
    return (
      <div className="my_proposals">
        <Row className="gy-3 align-items-center mb-4">
          <Col lg={9} xl={9} xxl={10}>
            <h3>My Proposals</h3>
            <p className="mb-0">Eius molestiae porro tempora dolores repellendus architecto qui eaque tempore illum incidunt dolor vel velit, similique iusto nulla rem. Repellat, distinctio maxime?</p>
          </Col>
          <Col lg={3} xl={3} xxl={2}>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                const stage = e.target.value;
                if (stage !== "Select") {
                  getProposalDetailsByStage(stage);
                }
                else {
                  getProposalDetails()
                }
              }}
            >
              <option>Select</option>
              <option value="Accepted">Accepted</option>
              <option value="InProgress">Inprogress</option>
              <option value="Rejected">Rejected</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="gy-4">
          {proposalDetails && proposalDetails.length > 0 ? (
            proposalDetails.map((item, index) => (
              <Col md={6} lg={6} xl={4} key={index}>
                <Card as={Card.Body} className="single_proposal rounded-5 h-100">
                  <Row className="align-items-center g-0">
                    <Col xs={8}>
                      <Row className="align-items-center g-2 flex-nowrap mb-0">
                        <Col xs="auto">
                          <div className="freelance_client_img position-relative">
                            <Image src={item?.profilePhoto ? item.profilePhoto : DefaultProfileImg} width={45} height={45} roundedCircle alt="profile" className="p-05 object-fit-cover border-1 border-solid freela-border-primary" />
                            <ProfileVerifyIcon width={14} height={14} className="position-absolute bottom-0 end-0" />
                          </div>
                        </Col>
                        <Col>
                          <h1 className="freela-fs-16px mb-0 freela-text-black freela-w-70 text-truncate">{item.firstName}</h1>
                          <p className="freela-fs-12px m-0 text-truncate">United States</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4}>
                      <Stack direction="vertical" className="justify-content-end gap-1">
                        <div className="freela-text-greaseblack freela-fs-12px">
                          Bid Date: {formatDateToDDMMYYYYC(item.deliveryDate)}
                        </div>
                        <div className="freela-text-greaseblack freela-fs-12px">
                          Status: <span className={
                            item.stage === 'accepted' ? 'freela-text-success' :
                              item.stage === 'inProgress' ? 'freela-text-warning' :
                                item.stage === 'rejected' ? 'freela-text-danger' : ''
                          }>
                            {item.stage}
                          </span>
                        </div>
                      </Stack>
                    </Col>
                  </Row>
                  <div className="content cursor-pointer my-2">
                    <Card.Title className="mb-1 freela-fs-18px text-truncate">{item.title}</Card.Title>
                    <Card.Text className="mb-0 freelance_client__description freela-text-greaseblack text_truncate2">{item.message}</Card.Text>
                  </div>
                  <Stack direction="horizontal">
                    <div className="paid lh-1">Paid in <br /> US Dollar <USDollerIcon /></div>
                    <div className="ms-auto">
                      <div className="project_budget">Project Budget</div>
                      <div className="freela-text-primary fw-500">${item.bidAmount}/hour</div>
                    </div>
                  </Stack>
                </Card>
              </Col>
            ))
          ) :
            <Col xs={12}>No Proposals available</Col>
          }
        </Row>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <div className="freela_horizontal_tabs">
        {role === "client" ?
          <>
            <div className="tab_lists pills pt-0">
              {['myBids', 'published'].map((list, idx) => (
                <Button key={idx}
                  onClick={() => setActiveButton(list)}
                  className={activeButton === list && "active"}
                >
                  {list === 'myBids' ? 'My Bids' : list === 'published' ? 'Saved Bids' : ''}
                </Button>
              ))}
            </div>
            <div className="mt-3">{renderClientContent()}</div>
          </>
          :
          <div className="mt-3">{renderFreelancerContent()}</div>}
      </div>
    </>
  );
};

export default MyProposals;
