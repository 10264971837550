import React from 'react'

const MyDisputes = () => {
  return (
    <div>
      MyDisputes
    </div>
  )
}

export default MyDisputes
