// Utility function to format date in 'DD-MM-YYYY' format
export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const year = date.getFullYear();
  // return `${day}-${month}-${year}`;
  return `${year}-${month}-${day}`;
};

// Utility function to format date for input in 'YYYY-MM-DD' format
export const formatForInput = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDateToDDMMYYYY = (dateStr) => {
  // console.log(dateStr,"dateStr");
  if (dateStr !== "undefined") {
    const dateParts = dateStr?.split("-");
    // return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    // console.log(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`,"`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`");

    return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
  }
};

export const formatDateToDDMMYYYYC = (dateStr) => {
  console.log(dateStr, "dateStr");

  // Check if dateStr is a valid string and in the expected format
  if (typeof dateStr === "string" && dateStr.includes("-")) {
    const dateParts = dateStr.split("-");

    // Ensure dateParts has exactly 3 elements (year, month, day)
    if (dateParts.length === 3) {
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Return DD-MM-YYYY format
    }
  }

  // Return empty string or handle invalid dateStr
  return "";
};

export const calculateDaysDifference = (startDate, endDate) => {
  // console.log(startDate,endDate);

  // Parse the input dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const differenceInMs = end - start;

  // Convert milliseconds to days
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  return differenceInDays;
};

export const getCurrentDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
};

export const calculateRemainingDays = (targetDate) => {
  const currentDate = new Date();
  const endDate = new Date(targetDate);
  // console.log(currentDate,endDate);
  // Calculate the difference in milliseconds
  const diffInMilliseconds = endDate - currentDate;

  // Convert milliseconds to days
  const remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  return remainingDays >= 0 ? remainingDays : 0; // Return 0 if the date has passed
};

export const timeAgo = (dateString) => {
  const now = new Date();
  const past = new Date(dateString);
  const diffInSeconds = Math.floor((now - past) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
  };

  for (const [unit, seconds] of Object.entries(intervals)) {
    const count = Math.floor(diffInSeconds / seconds);
    if (count >= 1) {
      return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
    }
  }

  return "Just now";
};


export const formatAmPmTime = (isoString) => {
  if (!isoString) return "Invalid Date"; // Handle empty input

  const date = new Date(isoString);
  if (isNaN(date.getTime())) return "Invalid Date"; // Ensure valid date parsing

  let hours = date.getUTCHours(); // Get UTC hours
  const minutes = date.getUTCMinutes(); // Get UTC minutes
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
};


export const formatDate = (isoString) => {  //TZ to DD-MM-YYYY function
  if (!isoString) return "Invalid Date"; 

  const date = new Date(isoString);
  if (isNaN(date.getTime())) return "Invalid Date"; // Ensure valid date parsing

  const day = date.getUTCDate().toString().padStart(2, "0"); // Get UTC day
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Get UTC month (0-based)
  const year = date.getUTCFullYear(); // Get UTC year

  return `${day}-${month}-${year}`;
};


export const calculateDueTime = (isoString) => {  //TZ to HH:MM:SS
  const dueDate = new Date(isoString);
  const now = new Date();

  let diff = Math.max(0, Math.floor((dueDate - now) / 1000)); // Difference in seconds

  const hours = Math.floor(diff / 3600);
  diff %= 3600;
  const minutes = Math.floor(diff / 60);
  const seconds = diff % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

