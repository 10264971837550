import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as FreelaLogo } from "../../../Images/freela-icons/freela-logo.svg";
import { ReactComponent as FreelaShortLogo } from "../../../Images/freela-icons/logo.svg";
import { ReactComponent as NotificationIcon } from "../../../Images/freela-icons/notification-icon.svg";
import profilePic from "../../../Images/freela-images/profile.png"; // Profile picture
import { useAuth } from "../../../Context/AuthContext";
import {
  Navbar,
  Container,
  Button,
  Dropdown,
  Image,
  Stack,
  Form,
} from "react-bootstrap";
import ServiceModal from "../ServiceModal";
import CustomModal from "../Modal/CustomModal";
import useToast from "../../../Hooks/useToast";
import ApiCall from "../../../Api/ApiCall";

export const TopNav = ({ userDetails, role, setRole, offcanvasShow }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();
  const [availability, setAvailability] = useState(false);

  const handleShowModal = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false); // Close the modal
  };

  const handleRoleSelection = (selectedRole) => {
    // console.log(selectedRole,"selectedRole");
    setRole(selectedRole); // Update the role
    setModalOpen(false); // Close the modal

    // Navigate based on the selected role
    if (selectedRole === "freelancer") {
      navigate("/create-service");
    } else {
      navigate("/create-project");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
  };

  const handleToggle = async (e) => {
    const newAvailability = e.target.checked;
    setAvailability(newAvailability);
    setLoading(true);
    try {
      const payload = { availability: newAvailability };

      const response = await ApiCall(
        "POST",
        "user/updateMetrics",
        payload,
        "application/json"
      );

      if (response.statusCode === 200 || response.statusCode === 201) {
        console.log("Successful:", response.data);
        notify("success", "Availability updated successfully!");
      } else {
        notify("error", response.message || "Update failed. Please try again.");
      }
    } catch (error) {
      notify(
        "error",
        error?.response?.data?.message || "Something went wrong."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Navbar className="header-bg shadow-sm top-0 start-0 py-1" sticky="top">
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/settings" className="outline-none">
          <FreelaLogo
            className="d-none d-md-inline-block"
            width={225}
            height={52}
          />
          <FreelaShortLogo
            className="d-md-none d-inline-block"
            width={52}
            height={52}
          />
        </Navbar.Brand>

        <Stack direction="horizontal" className="gap-3 ms-auto">
          <Button
            variant=""
            className="freela-btn-primary d-sm-inline-block d-none"
            onClick={handleShowModal}
          >
            {role === "freelancer" ? "Create Service" : "Create Project"}
          </Button>

          <Button
            variant=""
            style={{ height: 42, width: 42 }}
            className="freela-notification p-0 d-flex align-items-center justify-content-center rounded-circle position-relative"
          >
            <NotificationIcon width={25} />{" "}
            <span className="position-absolute top-0 start-100 mt-2 translate-middle badge border border-light rounded-circle bg-danger p-1">
              <span class="visually-hidden">unread messages</span>
            </span>
          </Button>

          <Dropdown>
            <Dropdown.Toggle
              className="freela-btn-primary-outline bg-transparent p-1 pe-2"
              variant=""
              id="dropdown-basic"
            >
              <Image
                src={userDetails?.profile.profilePic || profilePic}
                alt="Profile"
                className="rounded-circle"
                style={{ height: 32, width: 32 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="border-0 shadow-lg rounded-4">
              <Dropdown.Header className="text-truncate w-160px fs-6 text-dark py-0">
                {userDetails?.firstName}
              </Dropdown.Header>
              <Dropdown.Divider />
              <div className="px-2">
                {/* <Form.Check reverse={true} type="switch" id="custom-switch">
                  <Form.Check.Input />
                  <Form.Check.Label className="opacity-50">
                    Availability
                  </Form.Check.Label>
                </Form.Check> */}
                <Form.Group className="d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    reverse={true}
                    className="ms-2"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      checked={availability}
                      disabled={loading}
                      onChange={handleToggle} // Ensure it's attached here
                    />
                    <Form.Check.Label className="opacity-50 ms-2">
                      {loading ? "Updating..." : "Availability"}
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>

                <Dropdown.Item className="rounded-3" href="profile-preview">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="rounded-3" href="/settings">
                  Settings
                </Dropdown.Item>
                <Dropdown.Item
                  className="rounded-3"
                  onClick={handleLogout}
                  href="/"
                >
                  Logout
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Button
            variant="link"
            className="d-xl-none d-inline-block freela-text-primary p-0"
            onClick={offcanvasShow}
          >
            <i className="bi bi-three-dots-vertical fs-5" />
          </Button>
          {/* ServiceModal */}
          {isModalOpen && (
            <CustomModal
              isOpen={isModalOpen}
              modalSize="md"
              onClose={handleModalClose}
            >
              <ServiceModal
                onRoleSelect={handleRoleSelection}
                onClose={handleModalClose}
              />
            </CustomModal>
          )}
        </Stack>
      </Container>
    </Navbar>
  );
};
