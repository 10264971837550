// Layout.js
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {Container, Row, Col, Card} from "react-bootstrap"
import "./Layout.css"; // Optional: Styles for layout
import TopNav from "../TopNav/TopNav";
import Sidebar from "../Sidebar/Sidebar";

const Layout = ({ userDetails, role, setRole }) => {
  const location = useLocation();

  // Check if the current route is the ProfilePreview route
  const isProfilePreview = location.pathname === "/profile-preview";
  // console.log(isProfilePreview,"isProfilePreview");
  

  return (
    <>
    <div className="layout-container">
      <TopNav userDetails={userDetails} role={role} setRole={setRole} />{" "}
      </div>
    <Container fluid>
      
      <Row>
        <Col xs={3} sm={2} md={4} lg={3} xl={3} xxl={2} className="SideNavWidth my-4 mx-0">
        <Sidebar /> {/*Common Left Navigation */}
        </Col>
        <Col xs={9} sm={10} md={8} lg={9} xl={9} xxl={10} className="right_sec_width">
        <Card className="right_sec">
          <div className={`page-content ${isProfilePreview ? "no-padding" : ""}`}>
            <Outlet /> {/* Renders child routes here */}
          </div>
        </Card>
        </Col>
      </Row>
    </Container>
    </>
    
  );
};

export default Layout;
