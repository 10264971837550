import React, { useState } from "react";
import useCommonNavigate from '../../../Hooks/useCommonNavigate';
import "./WelcomeLayout.css"; // Import the CSS file
import freelancerImage from "../../../Images/freelancer.png";
import clientImage from "../../../Images/client.png"; // Path to client image
// import arrowRightCircle from "../../../Images/icons/Arrow-right-circle.svg";
// import backArrowCircleSvg from "../../../Images/icons/backArrow-circle.svg";
import arrow from "../../../Images/arrow.png"
import arrow_filled from "../../../Images/arrow_filled.png"
import {Container, Row, Col, Card} from "react-bootstrap";

const WelcomeLayout = ({role, setRole}) => {

  const { commonNavigate } = useCommonNavigate(); // Get the common navigate function

  const handleNavigate = (option) => {
    setRole(option); 
    commonNavigate("/profile");
  };

  const [isHovered, setIsHovered] = useState({ freelancer: false, client: false });

  const handleMouseEnter = (option) => {
    setIsHovered((prev) => ({ ...prev, [option]: true }));
  };

  const handleMouseLeave = (option) => {
    setIsHovered((prev) => ({ ...prev, [option]: false }));
  };

  // console.log(isHovered.client, "isHovered.client", isHovered.freelancer, "isHovered.freelancer");


  return (
    <>
 <Container className="welcome_layout">
  <div className="text-center">
    <h1>Welcome to Freela!</h1>
    <h5>Your journey to freedom starts here!</h5>
    <h5>What brings you to Freela today?</h5>
  </div>
{/* tab to big devices code */}
<div className="d-none d-md-block">
-  <Row className="justify-content-center mb-4">
    <Col sm={12} md={12} lg={6} xl={6} xxl={5}  className="px-0">
    <div className="box-outline" onClick={() => handleNavigate('freelancer')} onMouseEnter={() => handleMouseEnter('freelancer')}
          onMouseLeave={() => handleMouseLeave('freelancer')}>
      <div className="content-box position-relative">
      <div className="arrow_icon">
       <a><img src={arrow} alt="imager" className="outline"/></a>
       <a><img src={arrow_filled} alt="imager" className="filled"/></a>
      </div>
      <div className="option-image-sec">
      <img src={freelancerImage} alt="Freelancer"className="option-image"/>
      
      </div>
      <div>
        <h2>I’m a Freelancer</h2>
        <p>Ready to showcase my skills, win projects, and work on my own terms!</p>
      </div>
    </div>
  </div>
      
    </Col>
    <Col sm={12} md={12} lg={6} xl={6} xxl={5} className="px-0">
    <div className="box-outline" onClick={() => handleNavigate('client')} onMouseEnter={() => handleMouseEnter('client')}
        onMouseLeave={() => handleMouseLeave('client')}>
      <div className="content-box position-relative">
      <div className="arrow_icon">
       <a><img src={arrow} alt="imager" className="outline"/></a>
       <a><img src={arrow_filled} alt="imager" className="filled"/></a>
      </div>
      <div className="option-image-sec">
      <img src={clientImage} alt="Client" className="option-image"/>      
      </div>
      <div>
        <h2>I’m a Client</h2>
        <p> Looking to hire amazing talent and get my project done,
        hassle-free!</p>
      </div>
    </div>
  </div>
    
    </Col>
  </Row>
  </div>

{/* Mobile Code */}
<div className="d-md-none">
  <Row >
    <Col sm={12} md={6} lg={6} className="px-0">
    <Card className="welcome_card" onClick={() => handleNavigate('freelancer')} onMouseEnter={() => handleMouseEnter('freelancer')}
          onMouseLeave={() => handleMouseLeave('freelancer')}>
      <div className="content-box position-relative">
      {/* <div className="arrow_icon">
       <a><img src={arrow} alt="imager"/></a>
      </div> */}
      <div className="option-image-sec">
      <img src={freelancerImage} alt="Freelancer"className="option-image"/>
      
      </div>
      <div>
        <h2>I’m a Freelancer</h2>
        <p>Ready to showcase my skills, win projects, and work on my own terms!</p>
      </div>
    </div>
  </Card>      
    </Col>
    <Col sm={12} md={12} lg={6} className="px-0">
    <Card className="welcome_card" onClick={() => handleNavigate('client')} onMouseEnter={() => handleMouseEnter('client')}
        onMouseLeave={() => handleMouseLeave('client')}>
      <div className="content-box position-relative">
      {/* <div className="arrow_icon">
       <a><img src={arrow} alt="imager"/></a>
      </div> */}
      <div className="option-image-sec">
      <img src={clientImage} alt="Client" className="option-image"/>      
      </div>
      <div>
        <h2>I’m a Client</h2>
        <p> Looking to hire amazing talent and get my project done,
        hassle-free!</p>
      </div>
    </div>
  </Card>
    
    </Col>
  </Row>
  </div>
 </Container>
   
    </>
  );
};

export default WelcomeLayout;
