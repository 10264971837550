import React from 'react';
import { Form } from 'react-bootstrap';

const DateInput = ({ id, name, value, onChange, className, placeholder, required }) => {
  return (
    <Form.Control
      type="date"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={className || 'date-input'}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default DateInput;
