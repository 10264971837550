import React, { useState, useRef, useEffect } from "react";
import "./PortfolioTab.css";
import addIcon from "../../../../Images/icons/experience-icon.png";
import deleteIcon from "../../../../Images/icons/delete-icon.png";
import uploadIconSvg from "../../../../Images/icons/upload.svg";
import pdfIcon from "../../../../Images/icons/pdf-icon.svg";
import downloadIcon from "../../../../Images/icons/download-icon.svg";
import trashIcon from "../../../../Images/icons/trash-icon.svg";
import ApiCall from "../../../../Api/ApiCall";
import Loader from "../../../Common/Loader/Loader";
import { Card } from "react-bootstrap";

const PortfolioTab = ({ userDetails, onGetData }) => {
  const [portfolioRows, setPortfolioRows] = useState([
    {
      project: "",
      type: "",
      role: "",
      description: "",
      portfolioFile: null,
      behance: "",
      upwork: "",
    },
  ]);

  const portfolioInputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    if (
      userDetails?.freelancerSettings?.portfolio &&
      userDetails?.freelancerSettings?.portfolio.length > 0
    ) {
      const extractedFileUrls = userDetails?.freelancerSettings?.portfolio.map(
        (portfolio) => portfolio.fileUpload || []
      );

      // console.log(extractedFileUrls,"extractedFileUrls");
      
      setFileUrls(extractedFileUrls);

      const formattedPortfolio = userDetails?.freelancerSettings?.portfolio.map(
        (portfolio, index) => {
          return {
            project: portfolio.projectName,
            type: portfolio.type,
            role: portfolio.role,
            description: portfolio.description,
            portfolioFile:
              portfolio.fileUpload && portfolio.fileUpload.length > 0
                ? portfolio.fileUpload
                : "",
            behance: portfolio.behance,
            upwork: portfolio.upwork,
          };
        }
      );
      // console.log(formattedPortfolio,"formattedPortfolio");
      

      setPortfolioRows(formattedPortfolio);
    }
  }, [userDetails]);
  // console.log(portfolioRows,"portfolioRows", fileUrls,"fileUrls");
  

  useEffect(() => {
    const formattedData = portfolioRows.map((row, index) => ({
      projectName: row.project,
      type: row.type,
      role: row.role,
      description: row.description,

      behance: row.behance,
      upwork: row.upwork,
      fileUpload:
        fileUrls[index] !== undefined || fileUrls[index] !== null
          ? fileUrls[index]
          : row.portfolioFile || "",
    }));

    onGetData({ portfolio: formattedData });
  }, [portfolioRows, fileUrls]);

  const handleAddRow = () => {
    setPortfolioRows((prevRows) => [
      ...prevRows,
      {
        project: "",
        type: "",
        role: "",
        description: "",
        portfolioFile: "",
        behance: "",
        upwork: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = portfolioRows.filter((_, i) => i !== index);
    setPortfolioRows(updatedRows);
    portfolioInputRefs.current.splice(index, 1);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...portfolioRows];
    updatedRows[index][field] = value;
    setPortfolioRows(updatedRows);
  };

  // const handlePhotoChange = (e, index) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const updatedRows = [...portfolioRows];
  //     updatedRows[index].portfolioFile = file;
  //     setPortfolioRows(updatedRows);
  //   }
  // };

  const handlePhotoChange = async (e, index) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          const updatedRows = [...portfolioRows];
          updatedRows[index].portfolioFile = file;
          setPortfolioRows(updatedRows);

          setFileUrls((prevUrls) => {
            const updatedUrls = [...prevUrls];
            updatedUrls[index] = response.data.s3Url;
            return updatedUrls;
          });
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadClick = (index) => {
    portfolioInputRefs.current[index].click(); // Trigger file input click for a specific row
  };

  const handleDownload = (index) => {
    // console.log(portfolioRows,"portfolioRows");
    
    const fileURL = portfolioRows[index].portfolioFile;
    // console.log(fileURL,"fileURL");
    if (typeof fileURL === "string" && fileURL) {
      const link = document.createElement("a");
      link.href = fileURL;
      link.target = "_blank";
      link.download = fileURL.split("/").pop();
      link.click();
    } else {
      console.error("No file URL available for download");
    }
  };

 
  

  const handleDelete = (e, index) => {
    const updatedRows = [...portfolioRows];
    // console.log(updatedRows,"updatedRows");
    
    updatedRows[index].portfolioFile = '';
    setPortfolioRows(updatedRows);

    const updatedFileUrls = [...fileUrls];
    updatedFileUrls[index] = "";

    setFileUrls(updatedFileUrls);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="education-section">
        <div className="education-header">
          <div className="education-header-left">
            <img src={addIcon} alt="Add Icon" />
            <h2>Kindly include your portfolio here.</h2>
          </div>
          <div className="education-header-right">
            <button className="add-button" onClick={handleAddRow}>
              Add
            </button>
          </div>
        </div>
        {portfolioRows.map((row, index) => (
          <div key={index}>
            <div className="responsive-row">
            {/* Dotted Partition (for all rows except the first one) */}
            {index > 0 && (
              <div className="custom-hr"></div>
              // <div className="partition-line">
              //   {" "}
                
              // </div>
            )}

            {/* First row for project details */}
         
              <div className="input-group">
                <label htmlFor={`project-${index}`}>
                  {" "}
                  Project Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id={`project-${index}`}
                  value={row.project}
                  onChange={(e) =>
                    handleChange(index, "project", e.target.value)
                  }
                  placeholder="Project Name"
                />
              </div>

              <div className="input-group">
                <label htmlFor={`type-${index}`}>
                  Type<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id={`type-${index}`}
                  value={row.type}
                  onChange={(e) => handleChange(index, "type", e.target.value)}
                  placeholder="Type"
                />
              </div>

              <div className="input-group">
                <label htmlFor={`type-${index}`}>
                  Role<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id={`role-${index}`}
                  value={row.role}
                  onChange={(e) => handleChange(index, "role", e.target.value)}
                  placeholder="Role"
                />
              </div>

              {/* Delete Button */}
              <button
                className="delete-button"
                onClick={() => handleDeleteRow(index)}
              >
                <img src={deleteIcon} alt="Delete Icon" />
              </button>
      
            </div>

            {/* Second row for description */}
     
              <div className="input-group des_sec">
                <label className="mb-1" htmlFor={`description-${index}`}>Description</label>
                <textarea
                  id={`description-${index}`}
                  value={row.description}
                  onChange={(e) =>
                    handleChange(index, "description", e.target.value)
                  }
                  placeholder="Brief description about your project"
                  className="description-textarea"
                />
              </div>
       

            {/* Third row for certificate file upload */}
          
              <Card className="upload_doc_card mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  {row.portfolioFile ? (
                    <div className="file-preview">
                      <div className="file-details">
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          className="file-icon"
                        />
                        <div className="file-info">
                          <p className="file-title">{row.portfolioFile.name}</p>
                          <p className="file-meta">
                            {row.portfolioFile.size} KB ·{" "}
                            {new Date(
                              row.portfolioFile.lastModified
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <div className="file-actions">
                        <button onClick={() => handleDownload(index)}>
                          <img src={downloadIcon} alt="Download Icon" />
                        </button>
                        <button
                          onClick={(e) => {
                            if (
                              window.confirm("Do you want to delete this file?")
                            )
                              handleDelete(e, index);
                          }}
                        >
                          <img src={trashIcon} alt="Delete Icon" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                     <div className="d-flex align-items-center">
                      <img src={uploadIconSvg} alt="Upload Icon" className="upload_image" />
                      <p className="mb-0 ms-3">Please upload your Certificates</p>
                      </div>
                    </>
                  )}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    ref={(el) => (portfolioInputRefs.current[index] = el)}
                    style={{ display: "none" }}
                    onChange={(e) => handlePhotoChange(e, index)}
                  />

                  <button
                    className="secondary-btn"
                    onClick={() => handleUploadClick(index)}
                  >
                    Choose
                  </button>
                </div>
              </Card>
          

              <div className="input-group portfolio_links">
                <label className="mb-1" htmlFor={`behance-${index}`}>Behance</label>
                <textarea
                  id={`behance-${index}`}
                  value={row.behance}
                  onChange={(e) =>
                    handleChange(index, "behance", e.target.value)
                  }
                  placeholder=" https://yourlink"
                  className="portfolio-textarea"
                  rows={1}
                />
              </div>
          

           
              <div className="input-group portfolio_links">
                <label className="mb-1" htmlFor={`upwork-${index}`}>Upwork</label>
                <textarea
                  id={`upwork-${index}`}
                  value={row.upwork}
                  rows={1}
                  onChange={(e) =>
                    handleChange(index, "upwork", e.target.value)
                  }
                  placeholder=" https://yourlink"
                  className="portfolio-textarea"
                />
              </div>
            </div>
       
        ))}
      </div>
    </>
  );
};

export default PortfolioTab;
