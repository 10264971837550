import React, { useState } from "react";
import "./ClientProfileCreation.css";
import backArrowCircleSvg from "../../../../Images/icons/backArrow-circle.svg";
import About from "../../Profile/AboutTab/AboutTab";

import Experience from "../../ClientProfile/ExperienceTab/ExperienceTab";

import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";


const KYCCreation = () => <div>KYC Creation Section Content</div>;


const ClientProfileCreation = ({ handleBackArrow, userDetails, getUserDetails }) => {

  const userId = localStorage.getItem("userId");
  const { notify } = useToast();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [aboutData, setAboutData] = useState({}); 
  const [expData, setExpData] = useState({});

 const [loading, setLoading] = useState(false);



  const tabs = [
    { label: "About", component: <About userDetails={userDetails} onGetData={(data) => setAboutData(data)} /> },
    { label: "Experience", component: <Experience userDetails={userDetails} onGetData={(data) => setExpData(data)} /> },
    { label: "KYC Creation", component: <KYCCreation /> },

  ];

  const apiEndpoints = {
    About: `user/profile/about/${userId}`,
    Experience: `user/profile/experience/${userId}`,

  };

  const handleSkip = () => {
    if (activeTabIndex < tabs.length - 1) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }

  const handleNextTab = async () => {
    setLoading(true);
    const currentTab = tabs[activeTabIndex].label;
    const endpoint = apiEndpoints[currentTab];

    let payload;

    console.log(currentTab,"currentTab", payload,"payload", endpoint,"endpoint");
    
    let role = null;
    switch (currentTab) {
      case "About":
        payload = aboutData; // Get the data from About
        break;
          case "Experience":
        payload = expData; // Get the data from Education
        role = 'client';
        break;

      default:
        payload = {};
    }

    try {

      // console.log(currentTab,"currentTab");
      
      const response = await ApiCall("PUT", endpoint, payload, "application/json", role);
      if (response.statusCode === 200) {

        // On successful API call, move to the next tab
        if (activeTabIndex < tabs.length - 1) {
          notify('success', "details updated successful!");
          setActiveTabIndex(activeTabIndex + 1);
          getUserDetails()
        }
      } else {
        console.error("API call failed:", response.message);
        notify('error', response.data[0].msg)
      }
    } catch (error) {
      console.log(error, "error", activeTabIndex, "activeTabIndex");

      console.error("Error during API call:", error);
      (activeTabIndex === 0)
        ?
        (notify('error', error?.response?.data.data[0]?.msg))
        :
        notify('error', error?.response?.data.errors[0]?.msg)
    }finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handlePrevTab = () => {
    if (activeTabIndex <= tabs.length - 1) {
      setActiveTabIndex(activeTabIndex - 1); // Go to the prev tab
    }
  };


  return (
    <>
    {loading && <Loader />}
      {/* First Row: Back Arrow */}
      <div className="back-arrow-row">
        <img
          src={backArrowCircleSvg}
          alt="Back Arrow"
          className="back-arrow-icon"
          onClick={(activeTabIndex === 0) ? handleBackArrow : handlePrevTab}
        />
      </div>

      {/* Tab Navigation */}
      <div className="client-tabs-row">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${index === activeTabIndex ? "active" : ""}`}
            onClick={() => setActiveTabIndex(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Render active tab content */}
      <div className="tab-content">{tabs[activeTabIndex].component}</div>

      <div className="text-end me-3">
        <button className="secondary-btn" onClick={handleSkip}>
          Skip
        </button>
        <button className="primary-btn ms-3" onClick={handleNextTab}>
          Save & Continue
        </button>
      </div>
    </>
  );
};

export default ClientProfileCreation;
