import React, { useState,  useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import NoFaqs from "../../../Images/faqs.png";
import Accordion from "react-bootstrap/Accordion";
import "./Faqs.css";
import "./ExtraService.css";

const Faqs = ({ onSave, updatePayloadData }) => {
  const [showQA, setShowQA] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [faq, setFaq] = useState({ question: "", answer: "" });
  const [allFaqs, setAllFaqs] = useState([]); // Array to store all FAQs

  const handleQA = () => {
    setShowQA(true);
    setShowCard(true);
  };

  const handleQACard = () => {
    setShowCard(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFaq((prevFaq) => ({
      ...prevFaq,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const newFaq = { question: faq.question, answer: faq.answer };
    setAllFaqs([...allFaqs, newFaq]);
    setFaq({ question: "", answer: "" });
    setShowCard(false);
  };

//   console.log(faq, "faq", allFaqs, "allFaqs");

  const handleCancel = () => {
    setFaq({ question: "", answer: "" });
    setShowCard(false);
  };

  useEffect(() => {
    updatePayloadData({ faqs: allFaqs });
  }, [allFaqs]);

  return (
    <div>
      <div className="faq_heading service-tilte">
        <h2 className="my-0">Frequently Asked Questions</h2>
        <div>
          {showQA && (
            <button
              type="button"
              className="btn secondary-btn"
              onClick={handleQACard}
            >
              Add FAQ
            </button>
          )}
        </div>
      </div>
      {/* No Faqs */}
      {!showQA ? (
        <Card className="faqs_card">
          <div className="text-center">
            <img src={NoFaqs} alt="allFaqs" className="no_faqs" />
            <h6>
              Lorem ipsum is a placeholder text commonly used to demonstrate the
              visual form of a document or a typeface without relying on
              meaningful content.
            </h6>
            <div className="mt-3">
              <button
                type="button"
                onClick={handleQA}
                className="btn secondary-btn"
              >
                Add FAQ
              </button>
            </div>
          </div>
        </Card>
      ) : (
        <>
          {/* Add FAQS */}
          {showCard && (
            <Card className="faqs_card add_faqs_card p-4">
              <Form.Group className="mb-3">
                <Form.Label>Add a Question</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="question"
                  value={faq.question}
                  onChange={handleChange}
                />
                <Form.Label className="text-end w-100">
                  70/250 Characters
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Add an Answer</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="answer"
                  value={faq.answer}
                  onChange={handleChange}
                />
                <Form.Label className="text-end w-100">
                  100/1000 Characters
                </Form.Label>
              </Form.Group>
              <div className="d-flex justify-content-end faqs_link">
                <a onClick={handleCancel}>Cancel</a>
                <a className="ms-3" onClick={handleSave}>
                  Save
                </a>
              </div>
            </Card>
          )}

          <Accordion defaultActiveKey="0" className="faq_accordian">
            {allFaqs.map((faq, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>
                  {faq.answer}
                  {/* <div className="d-flex justify-content-end faqs_link pt-3">
                    <a href="#">Delete</a>
                    <a href="#" className="ms-3">
                      Edit
                    </a>
                  </div> */}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </>
      )}
    </div>
  );
};

export default Faqs;
