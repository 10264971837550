import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image, Stack, Button } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "../../../../Images/freela-icons/delete-icon.svg";
import { ReactComponent as PaperClipIcon } from "../../../../Images/freela-icons/paperclip-icon.svg";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import { useViewContext } from "../../../../Context/ViewContext";
import defaultProfileImg from "../../../../Images/freela-images/profile.png";

const MyMessages = ({ role }) => {
  // console.log(role, "role in mymsg");

  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const { handleViewProposal } = useViewContext();

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    if (role) {
      debouncedGetActiveRoleDetails();
    }
    return () => debouncedGetActiveRoleDetails.cancel();
  }, [role]);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `proposal/user/${userId}`
          : `proposal/client/${userId}`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  // console.log(activeRoleDetails, "activeRoleDetails");

  const handleDelete = async (id) => {
    console.log(id, "id");
    setLoading(true);
    try {
      const endpoint = `proposal?proposalId=${id}&roleType=${
        role === "freelancer" ? "Freelancer" : "Client"
      }`;
      console.log(endpoint, "endpoint");

      const response = await ApiCall(
        "DELETE",
        endpoint,
        {},
        "application/json"
      );
      console.log(response, "response");

      if (response.statusCode === 200 || response.statusCode === 201) {
        getActiveRoleDetails();
      } else {
        notify("error", response.message || "Failed to delete proposal");
      }
    } catch (error) {
      console.error("Delete error:", error.response?.data || error.message);
      notify("error", error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Row className="my_messages gy-4">
        <Col xs={12}>
          <h5>My Messages</h5>
          <p className="mb-0">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere
            enim quae quisquam sequi accusamus blanditiis esse omnis, magnam aut
            fuga voluptatibus eligendi ut facilis ipsa autem quo suscipit quidem
            minus.
          </p>
        </Col>

        <Col xs={12}>
          <Row className="my_messages gy-4">
            {activeRoleDetails && activeRoleDetails.length > 0 ? (
              activeRoleDetails.map((item, idx) => (
                <Col md={6} lg={6} xl={4} key={idx}>
                  <Card
                    as={Card.Body}
                    className="single_message rounded-5 freela-border-greylight2 h-100"
                  >
                    <Row className="align-items-center g-0">
                      <Col xs={8}>
                        <Row className="align-items-center g-2 flex-nowrap mb-2">
                          <Col xs="auto">
                            <Image
                              src={item?.profilePhoto ? item.profilePhoto: defaultProfileImg}
                              width={45}
                              height={45}
                              roundedCircle
                              alt="profile"
                              className="p-05 object-fit-cover border-1 border-solid freela-border-primary"
                            />
                          </Col>
                          <Col>
                            <h1 className="freela-fs-16px mb-0 freela-text-black freela-w-70 text-truncate">
                              {role === "freelancer"
                                ? item.clientName
                                : item.firstName}
                            </h1>
                            <p className="freela-fs-12px m-0 text-truncate">
                              India
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={4}>
                        {role === "freelancer" ? (
                          <div className="project_budget">
                            <div className="small fw-600">Project Budget</div>
                            <h6 className="freela-text-primary">
                              $ {item.estimateBudget.min} - ${" "}
                              {item.estimateBudget.max}
                            </h6>
                          </div>
                        ) : (
                          <div className="dateStamp freela-greaseblack-text freela-fs-14px">
                            09-02-2025
                          </div>
                        )}
                      </Col>
                    </Row>

                    <div className="content">
                      <Card.Text>
                        {role === "freelancer"
                          ? item.requestMessage
                          : item.requestMessage}
                      </Card.Text>
                    </div>
                    <Stack direction="horizontal">
                      <Button
                        variant=""
                        className="freela-btn-primary btn-rounded btn-xs"
                        onClick={() => handleDelete(item.proposalId)}
                      >
                        <DeleteIcon width={14} height={14} />
                      </Button>
                      <Stack direction="horizontal" className="ms-auto gap-3">
                        {role === "freelancer" && (
                          <Button
                            variant="link"
                            className="freela-text-primary freela-fs-14px text-decoration-none p-0"
                          >
                            <PaperClipIcon
                              width={16}
                              className="d-inline-block freela-fs-14px"
                            />{" "}
                            {item.attachments.length} files
                          </Button>
                        )}
                        <Button
                          variant="link"
                          className="freela-text-primary freela-fs-14px text-decoration-none p-0"
                          onClick={() => handleViewProposal(item.projectId)}
                        >
                          View Proposal
                        </Button>
                      </Stack>
                    </Stack>
                  </Card>
                </Col>
              ))
            ) : (
              <div>No Messages available</div>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MyMessages;
