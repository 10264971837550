// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.play_btn{
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 9;
    transform: translate(50%, -50%);
    width:80px;
}
.play_btn img{width:100%}

.pluscard{
height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #f0606c;
    border-radius: 30px;
    padding: 20px;
}
.pluscard .plusicon{
    margin: auto;
    font-size: 50px;
    cursor: pointer;
}
.uploaded_img img{
    height:200px;
}
.video-preview video{
    border-radius: 30px;
}
.uploaded_image{
    height:200px;
    border-radius: 30px;
    border:1px solid #dfdfdf;
}
.upload-service img{
    width:70px;
    height:70px;
}
@media (max-width:991px) {
    .uploaded_img img{
        height:auto;
    }
    .browse_card a{
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Service/CreateService.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,UAAU;IACV,+BAA+B;IAC/B,UAAU;AACd;AACA,cAAc,UAAU;;AAExB;AACA,aAAa;IACT,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,wBAAwB;AAC5B;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI;QACI,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".play_btn{\r\n    position: absolute;\r\n    top: 50%;\r\n    right: 50%;\r\n    z-index: 9;\r\n    transform: translate(50%, -50%);\r\n    width:80px;\r\n}\r\n.play_btn img{width:100%}\r\n\r\n.pluscard{\r\nheight: 200px;\r\n    align-items: center;\r\n    justify-content: center;\r\n    display: flex;\r\n    color: #f0606c;\r\n    border-radius: 30px;\r\n    padding: 20px;\r\n}\r\n.pluscard .plusicon{\r\n    margin: auto;\r\n    font-size: 50px;\r\n    cursor: pointer;\r\n}\r\n.uploaded_img img{\r\n    height:200px;\r\n}\r\n.video-preview video{\r\n    border-radius: 30px;\r\n}\r\n.uploaded_image{\r\n    height:200px;\r\n    border-radius: 30px;\r\n    border:1px solid #dfdfdf;\r\n}\r\n.upload-service img{\r\n    width:70px;\r\n    height:70px;\r\n}\r\n@media (max-width:991px) {\r\n    .uploaded_img img{\r\n        height:auto;\r\n    }\r\n    .browse_card a{\r\n        font-size: 16px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
