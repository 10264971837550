import React from 'react';

const DateInput = ({ id, name, value, onChange, className, placeholder, required }) => {
  return (
    <input
      type="date"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={className || 'date-input'}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default DateInput;
