import React, { useState } from 'react';
import "./Register.css";
import logo from "../../Images/logo.png";
import login from "../../Images/login.png";
import user from "../../Images/icons/user.png";
import ApiCall from '../../Api/ApiCall';
import useToast from '../../Hooks/useToast';
import Loader from '../Common/Loader/Loader';
import { Row, Col } from 'react-bootstrap';


const ForgotPwd = ({ openLogin, openForgotverify }) => {

  const { notify } = useToast();

    const handleLoginModal = () => {
        openLogin()
    }

    const [formData, setFormData] = useState({
        email: '',
       
    });

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    setError('');  // Clear previous error messages
    setLoading(true);

    try {
      // Prepare payload for forgot pwd
      const payload = {
        email: formData.email,
        authenticationType:"forgot password"

      };

      console.log(payload, "forgot pwd payload");


      // Make the API call
      const response = await ApiCall('POST', 'user/forgotpwd', payload);
      // Handle success with 200 status code
      if (response.statusCode === 200)  {
        console.log('forgot pwd successful:', response.message, "response.message", response.data);
        notify('success',  response.message);
        openForgotverify(response.data.email)
      }
      else {
        notify('error', response.message)
        setError('forgot pwd failed. Please try again.');
      }

    } catch (error) {
      // Handle API call error
      console.error('forgot pwd error:', error);
      notify('error', error.response.data.message )
      setError('forgot pwd failed. Please try again.');
    }
    finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

    return (
      <>
      {loading && <Loader />}
      <div>
        <div>
      <img src={logo} alt="Logo" className="forgot-logo" />
      </div>
      <Row className="middle_content">
      <Col xs={12} sm={12} md={5} className="login-left-content">
        <img src={login} alt="login" className="w-100 left-img" />
      </Col>
            {/* Left Section */}
          

            {/* Right Section */}
            <Col xs={12} sm={12} md={7} className="login-right-content my-5 px-4">
                <h1>Forgot Password,</h1>
                <p>
                    Forget your Flow password ? No worries. Enter your email  
                    address below to reset your password
                    <span><a onClick={handleLoginModal}>Back to Login</a></span>
                </p>

                {/* Register verify Form */}
                <form className="login-form" onSubmit={handleSubmit}>

                    <label htmlFor="email">
                        Email ID <span className="required-asterisk">*</span>
                    </label>
                    <div className="input-wrapper position-relative">
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter email id"
                            value={formData.email} onChange={handleChange}
                            required
                        />
                        <img src={user} alt="Person Icon" className="input-icon" />
                    </div>

                    <div className="text-center my-5">
          <button type="submit" className="primary-btn py-2 w-50">
           Verify
          </button>
          </div>
                </form>

            </Col>
        </Row>
      </div>
      
      </>
        
    );
};

export default ForgotPwd;


