import React from 'react'

const MyReferrals = () => {
  return (
    <div>
      MyReferrals
    </div>
  )
}

export default MyReferrals
