import React from 'react'
import { Row, Col, Stack, Button, Card, Form } from 'react-bootstrap'
import { ReactComponent as WebCamIcon } from '../../../../Images/freela-icons/webcam.svg'
import { ReactComponent as MicroPhoneIcon } from '../../../../Images/freela-icons/microphone.svg'
import { ReactComponent as ScreenShareIcon } from '../../../../Images/freela-icons/screen-share.svg'

const Step3 = ({ clickToBack, clickToNext }) => {
    const browserPermissionLists = [
        {
            id: 1,
            description: "Grant permission to WebCam",
            icon: <WebCamIcon />,
            permission: false
        },
        {
            id: 2,
            description: "Grant permission to Microphone",
            icon: <MicroPhoneIcon />,
            permission: false
        },
        {
            id: 3,
            description: "Grant Permission for Entire Screen Share",
            icon: <ScreenShareIcon />,
            permission: false
        }
    ];
    
    return (
        <Row className="py-4 ai_interview_step">
            <Col md={8} lg={8} xl={7} className='mx-auto'>
                <div className="content_one text-center">
                    <div className="h4 fw-500">Enable Camera and Microphone</div>
                    <p>To proceed with the interview, we need access to your camera and microphone. Please grant the necessary permissions.</p>
                </div>
                <div className='mx-lg-5 mt-5 px-lg-4'>
                    {browserPermissionLists.map((list, idx) => (
                        <Card as={Card.Body} key={idx} className="single_card rounded-4 flex-row align-items-center gap-2">
                            <Card className='icon_bg rounded-3 align-items-center justify-content-center border-0' style={{ height: 50, width: 50 }}>{list.icon}</Card>
                            <Card.Text className='mb-0 text-dark'>{list.description}</Card.Text>
                            <div className="ms-auto">
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-${idx}`}
                                    label=""
                                />
                            </div>
                        </Card>
                    ))}

                    <Stack direction='horizontal' className='gap-4 pt-4 justify-content-center'>
                        <Button variant='' className='freela-btn-primary-outline px-5' onClick={clickToBack}>Cancel</Button>
                        <Button variant='' className='freela-btn-primary px-5' onClick={clickToNext}>Next</Button>
                    </Stack>
                </div>
            </Col>
        </Row>
    )
}

export default Step3