// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqs_card{
    padding: 3rem 4rem;
    border-radius: 30px;
    border:1px solid #DFDFDF;
}
.faq_heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.faqs_card .no_faqs{
    width: 50%;
}
.faqs_card h6{
    color:#626262;
}

.add_faqs_card .form-control{
    border-radius:20px;
    border: 1px solid #DFDFDF;
}
.add_faqs_card label{
 color:#626262;
 font-size: 12px;
}
.faqs_link{
    color: #F0806C;
    font-weight: 600;
}
.faq_accordian .accordion-item{
    border: 1px solid #DFDFDF !important;
    border-radius: 18px;
    margin-top: 15px;
}
.faq_accordian .accordion-item:first-of-type>.accordion-header .accordion-button{
    border-radius: 18px;
}
.faq_accordian .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed{
    border-radius: 18px;
}

@media (max-width:767px) {
    .faqs_card .no_faqs{
        width: 70%;
    }
    .faqs_card{
        padding: 2rem 1rem;
    }
    .faqs_card h6{
        font-size:14px;
    }
    .faq_heading{
        display: inline-block;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Service/Faqs.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,UAAU;AACd;AACA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;CACC,aAAa;CACb,eAAe;AAChB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,kBAAkB;IACtB;IACA;QACI,cAAc;IAClB;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".faqs_card{\r\n    padding: 3rem 4rem;\r\n    border-radius: 30px;\r\n    border:1px solid #DFDFDF;\r\n}\r\n.faq_heading{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n.faqs_card .no_faqs{\r\n    width: 50%;\r\n}\r\n.faqs_card h6{\r\n    color:#626262;\r\n}\r\n\r\n.add_faqs_card .form-control{\r\n    border-radius:20px;\r\n    border: 1px solid #DFDFDF;\r\n}\r\n.add_faqs_card label{\r\n color:#626262;\r\n font-size: 12px;\r\n}\r\n.faqs_link{\r\n    color: #F0806C;\r\n    font-weight: 600;\r\n}\r\n.faq_accordian .accordion-item{\r\n    border: 1px solid #DFDFDF !important;\r\n    border-radius: 18px;\r\n    margin-top: 15px;\r\n}\r\n.faq_accordian .accordion-item:first-of-type>.accordion-header .accordion-button{\r\n    border-radius: 18px;\r\n}\r\n.faq_accordian .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed{\r\n    border-radius: 18px;\r\n}\r\n\r\n@media (max-width:767px) {\r\n    .faqs_card .no_faqs{\r\n        width: 70%;\r\n    }\r\n    .faqs_card{\r\n        padding: 2rem 1rem;\r\n    }\r\n    .faqs_card h6{\r\n        font-size:14px;\r\n    }\r\n    .faq_heading{\r\n        display: inline-block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
