import React, { useState } from "react";
import { ReactComponent as FreelaLogo } from "../../Images/freela-icons/freela-logo.svg";
import { ReactComponent as UserIcon } from "../../Images/freela-icons/user-icon.svg";
import LoginImg from "../../Images/freela-icons/login-left-img.svg";
import ApiCall from "../../Api/ApiCall";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import { forgotPwdValidationSchema } from "../Common/Validation/validationSchema";

const ForgotPwd = ({ openLogin, openForgotverify }) => {
  const { notify } = useToast();
  const [loading, setLoading] = useState(false);

  const handleLoginModal = () => {
    openLogin();
  };

  // Handle form submission
  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      // Prepare payload for forgot pwd
      const payload = {
        email: values.email,
        authenticationType: "forgot password",
      };
      // Make the API call
      const response = await ApiCall("POST", "user/forgotpwd", payload);
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        notify("success", response.message);
        openForgotverify(response.data.email);
      } else {
        notify("error", response.message);
        // setError('forgot pwd failed. Please try again.');
      }
    } catch (error) {
      // Handle API call error
      console.error("forgot pwd error:", error);
      notify("error", error.response.data.message);
      // setError('forgot pwd failed. Please try again.');
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Row className="login_form align-items-center">
        {/* Left Section */}
        <Col sm={12} md={12} lg={5} xl={5}>
          <FreelaLogo className="ms-lg-4 mt-lg-2" width={260} />
          <div className="d-flex align-items-center justify-content-center my-xl-5 py-xl-4">
            <Image src={LoginImg} alt="LoginImg" fluid />
          </div>
        </Col>
        {/* Left Section */}

        {/* Right Section */}
        <Col sm={12} md={12} lg={7} xl={7} className="my-xl-5">
          <h1 className="fs-4 mb-3">Forgot Password,</h1>
          <p>
            Forget your Flow password ? No worries. Enter your email address
            below to reset your password
            <Button
              variant="link"
              className="py-0 ps-1 text-decoration-none freela-text-primary"
              onClick={handleLoginModal}
            >
              Back to Login
            </Button>
          </p>

          <Formik
            initialValues={{ email: "" }}
            validationSchema={forgotPwdValidationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <FormikForm>
                {/* Email Field */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email ID <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="position-relative right-icon">
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                    <UserIcon
                      className="position-absolute top-50 end-0 translate-middle-y me-3"
                      width={20}
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="small"
                    className="text-danger d-block mt-1"
                  />
                </Form.Group>

                {/* Send OTP Button */}
                <Form.Group className="text-center">
                  <Button type="submit" className="freela-btn-primary px-5">
                    Send OTP
                  </Button>
                </Form.Group>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPwd;
