// Layout.js
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap"
import { TopNav } from "../Navbar/TopNav";
import { Asidebar } from "../Sidebar/Asidebar";

const Layout = ({ userDetails, role, setRole }) => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const location = useLocation();

  // Check if the current route is the ProfilePreview route
  const isProfilePreview = location.pathname === "/profile-preview";
  // console.log(isProfilePreview,"isProfilePreview");


  return (
    <>
      <TopNav userDetails={userDetails} role={role} setRole={setRole} offcanvasShow={() => setOffcanvasShow(true)} />
      <Container fluid className={showToggle ? 'freela-app py-3' : 'freela-app py-3 toggle_hide'}>
        <Asidebar
          offcanvasShow={offcanvasShow}
          offcanvasOpen={() => setOffcanvasShow(true)}
          offcanvasClose={() => setOffcanvasShow(false)}
          handleToggle={() => setShowToggle(!showToggle)}
        />
        <main className="freela-main">
          {/* <div className={`page-content ${isProfilePreview ? "no-padding" : ""}`}> */}
            <Outlet /> {/* Renders child routes here */}
          {/* </div> */}
        </main>
      </Container>
    </>
  );
};

export default Layout;
