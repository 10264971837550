import React from 'react';
import frel from "../../Images/payment_icons/frel.png";
import usd from "../../Images/payment_icons/usd.png";
import inr from "../../Images/payment_icons/inr.png";
import euro from "../../Images/payment_icons/euro.png";
import pound from "../../Images/payment_icons/pound.png";
import bitcoin from "../../Images/payment_icons/bitcoin.png";
import litecoin from "../../Images/payment_icons/litecoin.png";
import ethereum from "../../Images/payment_icons/ethereum.png";
import usdt from "../../Images/payment_icons/usdt.png";

const PaymentModes = ({ paymentData, type }) => {

   
    if (!paymentData) return <h5>N/A</h5>;
  
    const images = {
      freelcoin: { src: frel, label: "Freelcoin" },
      usd: { src: usd, label: "USD" },
      inr: { src: inr, label: "INR" },
      euro: { src: euro, label: "Euro" },
      pound: { src: pound, label: "Pound" },
      bitcoin: { src: bitcoin, label: "Bitcoin" },
      litecoin: { src: litecoin, label: "Litecoin" },
      ethereum: { src: ethereum, label: "Ethereum" },
      usdt: { src: usdt, label: "USDT" }
    };
  
    const selectedPayments = [];
  
    // Collect enabled payment methods
    if (paymentData.freelcoin) selectedPayments.push(images.freelcoin);
    if (paymentData.traditional) {
      Object.entries(paymentData.traditional).forEach(([key, value]) => {
        if (value && images[key]) selectedPayments.push(images[key]);
      });
    }
    if (paymentData.other) {
      Object.entries(paymentData.other).forEach(([key, value]) => {
        if (value && images[key]) selectedPayments.push(images[key]);
      });
    }
  
    return (
      <h5>
        {paymentData.any ? (
          "Any Payment Accepted"
        ) : selectedPayments.length > 0 ? (
          selectedPayments.map((payment, index) => (
            <span key={index} className="me-2">
              <img src={payment.src} alt={payment.label} />
              {type !== "workSpace" && payment.label}
            </span>
          ))
        ) : (
          "No Payment Accepted"
        )}
      </h5>
    );
  };

export default PaymentModes
