import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ApiCall from "../../../Api/ApiCall";
import uploadIconSvg from "../../../Images/icons/upload.svg";
import pdfIcon from "../../../Images/icons/pdf-icon.svg";
import { debounce } from "lodash";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";
import "./PlaceBid.css";
import { useNavigate } from "react-router-dom";
import { calculateDaysDifference, getCurrentDate } from "../../Common/utils/utils";

const PlaceBid = ({
  projectId,
  clientId,
  freelancerId,
  freelancerName,
  userId,
  type,
  daysLeft
}) => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const [certificateFile, setCertificateFile] = useState(null);
  const certiInputRef = useRef(null);
    const navigate = useNavigate();

  const [payloadData, setPayloadData] = useState({
    title: "",
    serviceId: "",
    bidAmount: 0,
    deliveryDate: "",
    deliveryTime: "",
    message: "",
    certificateFile: [],
  });

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const response = await ApiCall(
        "GET",
        `service/user?userId=${userId}&status=final`,
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitProposal = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: payloadData.title,
        bidAmount: payloadData.bidAmount,
        deliveryDate: payloadData.deliveryDate,
        deliveryTime: payloadData.deliveryTime,
        message: payloadData.message,
        attachments: payloadData.certificateFile,
        freelancerName: freelancerName,
        serviceId: payloadData?.serviceId,
        clientId: clientId,
        freelancerId: freelancerId,
        projectId: projectId,
        isRead: false,
      };

      // console.log(payload, "proposal post payload");

      const response = await ApiCall(
        "POST",
        "bid",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 201) {
        // console.log("successful:", response.data);
        {
          type === "bid"
            ? notify("success", "Project Bid created successfully!")
            : notify("success", "Proposal asked successfully!");
        }
        navigate("/find-works");
        
      } else {
        notify(
          "error",
          response.message || "Proposal failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0];
    if (file) {
      setPhoto(file);
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          setPayloadData((prevState) => ({
            ...prevState,
            [field]: [...(prevState[field] || []), response.data.s3Url],
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="text-center bid-sec">
        <h3 className="mt-3">Place your Bid</h3>
        <p className="w-75 mx-auto">
          Lorem ipsum is a placeholder text commonly used to demonstrate the
          visual form of a document or a typeface.
        </p>
      </div>
      <div className="px-5 scroll-container">
        <form className="bid-form">
          <div>
            <label>
              Select Service <span className="text-danger">*</span>
            </label>
            <Form.Select
              aria-label="Default select example"
              name="serviceId"
              value={payloadData.serviceId}
              onChange={(e) => {
                const selectedService = activeRoleDetails.find(
                  (service) => service.serviceId === e.target.value
                );
                setPayloadData((prevData) => ({
                  ...prevData,
                  serviceId: e.target.value,
                  title: selectedService ? selectedService.title : "",
                }));
              }}
            >
              <option value="">Select Service</option>
              {activeRoleDetails.map((service, index) => (
                <option key={index} value={service.serviceId}>
                  {service.title}
                </option>
              ))}
            </Form.Select>
          </div>

          <div>
            <label htmlFor="amount">Bid amount</label>
            <div className="input-wrapper position-relative">
              <input
                type="number"
                id="amount"
                name="bidAmount"
                value={payloadData.bidAmount}
                onChange={(e) =>
                  setPayloadData((prevData) => ({
                    ...prevData,
                    bidAmount: e.target.value,
                  }))
                }
                placeholder="20 USD"
                required
              />
            </div>
          </div>

          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className="input-group">
                <label htmlFor="delivery-date">Delivery Date</label>
                <input
                  type="date"
                  id="delivery-date"
                  name="deliveryDate"
                  value={payloadData.deliveryDate}
                  onChange={(e) =>
                    setPayloadData((prevData) => ({
                      ...prevData,
                      deliveryDate: e.target.value,
                    }))
                  }
                  placeholder="Delivery Date"
                  required
                />
              </div>
            </Col>

            <Col sm={12} md={12} lg={6}>
              <label htmlFor="delivery-time">Delivery Timeline</label>
              <div className="input-wrapper position-relative">
                <input
                  type="text"
                  id="delivery-time"
                  name="deliveryTime"
                  value={payloadData.deliveryTime}
                  onChange={(e) =>
                    setPayloadData((prevData) => ({
                      ...prevData,
                      deliveryTime: e.target.value,
                    }))
                  }
                  placeholder="12:00 AM"
                  required
                />
              </div>
            </Col>
          </Row>

          <div>
            <Form.Group className="mb-3 mt-4 bid-text">
              <Form.Control
                as="textarea"
                rows={3}
                id="message"
                name="message"
                value={payloadData.message}
                onChange={(e) =>
                  setPayloadData((prevData) => ({
                    ...prevData,
                    message: e.target.value,
                  }))
                }
                placeholder="Send a message to the employer to highlight your qualifications"
              />
            </Form.Group>
          </div>

          <Row>
            <Col sm={12} md={6}>
              <Card className="border-0">
                <div className="d-flex justify-content-between align-items-center flex_wrap">
                  {certificateFile ? (
                    <div className="file-preview">
                      <div className="file-details">
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          className="file-icon"
                        />
                        <div className="file-info ms-3">
                          <p className="file-title mb-0">
                            {certificateFile.name}
                          </p>
                          <p className="file-meta mb-0">
                            {certificateFile.size} KB ·{" "}
                            {new Date(
                              certificateFile.lastModified
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <img
                          src={uploadIconSvg}
                          alt="Upload Icon"
                          width="40px"
                          height="40px"
                          onClick={() => handleUploadClick(certiInputRef)}
                        />
                        <input
                          type="file"
                          accept=".pdf,.docx"
                          style={{ display: "none" }}
                          ref={certiInputRef}
                          onChange={(e) =>
                            handlePhotoChange(
                              e,
                              setCertificateFile,
                              "certificateFile"
                            )
                          }
                        />
                        {/* <label>
                            Please upload your documents (Doc , Pdf..etc)
                          </label> */}
                      </div>
                      {/* <div>
                          <button
                            type="button"
                            className="secondary-btn"
                            onClick={() => handleUploadClick(certiInputRef)}
                          >
                            Choose
                          </button>
                          <input
                            type="file"
                            accept=".pdf,.docx"
                            style={{ display: "none" }}
                            ref={certiInputRef}
                            onChange={(e) =>
                              handlePhotoChange(
                                e,
                                setCertificateFile,
                                "certificateFile"
                              )
                            }
                          />
                        </div> */}
                    </>
                  )}
                </div>
              </Card>
            </Col>
            <Col
              sm={12}
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <h2 htmlFor="delivery-date">{daysLeft} days left for Bid</h2>
            </Col>
          </Row>

          <div className="text-center my-4" onClick={handleSubmitProposal}>
            <button type="submit" className="primary-btn py-2 px-5">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlaceBid;
