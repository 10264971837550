import React, { useEffect, useState } from "react";
import "./AboutTab.css"; // Create a CSS file for custom styling
import DateInput from "../../../Common/utils/DateInput";
import {
  formatDateString,
  formatForInput,
  formatDateToDDMMYYYY,
} from "../../../Common/utils/utils";
import { Container, Form } from "react-bootstrap";

const AboutTab = ({ userDetails, onGetData }) => {
  const [aboutInfo, setAboutInfo] = useState({
    dateOfBirth: "",
    gender: "",
    bio: "",
  });

  // console.log(aboutInfo,"aboutInfo");
  

  // Initialize aboutInfo with existing userDetails data if available
  useEffect(() => {
    if (userDetails?.about && userDetails.about.length > 0) {
      const { dob, bio, gender } = userDetails.about[0]; // Get the first entry
      setAboutInfo({
        dateOfBirth: formatDateString(dob), // Format the existing date
        bio: bio,
        gender: gender,
      });
    }
  }, [userDetails]);

  // console.log(aboutInfo,"aboutInfo");

  // Handle input change for both date and bio
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Transform the aboutInfo into the required payload structure
    const payload = {
      about: [
        {
          dob: formatDateToDDMMYYYY(aboutInfo.dateOfBirth), // Call a function to format the date
          bio: aboutInfo.bio,
          gender: aboutInfo.gender,
        },
      ],
    };

    onGetData(payload);
  }, [aboutInfo]);

  return (
    <div className="about_tab_content">
      <div className="about-section">
        {/* Date of Birth */}
        <div className="about_input_sec">
          <div className="input-group">
            <Form.Label htmlFor="dateOfBirth">
              Date of Birth <sup className="text-danger">*</sup>
            </Form.Label>
            {/* <label htmlFor="dateOfBirth">Date of Birth <span className="text-danger">*</span></label> */}
            <div className="input-wrapper">
              <DateInput
                id="dateOfBirth"
                name="dateOfBirth"
                value={formatForInput(aboutInfo.dateOfBirth)}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* <div className="ms-0 ms-lg-5">
            <Form.Label htmlFor="exampleColorInput">Select Gender</Form.Label>
            <div className="gender_card">
              <Form.Check inline label="Male" type="radio" />
              <Form.Check inline label="Female" type="radio" />
              <Form.Check inline label="Others" type="radio" />
            </div>
          </div> */}

          <div className="ms-0 ms-lg-5">
            <Form.Label htmlFor="exampleColorInput">Select Gender</Form.Label>
            <div className="gender_card">
              <Form.Check
                inline
                label="Male"
                type="radio"
                name="gender"
                value="Male"
                checked={aboutInfo.gender === "Male"} // Bind with state
                onChange={handleChange} // Update state
              />
              <Form.Check
                inline
                label="Female"
                type="radio"
                name="gender"
                value="Female"
                checked={aboutInfo.gender === "Female"} // Bind with state
                onChange={handleChange} // Update state
              />
              <Form.Check
                inline
                label="Others"
                type="radio"
                name="gender"
                value="Others"
                checked={aboutInfo.gender === "Others"} // Bind with state
                onChange={handleChange} // Update state
              />
            </div>
          </div>
        </div>
        {/* Bio */}

        <Form.Group className="mb-3 mt-4">
          <Form.Label>Bio</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            id="bio"
            name="bio"
            value={aboutInfo.bio}
            onChange={handleChange}
            className="bio-textarea"
            placeholder="Write something about yourself..."
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default AboutTab;
