import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import DateInput from "../../../Common/utils/DateInput";
import {
  formatDateString,
  formatForInput,
  formatDateToDDMMYYYY,
} from "../../../Common/utils/utils";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as EmailIcon } from "../../../../Images/freela-icons/email-icon.svg";

const AboutTab = ({ userDetails, onGetData, onGetErrData }) => {
  const [aboutInfo, setAboutInfo] = useState({
    dateOfBirth: "",
    gender: "",
    phoneNumber: "",
    email: "",
    bio: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userDetails?.about && userDetails.about.length > 0) {
      // Ensure it's not empty
      const { dob, bio, gender } = userDetails.about[0]; // Safe to access now

      setAboutInfo({
        dateOfBirth: formatDateString(dob), // Format the existing date
        bio: bio || "", // Prevent undefined errors
        gender: gender || "", // Prevent undefined errors
        phoneNumber: userDetails?.phoneNumber || "",
        email: userDetails?.email || "",
      });
    } else {
      // Initialize default values if about is missing or empty
      setAboutInfo({
        dateOfBirth: "",
        bio: "",
        gender: "",
        phoneNumber: userDetails?.phoneNumber || "",
        email: userDetails?.email || "",
      });
    }
  }, [userDetails]);

  useEffect(() => {
    // Transform the aboutInfo into the required payload structure
    const payload = {
      about: [
        {
          dob: formatDateToDDMMYYYY(aboutInfo.dateOfBirth), // Call a function to format the date
          bio: aboutInfo.bio,
          gender: aboutInfo.gender,
        },
      ],
    };

    onGetData(payload);
  }, [aboutInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleValidate();
  }, [aboutInfo]);

  const handleValidate = () => {
    let newErrors = {};

    if (aboutInfo.dateOfBirth === "-undefined-undefined") {
      newErrors.dateOfBirth = "Date of Birth is required";
    }

    if (aboutInfo.bio.length === 0) {
      newErrors.bio = "Bio is required";
    } else if (
      (aboutInfo.bio.length > 0 && aboutInfo.bio.length < 11) ||
      aboutInfo.bio.length > 140
    ) {
      newErrors.bio = "Bio must be between 10 and 140 characters long";
    }

    if (aboutInfo.gender.length === 0) {
      newErrors.gender = "Gender is required";
    }

    setErrors(newErrors);

    // Return true if there are no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onGetErrData(Object.keys(errors).length);
  }, [errors]);

  return (
    <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
      <Row className="gy-3">
        <Form.Group as={Col} md={6} lg={6} xl={3} xxl={2}>
          <Form.Label>
            Date of Birth <span className="text-danger">*</span>
          </Form.Label>
          <DateInput
            id="dateOfBirth"
            name="dateOfBirth"
            value={formatForInput(aboutInfo.dateOfBirth)}
            onChange={handleChange}
          />
          {errors?.dob && <div className="text-danger mt-2">{errors?.dob}</div>}
        </Form.Group>
        <Form.Group as={Col} md={6} lg={6} xl={5} xxl={4}>
          <div className="fs-14 mb-2">Select Gender</div>
          <div className="border rounded-pill p-2 px-3">
            {["Male", "Female", "Others"].map((type) => (
              <Form.Check
                key={`inline-${type}`}
                id={`inline-radio-${type}`}
                className="pt-05"
                inline
              >
                <Form.Check.Input
                  checked={aboutInfo.gender === type}
                  onChange={handleChange}
                  name="gender" 
                  type="radio"
                  value={type}
                />
                <Form.Check.Label>{type}</Form.Check.Label>
              </Form.Check>
            ))}
          </div>
          {errors?.gender && (
            <div className="text-danger mt-2">{errors?.gender}</div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6} lg={6} xl={4} xxl={3}>
          <Form.Label>
            Phone Number <span className="text-danger">*</span>
          </Form.Label>
          <PhoneInput
            as={Form.Control}
            country="in"
            value={`+${userDetails?.code}${aboutInfo.phoneNumber}`}
            readOnly
          />
        </Form.Group>

        <Form.Group as={Col} md={6} lg={6} xl={4} xxl={3}>
          <Form.Label>
            Email ID<span className="text-danger">*</span>
          </Form.Label>
          <div className="position-relative">
            <Form.Control
              name="email"
              type="email"
              placeholder="Enter your email"
              value={aboutInfo.email}
              readOnly
            />
            <EmailIcon
              className="position-absolute top-50 end-0 translate-middle-y me-3"
              width={18}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} xs={12}>
          <Form.Label>Bio</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            id="bio"
            name="bio"
            value={aboutInfo.bio}
            onChange={handleChange}
            className="bio-textarea"
            placeholder="Write something about yourself..."
            maxLength={140}
          />
          <div className="text-end">
            <Form.Text>{aboutInfo.bio.length}/140 characters</Form.Text>
          </div>
          {errors?.bio && <div className="text-danger mt-2">{errors?.bio}</div>}
        </Form.Group>
      </Row>
    </Card>
  );
};

export default AboutTab;
