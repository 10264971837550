import React from "react";
import "./ProfilePreview.css"; // Import your custom styles
import internet from "../../../Images/icons/internet-icon.svg";
import blackArrow from "../../../Images/icons/black-arrow-icon.svg";
import mail from "../../../Images/icons/mail.svg";
import phone from "../../../Images/icons/phone.svg";
import map from "../../../Images/icons/map-pin.svg";
import facebook from "../../../Images/icons/facebook.svg";
import linkedin from "../../../Images/icons/linkedin.svg";
import behance from "../../../Images/icons/behance.svg";
import dribbble from "../../../Images/icons/dribbble.svg";
import profilePic from "../../../Images/profile.png";
import coverPhoto from "../../../Images/cover.png"
import { Card, Row, Col } from "react-bootstrap";

const ProfilePreview = ({ userDetails, getUserDetails }) => {
  console.log(userDetails, "userDetails");

  return (
    <div className="profile-preview-layout">
      {/* <img
        src={userDetails?.profile?.coverPhoto}
        alt="Cover"
        className="preview-cover-image"
      /> */}
      <img src={userDetails?.profile?.coverPhoto ||coverPhoto} alt="Cover" className="preview-cover-image w-100" />

      <Card className="profile-header">
        <div className="d-flex justify-content-between flex-column flex-sm-row">
        <div className="profile-photo-preview d-flex">
          {/* <img src={userDetails?.profile?.profilePic} alt="Profile" /> */}
          <div className="me-4">
           <img src={userDetails?.profile?.profilePic || profilePic} alt="Profile" className="profile_img_width"/>
          </div>
         
          <div className="profile-details">
          <div className="profile-details-header">
              <div className="d-flex">
                 {/* <span>
              <h1>{userDetails?.firstName}</h1>
            </span> */}

                <h4>{userDetails?.firstName}</h4>
                <label className="status-available">Available</label>
              </div>
              <p>UI UX Designer</p>
              <div className="profile-status">
            <span className="profile-status-main kyc-verified">KYC Verified</span>
            <span className="profile-status-main ai-verified">AI Verified</span>
            <span className="profile-status-main freelance-status">Freelancing</span>
          </div>
           

          </div>

         
        </div>
        </div>
        <div>
          <button type="button" className="primary-btn">Follow</button>
        </div>
        </div>
       
      </Card>
    <div className="profile_preview">
      <Row>
        <Col lg={9} md={7} sm={12} className="left-section">
          {/* <!-- About Me Section --> */}
            <Card>
                <h4>About Me</h4>
                <p>{userDetails?.about[0]?.bio}</p>
            </Card>
             {/* <!-- Education Section Start --> */}
            <Card>
              <h4>Education</h4>
               {userDetails?.freelancerSettings?.education.map((row, index) => (
              <div class="grid-container" key={index}>
                <div class="grid-item">
                    <label>Degree</label>
                    <h6>{row.degree}</h6>
                </div>
                <div class="grid-item">
                    <label>University</label>
                    <h6>{row.university}</h6>
                </div>
                <div class="grid-item">
                    <label>Starting from</label>
                    <h6>{new Date(row.start).toLocaleDateString()}</h6>
                </div> 
                <div class="grid-item">
                    <label>Ending</label>
                    <h6>{new Date(row.end).toLocaleDateString()}</h6>
                </div>
              </div>
               ))}           
            </Card>
            {/* <!-- Education Section End --> */}  
            {/* <!-- Experience Section --> */}
            <Card>
              <h4>Experience</h4>
              {userDetails?.freelancerSettings?.experience.map((row, index) => (
              <div class="grid-container" key={index}>
                <div class="grid-item">
                    <label>Designation</label>
                    <h6>{row.designation}</h6>
                </div>
                <div class="grid-item">
                    <label>Company</label>
                    <h6>{row.company}</h6>
                </div>
                <div class="grid-item">
                    <label>Starting from</label>
                    <h6>{new Date(row.start).toLocaleDateString()}</h6>
                </div> 
                <div class="grid-item">
                    <label>Ending</label>
                    <h6>{new Date(row.end).toLocaleDateString()}</h6>
                </div>
              </div>
               ))}           
            </Card>
            {/* Experience Section End */}         
            {/* <!-- Courses & Certifications Section Start --> */}
            <Card>
              <h4>Courses & Certifications</h4>
              {userDetails?.freelancerSettings?.courses.map((row, index) => (
              <div class="grid-container" key={index}>
                <div class="grid-item">
                    <label>Course</label>
                    <h6>{row.certificateName}</h6>
                </div>
                <div class="grid-item">
                    <label>Company</label>
                    <h6>{row.company}</h6>
                </div>
                <div class="grid-item">
                    <label>Starting from</label>
                    <h6>{new Date(row.start).toLocaleDateString()}</h6>
                </div> 
                <div class="grid-item">
                    <label>Ending</label>
                    <h6>{new Date(row.end).toLocaleDateString()}</h6>
                </div>
              </div>
               ))} 
               <div className="mt-3">
                <h4>Certificates</h4>
                </div>  
                {userDetails?.freelancerSettings?.courses.map((row, index) => (
                  <div className="certificate_image">                    
                    <img
                      key={index}
                      src={row.file[0] || "certificate-image.jpg"}
                      alt="Course Certificate"
                      className="certificate-image"
                    /> 
                  </div>
                ))}
            </Card>
            {/* <!-- Courses & Certifications Section End --> */}
            {/* <!-- Portfolio Section Start--> */}
            <Card>
              <h4>Portfolio</h4>
              {userDetails?.freelancerSettings?.portfolio.map((row, index) => (
                <div>
              <div class="grid-container" key={index}>
                <div class="grid-item">
                    <label>Course</label>
                    <h6>{row.projectName}</h6>
                </div>
                <div class="grid-item">
                    <label>Type</label>
                    <h6>{row.type}</h6>
                </div>
                <div class="grid-item">
                    <label>Role</label>
                    <h6>{row.role}</h6>
                </div>                
              </div>
              <div class="portfolio-description">
                  <h4>Description</h4>
                  <p>{row.description}</p>
              </div>
              <div className="certificate_image">
                  <img
                    src={row.fileUpload}
                    alt="project Certificate"
                    className="certificate-image"
                  />
                </div>
                <div class="portfolio-link">
                  <p>Behance</p>
                  <input
                    type="text"
                    value={row.behance}
                    className="portfolio-input"
                    readOnly
                  />
                </div>

                <div class="portfolio-link">
                  <p>Upwork</p>
                  <input
                    type="text"
                    value={row.upwork}
                    className="portfolio-input"
                    readOnly
                  />
                </div>
              </div>              
               ))}                 
              
            </Card>
            {/* Portfolio Section End */}
        </Col>

        <Col lg={3} md={5} sm={12} className="right-section">
            <Card>
              <div>
              <h4>Contact Information</h4>
              </div>        

               {userDetails?.clientSettings?.weblinks.map((row, index) => ( 
              <div className="contact-info" key={index}>
                <img src={internet} alt="Website Icon" class="website-icon" />
                <span className="ms-2">{row}</span>
                <img
                  src={blackArrow}
                  alt="Website Icon"
                  className="blackArrowicon ms-1"
                />
              </div>
             ))}

            <div className="contact-info mt-3">
              <img src={mail} alt="mail Icon" class="website-icon" />
              <span className="ms-2">{userDetails?.email}</span>
            </div>
            <div className="contact-info mt-3">
              <img src={phone} alt="phone Icon" class="website-icon" />
              <span className="ms-2"> + {userDetails?.phoneNumber}</span>
            </div>

            <div className="contact-info mt-3">
              <img src={map} alt="map Icon" class="website-icon" />
              <span className="ms-2">
                {" "}
                1261 Silver Lake Blvd. Suite 22 Los Angeles, CA 90039
              </span>
            </div>      
              <div>
                <div className="mt-4">
                 <h4>Skills</h4>
                </div>                
                <div className="tagList">
                  <ul>
                  {userDetails?.freelancerSettings?.skills.map((row, index) => (             
                      <li  key={index}>
                        <span>{row}</span>                        
                      </li>
                    ))}          
                  </ul>
                </div>
              </div>
              <div>
                <div className="mt-4">
                 <h4>Projects</h4>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <label>Project Views</label>
                  <label>120</label>  
                </div>
                <div className="d-flex justify-content-between my-2">
                  <label>Followers</label>
                  <label>0</label>         
                </div>
                <div className="d-flex justify-content-between my-2">
                  <label>Following</label>
                  <label>0</label>         
                </div>   
              </div>
              <div className="mt-4">
                <div>
                  <h4>Links</h4>
                </div>
                <div class="social-links">
              <a href="#">
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a href="#" className="ms-3">
                <img src={facebook} alt="Facebook" />
              </a>
              <a href="#" className="ms-3">
                <img src={behance} alt="Behance" />
              </a>
              <a href="#" className="ms-3">
                <img src={dribbble} alt="Dribbble" />
              </a>
            </div>
              </div>
            </Card>
          

        </Col>
      </Row>
      </div>
    </div>
  );
};

export default ProfilePreview;
