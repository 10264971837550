// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.des_sec textarea{
  border-radius: 30px !important;
  padding: 20px;
  border: 1px solid #DFDFDF;
}
.des_sec label{
  color:  #626262;
  font-size: 14px;
  margin-top:10px;
}
.upload_image{
  width: 50px;
  height:50px
}
.responsive-row label{
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Profile/CourseTab/CourseTab.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,eAAe;EACf,eAAe;AACjB;AACA;EACE,WAAW;EACX;AACF;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".des_sec textarea{\r\n  border-radius: 30px !important;\r\n  padding: 20px;\r\n  border: 1px solid #DFDFDF;\r\n}\r\n.des_sec label{\r\n  color:  #626262;\r\n  font-size: 14px;\r\n  margin-top:10px;\r\n}\r\n.upload_image{\r\n  width: 50px;\r\n  height:50px\r\n}\r\n.responsive-row label{\r\n  margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
