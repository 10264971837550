import React, { useEffect, useRef, useState } from "react";
import ProjectSkills from "./ProjectSkills";
import Form from "react-bootstrap/Form";
import closeIcon from "../../../Images/icons/close-x.svg";
import uploadIconSvg from "../../../Images/icons/upload.svg";
import "./Project.css";

import {  Row, Col, Card } from "react-bootstrap";
import ApiCall from "../../../Api/ApiCall";
import playBtn from "../../../Images/play_btn.png";
import pdfIcon from "../../../Images/icons/pdf-icon.svg";

const Project = ({userDetails}) => {
  const [showProSkills, SetShowProSkills] = useState(false);
  const [payloadData, setPayloadData] = useState({
    projectName: "",
    description: "",
    categoryId: "",
    category: "",
    subCategory: "",
    searchTags: "",
    searchTagsList: [],
    video: null,
    videoDescription: "",
    photoDescription: "",
    instruction: "",
    images: [],
    certificateFile: "",
    profilePhoto: "",
    coverPhoto: "",
  });
  const [searchTagsList, setSearchTagsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [certificateFile, setCertificateFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const certiInputRef = useRef(null);
  const profileInputRef = useRef(null);
  const coverInputRef = useRef(null);


  console.log(payloadData,"payloadData",searchTagsList, "searchTagsList");

  const handleProSkillNext = (boolean) => {
    SetShowProSkills(boolean);
  };



  const getCategories = async () => {
    setLoading(true);

    try {
      const response = await ApiCall(
        "GET",
        "metadata/getCategories",
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setCategoriesList(response.data);
      } else {
        console.error("Failed to fetch categories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching categories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getSubCategories = async (categoryId) => {
    setLoading(true);
    try {
      const response = await ApiCall(
        "GET",
        `metadata/${categoryId}/subcategories`,
        null,
        "application/json"
      );
      if (response.statusCode === 200) {
        setSubCategoriesList(response.data);
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching subcategories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payloadData.categoryId) {
      getSubCategories(payloadData.categoryId);
    } else {
      setSubCategoriesList([]);
      setPayloadData((prevData) => ({ ...prevData, subCategory: "" }));
    }
  }, [payloadData.category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayloadData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlesearchTagsChange = (e) => {
    setPayloadData({ ...payloadData, searchTags: e.target.value });
  };

  const handleDeleteSearchTags = (index) => {
    setSearchTagsList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    const trimmedTag = payloadData.searchTags.trim();

    if (e.key === "Enter" && trimmedTag) {
      e.preventDefault();

      if (!searchTagsList.includes(trimmedTag)) {
        setSearchTagsList((prev) => [...prev, trimmedTag]);
      }

      setPayloadData({ ...payloadData, searchTags: "" });
    }
  };

  useEffect(() => {
    setPayloadData((prev) => ({ ...prev, searchTagsList }));
  }, [searchTagsList]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("handleFileChange", file, "file");
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const playURL = URL.createObjectURL(file);
        setVideoSrc(playURL);
        setIsPlaying(false); // Reset play state

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // const playURL = URL.createObjectURL(file);
          // setVideoSrc(playURL);
          // setIsPlaying(false); // Reset play state

          setPayloadData((prevData) => ({
            ...prevData,
            video: response.data.s3Url,
          }));
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false); // Hide loading state after upload attempt
      }
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleChooseClick = () => {
    console.log("handleChooseClick");

    fileInputRef.current.click();
  };

  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0]; // Get the file from input
    if (file) {
      setPhoto(file);
      try {
        // Prepare the form data with the uploaded file
        const formData = new FormData();
        formData.append("file", file);

        // Call the API to upload the file
        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // Handle successful file upload response
          // setPhoto(file);
          setPayloadData((prevState) => ({
            ...prevState,
            [field]: response.data.s3Url, // Update the correct field dynamically
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!showProSkills ? (
        <>
          <div className="p-0 p-md-4 pb-4 create_project">
            <div>
              <h5>Create Project</h5>
            </div>
            <div>
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Project Name<sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  name="projectName"
                  value={payloadData?.projectName}
                  onChange={handleChange}
                  maxLength="150"
                />
              </Form.Group>
              <div className="character-count text-end">
                {payloadData.projectName.length}/150 characters
              </div>
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Project Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="write something about project"
                  name="description"
                  value={payloadData?.description}
                  onChange={handleChange}
                />
              </Form.Group>
              <div className="character-count text-end">
                {payloadData.description.length}/5000 Characters
              </div>
              <Row className="mb-3">
                <Col sm={12} md={6} lg={6}>
                  <Form.Label>
                    Select Category<sup className="text-danger">*</sup>
                  </Form.Label>
                  {/* <Form.Select>
                    <option>Select Category</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select> */}

                  <Form.Select
                    id="category"
                    value={payloadData.categoryId}
                    onChange={(e) => {
                      const selectedCategory = categoriesList.find(
                        (category) => category._id === e.target.value
                      );
                      setPayloadData({
                        ...payloadData,
                        categoryId: selectedCategory._id,
                        category: selectedCategory.categoryName,
                      });
                    }}
                    required
                    disabled={loading}
                  >
                    <option value="" disabled>
                      {loading ? "Loading categories..." : "Select Category"}
                    </option>
                    {categoriesList.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.categoryName}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Form.Label>
                    Select Sub Category<sup className="text-danger">*</sup>
                  </Form.Label>
                  {/* <Form.Select aria-label="Select Sub Category*">
                    <option>Select Category</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select> */}

                  <Form.Select
                    id="subCategory"
                    value={payloadData.subCategory}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        subCategory: e.target.value,
                      })
                    }
                    required
                    disabled={loading || !payloadData.category}
                  >
                    <option value="" disabled>
                      {loading
                        ? "Loading subcategories..."
                        : "Select Sub Category"}
                    </option>
                    {subCategoriesList.map((subCategory, index) => (
                      <option key={index} value={subCategory}>
                        {subCategory}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Search Tags</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Search Tags"
                  value={payloadData.searchTags}
                  onChange={handlesearchTagsChange}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>

              {/* <div className="tagList">
                <ul>
                  <li>
                    <span>Figma</span>
                    <span>
                      <img
                        src={closeIcon}
                        alt="Delete Icon"
                        className="close-icon"
                      />
                    </span>
                  </li>
                </ul>
              </div> */}

              <div className="tagList">
                <ul>
                  {searchTagsList.map((tag, index) => (
                    <li key={index}>
                      <span>{tag}</span>
                      <span onClick={() => handleDeleteSearchTags(index)}>
                        <img
                          src={closeIcon}
                          alt="Delete Icon"
                          className="delete-icon"
                        />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="row-service">
                <div className="img-vid">
                  <div className="upload-title">
                    Upload Cover and Images <sup className="text-danger">*</sup>
                  </div>

                  <div className="instruction">
                    Your service must have a video or at least 1 image.
                  </div>

                  <div className="file-size-note">
                    Video file size must be less than 50 MB.
                  </div>
                </div>
              </div>
              <div className="uploaded_img">
                <div className="upload_card">
                  <Card>
                    {coverPhoto ? (
                      <img
                        src={URL.createObjectURL(coverPhoto)}
                        alt="Cover Preview"
                        className="preview-cover"
                      />
                    ) : (
                      <>
                        <div className="text-center">
                          <img src={uploadIconSvg} alt="Upload Icon" width="70px" height="70px" />
                        </div>
                        <p className="text-center my-3">
                          Please upload your cover image
                        </p>
                        <div className="text-center">
                          <button
                            type="button"
                            className="secondary-btn mt-3"
                            onClick={() => handleUploadClick(coverInputRef)}
                          >
                            Choose
                          </button>
                          <input
                            type="file"
                            accept="image/*"
                            ref={coverInputRef}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handlePhotoChange(e, setCoverPhoto, "coverPhoto")
                            }
                          />
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </div>
              <div>
                <h3>Instruction</h3>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Give links or special instructions for your Freelancers to
                    follow.
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Describe instructions here"
                    name="instruction"
                    value={payloadData?.instruction}
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="character-count text-end">
                  {payloadData.instruction.length}/1000 characters
                </div>
              </div>
              <div>
                <div>
                  <h3>Upload Video</h3>
                  <Form.Label>
                    Add a video that explains your project, or just to say hello
                    on your listing.
                  </Form.Label>
                </div>
                <div className="browse_sec">
                  <Row>
                    <Col sm={12} md={4} lg={3}>
                      <Card className="create_project_browse">
                        <div className="">
                          {videoSrc ? (
                            <div className="video-preview position-relative">
                              {/* Video Preview */}
                              <video
                                ref={videoRef}
                                src={videoSrc}
                                className="w-100" // Adjust size as needed, full width of the container
                                onClick={handlePlayPause} // Toggle play/pause on click
                                onEnded={() => setIsPlaying(false)} // Reset play state when video ends
                                style={{ cursor: "pointer" }}
                                controls={false} // Hide default controls
                              />
                              {/* Play/Pause Button */}
                              <button
                                onClick={handlePlayPause}
                                className="play-button position-absolute"
                                style={{
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  background: "transparent",
                                  border: "none",
                                }}
                              >
                                <div className="play_btn">
                                  <img
                                    src={isPlaying ? "pauseIcon" : playBtn}
                                    alt={isPlaying ? "Pause" : "Play"}
                                  />
                                </div>
                              </button>
                            </div>
                          ) : (
                            <>
                              <div className="text-center">
                                <img src={uploadIconSvg} alt="Upload Icon" />
                              </div>
                              <h5>Please upload your Video</h5>
                              <div>
                                <a onClick={handleChooseClick}>Browse</a>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  accept="video/*"
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </Card>
                    </Col>
                    <Col sm={12} md={8} lg={9}>
                      <Form.Group className="mb-1 mt-3 mt-md-0">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Describe here"
                          name="videoDescription"
                          value={payloadData?.videoDescription}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
              <div>
                <div>
                  <h3>Photo</h3>
                  <Form.Label>
                    Add a photo that explains your project, or just to say hello
                    on your listing.
                  </Form.Label>
                </div>
                <div className="browse_sec">
                  <Row>
                    <Col sm={12} md={4} lg={3}>
                      <Card className="create_project_browse">
                        {profilePhoto ? (
                          <img
                            src={URL.createObjectURL(profilePhoto)}
                            alt="Profile Preview"
                            className="preview-image"
                          />
                        ) : (
                          <>
                            <div className="text-center">
                              <img src={uploadIconSvg} alt="Upload Icon" />
                            </div>
                            <h5>Please upload your photo</h5>
                            <div>
                              <a
                                onClick={() =>
                                  handleUploadClick(profileInputRef)
                                }
                              >
                                Browse
                              </a>
                              <input
                                type="file"
                                accept=".png,.jpeg,.jpg"
                                ref={profileInputRef}
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  handlePhotoChange(
                                    e,
                                    setProfilePhoto,
                                    "profilePhoto"
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </Card>
                    </Col>
                    <Col sm={12} md={8} lg={9}>
                      <Form.Group className="mb-1 mt-3 mt-md-0">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Describe here"
                          name="photoDescription"
                          value={payloadData?.photoDescription}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
              <div>
                <div>
                  <h3>Upload Document</h3>
                </div>
                <Card className="upload_doc_card">
                  <div className="d-flex justify-content-between align-items-center flex_wrap">
                    {certificateFile ? (
                      <div className="file-preview">
                        <div className="file-details">
                          <img
                            src={pdfIcon}
                            alt="PDF Icon"
                            className="file-icon"
                          />
                          <div className="file-info ms-3">
                            
                            <p className="file-title mb-0">{certificateFile.name}</p>
                            <p className="file-meta mb-0">
                              {certificateFile.size} KB ·{" "}
                              {new Date(
                                certificateFile.lastModified
                              ).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        {/* <div className="file-actions">
                          <button >
                            <img src={downloadIcon} alt="Download Icon" />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              // handleDelete();
                            }}
                          >
                            <img src={trashIcon} alt="Delete Icon" />
                          </button>
                        </div> */}
                      </div>
                    ) : (
                      <>
                        <div>
                          <img
                            src={uploadIconSvg}
                            alt="Upload Icon"
                            width="40px"
                            height="40px"
                          />
                          <label>
                            Please upload your documents (Doc , Pdf..etc)
                          </label>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="secondary-btn"
                            onClick={() => handleUploadClick(certiInputRef)}
                          >
                            Choose
                          </button>
                          <input
                            type="file"
                            accept=".pdf,.docx"
                            style={{ display: "none" }}
                            ref={certiInputRef}
                            onChange={(e) =>
                              handlePhotoChange(
                                e,
                                setCertificateFile,
                                "certificateFile"
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="text-end">
            <button
              type="button"
              className="primary-btn"
              onClick={()=>handleProSkillNext(true)}
            >
              Save and Continue
            </button>
          </div>
        </>
      ) : (
        <ProjectSkills payloadData={payloadData}setPayloadData={setPayloadData} handleBack={()=>handleProSkillNext()} videoSrc={videoSrc} userDetails={userDetails}/>
      )}
    </>
  );
};

export default Project;
