import React, { useState, useRef, useEffect } from "react";
import "./CourseTab.css";
import addIcon from "../../../../Images/icons/experience-icon.png";
import deleteIcon from "../../../../Images/icons/delete-icon.png";
import uploadIconSvg from "../../../../Images/icons/upload.svg";
import pdfIcon from "../../../../Images/icons/pdf-icon.svg";
import downloadIcon from "../../../../Images/icons/download-icon.svg";
import trashIcon from "../../../../Images/icons/trash-icon.svg";
import ApiCall from "../../../../Api/ApiCall";
import Loader from "../../../Common/Loader/Loader";
import { Card } from "react-bootstrap";
import {
  formatDateString,
  formatDateToDDMMYYYYC,
  formatForInput,
} from "../../../Common/utils/utils";

const CourseTab = ({ userDetails, onGetData }) => {
  // Array to store references for each file input
  const [certificationRows, setCertificationRows] = useState([
    {
      certification: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
      certificateFile: "",
    },
  ]);

  const certificationInputRefs = useRef([]); // Array of refs for all rows
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    if (
      userDetails?.freelancerSettings?.courses &&
      userDetails?.freelancerSettings?.courses.length > 0
    ) {
      const extractedFileUrls = userDetails?.freelancerSettings?.courses.map(
        (course) => course.file || []
      );
      setFileUrls(extractedFileUrls);

      const formattedCourses = userDetails?.freelancerSettings?.courses.map(
        (course, index) => {
          return {
            certification: course.certificateName,
            company: course.company,
            start: formatDateString(course.start),
            end: formatDateString(course.end),
            description: course.description,
            certificateFile:
              course.file && course.file.length > 0 ? course.file[0] : "",
          };
        }
      );

      setCertificationRows(formattedCourses);
    }
  }, [userDetails]);

  useEffect(() => {
    const formattedData = certificationRows.map((row, index) => ({
      certificateName: row.certification,
      company: row.company,
      start: formatDateToDDMMYYYYC(row?.start),
      end: formatDateToDDMMYYYYC(row?.end),
      description: row.description,
      file:
        fileUrls[index] !== undefined || fileUrls[index] !== null
          ? fileUrls[index]
          : row.certificateFile || "",
    }));

    onGetData({ courses: formattedData });
  }, [certificationRows, fileUrls]);

  const handleAddRow = () => {
    setCertificationRows((prevRows) => [
      ...prevRows,
      {
        certification: "",
        company: "",
        startDate: "",
        endDate: "",
        description: "",
        certificateFile: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = certificationRows.filter((_, i) => i !== index);
    setCertificationRows(updatedRows);
    certificationInputRefs.current.splice(index, 1);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...certificationRows];
    updatedRows[index][field] = value;
    setCertificationRows(updatedRows);
  };

  const handlePhotoChange = async (e, index) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          const updatedRows = [...certificationRows];
          updatedRows[index].certificateFile = file;
          setCertificationRows(updatedRows);

          setFileUrls((prevUrls) => {
            const updatedUrls = [...prevUrls];
            updatedUrls[index] = response.data.s3Url;
            return updatedUrls;
          });
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadClick = (index) => {
    certificationInputRefs.current[index].click(); // Trigger file input click for a specific row
  };

  // const handleDownload = (index) => {
  //   const fileURL = certificationRows[index].certificateFile;
  //   if (fileURL) {
  //     const link = document.createElement("a");
  //     link.href = fileURL;
  //     link.target = "_blank";
  //     link.download = fileURL.split("/").pop();
  //     link.click();
  //   } else {
  //     console.error("No file URL available for download");
  //   }
  // };

  const handleDownload = (index) => {
    const fileURL = certificationRows[index].certificateFile;
  
    if (typeof fileURL === "string" && fileURL) {
      const link = document.createElement("a");
      link.href = fileURL;
      link.target = "_blank";
      link.download = fileURL.split("/").pop(); // Extract the filename from the URL
      link.click();
    } else {
      console.error("No valid file URL available for download");
    }
  };
  

  const handleDelete = (e, index) => {
    const updatedRows = [...certificationRows];
    updatedRows[index].certificateFile = null;
    setCertificationRows(updatedRows);

    const updatedFileUrls = [...fileUrls];
    updatedFileUrls[index] = "";

    setFileUrls(updatedFileUrls);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="education-section">
        <div className="education-header">
          <div className="education-header-left">
            <img src={addIcon} alt="Add Icon" />
            <h2>Kindly include any courses and certifications here..</h2>
          </div>
          <div className="education-header-right">
            <button className="add-button" onClick={handleAddRow}>
              Add
            </button>
          </div>
        </div>
        {certificationRows.map((row, index) => (
          <div  key={index}>
            {/* First row for certification details */}
            <div className="responsive-row">
              <div className="input-group">
                <label htmlFor={`certification-${index}`}>
                  Certification<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id={`certification-${index}`}
                  value={row.certification}
                  onChange={(e) =>
                    handleChange(index, "certification", e.target.value)
                  }
                  placeholder="Certification"
                />
              </div>

              <div className="input-group">
                <label htmlFor={`company-${index}`}>
                  Company<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id={`company-${index}`}
                  value={row.company}
                  onChange={(e) =>
                    handleChange(index, "company", e.target.value)
                  }
                  placeholder="Company"
                />
              </div>

              {/* Starting from Field */}
              <div className="input-group">
                <label htmlFor={`start-${index}`}>
                  Start Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id={`start-${index}`}
                  placeholder="Starting from *"
                  value={formatForInput(row.start)} // Change here
                  onChange={(e) => handleChange(index, "start", e.target.value)} // Change here
                  required
                />
              </div>

              {/* Ending Field */}
              <div className="input-group">
                <label htmlFor={`end-${index}`}>
                  End Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id={`end-${index}`}
                  placeholder="Ending *"
                  value={formatForInput(row.end)} // Change here
                  onChange={(e) => handleChange(index, "end", e.target.value)} // Change here
                  required
                />
              </div>

              {/* Delete Button */}
              <button
                className="delete-button"
                onClick={() => handleDeleteRow(index)}
              >
                <img src={deleteIcon} alt="Delete Icon" />
              </button>
              </div>
            

            {/* Second row for description */}
           
              <div className="input-group des_sec">
                <label className="mb-1" htmlFor={`description-${index}`}>Description</label>
                <textarea
                  id={`description-${index}`}
                  value={row.description}
                  onChange={(e) =>
                    handleChange(index, "description", e.target.value)
                  }
                  placeholder="Brief description about your certification"
                  className="description-textarea"
                />
              </div>
           

            {/* Third row for certificate file upload */}
            <Card className="upload_doc_card mt-3">
            <div className="d-flex justify-content-between align-items-center">
               
                  {row.certificateFile ? (
                    <div className="file-preview">
                      <div className="file-details">
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          className="file-icon"
                        />
                        <div className="file-info">
                          <p className="file-title">
                            {row.certificateFile.name}
                          </p>
                          <p className="file-meta">
                            {row.certificateFile.size} KB ·{" "}
                            {new Date(
                              row.certificateFile.lastModified
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <div className="file-actions">
                        <button onClick={() => handleDownload(index)}>
                          <img src={downloadIcon} alt="Download Icon" />
                        </button>
                        <button
                          onClick={(e) => {
                            if (
                              window.confirm("Do you want to delete this file?")
                            )
                              handleDelete(e, index);
                          }}
                        >
                          <img src={trashIcon} alt="Delete Icon" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                     <div className="d-flex align-items-center">
                      <img src={uploadIconSvg} alt="Upload Icon" className="upload_image" />
                      <p className="ms-3 mb-0">Please upload your Certificates</p>
                      </div>
                    </>
                  )}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    ref={(el) => (certificationInputRefs.current[index] = el)}
                    style={{ display: "none" }}
                    onChange={(e) => handlePhotoChange(e, index)}
                  />

                  <button
                    className="secondary-btn"
                    onClick={() => handleUploadClick(index)}
                  >
                    Choose
                  </button>
                
              </div>
              </Card>
            
          </div>
        ))}
      </div>
    </>
  );
};

export default CourseTab;
