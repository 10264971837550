export const TopRightShape = () => {
    return (
        <svg
            className="top_right_shape"
            width="200"
            viewBox="0 0 206 223"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                opacity="0.1"
                cx="145"
                cy="78"
                r="145"
                fill="#FF6347"
            />
        </svg>
    )
}

export const TopLeftShape = () => {
    return (
        <svg
            className="top_left_shape"
            width="125"
            viewBox="0 0 165 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                opacity="0.05"
                cx="20"
                cy="-42"
                r="145"
                fill="#FF6347"
            />
        </svg>
    )
}

export const BottomShape = () => {
    return (
        <svg
            className="bottom_shape"
            width="250"
            viewBox="0 0 290 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                opacity="0.1"
                cx="145"
                cy="145"
                r="145"
                fill="#FF6347"
            />
        </svg>
    )
}

export const BottomCircle = () => {
    return (
        <svg
            className="bottom_circle"
            width="25"
            viewBox="0 0 37 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="1.74791"
                cy="20.621"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 1.74791 20.621)"
                fill="white"
            />
            <ellipse
                cx="1.74791"
                cy="11.2812"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 1.74791 11.2812)"
                fill="white"
            />
            <ellipse
                cx="1.74791"
                cy="1.94527"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 1.74791 1.94527)"
                fill="white"
            />
            <ellipse
                cx="10.0487"
                cy="20.621"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 10.0487 20.621)"
                fill="white"
            />
            <ellipse
                cx="10.0487"
                cy="11.2812"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 10.0487 11.2812)"
                fill="white"
            />
            <ellipse
                cx="10.0487"
                cy="1.94527"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 10.0487 1.94527)"
                fill="white"
            />
            <ellipse
                cx="18.3504"
                cy="20.621"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 18.3504 20.621)"
                fill="white"
            />
            <ellipse
                cx="18.3504"
                cy="11.2851"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 18.3504 11.2851)"
                fill="white"
            />
            <ellipse
                cx="18.3504"
                cy="1.94527"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 18.3504 1.94527)"
                fill="white"
            />
            <ellipse
                cx="26.6502"
                cy="20.621"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 26.6502 20.621)"
                fill="white"
            />
            <ellipse
                cx="26.6502"
                cy="11.2812"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 26.6502 11.2812)"
                fill="white"
            />
            <ellipse
                cx="26.6502"
                cy="1.94527"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 26.6502 1.94527)"
                fill="white"
            />
            <ellipse
                cx="34.9512"
                cy="20.621"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 34.9512 20.621)"
                fill="white"
            />
            <ellipse
                cx="34.9513"
                cy="11.2812"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 34.9513 11.2812)"
                fill="white"
            />
            <ellipse
                cx="34.9513"
                cy="1.94527"
                rx="1.94536"
                ry="1.72935"
                transform="rotate(-90 34.9513 1.94527)"
                fill="white"
            />
        </svg>
    )
}

export const BottomVerticalCircle = () => {
    return (
        <svg
            className="bottom_vertical_circle"
            width="15"
            viewBox="0 0 23 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="2.01176"
                cy="2.68248"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="11.3497"
                cy="2.68248"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="20.6871"
                cy="2.68248"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="2.01176"
                cy="10.9833"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="11.3497"
                cy="10.9833"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="20.6871"
                cy="10.9833"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="2.01176"
                cy="19.284"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="11.3497"
                cy="19.284"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="20.6871"
                cy="19.284"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="2.01176"
                cy="27.5848"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="11.3497"
                cy="27.5848"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="20.6871"
                cy="27.5848"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="2.01176"
                cy="35.8856"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="11.3497"
                cy="35.8856"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
            <ellipse
                cx="20.6871"
                cy="35.8856"
                rx="1.94536"
                ry="1.72935"
                fill="white"
            />
        </svg>
    )
}

export const NavigationIcon = () => {
    return (
        <svg
            className="position-absolute top-50 start-0 translate-middle-y ms-2 ps-2"
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.308 13.4314C12.886 13.4314 14.1652 12.0345 14.1652 10.3114C14.1652 8.58828 12.886 7.19141 11.308 7.19141C9.73009 7.19141 8.4509 8.58828 8.4509 10.3114C8.4509 12.0345 9.73009 13.4314 11.308 13.4314Z"
                stroke="#F0806C"
                stroke-width="1.5"
            />
            <path
                d="M3.63403 8.49C5.43807 -0.169998 17.1872 -0.159997 18.9821 8.5C20.0352 13.58 17.1414 17.88 14.6048 20.54C12.7641 22.48 9.852 22.48 8.00218 20.54C5.4747 17.88 2.58092 13.57 3.63403 8.49Z"
                stroke="#F0806C"
                stroke-width="1.5"
            />
        </svg>
    )
} 