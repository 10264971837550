import React from "react";
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import RegisterCongratsImg from '../../Images/freela-icons/RegisterCongratsImg.svg';
import { ReactComponent as FreelaLogo } from '../../Images/freela-icons/freela-logo.svg';


const RegisterCongrats = ({ openLogin }) => {

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form behavior
        openLogin(); // Call the function to open the login modal
    }

    return (
        <Row className="login_form align-items-center">
            {/* Left Section */}
            <Col sm={12} md={12} lg={5} xl={5}>
                <FreelaLogo className="ms-lg-4 mt-lg-2" width={260} />
                <div className="d-flex align-items-center justify-content-center my-xl-5 py-xl-2">
                    <Image src={RegisterCongratsImg} alt="LoginImg" fluid />
                </div>
            </Col>

            {/* Right Section */}
            <Col sm={12} md={12} lg={7} xl={7} className="text-center">
                <h1 className="fs-3 freela-text-primary">Congratulations!</h1>
                <p className="text-secondary">your account has been successfully created.<br />
                    continue your journey.</p>
                <Form onSubmit={handleSubmit} className="mt-5 pt-xl-3">
                    <Form.Group as={Row} className="text-center mt-5">
                        <Col md={6} className="mt-2 mx-auto d-grid">
                            <Button type="submit" variant="" className="freela-btn-primary px-5">Continue</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    );
};

export default RegisterCongrats;


