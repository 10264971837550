import React, { useEffect, useState } from "react";
import "./ExperienceTab.css";
import addIcon from "../../../../Images/icons/Book_light.svg"; // import your icon
import deleteIcon from "../../../../Images/icons/delete-icon.png"; // import delete icon
import { formatDateString, formatForInput, formatDateToDDMMYYYY } from '../../../Common/utils/utils';

const ExperienceTab = ({ userDetails, onGetData }) => {
  const [experienceRows, setExperienceRows] = useState([
    { designation: "", company: "", start: "", end: "" }, // Change startDate and endDate to start and end
  ]);


  useEffect(() => {
    // Check if userDetails has experience data and set initial state
    if (userDetails?.freelancerSettings?.experience && userDetails?.freelancerSettings?.experience.length > 0) {
      const formattedExperience = userDetails?.freelancerSettings?.experience.map((exp) => ({
        designation: exp.designation,
        company: exp.company,
        start: formatDateString(exp.start), // Change here
        end: formatDateString(exp.end),     // Change here
      }));
      setExperienceRows(formattedExperience);
    }
  }, [userDetails]);

  useEffect(() => {
    // Map to the required structure before passing data to parent component
    const formattedData = experienceRows.map((row) => ({
      designation: row.designation,
      company: row.company,
      start: formatDateToDDMMYYYY(row.start), // Change here
      end: formatDateToDDMMYYYY(row.end),     // Change here
    }));
    onGetData({ experience: formattedData }); // Wrap in an object
  }, [experienceRows]);

  const handleAddRow = () => {
    setExperienceRows([
      ...experienceRows,
      { designation: "", company: "", start: "", end: "" }, // Change here
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = experienceRows.filter((_, i) => i !== index);
    setExperienceRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...experienceRows];
    updatedRows[index][field] = value;
    setExperienceRows(updatedRows);
  };

  return (
    <div className="education-section">
      {/* First Row: Title and Add Button */}
      <div className="education-header">
        <div className="education-header-left">
          <img src={addIcon} alt="Add Icon" />
          <h2>Add experience here.</h2>
        </div>
        <div className="education-header-right">
          <button className="add-button" onClick={handleAddRow}>
            Add
          </button>
        </div>
      </div>

      {/* Dynamic Rows for Experience Fields */}
      {experienceRows.map((row, index) => (
        <div className="responsive-row" key={index}>
          {/* Designation Field */}
          <div className="input-group">
            <label htmlFor={`designation-${index}`}>Designation <span className="text-danger">*</span></label>
            <input
              type="text"
              id={`designation-${index}`}
              placeholder="Designation"
              value={row.designation}
              onChange={(e) => handleChange(index, "designation", e.target.value)}
              required
            />
          </div>

          {/* Company Name Field */}
          <div className="input-group">
            <label htmlFor={`company-${index}`}>Company Name <span className="text-danger">*</span></label>
            <input
              type="text"
              id={`company-${index}`}
              placeholder="Company Name"
              value={row.company}
              onChange={(e) => handleChange(index, "company", e.target.value)}
              required
            />
          </div>

          {/* Starting from Field */}
          <div className="input-group">
            <label htmlFor={`start-${index}`}>Starting from <span className="text-danger">*</span></label>
            <input
              type="date"
              id={`start-${index}`}
              placeholder="Starting from *"
              value={formatForInput(row.start)} // Change here
              onChange={(e) => handleChange(index, "start", e.target.value)} // Change here
              required
            />
          </div>

          {/* Ending Field */}
          <div className="input-group">
            <label htmlFor={`end-${index}`}>Ending <span className="text-danger">*</span></label>
            <input
              type="date"
              id={`end-${index}`}
              placeholder="Ending *"
              value={formatForInput(row.end)} // Change here
              onChange={(e) => handleChange(index, "end", e.target.value)} // Change here
              required
            />
          </div>

          {/* Delete Button */}
          <button className="delete-button" onClick={() => handleDeleteRow(index)}>
            <img src={deleteIcon} alt="Delete Icon" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ExperienceTab;
