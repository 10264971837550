import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Nav,
  Image,
  Stack,
  Button,
  Figure,
  Form,
  Modal,
} from "react-bootstrap";
import profileImg from "../../../../Images/freela-images/profile.png";
import { ReactComponent as ProfileVerifyIcon } from "../../../../Images/freela-icons/verify-shield-icon.svg";
import { ReactComponent as SmaileIcon } from "../../../../Images/freela-icons/smile-icon.svg";
import { ReactComponent as AirpalnIcon } from "../../../../Images/freela-icons/airplan-icon.svg";
import { ReactComponent as PaperClipIcon } from "../../../../Images/freela-icons/paperclip-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../../Images/freela-icons/phone-icon.svg";
import { ReactComponent as ChatIcon } from "../../../../Images/freela-icons/chat-icon.svg";
import { ReactComponent as ScreenShare2Icon } from "../../../../Images/freela-icons/screen-share2.svg";
import { ReactComponent as ModalCloseIcon } from "../../../../Images/freela-icons/modal-close.svg";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import {
  calculateDueTime,
  formatAmPmTime,
  formatDate,
} from "../../../Common/utils/utils";
import PaymentModes from "../../../Common/PaymentModes";
import Dispute from "./Dispute";

const MyWorkspace = ({ role, getIds }) => {
  // console.log(getIds, "getIds");
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const [raiseDisputeModal, setRaiseDisputeModal] = useState(false);
  const [singleProfileData, setSingleProfileData] = useState({});
  const [profileList, setProfileList] = useState([]);
  const areGetIdsEmpty = Object.values(getIds).every((value) => value === "");
  // console.log(areGetIdsEmpty, "areGetIdsEmpty");

  const debouncedGetActiveRoleDetails = debounce(() => {
    getProfileList();
    handleGetDetails();
  }, 300);

  useEffect(() => {
    if (!areGetIdsEmpty) debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  // console.log(role, "role in MyTasks");

  const getProfileList = async () => {
    setLoading(true);
    setProfileList([]);
    try {
      const endpoint = `metadata/getChatHistory?projectId=${getIds.projectId}&clientId=${getIds.clientId}&freelancerId=${getIds.freelancerId}`;

      //console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setProfileList(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetDetails = async () => {
    setLoading(true);
    setSingleProfileData([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `project/viewWorkspaceDetails/${getIds.projectId}`
          : `project/viewWorkspaceDetails/${getIds.projectId}`;
      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setSingleProfileData(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const paymentData = singleProfileData?.acceptedPaymentModes;

  const handleUpdateBid = async () => {
    setLoading(true);

    try {
      const payload = {
        projectId: getIds?.projectId,
        projectStatus: "Completed",
        bidStage: "Completed",
      };
      const response = await ApiCall(
        "POST",
        "project/updateProjectActivity",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        // console.log("successful:", response.data);
        notify("success", "You have finished the project");
      } else {
        notify(
          "error",
          response.message || "Proposal failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const ReUseCard = () => {
    return (
      <>
        <Row className="gy-4">
          <Col xs={12}>
            <div className="top_content">
              <h3>My Workspace</h3>
              <p className="freela-text-greaseblack">
                Lorem ipsum is a dummy or placeholder text commonly used in
                graphic design
              </p>
            </div>
          </Col>
          <Col lg={4} xl={3} xxl={3}>
            <Card className="freela-bg-greylight3 freela-border-greylight8 rounded-5 overflow-hidden">
              <Card.Header className="border-0 freela-bg-primary-outline py-4">
                <Card.Title as="h6" className="mb-0">
                  Progress
                </Card.Title>
              </Card.Header>
              <Card.Body className="pe-1">
                <Nav className="flex-column flex-nowrap get_profiles">
                  {profileList.map((list, idx) => (
                    <Nav.Item
                      key={idx}
                      className="profile_single d-flex align-items-start gap-2 mb-4"
                    >
                      <Image
                        src={profileImg}
                        alt="profileImg"
                        className="p-05 object-fit-cover border-1 border-solid freela-border-primary"
                        roundedCircle
                        width={35}
                        height={35}
                      />
                      <div className="profile_content">
                        <h6 className="mb-0 fw-400 text-truncate">
                          {/* {list.name} */}
                          Rajasri
                        </h6>
                        <div className="mb-0 d-flex freela-text-greaseblack">
                          <div className="icon-block">
                            {list.entryType === "upload" ? (
                              <PaperClipIcon
                                className="me-1 filter-bio d-inline-flex"
                                width={18}
                                height={17}
                              />
                            ) : list.entryType === "audio_call" ? (
                              <PhoneIcon
                                className="me-1 filter-bio d-inline-flex"
                                width={14}
                                height={14}
                              />
                            ) : list.entryType === "video_call" ? (
                              <PhoneIcon
                                className="me-1 filter-bio d-inline-flex"
                                width={14}
                                height={14}
                              />
                            ) : list.entryType === "chat" ? (
                              <ChatIcon
                                className="me-1 filter-bio d-inline-flex"
                                width={14}
                                height={14}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          {formatAmPmTime(list.timestamp)}
                        </div>
                      </div>
                    </Nav.Item>
                  ))}
                </Nav>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8} xl={9} xxl={9}>
            <Card className="freela-bg-greylight3 freela-border-greylight8 rounded-5 overflow-hidden">
              <Card.Header className="d-flex align-items-center justify-content-between gap-2 border-0 freela-bg-primary-outline py-3">
                <Card.Title as="h6" className="mb-0">
                  {singleProfileData?.title}
                </Card.Title>
                <Stack direction="horizontal" className="gap-3">
                  <Button
                    variant=""
                    size="sm"
                    className="freela-btn-primary-outline"
                    onClick={() => setRaiseDisputeModal(true)}
                  >
                    Dispute
                  </Button>

                  <Button
                    variant=""
                    size="sm"
                    className="freela-btn-primary-outline"
                  >
                    Wallet
                  </Button>
                  <Button
                    variant=""
                    size="sm"
                    className="freela-btn-primary-outline"
                    onClick={() => handleUpdateBid()}
                  >
                    Completed
                  </Button>
                </Stack>
              </Card.Header>
              <Card.Body>
                <Row className="gy-4">
                  <Col md={6} lg={4} xl={2}>
                    <div className="freela-text-greaseblock">Freelancer</div>
                    <div className="d-flex align-items-center gap-2">
                      <div className="position-relative">
                        <Image
                          src={
                            singleProfileData?.assignedUserprofilePhoto ||
                            profileImg
                          }
                          alt="freelancerImg"
                          className="p-05 object-fit-cover border-1 border-solid freela-border-primary"
                          roundedCircle
                          width={35}
                          height={35}
                        />
                        <ProfileVerifyIcon
                          width={14}
                          height={14}
                          className="position-absolute bottom-0 end-0"
                        />
                      </div>
                      <h6 className="text-truncate freela-fs-14px">
                        {singleProfileData?.assignedUserfirstName}
                      </h6>
                    </div>
                  </Col>
                  <Col md={6} lg={4} xl={2}>
                    <div className="freela-text-greaseblock">Client</div>
                    <div className="d-flex align-items-center gap-2">
                      <div className="position-relative">
                        <Image
                          src={singleProfileData?.profilePhoto || profileImg}
                          alt="freelancerImg"
                          className="p-05 object-fit-cover border-1 border-solid freela-border-primary"
                          roundedCircle
                          width={35}
                          height={35}
                        />
                        <ProfileVerifyIcon
                          width={14}
                          height={14}
                          className="position-absolute bottom-0 end-0"
                        />
                      </div>
                      <h6 className="text-truncate freela-fs-14px">
                        {singleProfileData?.firstName}
                      </h6>
                    </div>
                  </Col>
                  <Col md={6} lg={4} xl={2}>
                    <div className="freela-text-greaseblock">Bid Date</div>
                    <h6 className="freela-fs-14px">
                      {formatDate(singleProfileData?.bidDate)}
                    </h6>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <div className="freela-text-greaseblock">Timeline</div>
                    <h6 className="freela-fs-14px">
                      {formatDate(singleProfileData?.deliveryDate)}
                    </h6>
                  </Col>
                  <Col md={6} lg={4} xl={3}>
                    <div className="freela-text-greaseblock">
                      ID-{singleProfileData.id}
                    </div>
                    <h6 className="freela-fs-14px d-flex align-items-center gap-1">
                      <span>Payment mode</span>

                      <PaymentModes
                        paymentData={paymentData}
                        type="workSpace"
                      />
                    </h6>
                  </Col>
                </Row>

                <div className="divider border-bottom freela-border-greylight8 my-3"></div>

                <Row className="gy-4">
                  <Col xs={6} sm={6} md={4} lg={4} xl={3}>
                    <div className="freela-text-greaseblock mb-1">Payment</div>
                    <div className="p-1 pe-4 d-inline-flex align-items-center freela-text-primary border-1 border-solid freela-border-primary rounded-pill">
                      <svg
                        className="d-inline-block me-1"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
                          stroke="#989898"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 6V18"
                          stroke="#989898"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#989898"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      $ {singleProfileData?.amountforFreelancer}
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={4} lg={4} xl={3}>
                    <div className="freela-text-greaseblock mb-1">Due Time</div>
                    <div className="p-1 pe-3 d-inline-flex align-items-center freela-text-primary border-1 border-solid freela-border-primary rounded-pill">
                      <svg
                        className="d-inline-block me-1"
                        width="20"
                        height="19"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5 12C22.5 17.52 18.02 22 12.5 22C6.98 22 2.5 17.52 2.5 12C2.5 6.48 6.98 2 12.5 2C18.02 2 22.5 6.48 22.5 12Z"
                          stroke="#989898"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.2099 15.1817L13.1099 13.3317C12.5699 13.0117 12.1299 12.2417 12.1299 11.6117V7.51172"
                          stroke="#989898"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {calculateDueTime(singleProfileData?.projectDueDate)}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <p className="freela-text-greaseblack">
                      {singleProfileData.description}
                    </p>
                    {/* <p className="freela-text-greaseblack">
                      Link {singleProfileData.link}
                    </p> */}
                  </Col>
                  {/* <Col sm={8} md={6} lg={6}>
                    <Card className="freela-bg-greylight9 mb-3 freela-border-greylight10 d-flex p-3 flex-row gap-4 rounded-4 mb-0">
                      <Figure className="d-flex gap-2 mb-0">
                        <PdfIcon width={40} height={40} />
                        <Figure.Caption className="file-fetails">
                          <p className="file-title text-dark mb-0 w-75 text-truncate">
                            {" "}
                            {singleProfileData.fileName}
                          </p>
                          <p className="file-meta mb-0">
                            {singleProfileData.filesizeDate}
                          </p>
                        </Figure.Caption>
                      </Figure>
                      <Stack
                        direction="horizontal"
                        className="ms-auto ms-xl-5 gap-2"
                      >
                        <Button variant="link p-0 freela-greaseblack-text">
                          <i className="bi bi-download" />
                        </Button>
                        <Button variant="link p-0 freela-danger-text">
                          <i className="bi bi-trash3" />
                        </Button>
                      </Stack>
                    </Card>
                  </Col> */}
                </Row>

                <Row className="gy-4">
                  <Col>
                    <Form.Group className="position-relative">
                      <Form.Control
                        className="py-3 ps-4 rounded-2"
                        placeholder="Type a new message here"
                      />
                      <Stack
                        direction="horizontal"
                        className="border-start ps-2 border-1 border-freela-greylight8 gap-2 me-2 position-absolute top-50 end-0 translate-middle-y"
                      >
                        <Button variant="link p-0">
                          <SmaileIcon className="filter-bio" width={18} />
                        </Button>
                        <Button variant="link p-0">
                          <AirpalnIcon className="filter-bio" width={18} />
                        </Button>
                      </Stack>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Stack
                      direction="horizontal"
                      className="rounded-2 freela-bg-white p-2 border border-freela-greylight8 gap-2"
                    >
                      <Button variant="link p-0">
                        <PaperClipIcon className="filter-bio" width={20} />
                      </Button>
                      <Button variant="link p-0">
                        <PhoneIcon className="filter-bio" width={18} />
                      </Button>
                      <Button variant="link p-0">
                        <ChatIcon className="filter-bio" width={18} />
                      </Button>
                      <Button variant="link p-0">
                        <ScreenShare2Icon className="filter-bio" width={18} />
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {areGetIdsEmpty ? (
        "No Records Found"
      ) : (
        <div className="my_workspace">
          {role === "client" ? <ReUseCard /> : <ReUseCard />}

          {raiseDisputeModal && (
            <Modal
              show={raiseDisputeModal}
              onHide={() => setRaiseDisputeModal(false)}
              size="md"
              className="freela-modal"
              centered
            >
              <Modal.Body>
                <Button
                  variant="link"
                  className="close-icon"
                  onClick={() => setRaiseDisputeModal(false)}
                >
                  <ModalCloseIcon width={30} />
                </Button>
                <Dispute projectId={getIds.projectId} />
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default MyWorkspace;
