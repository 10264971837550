// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project_skills .card{
    border-radius: 30px;
    padding: 20px;
}
.project_skills .card p{
    color: #626262;;
}
.project_skills h5{
    font-size: 22px;
    font-weight: 400;
}
.time_card .input-group{
    display: flex;
    flex-direction: row;
    width: 40%;
}
.time_card .input-group button{
    border: 1px solid #DFDFDF;
    background: #F4F4F4;
    color: #626262;
    font-weight: 400;
    font-size: 16px;
}
.time_card .input-group button.dropdown-toggle::after{
    margin-left:15px;
}
.project_skills h5{
    margin-top:2rem;;
    margin-bottom: 1rem;
}
.payment_section h5{
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 60px;
    display: flex;
    align-items: end;
}
@media (max-width:540px) {
    .time_card .input-group{       
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Project/ProjectSkills.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".project_skills .card{\r\n    border-radius: 30px;\r\n    padding: 20px;\r\n}\r\n.project_skills .card p{\r\n    color: #626262;;\r\n}\r\n.project_skills h5{\r\n    font-size: 22px;\r\n    font-weight: 400;\r\n}\r\n.time_card .input-group{\r\n    display: flex;\r\n    flex-direction: row;\r\n    width: 40%;\r\n}\r\n.time_card .input-group button{\r\n    border: 1px solid #DFDFDF;\r\n    background: #F4F4F4;\r\n    color: #626262;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n}\r\n.time_card .input-group button.dropdown-toggle::after{\r\n    margin-left:15px;\r\n}\r\n.project_skills h5{\r\n    margin-top:2rem;;\r\n    margin-bottom: 1rem;\r\n}\r\n.payment_section h5{\r\n    margin-top: 2rem;\r\n    margin-bottom: 1rem;\r\n    height: 60px;\r\n    display: flex;\r\n    align-items: end;\r\n}\r\n@media (max-width:540px) {\r\n    .time_card .input-group{       \r\n        width: 90%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
