import React, { useEffect, useState } from "react";
import "./ExperienceTab.css";
import addIcon from "../../../../Images/icons/Book_light.svg"; // import your icon
import deleteIcon from "../../../../Images/icons/delete-icon.png"; // import delete icon
import {Container, Row, Col, Card} from "react-bootstrap"
import {
  formatDateString,
  formatForInput,
  formatDateToDDMMYYYY,
} from "../../../Common/utils/utils";

const ExperienceTab = ({ userDetails, onGetData }) => {
  const [experienceRows, setExperienceRows] = useState([
    { designation: "", company: "", start: "", end: "", weblinks: "" }, 
  ]);


  useEffect(() => {
    if (userDetails?.clientSettings) {
      const experienceData = userDetails.clientSettings.experience || [];
      const weblinkData = userDetails.clientSettings.weblinks || [];
  
      // Combine experience and weblinks into a single array of objects
      const combinedData = experienceData.map((exp, index) => ({
        designation: exp.designation || "",
        company: exp.company || "",
        start: formatDateString(exp.start) || "",
        end: formatDateString(exp.end) || "",
        weblinks: weblinkData[index] || "", // Use the corresponding weblink if available
      }));
  
      setExperienceRows(combinedData);
    }
  }, [userDetails]);
  

  useEffect(() => {

    const formattedExperience = experienceRows
      .filter((row) => row.designation || row.company) // Filter out entries that are only weblinks
      .map((row) => ({
        designation: row.designation,
        company: row.company,
        start: formatDateToDDMMYYYY(row.start),
        end: formatDateToDDMMYYYY(row.end),
      }));

    const formattedWeblinks = experienceRows
      .filter((row) => row.weblinks) 
      .map((row) => row.weblinks); 

    onGetData({     
        experience: formattedExperience,
        weblinks: formattedWeblinks,      
    });
  }, [experienceRows]);

  const handleAddRow = () => {
    setExperienceRows([
      ...experienceRows,
      { designation: "", company: "", start: "", end: "" },
    ]);
  };


  const handleDeleteRow = (index) => {
    const updatedRows = experienceRows.filter((_, i) => i !== index);
    setExperienceRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...experienceRows];
    updatedRows[index][field] = value;
    setExperienceRows(updatedRows);
  };

  return (
    <>
      <Card className="experience-section">
        {/* First Row: Title and Add Button */}
        <div className="heading-sec">
          <div className="d-flex align-items-center">
            <img src={addIcon} alt="Add Icon" />
            <h2>Add experience here.</h2>
          </div>
          <div>
            <button className="secondary-btn" onClick={handleAddRow}>
              Add
            </button>
          </div>
        </div>

        {/* Dynamic Rows for Experience Fields */}
       
        {experienceRows.map((row, index) => (
          <div className="responsive-row mt-3" key={index}>
            {/* Designation Field */}
            <div className="input-group">
              <label htmlFor={`designation-${index}`}>
                Designation <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id={`designation-${index}`}
                placeholder="Designation"
                value={row.designation}
                onChange={(e) =>
                  handleChange(index, "designation", e.target.value)
                }
                required
              />
            </div>

            {/* Company Name Field */}
            <div className="input-group">
              <label htmlFor={`company-${index}`}>
                Company Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id={`company-${index}`}
                placeholder="Company Name"
                value={row.company}
                onChange={(e) => handleChange(index, "company", e.target.value)}
                required
              />
            </div>

            {/* Starting from Field */}
            <div className="input-group">
              <label htmlFor={`start-${index}`}>
                Starting from <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                id={`start-${index}`}
                placeholder="Starting from *"
                value={formatForInput(row.start)}
                onChange={(e) => handleChange(index, "start", e.target.value)}
                required
              />
            </div>

            {/* Ending Field */}
            <div className="input-group">
              <label htmlFor={`end-${index}`}>
                Ending <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                id={`end-${index}`}
                placeholder="Ending *"
                value={formatForInput(row.end)}
                onChange={(e) => handleChange(index, "end", e.target.value)}
                required
              />
            </div>

            {/* Delete Button */}
            <button
              className="delete-button"
              onClick={() => handleDeleteRow(index)}
            >
              <img src={deleteIcon} alt="Delete Icon" />
            </button>
          </div>
        ))}
      </Card>

      <Card className="experience-section website_links_sec mt-4">
      <div className="d-flex align-items-center heading_sec">
            <img src={addIcon} alt="Add Icon" />
            <h2>Add website links here</h2>
          </div>
        

        {/* Dynamic Rows for Experience Fields */}
        {experienceRows.map((row, index) => (
          <div className="weblinks-row" key={index}>
            {/* Designation Field */}
            <div className="input-group">
              <label htmlFor={`weblinks-${index}`}>
                Website link <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id={`weblinks-${index}`}
                placeholder="Enter website link here"
                value={row.weblinks}
                onChange={(e) =>
                  handleChange(index, "weblinks", e.target.value)
                }
                required
              />
            </div>

            {/* Delete Button */}
            {/* <button
              className="delete-button"
              onClick={() => handleDeleteRow(index)}
            >
              <img src={deleteIcon} alt="Delete Icon" />
            </button> */}
          </div>
        ))}
      </Card>
    </>
  );
};

export default ExperienceTab;
