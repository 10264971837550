import { useState } from "react";

const MyProposals = ({ role }) => {
  const [activeButton, setActiveButton] = useState("myBids"); // Default to "My Bids"

  const renderClientContent = () => {
    switch (activeButton) {
      case "myBids":
        return <div>Showing My Bids content</div>;
      case "published":
        return <div>Showing Published content</div>;
      default:
        return <div>No content available</div>;
    }
  };

  const renderFreelancerContent = () => {
    return (
      <div>
        <h3>Freelancer Proposals</h3>
        <p>Here are the proposals you've made for various projects.</p>
        <div className="proposal-cards">
          <div className="card">Proposal 1</div>
          <div className="card">Proposal 2</div>
          <div className="card">Proposal 3</div>
        </div>
      </div>
    );
  };

  return (
    <div className="my-proposals">
      {role === "client" ? (
        <div>
          {/* Buttons for Client */}
          <div className="button-group">
            <button
              onClick={() => setActiveButton("myBids")}
              className={activeButton === "myBids" ? "active" : ""}
            >
              My Bids
            </button>
            <button
              onClick={() => setActiveButton("published")}
              className={activeButton === "published" ? "active" : ""}
            >
              Published
            </button>
          </div>

          {/* Dynamic Content for Client */}
          <div className="content">{renderClientContent()}</div>
        </div>
      ) : (
        renderFreelancerContent()
      )}
    </div>
  );
};

export default MyProposals;
