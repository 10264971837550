import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import useScrollToTop from "./Hooks/useScrollToTop";

import Layout from "./Components/Common/Layout/Layout";
import FindWorks from "./Components/Pages/FindWorks/FindWorks";
import Dashboard from "./Components/Pages/Dashboard";
import MyBids from "./Components/Pages/MyBids";
import MyWorks from "./Components/Pages/MyWorks/MyWorks";
import MyWallet from "./Components/Pages/MyWallet";
import MyTasks from "./Components/Pages/MyTasks";
import MyReferrals from "./Components/Pages/MyReferrals";
import MyDisputes from "./Components/Pages/MyDisputes";
import Settings from "./Components/Pages/Settings";
import Subscription from "./Components/Pages/Subscription";
import WelcomeLayout from "./Components/Pages/WelcomeLayout/WelcomeLayout";

import Profile from "./Components/Pages/Profile/Profile/Profile";
import ProfilePreview from "./Components/Pages/ProfilePreview/ProfilePreview";

import Loader from "./Components/Common/Loader/Loader";

import { useAuth } from "./Context/AuthContext";
import ApiCall from "./Api/ApiCall";
import ClientProfile from "./Components/Pages/ClientProfile/ClientProfile/ClientProfile";
import Service from "./Components/Pages/Service/Service";
import Project from "./Components/Pages/Project/Project";
import useToast from "./Hooks/useToast";

const MainLayout = ({ firstTimeLogin }) => {
  useScrollToTop(); // Use the scroll hook inside a Router context

  const [userDetails, setUserDetails] = useState(null);
  const { isLogin, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();
  const navigate = useNavigate();

  // console.log(isLogin,"isLogin",userDetails?.firstName, userDetails,"userDetails");

  useEffect(() => {
    if (isLogin && !userDetails) {
      // console.log(isLogin, "isLogin", userDetails, "userDetails");

      // Fetch user details after login only if not already fetched
      getUserDetails();
    }
  }, [isLogin, userDetails]);

  const getUserDetails = async () => {
    setLoading(true);

    try {
      // Make the API call with the correct method and endpoint
      const response = await ApiCall(
        "GET",
        "user/userdetails",
        null,
        "application/json"
      );

      // console.log(response, "response");

      // Handle success with a 200 status code
      if (response.statusCode === 200) {
        // console.log(response.data); // Log or handle the user details data
        setUserDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        notify(
          "error",
          error.response.data.message || "Session expired. Logging out..."
        );
        logout();
        navigate("/");
      } else {
        notify("error", error);
      }

      // Handle API call error
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  // Lift the role state to MainLayout
  const [role, setRole] = useState(null);

  // console.log(role, "role");

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route
          path="/"
          element={
            <Layout userDetails={userDetails} role={role} setRole={setRole} />
          }
        >
          {/* Nested routes within Layout */}
          <Route path="find-works" element={<FindWorks />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="my-bids" element={<MyBids />} />
          <Route path="my-works" element={<MyWorks />} />
          <Route path="my-wallet" element={<MyWallet />} />
          <Route path="my-tasks" element={<MyTasks />} />
          <Route path="my-referrals" element={<MyReferrals />} />
          <Route path="my-disputes" element={<MyDisputes />} />
          <Route
            path="settings"
            element={
              firstTimeLogin ? (
                <WelcomeLayout role={role} setRole={setRole} />
              ) : (
                <Settings />
              )
            }
          />
          <Route path="subscription" element={<Subscription />} />
          <Route
            path="profile"
            element={
              role === "freelancer" ? (
                <Profile
                  userDetails={userDetails}
                  getUserDetails={getUserDetails}
                />
              ) : (
                <ClientProfile
                  userDetails={userDetails}
                  getUserDetails={getUserDetails}
                />
              )
            }
          />
          <Route
            path="profile-preview"
            element={
              <ProfilePreview
                userDetails={userDetails}
                getUserDetails={getUserDetails}
              />
            }
          />
          <Route
            path="create-service"
            element={
              <Service
                userDetails={userDetails}
                getUserDetails={getUserDetails}
              />
            }
          />
          <Route
            path="create-project"
            element={
              <Project
                userDetails={userDetails}
                getUserDetails={getUserDetails}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default MainLayout;
