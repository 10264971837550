import React, { useEffect, useState } from "react";
import { Row, Col, Card, Stack, Form, Button } from "react-bootstrap";
import {
  formatDateString,
  formatForInput,
  formatDateToDDMMYYYY,
} from "../../../Common/utils/utils";
import { ReactComponent as ExperienceIcon } from "../../../../Images/freela-icons/experience-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../Images/freela-icons/delete-icon.svg";

const ExperienceTab = ({ userDetails, onGetData, onGetErrData }) => {
  const [experienceRows, setExperienceRows] = useState([
    { designation: "", company: "", start: "", end: "" }, // Change startDate and endDate to start and end
  ]);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");

  useEffect(() => {
    // Check if userDetails has experience data and set initial state
    if (
      userDetails?.freelancerSettings?.experience &&
      userDetails?.freelancerSettings?.experience.length > 0
    ) {
      const formattedExperience =
        userDetails?.freelancerSettings?.experience.map((exp) => ({
          designation: exp.designation,
          company: exp.company,
          start: formatDateString(exp.start), // Change here
          end: formatDateString(exp.end), // Change here
        }));
      setExperienceRows(formattedExperience);
    }
  }, [userDetails]);

  useEffect(() => {
    // Map to the required structure before passing data to parent component
    const formattedData = experienceRows.map((row) => ({
      designation: row.designation,
      company: row.company,
      start: formatDateToDDMMYYYY(row.start), // Change here
      end: formatDateToDDMMYYYY(row.end), // Change here
    }));
    onGetData({ experience: formattedData }); // Wrap in an object
  }, [experienceRows]);

  const handleAddRow = () => {
    setExperienceRows([
      ...experienceRows,
      { designation: "", company: "", start: "", end: "" }, // Change here
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = experienceRows.filter((_, i) => i !== index);
    setExperienceRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...experienceRows];
    updatedRows[index][field] = value;
    setExperienceRows(updatedRows);
  };

  useEffect(() => {
    handleValidate();
  }, [experienceRows]);

  const handleValidate = () => {
    let hasValidEntry = false;
    let newErrors = {};

    experienceRows.forEach((edu, index) => {
      let entryErrors = {};

      if (!edu.designation.trim()) {
        entryErrors.designation = "Designation is required";
      } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.designation.trim())) {
        entryErrors.designation =
          "Designation must be between 2 to 50 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.company.trim()) {
        entryErrors.company = "Company is required ";
      } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.company.trim())) {
        entryErrors.company =
          "Company must be between 2 to 50 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (edu.start === "") {
        entryErrors.start = "Start Date is required";
      } else {
        hasValidEntry = true;
      }

      if (edu.end === "") {
        entryErrors.end = "End Date is required";
      } else {
        hasValidEntry = true;
      }

      if (Object.keys(entryErrors).length > 0) {
        newErrors[index] = entryErrors;
      }
    });

    if (!hasValidEntry || experienceRows.length === 0) {
      setFormError("Please add your employment details.");
    } else {
      setFormError("");
    }

    setErrors(newErrors);

    // Return true if there are no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onGetErrData(Object.keys(errors).length || (formError ? 1 : 0));
  }, [errors, formError]);

  return (
    <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
      <Row className="gy-3 mb-4 align-items-center">
        <Col md={6} lg={8} xl={8}>
          <Stack direction="horizontal" className="gap-2">
            <ExperienceIcon width={50} />
            <div className="freela-fs-20 fw-500 freela-text-primary">
              Add Work Experience
            </div>
          </Stack>
        </Col>
        <Col md={6} lg={4} xl={4}>
          <Stack
            direction="horizontal"
            className="gap-2 justify-content-md-end"
          >
            <Form.Check
              inline
              label="Currently Working"
              name="CurrentlyWorking"
              type="checkbox"
              id="CurrentlyWorking-checkbox"
            />
            <Button
              variant=""
              className="freela-btn-primary-outline"
              onClick={handleAddRow}
            >
              Add
            </Button>
          </Stack>
        </Col>
      </Row>
      {formError && <div className="text-danger mt-2">{formError}</div>}
      {experienceRows.map((row, index) => (
        <Row className="align-items-end mt-3">
          <Col lg={11} xl={11}>
            <Row className="gy-3">
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  Designation <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id={`designation-${index}`}
                  placeholder="Designation"
                  value={row.designation}
                  onChange={(e) =>
                    handleChange(index, "designation", e.target.value)
                  }
                  maxLength={50}
                  required
                />
                {errors[index]?.designation && (
                  <div className="text-danger mt-2">
                    {errors[index].designation}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  Company Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id={`company-${index}`}
                  placeholder="Company Name"
                  value={row.company}
                  onChange={(e) =>
                    handleChange(index, "company", e.target.value)
                  }
                  maxLength={50}
                  required
                />
                {errors[index]?.company && (
                  <div className="text-danger mt-2">
                    {errors[index].company}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  From <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  id={`start-${index}`}
                  placeholder="Starting from *"
                  value={formatForInput(row.start)} // Change here
                  onChange={(e) => handleChange(index, "start", e.target.value)} // Change here
                  required
                />
                {errors[index]?.start && (
                  <div className="text-danger mt-2">{errors[index].start}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  To <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  id={`end-${index}`}
                  placeholder="Ending *"
                  value={formatForInput(row.end)} // Change here
                  onChange={(e) => handleChange(index, "end", e.target.value)} // Change here
                  required
                />
                {errors[index]?.end && (
                  <div className="text-danger mt-2">{errors[index].end}</div>
                )}
              </Form.Group>
            </Row>
          </Col>

          <Col lg={1} xl={1}>
            <Button
              variant=""
              className="freela-btn-primary btn-rounded"
              onClick={() => handleDeleteRow(index)}
            >
              <DeleteIcon width={20} height={20} />
            </Button>
          </Col>
        </Row>
      ))}
    </Card>
  );
};

export default ExperienceTab;
