import React, { useState, useRef, useEffect } from "react";
import useCommonNavigate from "../../../../Hooks/useCommonNavigate";
import { ReactComponent as BackArrowIcon } from "../../../../Images/freela-icons/backArrow-circle.svg";
import { ReactComponent as FileUploadIcon } from "../../../../Images/freela-icons/file-upload-icon.svg";
import { ReactComponent as ProfileRemoveIcon } from "../../../../Images/freela-icons/profile-remove-icon.svg";
import { ReactComponent as ProfileUploadIcon } from "../../../../Images/freela-icons/profile-upload-icon.svg";

import ProfileCreation from "../ClientProfileCreation/ClientProfileCreation";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { Card, Row, Col, Button, Image, Stack, Form } from "react-bootstrap";


const ClientProfile = ({ userDetails, getUserDetails }) => {

  const [certificateFile, setCertificateFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const profileInputRef = useRef(null);
  const coverInputRef = useRef(null);
  const [showProfileCreation, setShowProfileCreation] = useState(false); // State to toggle ProfileCreation component
  const { commonNavigate } = useCommonNavigate();
  const [error, setError] = useState("");
  const { notify } = useToast();
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState({
    certificateFile: "",
    profilePhoto: "",
    coverPhoto: "",
  });

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userDetails) {
      setFileUrls({
        certificateFile: userDetails.profile?.resume || "",
        profilePhoto: userDetails.profile?.profilePic || "",
        coverPhoto: userDetails.profile?.coverPhoto || "",
      });
    }
  }, [userDetails]);

  const resetValues = () => {
    setCertificateFile(null);
    setProfilePhoto(null);
    setCoverPhoto(null);
  };

  const handleNavigate = () => {
    commonNavigate("/settings");
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0]; // Get the file from input
    if (file) {
      try {
        // Prepare the form data with the uploaded file
        const formData = new FormData();
        formData.append("file", file);

        // Call the API to upload the file
        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // Handle successful file upload response
          setPhoto(file);
          setFileUrls((prevState) => ({
            ...prevState,
            [field]: response.data.s3Url, // Update the correct field dynamically
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handles upload click to trigger file input
  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };



  const handleSkip = () => {
    setShowProfileCreation(true); // Show the ProfileCreation component
  };

  const handleBackArrow = () => {
    setShowProfileCreation(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const payload = {
      profile: {
        profilePic: fileUrls.profilePhoto,
        coverPhoto: fileUrls.coverPhoto,
        resume: fileUrls.certificateFile,
      },
    };

    try {
      console.log(payload, "payload ");

      // Make the API call
      const response = await ApiCall(
        "PUT",
        `user/${userId}/photos`,
        payload,
        "application/json"
      );

      // Handle success with 200 status code
      if (response.statusCode === 200) {
        notify("success", "Photos uploaded successfully!");
        resetValues();
        setShowProfileCreation(true);
        getUserDetails();
      } else {
        setError("Upload failed. Please try again.");
        notify("error", response.message || "Upload failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      console.error("Upload error:", error);
      setError("Upload failed. Please try again.");
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };



  return (
    <>
      {loading && <Loader />}
      {showProfileCreation ?
        <ProfileCreation
          handleBackArrow={handleBackArrow}
          userDetails={userDetails}
          getUserDetails={getUserDetails}
        />
        :
        <section className="client_profile card rounded-5 p-3">
          <Row className="gy-4">
            <Col xs={12}>
              <Button variant="link p-0" onClick={handleNavigate}>
                <BackArrowIcon width={45} height={45} />
              </Button>
            </Col>
            <Col xs={12}>
              <div className="section-heading">
                <div className="fs-5 section-heading-title">Create your profile</div>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="gy-4">
                {/* Profile Photo Upload */}
                <Col sm={6} md={5} lg={5} xl={4}>
                  <div className="fs-6 mb-1">Profile photo</div>
                  <Card className="profile-img rounded-5 position-relative">
                    {profilePhoto ?
                      <div className="profile_preview">
                        <Image src={URL.createObjectURL(profilePhoto)} className="object-fit-cover w-100 h-100" />
                        <Stack className="gap-2 position-absolute top-0 end-0 mt-2 me-2" direction="horizontal">
                          <Button variant="link p-0" onClick={(e) => console.log(e)}><ProfileRemoveIcon width={30} height={30} /></Button>
                          <Button variant="link p-0" onClick={() => handleUploadClick(profileInputRef)}>
                            <ProfileUploadIcon width={30} height={30} />
                          </Button>
                        </Stack>
                      </div>
                      : userDetails?.profile?.profilePic ?
                        <div className="profile_preview">
                          <Image src={userDetails?.profile?.profilePic} className="object-fit-cover w-100 h-100" />
                          <Stack className="gap-2 position-absolute top-0 end-0 mt-2 me-2" direction="horizontal">
                            <Button variant="link p-0" onClick={(e) => console.log(e)}><ProfileRemoveIcon width={30} height={30} /></Button>
                            <Button variant="link p-0" onClick={() => handleUploadClick(profileInputRef)}>
                              <ProfileUploadIcon width={30} height={30} />
                            </Button>
                          </Stack>
                        </div>
                        :
                        <div className="content d-flex flex-column align-items-center py-4">
                          <FileUploadIcon className="mb-2" width={50} height={50} />
                          <Card.Text className="mb-0 fs-6">Please upload your Photo</Card.Text>
                          <Card.Text as="small" className="mb-0">(Max file size: 2 MB)</Card.Text>
                          <Button variant="link p-0" className="freela-text-primary text-decoration-none" onClick={() => handleUploadClick(profileInputRef)}>Browse</Button>
                        </div>
                    }
                    <Form.Control type="file" accept="image/*" ref={profileInputRef} className="d-none"
                      onChange={(e) => handlePhotoChange(e, setProfilePhoto, "profilePhoto")}
                    />
                  </Card>
                </Col>

                {/* Cover Photo Upload */}
                <Col sm={6} md={7} lg={7} xl={8}>
                  <div className="fs-6 mb-1">Cover Photo</div>
                  <Card className="cover-photo rounded-5 position-relative">
                    {coverPhoto ?
                      <div className="coverphoto_preview">
                        <Image src={URL.createObjectURL(coverPhoto)} className="object-fit-cover w-100 h-100" />
                        <Stack className="gap-2 position-absolute top-0 end-0 mt-2 me-2" direction="horizontal">
                          <Button variant="link p-0"><ProfileRemoveIcon width={30} height={30} /></Button>
                          <Button variant="link p-0" onClick={() => handleUploadClick(coverInputRef)}><ProfileUploadIcon width={30} height={30} /></Button>
                        </Stack>
                      </div>
                      : userDetails?.profile?.coverPhoto ?
                        <div className="coverphoto_preview">
                          <Image src={userDetails?.profile?.coverPhoto} className="object-fit-cover w-100 h-100" />
                          <Stack className="gap-2 position-absolute top-0 end-0 mt-2 me-2" direction="horizontal">
                            <Button variant="link p-0"><ProfileRemoveIcon width={30} height={30} /></Button>
                            <Button variant="link p-0" onClick={() => handleUploadClick(coverInputRef)}><ProfileUploadIcon width={30} height={30} /></Button>
                          </Stack>
                        </div>
                        :
                        <div className="content d-flex flex-column align-items-center py-4">
                          <FileUploadIcon className="mb-2" width={50} height={50} />
                          <Card.Text className="mb-0 fs-6">Please upload your cover photo</Card.Text>
                          <Card.Text as="small" className="mb-0">(Max file size: 2 MB)</Card.Text>
                          <Button variant="link p-0" className="freela-text-primary text-decoration-none" onClick={() => handleUploadClick(coverInputRef)}>Browse</Button>
                        </div>
                    }
                    <Form.Control type="file" accept="image/*" ref={coverInputRef} className="d-none"
                      onChange={(e) => handlePhotoChange(e, setCoverPhoto, "coverPhoto")}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <Stack direction="horizontal" className="gap-3 justify-content-end">
                <Button variant="" className="freela-btn-primary-outline" onClick={handleSkip}>
                  Skip
                </Button>
                {(certificateFile || profilePhoto || coverPhoto) && (
                  <Button
                    variant="" className="freela-btn-primary"
                    onClick={(e) => handleSubmit(e, "submit")}
                  >
                    Save &amp; Continue
                  </Button>
                )}
              </Stack>
            </Col>
          </Row>
        </section>
      }
    </>
  );
};

export default ClientProfile;
