// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo{
  width: 80%;
  margin: 1rem 1rem;
}
.forgot-logo{
  width: 35%;
  margin: 1rem 1rem;
}
.login-form label{
 color: #626262;
 font-size: 14px;
 margin-top: 1.2rem;
}
.login-form input{
  border: 1px solid #DFDFDF;
  border-radius:30px;
  padding: 10px 10px;
  width: 100%;
  font-size: 14px;
  padding-right:40px;
}
.login-form .input-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.social-icons img{
  width: 50px;
  height:50px;
}
.login-right-content a{
  color: #F35427 !important;
}
@media (max-width:767px) {
  .login-left-content .left-img{
    display: none;
  }
  .logo, .forgot-logo{
    width: 50%;
  }
  
}
@media (max-width:540px) {
  .logo, .forgot-logo{
    width: 70%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;CACC,cAAc;CACd,eAAe;CACf,kBAAkB;AACnB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,UAAU;EACZ;;AAEF;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".logo{\r\n  width: 80%;\r\n  margin: 1rem 1rem;\r\n}\r\n.forgot-logo{\r\n  width: 35%;\r\n  margin: 1rem 1rem;\r\n}\r\n.login-form label{\r\n color: #626262;\r\n font-size: 14px;\r\n margin-top: 1.2rem;\r\n}\r\n.login-form input{\r\n  border: 1px solid #DFDFDF;\r\n  border-radius:30px;\r\n  padding: 10px 10px;\r\n  width: 100%;\r\n  font-size: 14px;\r\n  padding-right:40px;\r\n}\r\n.login-form .input-icon {\r\n  position: absolute;\r\n  right: 16px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n.social-icons img{\r\n  width: 50px;\r\n  height:50px;\r\n}\r\n.login-right-content a{\r\n  color: #F35427 !important;\r\n}\r\n@media (max-width:767px) {\r\n  .login-left-content .left-img{\r\n    display: none;\r\n  }\r\n  .logo, .forgot-logo{\r\n    width: 50%;\r\n  }\r\n  \r\n}\r\n@media (max-width:540px) {\r\n  .logo, .forgot-logo{\r\n    width: 70%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
