import React, { useState } from "react";
import useCommonNavigate from "../../Hooks/useCommonNavigate";
import { loginValidationSchema } from "../Common/Validation/validationSchema";
import { ReactComponent as FreelaLogo } from "../../Images/freela-icons/freela-logo.svg";
import { ReactComponent as UserIcon } from "../../Images/freela-icons/user-icon.svg";
import { ReactComponent as EyeIcon } from "../../Images/freela-icons/eye-icon.svg";
import { ReactComponent as EyeOffIcon } from "../../Images/freela-icons/eyeoff-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../Images/freela-icons/linkedin-icon.svg";
import LoginImg from "../../Images/freela-icons/login-left-img.svg";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import ApiCall from "../../Api/ApiCall";
import { Row, Col, Image, Button, Form, Stack } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";

const Login = ({ openRegister, openForgotPwd }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const { notify } = useToast();
  const { commonNavigate } = useCommonNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const clientId = "YOUR_GOOGLE_CLIENT_ID"; // Replace with actual Google client ID

  // Function to handle login
  const handleSubmit = async (values) => {
    setLoading(true); // Set loading state to true
    try {
      const payload = {
        email: values.email,
        password: values.password,
      };

      const response = await ApiCall(
        "POST",
        "user/login",
        payload,
        "application/json"
      );

      if (response.statusCode === 200) {
        localStorage.setItem("keepMeLoggedIn", keepMeLoggedIn);
        const token = response.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("userId", response.data.userId);
        login(token);
        notify("success", "Login successful!");
        commonNavigate("/settings");
      } else {
        notify("error", response.message || "Login failed. Please try again.");
      }
    } catch (error) {
      if(error.response && error.response.data){
        notify("error", error.response.data.message || "Login failed. Please try again.");
      }
      else{
        notify("error", "Login failed. Please try again.");
      }
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Row className="login_form">
        {/* Left Section */}
        <Col sm={12} md={12} lg={5} xl={5}>
          <FreelaLogo className="ms-lg-4 mt-lg-2" width={260} />
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Image src={LoginImg} alt="LoginImg" className="h-400" fluid />
          </div>
        </Col>

        {/* Right Section */}
        <Col sm={12} md={12} lg={7} xl={7}>
          <h1 className="fs-4 mb-0">Welcome back,</h1>
          <p>
            Don’t have an account?
            <Button
              variant="link"
              className="py-0 ps-1 text-decoration-none freela-text-primary"
              onClick={openRegister}
            >
              Create new account
            </Button>
          </p>

          {/* Login Form with Formik */}

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <FormikForm>
                {/* Email Field */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email ID <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="position-relative right-icon">
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                    <UserIcon
                      className="position-absolute top-50 end-0 translate-middle-y me-3"
                      width={20}
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="small"
                    className="text-danger d-block mt-1"
                  />
                </Form.Group>

                {/* Password Field */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="position-relative right-icon">
                    <Field
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter password"
                    />
                    <Button
                      variant="link"
                      className="text-decoration-none position-absolute top-50 end-0 translate-middle-y me-3"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeIcon width={20} />
                      ) : (
                        <EyeOffIcon width={20} />
                      )}
                    </Button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="small"
                    className="text-danger d-block mt-1"
                  />
                </Form.Group>

                {/* Keep Me Logged In & Forgot Password */}
                <Stack
                  direction="horizontal"
                  className="justify-content-between mb-3"
                >
                  <Form.Group>
                    <Field
                      type="checkbox"
                      name="keepMeLoggedIn"
                      className="me-2"
                      checked={keepMeLoggedIn}
                      onChange={() => setKeepMeLoggedIn(!keepMeLoggedIn)}
                    />
                    Keep me logged in
                  </Form.Group>
                  <Button
                    variant="link"
                    className="freela-text-primary text-decoration-none"
                    onClick={openForgotPwd}
                  >
                    Forgot Password?
                  </Button>
                </Stack>

                {/* Social Login */}
                <Form.Group className="my-4 py-3 position-relative px-5">
                  <div className="px-2 bg-white position-absolute top-50 start-50 translate-middle">
                    <span className="opacity-75">or Login with</span>
                  </div>
                  <div
                    className="text-decorator"
                    style={{ borderTop: "2px dotted #858282" }}
                  ></div>
                </Form.Group>

                <Stack
                  direction="horizontal"
                  className="social-icons justify-content-center gap-3"
                >
                  <Button
                    variant="link"
                    className="freela-text-primary text-decoration-none"
                    onClick={() =>
                      window.open("https://www.linkedin.com/login", "_blank")
                    }
                  >
                    <LinkedinIcon />
                  </Button>
                  <GoogleOAuthProvider clientId={clientId}>
                    <GoogleLoginButton handleSocial={handleSubmit} />
                  </GoogleOAuthProvider>
                  <FacebookLoginButton handleSocial={handleSubmit} />
                </Stack>

                {/* Login Button */}
                <Form.Group className="text-center mt-5 w-250px d-grid mx-auto">
                  <Button type="submit" className="freela-btn-primary px-5 mt-3">
                    Login
                  </Button>
                </Form.Group>
              </FormikForm>
            )} 
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default Login;
