import React, { useState } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import Login from "../../Login/Login";
import { ReactComponent as FreelaLogo } from '../../../Images/freela-icons/freela-logo.svg';
import Register from "../../Register/Register";
import RegisterVerify from "../../Register/RegisterVerify";
import RegisterCongrats from "../../Register/RegisterCongrats";
import ForgotPwd from "../../Register/ForgotPwd";
import ForgotVerify from "../../Register/ForgotVerify";

export const LoginNavbar = () => {
  const [isMenuToggle, setMenuToggle] = useState(false); // menu toggle
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false); // State to track form type
  const [showVerifyModal, setShowVerifyModal] = useState(false); // New state for RegisterVerify modal
  const [showCongratsModal, setShowCongratsModal] = useState(false); // New state for RegisterCongrats modal
  const [showLoginModal, setShowLoginModal] = useState(false); // State for Login modal
  const [showRegModal, setShowRegModal] = useState(false); // State for Register modal
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false); // State for forgot password modal
  const [showForgotVerPwdModal, setShowForgotVerPwdModal] = useState(false); // State for forgot verify password modal
  const [regEmail, setRegEmail] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");

  const handleOpenModal = (isRegister) => {
    setIsRegister(isRegister); // Set the form type based on the button clicked
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowVerifyModal(false); // Close the RegisterVerify modal if it's open
    setShowCongratsModal(false); // Close the RegisterCongrats modal if it's open
    setShowLoginModal(false); // Close the Login modal if it's open
    setShowRegModal(false);
    setShowForgotPwdModal(false);
    setShowForgotVerPwdModal(false);
  };

  // Function to open the RegisterVerify modal
  const openVerifyModal = (email) => {
    handleCloseModal()
    setRegEmail(email);
    setIsModalOpen(false); // Close the registration modal
    setShowVerifyModal(true); // Open the verification modal
  };

  // console.log(showVerifyModal,"showVerifyModal");

  const openCongratsModal = () => {
    handleCloseModal()
    setShowVerifyModal(false);
    setShowCongratsModal(true);
  };

  // Function to open the Login modal
  const openLogin = () => {
    // console.log(showLoginModal, showRegModal, "openLogin");
    handleCloseModal()
    setShowForgotVerPwdModal(false);
    setShowForgotPwdModal(false);
    setShowRegModal(false);
    setShowLoginModal(true); // Open the login modal
  };

  const openRegister = () => {
    // console.log(showLoginModal, showRegModal, "openRegister");
    handleCloseModal()
    setShowLoginModal(false);
    setShowForgotPwdModal(false);
    setShowRegModal(true); // Open the register modal
  };

  const openForgotPwd = () => {
    handleCloseModal()
    setShowLoginModal(false);
    setShowRegModal(false); // Open the register modal
    setShowForgotVerPwdModal(false);
    setShowForgotPwdModal(true); // Open the login modal
  };

  const openForgotverify = (email) => {
    // console.log(email, "email");
    handleCloseModal()
    setVerifyEmail(email);
    setShowLoginModal(false);
    setShowForgotPwdModal(false);
    setShowForgotVerPwdModal(true);
  };

  return (
    <>
      <Navbar expand="lg" className="header-bg shadow-sm top-0 start-0 py-0" sticky="top">
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/" className="outline-none">
            <FreelaLogo width={240} height={60} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" className="p-1 shadow-none outline-none" onClick={()=> setMenuToggle(!isMenuToggle)}>
            {isMenuToggle ? <i className="bi bi-x fs-1"/> : <i className="bi bi-list fs-1"/> }
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className="flex-row justify-content-end">
            <Nav className="ms-xl-5 gap-3">
             <Nav.Item>
              <NavLink as={Nav.Link} className="text-decoration-none text-dark" to="/about">About Us</NavLink>
             </Nav.Item>
             <Nav.Item>
              <NavLink as={Nav.Link} className="text-decoration-none text-dark" to="/blog">Blog</NavLink>
             </Nav.Item>
            </Nav>
            <Nav className="ms-auto d-inline-flex gap-3 mt-3 mt-lg-0">
              <Button
              variant=""
                className="freela-btn-primary-outline d-flex"
                onClick={() => handleOpenModal(true)}
              >
                Sign Up
              </Button>
              <Button
              variant=""
                className="freela-btn-primary"
                onClick={() => handleOpenModal(false)}
              >
                Sign In
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Modal content for Sign Up or Sign In */}

      {isRegister ? (
        <CustomModal isOpen={isModalOpen} modalSize='lg' onClose={handleCloseModal}>
          <Register openVerifyModal={openVerifyModal} openLogin={openLogin} />
        </CustomModal>
      ) : (
        // Login Form
        <CustomModal isOpen={isModalOpen} modalSize='lg' onClose={handleCloseModal}>
          <Login openRegister={openRegister} openForgotPwd={openForgotPwd} />
        </CustomModal>
      )}

      {/* RegisterVerify Modal */}
      {showVerifyModal && (
        <CustomModal isOpen={showVerifyModal} modalSize='lg' onClose={handleCloseModal}>
          <RegisterVerify
            regEmail={regEmail}
            openCongratsModal={openCongratsModal}
          />
        </CustomModal>
      )}

      {/* RegisterCongrats Modal */}
      {showCongratsModal && (
        <CustomModal isOpen={showCongratsModal} modalSize='lg' onClose={handleCloseModal}>
          <RegisterCongrats openLogin={openLogin} />
        </CustomModal>
      )}

      {/* Login Modal */}
      {showLoginModal && (
        <CustomModal isOpen={showLoginModal} modalSize='lg' onClose={handleCloseModal}>
          <Login openRegister={openRegister} openForgotPwd={openForgotPwd} />
        </CustomModal>
      )}

      {showRegModal && (
        <CustomModal isOpen={showRegModal} modalSize='lg' onClose={handleCloseModal}>
          <Register openVerifyModal={openVerifyModal} openLogin={openLogin} />
        </CustomModal>
      )}

      {showForgotPwdModal && (
        <CustomModal isOpen={showForgotPwdModal} modalSize='lg' onClose={handleCloseModal}>
          <ForgotPwd
            openLogin={openLogin}
            openForgotverify={openForgotverify}
          />
        </CustomModal>
      )}

      {showForgotVerPwdModal && (
        <CustomModal isOpen={showForgotVerPwdModal} modalSize='lg' onClose={handleCloseModal}>
          <ForgotVerify verifyEmail={verifyEmail} openLogin={openLogin} />
        </CustomModal>
      )}
    </>
  );
};