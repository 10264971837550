import React, { useState } from "react";
import "./Register.css";
import logo from "../../Images/logo.png";
import register from "../../Images/register.png";
import user from "../../Images/icons/user.png";
import eye from "../../Images/icons/Eye.png";
import eyeOff from "../../Images/icons/Eyeoff.png";
import dropdownBox from "../../Images/icons/dropdown-box-outline.png";
import ApiCall from "../../Api/ApiCall";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactCountryFlag from "react-country-flag";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";
import { Row, Col } from 'react-bootstrap';

const Register = ({ openVerifyModal, openLogin }) => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    password: "",
    cpassword: "",
    phone: "",
    code: "",
    email: "",
    consent: false,
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { notify } = useToast();

  // Handle input changes

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle input changes
  // const handlePhoneChange = (e) => {
  //   console.log(e,"essssssssssssssssssssssssssssssssssssssssss");

  //   setFormData(prevState => ({ ...prevState, phone: e }));
  // };

  const handlePhoneChange = (phone, country) => {
    // console.log(phone, country, "phone, country");
    // Extract country code and phone number separately
    setFormData((prevState) => ({
      ...prevState,
      phone: phone, // Storing only the phone number
      code: country.dialCode, // Storing the country code separately
    }));
  };

  // console.log(formData, "formData");

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error messages
    setLoading(true);
    // Check if passwords match
    if (formData.password !== formData.cpassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Prepare payload for registration
      const payload = {
        firstName: formData.fname,
        lastName: formData.lname,
        password: formData.password,
        confirmPass: formData.cpassword,
        phoneNumber: formData.phone,
        code: formData.code,
        consent: formData.consent,
        email: formData.email,
      };

      // console.log(payload, "register payload");

      // Make the API call
      const response = await ApiCall(
        "POST",
        "user/register",
        payload,
        "application/json"
      );

      // Handle success with 200 status code
      if (response.statusCode === 201) {
        notify("success", "Registration successful!");

        // console.log('Registration successful:', response.data.email, "response.data.email", response.message, "response.message", response.data);
        openVerifyModal(response.data.email); // Call the parent function to open RegisterVerify modal
      } else {
        notify(
          "error",
          response.message || "Registration failed. Please try again."
        );
        setError("Registration failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      console.error("Registration error:", error);
      notify(
        "error",
        error.response.data.message || "Registration failed. Please try again."
      );
      setError("Registration failed. Please try again.");
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleLoginModal = () => {
    openLogin();
  };

  return (
    <>
      {loading && <Loader />}
      <Row>
        {/* Left Section */}
        <Col xs={12} sm={12} md={5} className="login-left-content">
          <img src={logo} alt="Logo" className="logo" />
          <img src={register} alt="register" className="w-100 left-img" />
        </Col>

        {/* Right Section */}
        <Col xs={12} sm={12} md={7} className="login-right-content px-4">
        <h3 className="mt-3">Registration</h3>
          <p>
            If you already have an account,{" "}
            <a onClick={handleLoginModal}>Login here</a>
          </p>
          {error && <p className="error-message">{error}</p>}{" "}
          {/* Display error if any */}
          {/* Register Form */}
          <form className="login-form" onSubmit={handleSubmit}>
            {/* First row: First Name and Last Name */}
            <Row>
              <Col sm={12} md={12} lg={6}>
                <label htmlFor="fname">
                  First Name <span className="required-asterisk">*</span>
                </label>
                <div className="input-wrapper position-relative">
                  <input
                    type="text"
                    id="fname"
                    placeholder="Enter your first name"
                    value={formData.fname}
                    onChange={handleChange}
                    required
                  />
                  <img src={user} alt="Person Icon" className="input-icon" />
                </div>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <label htmlFor="lname">
                  Last Name <span className="required-asterisk">*</span>
                </label>
                <div className="input-wrapper position-relative">
                  <input
                    type="text"
                    id="lname"
                    placeholder="Enter your last name"
                    value={formData.lname}
                    onChange={handleChange}
                    required
                  />
                  <img src={user} alt="Person Icon" className="input-icon" />
                </div>
              </Col>
            </Row>

            {/* Second row: Password and Confirm Password */}
            <Row>
              <Col sm={12} md={12} lg={6}>
                <label htmlFor="password">
                  Password <span className="required-asterisk">*</span>
                </label>
                <div className="input-wrapper position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <img
                    src={showPassword ? eye : eyeOff}
                    alt="Eye Icon"
                    className="input-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <label htmlFor="cpassword">
                  Confirm Password <span className="required-asterisk">*</span>
                </label>
                <div className="input-wrapper position-relative">
                  <input
                    type={showCPassword ? "text" : "password"}
                    id="cpassword"
                    placeholder="Confirm your password"
                    value={formData.cpassword}
                    onChange={handleChange}
                    required
                  />
                  <img
                    src={showCPassword ? eye : eyeOff}
                    alt="Eye Icon"
                    className="input-icon"
                    onClick={() => setShowCPassword(!showCPassword)}
                  />
                </div>
              </Col>
            </Row>

            {/* Third row: Phone and Email */}
            <Row>
              <Col sm={12} md={12} lg={6}>
                <label htmlFor="phone">Phone Number</label>
                <div className="input-wrapper position-relative">
                  <div className="phone-inputbox">
                    <ReactCountryFlag
                      countryCode={formData.country?.countryCode}
                      svg
                      style={{
                        border: "none", // Remove border
                        background: "transparent", // Hide background
                        boxShadow: "none", // Remove shadow if any
                        display: 'flex', // Align items if necessary
                        alignItems: 'center', // Center the flag icon
                      }}
                    />
                    <span>
                      <PhoneInput
                        country={"in"}
                        value={formData.phone}
                        onChange={(phone, country) =>
                          handlePhoneChange(phone, country)
                        }
                        placeholder="9876543210"
                        inputStyle={{
                          border: "none",
                          background: "transparent", // Hide background
                          boxShadow: "none", // Remove shadow if any
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>

              <Col sm={12} md={12} lg={6}>
                <label htmlFor="email">
                  Email ID <span className="required-asterisk">*</span>
                </label>
                <div className="input-wrapper position-relative">
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter email id"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <img
                    src={dropdownBox}
                    alt="Dropdown Icon"
                    className="input-icon"
                  />
                </div>
              </Col>
            </Row>

            <div className="auth-options">
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  required
                  checked={formData.consent} // Reflect the current state
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      consent: e.target.checked,
                    }))
                  } // Update the state
                />
                <span className="checkbox-text">
                  I have read and accept the Terms of Use, Privacy Policy, Terms
                  & Conditions
                </span>
              </label>
            </div>
            <div className="text-center my-4">
          <button type="submit" className="primary-btn py-2 w-50">
           Register
          </button>
          </div>
           
          </form>
        </Col>
        </Row>
    </>
  );
};

export default Register;
