import React from "react";
import {
  Row,
  Col,
  CardGroup,
  Button,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ReactComponent as StatusFlag } from "../../../Images/freela-icons/status-flag.svg";
import { ReactComponent as VerifyIcon } from "../../../Images/freela-icons/verified-icon.svg";
import { ReactComponent as NotVerifyIcon } from "../../../Images/freela-icons/not-verified-icon.svg";
import { ReactComponent as InterviewCompletedIcon } from "../../../Images/freela-icons/interview-completed.svg";
import { ReactComponent as InterviewNotCompletedIcon } from "../../../Images/freela-icons/interview-not-completed.svg";

export const ComparingGrid = ({ comparingData, setEnableGrid, handleBid }) => {
  return (
    <section className="compare_grid">
      <Row className="align-items-center">
        <Col md={8} lg={9} xl={10}>
          <h5 className="fw-600">Mobile App Design</h5>
          <p className="text_truncate2">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque,
            maiores culpa dolores quae suscipit recusandae! Asperiores at
            possimus sunt repellat iusto, quos quaerat beatae aspernatur
            voluptatem ducimus tempora odit eligendi.
          </p>
        </Col>
        <Col md={4} lg={3} xl={2} className="text-md-end">
          <Button
            variant=""
            className="freela-btn-primary px-5"
            onClick={() => setEnableGrid(false)}
          >
            Back
          </Button>
        </Col>
        <Col xs={12} className="mt-3">
          <div className="compare_grid_content">
            <CardGroup as={Row} xs={6} className="flex-nowrap g-0">
              <Card as={Col}>
                <ListGroup variant="flush">
                  <ListGroup.Item>Compare List</ListGroup.Item>
                  {/* <ListGroup.Item>Reputation</ListGroup.Item>
                                    <ListGroup.Item>KYC</ListGroup.Item>
                                    <ListGroup.Item>AI Interview</ListGroup.Item> */}
                  <ListGroup.Item>Bid Amount</ListGroup.Item>
                  <ListGroup.Item>Client Rating</ListGroup.Item>
                  <ListGroup.Item>Quality of work</ListGroup.Item>
                  {/* <ListGroup.Item>Understanding Requirements</ListGroup.Item> */}
                  <ListGroup.Item>Communicatin</ListGroup.Item>
                  <ListGroup.Item>Timelines</ListGroup.Item>
                  <ListGroup.Item>NPS</ListGroup.Item>
                  <ListGroup.Item>Overall Ratings</ListGroup.Item>
                  <ListGroup.Item>&nbsp;</ListGroup.Item>
                </ListGroup>
              </Card>
              {comparingData?.map((list, idx) => (
                <Card as={Col} key={idx}>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="text-truncate">
                      {list.firstName}
                    </ListGroup.Item>
                    {/* <ListGroup.Item className='status_flag'>
                                            <OverlayTrigger placement="left" overlay={<Tooltip className='text-capitalize'>{list.statusFlag}</Tooltip>}>
                                                <StatusFlag width={25} className={`flag-${list.statusFlag}`} />
                                            </OverlayTrigger>
                                        </ListGroup.Item> */}
                    {/* <ListGroup.Item>
                                            {list.verifyStatus === true ? <VerifyIcon width={24} /> : <NotVerifyIcon width={24} />}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            {list.interviewStatus === true ? <InterviewCompletedIcon width={24} /> : <InterviewNotCompletedIcon width={24} />}
                                        </ListGroup.Item> */}
                    <ListGroup.Item>{list?.bidAmount}/hr</ListGroup.Item>
                    <ListGroup.Item className="text-warning">
                      <i className="bi bi-star-fill me-1" />{" "}
                      {list?.clientRating?.rating}
                      <small className="ms-1 freela-greaseblack-text">
                        ({list?.clientRating?.totalUsers})
                      </small>
                    </ListGroup.Item>
                    <ListGroup.Item className="text-warning">
                      <i className="bi bi-star-fill me-1" />{" "}
                      {list?.qualityWork?.rating}
                      <small className="ms-1 freela-greaseblack-text">
                        ({list?.qualityWork?.totalUsers})
                      </small>
                    </ListGroup.Item>
                    {/* <ListGroup.Item className="text-warning"><i className="bi bi-star-fill me-1" /> {list.understandRequirements}<small className="ms-1 freela-greaseblack-text">({list.peopleNoofunderstandRequirements})</small></ListGroup.Item> */}
                    <ListGroup.Item className="text-warning">
                      <i className="bi bi-star-fill me-1" />{" "}
                      {list?.communication?.rating}
                      <small className="ms-1 freela-greaseblack-text">
                        ({list?.communication?.totalUsers})
                      </small>
                    </ListGroup.Item>
                    <ListGroup.Item className="text-warning">
                      <i className="bi bi-star-fill me-1" />{" "}
                      {list?.timelines?.rating}
                      <small className="ms-1 freela-greaseblack-text">
                        ({list?.timelines?.totalUsers})
                      </small>
                    </ListGroup.Item>
                    <ListGroup.Item className="text-warning">
                      <i className="bi bi-star-fill me-1" /> {list?.nps?.rating}
                      <small className="ms-1 freela-greaseblack-text">
                        ({list?.nps?.totalUsers})
                      </small>
                    </ListGroup.Item>
                    <ListGroup.Item className="text-warning">
                      <i className="bi bi-star-fill me-1" />{" "}
                      {list?.overallRating?.rating}
                      <small className="ms-1 freela-greaseblack-text">
                        ({list?.overallRating?.totalUsers})
                      </small>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Button
                        variant=""
                        size="sm"
                        className="freela-btn-primary-outline px-4"
                        onClick={()=> handleBid(list?.bidId, "Accepted")}
                      >
                        Accept
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}
            </CardGroup>
          </div>
        </Col>
      </Row>
    </section>
  );
};
