import React, { useState } from "react";
import useCommonNavigate from "../../Hooks/useCommonNavigate";
import "./Login.css";
import logo from "../../Images/logo.png";
import loginImage from "../../Images/login.png";
import LinkedIn from "../../Images/icons/linkedin-icon.svg";
import Google from "../../Images/icons/google-icon.svg";
import Facebook from "../../Images/icons/facebook-icon.svg";
import user from "../../Images/icons/user.png";
import eye from "../../Images/icons/Eye.png";
import eyeOff from "../../Images/icons/Eyeoff.png";
import orLogin from "../../Images/login_with_img.png";
import ApiCall from "../../Api/ApiCall";
import { Row, Col } from 'react-bootstrap';

import { useAuth } from "../../Context/AuthContext";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";

const Login = ({ openRegister, openForgotPwd }) => {
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { commonNavigate } = useCommonNavigate();
  const { notify } = useToast();
  const [loading, setLoading] = useState(false);


  const handleRegisterModal = () => {
    openRegister();
  };

  const handleForgotPwdModal = () => {
    openForgotPwd();
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");

  const { login } = useAuth();

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error messages
    setLoading(true);



    try {
      // Prepare payload for login
      const payload = {
        email: formData.email,
        password: formData.password,
      };

      console.log(payload, "login payload");

      // Make the API call
      const response = await ApiCall("POST", "user/login", payload , 'application/json');
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log(
          "Login successful:",
          response.data.token,
          "response.data.token",
          response.message,
          "response.message",
          response.data
        );

        // Store the keep me logged in status
        localStorage.setItem("keepMeLoggedIn", keepMeLoggedIn);

      
        // Store the token in local storage
        const token = response.data.token; // Get the token value
        localStorage.setItem("token", token); // Store the token
        localStorage.setItem("userId", response.data.userId);
        login(token); // Call login with the token value

        notify('success', "Login successful!");
        commonNavigate("/settings");

      } else {
        setError("Login failed. Please try again.");
        notify('error', response.message || "Login failed. Please try again.")
      }
    } catch (error) {    
      // Handle API call error
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
      notify('error', error.response.data.message);
    }
    finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (

    <>
    {loading && <Loader />}
    <Row>
      {/* Left Section */}
      <Col xs={12} sm={12} md={6} className="login-left-content">
        <img src={logo} alt="Logo" className="logo" />
        <img src={loginImage} alt="login" className="w-100 left-img mt-4" />
      </Col>

      {/* Right Section */}
      <Col xs={12} sm={12} md={6} className="login-right-content px-4">
        <h3 className="mt-3">Welcome back,</h3>
        <p>
          Don’t have an account?{" "}
          <a onClick={handleRegisterModal}>Create new account</a>
        </p>

        {/* Login Form */}

        <form className="login-form" onSubmit={handleSubmit}>
          {/* Email Field */}
          <label htmlFor="email">
            Email ID <span className="text-danger">*</span>
          </label>
          <div className="input-wrapper position-relative">
            <input
              type="email"
              id="email"
              placeholder="Enter email id"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <img src={user} alt="Person Icon" className="input-icon" />
          </div>

          {/* Password Field */}
          <label htmlFor="password">
            Password <span className="text-danger">*</span>
          </label>
          <div className="input-wrapper position-relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <img
              src={showPassword ? eye : eyeOff}
              alt="Eye Icon"
              className="input-icon"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          <div className="auth-options">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={keepMeLoggedIn}
                onChange={() => setKeepMeLoggedIn(!keepMeLoggedIn)} // Toggle checkbox state
              />
              <span className="checkbox-text">Keep me login</span>
            </label>
            <a onClick={handleForgotPwdModal} className="forgot-password">
              Forgot password?
            </a>
          </div>

          <div className="login-alt">
            <div className="text-center mt-3">
            <img src={orLogin} alt="image" className="w-75" />
            </div>
            <div className="social-icons text-center mt-4">
              <img
                src={LinkedIn}
                alt="LinkedIn"
                onClick={() =>
                  window.open("https://www.linkedin.com/login", "_blank")
                }
                style={{ cursor: "pointer" }}
              />
              <img
                src={Google}
                alt="Google"
                onClick={() =>
                  window.open("https://accounts.google.com/login", "_blank")
                }
                style={{ cursor: "pointer" }}
              />
              <img
                src={Facebook}
                alt="Facebook"
                onClick={() =>
                  window.open("https://www.facebook.com/login", "_blank")
                }
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="text-center my-4 mt-5">
          <button type="submit" className="primary-btn py-2 w-50">
            Login
          </button>
          </div>
        </form>
      </Col>
    </Row>
    </>
    
  );
};

export default Login;
