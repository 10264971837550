import React from "react";

const MyTasks = ({ role }) => {
  return (
    <>
      {role === "client" ? (
        <div>My client Tasks</div>
      ) : (
        <div> My freelancer Tasks</div>
      )}
    </>
  );
};

export default MyTasks;
