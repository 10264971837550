import React, { useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import About from "../../Profile/AboutTab/AboutTab";
import Experience from "../../ClientProfile/ExperienceTab/ExperienceTab";
import ClinetKYCCreation from "../ClinetKYCCreation/ClinetKYCCreation";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { ReactComponent as BackArrowIcon } from "../../../../Images/freela-icons/backArrow-circle.svg";

const ClientProfileCreation = ({ handleBackArrow, userDetails, getUserDetails }) => {
  const userId = localStorage.getItem("userId");
  const { notify } = useToast();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [aboutData, setAboutData] = useState({});
  const [expData, setExpData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tabErrors, setTabErrors] = useState(0);

  const tabs = [
    { label: "About", component: <About userDetails={userDetails} onGetData={(data) => setAboutData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "Experience", component: <Experience userDetails={userDetails} onGetData={(data) => setExpData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "KYC Creation", component: <ClinetKYCCreation onGetErrData={(data) => setTabErrors(data)}/> },

  ];

  const apiEndpoints = {
    About: `user/profile/about/${userId}`,
    Experience: `user/profile/experience/${userId}`,

  };

  const handleSkip = () => {
    if (activeTabIndex < tabs.length - 1) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }

  const handleNextTab = async () => {
    setLoading(true);
    const currentTab = tabs[activeTabIndex].label;
    const endpoint = apiEndpoints[currentTab];

    let payload;

    console.log(currentTab, "currentTab", payload, "payload", endpoint, "endpoint");

    let role = null;
    switch (currentTab) {
      case "About":
        payload = aboutData; // Get the data from About
        break;
      case "Experience":
        payload = expData; // Get the data from Education
        role = 'client';
        break;

      default:
        payload = {};
    }

    try {

      // console.log(currentTab,"currentTab");

      const response = await ApiCall("PUT", endpoint, payload, "application/json", role);
      if (response.statusCode === 200) {

        // On successful API call, move to the next tab
        if (activeTabIndex < tabs.length - 1) {
          notify('success', "details updated successful!");
          setActiveTabIndex(activeTabIndex + 1);
          getUserDetails()
        }
      } else {
        console.error("API call failed:", response.message);
        notify('error', response.data[0].msg)
      }
    } catch (error) {
      console.log(error, "error", activeTabIndex, "activeTabIndex");

      console.error("Error during API call:", error);
      (activeTabIndex === 0)
        ?
        (notify('error', error?.response?.data.data[0]?.msg))
        :
        notify('error', error?.response?.data.errors[0]?.msg)
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handlePrevTab = () => {
    if (activeTabIndex <= tabs.length - 1) {
      setActiveTabIndex(activeTabIndex - 1); // Go to the prev tab
    }
  };


  return (
    <>
      {loading && <Loader />}

      <section className="client_profile card h-100 rounded-5">
        <Card.Header className="bg-transparent border-0 p-0">
          <Row className="mt-3">
            <Col xs={12} className="mb-2">
              <Button variant="link p-0 mx-3" onClick={(activeTabIndex === 0) ? handleBackArrow : handlePrevTab}>
                <BackArrowIcon width={45} height={45} />
              </Button>
            </Col>
            <Col xs={12}>
              <div className="freela_horizontal_tabs">
                <div className="tab_lists justify-content-lg-center px-4">
                  {tabs.map((tab, index) => (
                    <Button variant="link px-1"
                      key={index}
                      className={`tab-button ${index === activeTabIndex && "active"}`}
                      onClick={() => setActiveTabIndex(index)}
                    >
                      {tab.label}
                    </Button>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="gy-4">
            <Col xs={12}>
              <div className="tab-content">{tabs[activeTabIndex].component}</div>
            </Col>
            <Col xs={12}>
              <Stack direction="horizontal" className="justify-content-end gap-3">
              {activeTabIndex !== 2 &&  <Button variant="" className="freela-btn-primary-outline" onClick={handleSkip}>Skip</Button>}
                <Button className="freela-btn-primary" variant="" disabled={tabErrors > 0 || activeTabIndex === 2} onClick={handleNextTab}>Save &amp; Continue</Button>
              </Stack>
            </Col>
          </Row>
        </Card.Body>
      </section>
    </>
  );
};

export default ClientProfileCreation;
