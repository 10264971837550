import React, { useState, useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import info from "../../../Images/icons/information.png";
import delete_icon from "../../../Images/icons/delete-icon.png";

import "./ExtraService.css";
const ExtraService = ({ updatePayloadData }) => {
  const [addons, setAddons] = useState([]);

  const [formInput, setFormInput] = useState({
    addonTitle: "",
    addonPrice: 0,
    addonDescription: "",
    addonStatus: true,
    addonTime: "hour",
  });

  const handleFormChange = (field, value) => {
    setFormInput((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const addNewAddon = () => {
    setAddons((prev) => [...prev, formInput]);
    setFormInput({
      addonTitle: "",
      addonPrice: 0,
      addonDescription: "",
      addonStatus: true,
      addonTime: "hour",
    });
  };

  const handleDelete = () => {
    setFormInput({
      addonTitle: "",
      addonPrice: 0,
      addonDescription: "",
      addonStatus: true,
      addonTime: "hour",
    });
  };

  const handleAddonChange = (index, field, value) => {
    const updatedAddons = [...addons];
    updatedAddons[index][field] = value;
    setAddons(updatedAddons);
  };

  // console.log(addons, "addons");

  useEffect(() => {
    updatePayloadData({ addons });
  }, [addons]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center service-tilte">
        <h2 className="my-0">Extra Service</h2>
        <button type="button" className="secondary-btn" onClick={addNewAddon}>
          Add Option
        </button>
      </div>

      <Card className="extra_service_card_edit p-4">
        <div className="extra_service_inputs">
          <Form.Group className="w-50 me-3 mt-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Add Title Here"
              value={formInput.addonTitle}
              onChange={(e) => handleFormChange("addonTitle", e.target.value)}
            />
          </Form.Group>
          <div className="d-flex mt-3 align-items-end">
            <Form.Group className="me-3">
              <Form.Label>For an Extra</Form.Label>
              <Form.Control
                type="number"
                value={formInput.addonPrice}
                onChange={(e) => handleFormChange("addonPrice", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="me-3">
              <Form.Label>Additional Time</Form.Label>
              <Form.Select
                value={formInput.addonTime}
                onChange={(e) => handleFormChange("addonTime", e.target.value)}
              >
                <option value="">Time</option>
                <option value="hour">Hour</option>
              </Form.Select>
            </Form.Group>
            <div>
              <img
                src={delete_icon}
                alt="delete"
                width="35px"
                height="35px"
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <label>Description</label>
          <textarea
            className="w-100"
            value={formInput.addonDescription}
            onChange={(e) =>
              handleFormChange("addonDescription", e.target.value)
            }
          >
            Enter Text here
          </textarea>
          <label className="text-end">0/100 Characters</label>
        </div>
      </Card>

      {addons.length !== 0 && (
        <Card className="extra_service_card">
          <ul className="mb-0">
            {addons.map((addon, index) => (
              <li key={index}>
                <div className="d-flex w-50">
                  <Form.Check
                    type="checkbox"
                    className="custom-checkbox m-0"
                    checked={addon.addonStatus}
                    onChange={(e) =>
                      handleAddonChange(index, "addonStatus", e.target.checked)
                    }
                  />
                  <label className="ms-2">
                    {addon.addonTitle}
                    <img src={info} alt="info" className="ms-2" />
                  </label>
                </div>
                <div className="d-flex align-items-center es_input">
                  <label className="me-2">For an extra</label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={addon.addonPrice}
                      readOnly
                    />
                    <InputGroup.Text>$</InputGroup.Text>
                  </InputGroup>
                </div>
              </li>
            ))}
          </ul>
        </Card>
      )}
    </div>
  );
};
export default ExtraService;
