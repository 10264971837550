import { useState } from "react";
import MyMessages from './MyMessages';
import PublishedMessages from './PublishedMessages';
import DraftMessages from './DraftMessages';
import SavedProjects from "./SavedProjects";
import { Button } from "react-bootstrap";


const MyService = ({ role, counts }) => {
  const [activeButton, setActiveButton] = useState("myMessages");

  // console.log(role,"role in myservice");


  const renderContent = () => {
    switch (activeButton) {
      case "myMessages":
        return <MyMessages role={role} />;

      case "published":
        return <PublishedMessages role={role} />;

      case "drafts":
        return <DraftMessages role={role} />;
      case "saved":
        return <SavedProjects role={role} />;
      default:
        return null;
    }
  };


  return (
    <div className="freela_horizontal_tabs">
      <div className="tab_lists pills pt-0">
        {['myMessages', 'published', 'drafts', 'saved'].map((list, idx) => (
          <Button key={idx} onClick={() => setActiveButton(list)} variant="" className={activeButton === list && 'active'}>
            {list === 'myMessages' ? `My Messages ` : list === 'published' ? `Published (${counts.finalCount})` : list === 'drafts' ? `Drafts (${counts.draftCount})` : list === 'saved' ? `My Saved Projects ` : ''}
          </Button>
        ))}
      </div>
      <div className="tab_content mt-4">{renderContent()}</div>
    </div>
  );
};

export default MyService;
