// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio_links textarea{
  border-radius: 30px !important;
  border: 1px solid #DFDFDF;
  padding: 10px;
  outline:none;
}
.portfolio_links label{
  color:  #626262;
  font-size: 14px;
  margin-top:1rem;
}
.custom-hr{
  border-top: 1px dashed #989898;
  margin: 20px 0;
  width: 100%;
 
}

`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Profile/Portfolio/PortfolioTab.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,YAAY;AACd;AACA;EACE,eAAe;EACf,eAAe;EACf,eAAe;AACjB;AACA;EACE,8BAA8B;EAC9B,cAAc;EACd,WAAW;;AAEb","sourcesContent":[".portfolio_links textarea{\r\n  border-radius: 30px !important;\r\n  border: 1px solid #DFDFDF;\r\n  padding: 10px;\r\n  outline:none;\r\n}\r\n.portfolio_links label{\r\n  color:  #626262;\r\n  font-size: 14px;\r\n  margin-top:1rem;\r\n}\r\n.custom-hr{\r\n  border-top: 1px dashed #989898;\r\n  margin: 20px 0;\r\n  width: 100%;\r\n \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
