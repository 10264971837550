import React from "react";
import FacebookLogin from "react-facebook-login";
import { Button } from "react-bootstrap";
import { ReactComponent as FbIcon } from "../../Images/freela-icons/fb-icon.svg";

const FacebookLoginButton = ({ handleSocial }) => {
  const handleLogin = (response) => {
    console.log("Login Response:", response);

    handleSocial("FACEBOOK", response?.accessToken);
  };

  return (
    <FacebookLogin
      appId="965479181715667"
      fields="name,email,picture"
      callback={handleLogin}
      render={(renderProps) => (
        <Button
          variant="link"
          className="freela-text-primary text-decoration-none"
          onClick={renderProps.onClick} // Trigger login on button click
        >
          <FbIcon /> {/* Your custom Facebook icon */}
        </Button>
      )}
    />
  );
};

export default FacebookLoginButton;
