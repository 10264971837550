import React, { useState } from "react";
import { ReactComponent as FreelaLogo } from "../../Images/freela-icons/freela-logo.svg";
import RegisterVerifyImg from "../../Images/freela-icons/forgot-verify-img.svg";
import { Row, Col, Button, Form, Image } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import { registerVerifyValidationSchema } from "../Common/Validation/validationSchema";
import ApiCall from "../../Api/ApiCall";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";

const RegisterVerify = ({ regEmail, openCongratsModal }) => {
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();

  // Handle OTP submission
  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const payload = {
        email: regEmail,
        otp: Number(values.otp),
        authenticationType: "Registration",
      };
      const response = await ApiCall(
        "POST",
        "user/verifycode",
        payload,
        "application/json"
      );

      if (response.statusCode === 200) {
        notify("success", "Registration verified successfully!");
        openCongratsModal(); // Open success modal
      } else {
        notify("error", response.message || "Verification failed. Try again.");
      }
    } catch (error) {
      notify("error", "Verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP resend
  const handleResend = async () => {
    setLoading(true);

    try {
      const payload = {
        email: regEmail,
        authenticationType: "Registration",
      };

      const response = await ApiCall(
        "POST",
        "user/resendcode",
        payload,
        "application/json"
      );

      if (response.statusCode === 200) {
        notify("success", "OTP resent successfully!");
      } else {
        notify("error", response.message || "Resending failed. Try again.");
      }
    } catch (error) {
      notify("error", "OTP resend failed. Try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Row className="login_form align-items-center">
        {/* Left Section */}
        <Col sm={12} md={12} lg={5} xl={5}>
          <FreelaLogo className="ms-lg-4 mt-lg-2" width={260} />
          <div className="d-flex align-items-center justify-content-center my-xl-5 py-xl-2">
            <Image src={RegisterVerifyImg} alt="VerifyImg" fluid />
          </div>
        </Col>

        {/* Right Section */}
        <Col sm={12} md={12} lg={7} xl={7}>
          <h1 className="fs-5 mb-2">Enter OTP to verify your account</h1>
          <p className="text-secondary">
            An OTP has been sent to your email {regEmail}
          </p>

          {/* OTP Form using Formik */}
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={registerVerifyValidationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <FormikForm>
                {/* OTP Input */}
                <Form.Group className="mt-4 mb-2">
                  <Field
                    name="otp"
                    type="text"
                    className="form-control"
                    placeholder="Enter OTP"
                  />
                  <ErrorMessage
                    name="otp"
                    component="small"
                    className="text-danger d-block mt-1"
                  />
                </Form.Group>

                {/* Resend OTP */}
                <Form.Group className="mb-3 text-end">
                  Didn't receive the code?
                  <Button
                    variant=""
                    className="py-0 ps-1 text-decoration-none freela-text-primary"
                    onClick={handleResend}
                  >
                    Resend OTP
                  </Button>
                </Form.Group>

                {/* Submit Button */}
                <Form.Group as={Row} className="text-center mt-5">
                  <Col md={6} className="mt-4 mx-auto d-grid">
                    <Button
                      type="submit"
                      variant=""
                      className="freela-btn-primary px-5"
                    >
                      Verify
                    </Button>
                  </Col>
                </Form.Group>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default RegisterVerify;
