import React, { useEffect, useState } from "react";
import {
  Form,
  Stack,
  Image,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { ReactComponent as StatusFlag } from "../../../../Images/freela-icons/status-flag.svg";
import ProfilePhoto from "../../../../Images/freela-images/profile.png";
import Loader from "../../../Common/Loader/Loader";
import useToast from "../../../../Hooks/useToast";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../../../Api/ApiCall";

export const CompareListModal = ({
  activeRoleDetails,
  projectId,
  setModalShow,
  setEnableGrid,
  setComparingData,
}) => {
  
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();

  const handleCheckboxChange = (userId) => {
    setSelectedUserIds(
      (prevSelected) =>
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId) // Remove if already selected
          : [...prevSelected, userId] // Add if not selected
    );
  };

  const handleCompareProfiles = async () => {
    setLoading(true);
    try {
      const payload = {
        userProfiles: selectedUserIds,
        projectId: projectId,
      };
      const response = await ApiCall(
        "POST",
        "bid/compareProfiles",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log("successful:", response.data);
        setComparingData(response.data);
        setEnableGrid(true);
        setModalShow(false);
        
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      <div className="comparelist_modal">
        {loading && <Loader />}
        <Stack direction="horizontal" className="mb-2 mt-4 mt-lg-0">
          <h6>Compare List</h6>
          <h6 className="ms-auto">Selected - {selectedUserIds?.length} </h6>
        </Stack>
        {activeRoleDetails?.map((list, idx) => (
          <Form
            as="ul"
            className="comparelist_modal_content ps-0 mb-2 pe-3"
            key={idx}
          >
            <Stack as="li" direction="horizontal">
              <Form.Check
                type="checkbox"
                className="d-flex align-items-center gap-3"
                id={`comparelist-checkbox-${idx}`}
              >
                <Form.Check.Input
                  checked={selectedUserIds.includes(list.userId)}
                  onChange={() => handleCheckboxChange(list.userId)}
                />
                <Form.Check.Label className="d-flex align-items-center gap-3">
                  <div className="position-relative">
                    <Image
                      src={list?.profilePhoto || ProfilePhoto}
                      className="img-thumbnail border border-1 freela-border-primary rounded-circle p-05 mb-0"
                      width={52}
                      height={52}
                    />
                    <span
                      className="position-absolute bottom-0 mt-3 start-100 translate-middle badge border border-light rounded-circle bg-success"
                      style={{ padding: 6 }}
                    >
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                  <p className="w-200px text-truncate mb-0">
                    {list?.firstName}
                  </p>
                </Form.Check.Label>
              </Form.Check>
              <div className="status_flag ms-auto">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>{list.flag}</Tooltip>}
                >
                  <StatusFlag className={`flag-${list.flag}`} />
                </OverlayTrigger>
              </div>
            </Stack>
          </Form>
        ))}
        <Form.Group className="text-center mb-0">
          <Button
            variant=""
            className="freela-btn-primary px-5"
            onClick={() => handleCompareProfiles()}
            disabled={selectedUserIds.length < 2}
          >
            Continue
          </Button>
        </Form.Group>
      </div>
    </>
  );
};
