import { createContext, useContext, useState, useEffect, useCallback } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(!!localStorage.getItem("token"));

  const login = (token) => {
    if (token !== undefined) {
      localStorage.setItem("token", token);
      setIsLogin(true);
      resetInactivityTimer(); // Start inactivity timer on login
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setIsLogin(false);
    clearInactivityTimer(); // Clear the timer on logout
  };

  // Inactivity Timer Management
  let inactivityTimeout;

  const resetInactivityTimer = useCallback(() => {
    clearTimeout(inactivityTimeout); // Clear any existing timer
    inactivityTimeout = setTimeout(() => {
      logout(); // Logout after 1 hour (3600000ms) of inactivity
    }, 3600000); // 1 hour
  }, [logout]);

  const clearInactivityTimer = () => {
    clearTimeout(inactivityTimeout); // Clear the timer when necessary
  };

  const trackActivity = useCallback(() => {
    if (isLogin) {
      resetInactivityTimer(); // Reset the timer on user activity
    }
  }, [isLogin, resetInactivityTimer]);

  useEffect(() => {
    if (isLogin) {
      // Attach event listeners for user activity
      window.addEventListener("mousemove", trackActivity);
      window.addEventListener("keydown", trackActivity);

      // Start the timer initially
      resetInactivityTimer();
    }

    return () => {
      // Cleanup event listeners and timer on unmount or logout
      window.removeEventListener("mousemove", trackActivity);
      window.removeEventListener("keydown", trackActivity);
      clearInactivityTimer();
    };
  }, [isLogin, trackActivity, resetInactivityTimer]);

  return (
    <AuthContext.Provider value={{ isLogin, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
