import React, { useState, useRef, useEffect } from "react";
import useCommonNavigate from "../../../../Hooks/useCommonNavigate";
import "./Profile.css";
import backArrowCircleSvg from "../../../../Images/icons/backArrow-circle.svg";
import uploadIconSvg from "../../../../Images/icons/upload.svg";

import ProfileCreation from "../ProfileCreation/ProfileCreation";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";

import pdfIcon from "../../../../Images/icons/pdf-icon.svg";
import downloadIcon from "../../../../Images/icons/download-icon.svg";
import trashIcon from "../../../../Images/icons/trash-icon.svg";
import camera from "../../../../Images/icons/camera.svg";
import { Card, Row, Col } from "react-bootstrap";

const Profile = ({ userDetails, getUserDetails }) => {
  const [certificateFile, setCertificateFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const certiInputRef = useRef(null);
  const profileInputRef = useRef(null);
  const coverInputRef = useRef(null);
  const [showProfileCreation, setShowProfileCreation] = useState(false); // State to toggle ProfileCreation component
  const { commonNavigate } = useCommonNavigate();
  const [error, setError] = useState("");
  const { notify } = useToast();
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState({
    certificateFile: "",
    profilePhoto: "",
    coverPhoto: "",
  });

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userDetails) {
      setFileUrls({
        certificateFile: userDetails.profile?.resume || "",
        profilePhoto: userDetails.profile?.profilePic || "",
        coverPhoto: userDetails.profile?.coverPhoto || "",
      });
    }
  }, [userDetails]);

  const resetValues = () => {
    setCertificateFile(null);
    setProfilePhoto(null);
    setCoverPhoto(null);
  };

  const handleNavigate = () => {
    commonNavigate("/settings");
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0]; // Get the file from input
    if (file) {
      try {
        // Prepare the form data with the uploaded file
        const formData = new FormData();
        formData.append("file", file);

        // Call the API to upload the file
        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // Handle successful file upload response
          setPhoto(file);
          setFileUrls((prevState) => ({
            ...prevState,
            [field]: response.data.s3Url, // Update the correct field dynamically
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handles upload click to trigger file input
  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };

  const handleSkip = () => {
    setShowProfileCreation(true); // Show the ProfileCreation component
  };

  const handleBackArrow = () => {
    setShowProfileCreation(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const payload = {
      profile: {
        profilePic: fileUrls.profilePhoto,
        coverPhoto: fileUrls.coverPhoto,
        resume: fileUrls.certificateFile,
      },
    };

    try {
      console.log(payload, "payload ");

      // Make the API call
      const response = await ApiCall(
        "PUT",
        `user/${userId}/photos`,
        payload,
        "application/json"
      );

      // Handle success with 200 status code
      if (response.statusCode === 200) {
        notify("success", "Photos uploaded successfully!");
        resetValues();
        setShowProfileCreation(true);
        getUserDetails();
      } else {
        setError("Upload failed. Please try again.");
        notify("error", response.message || "Upload failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      console.error("Upload error:", error);
      setError("Upload failed. Please try again.");
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (certificateFile) {
      const fileURL = URL.createObjectURL(certificateFile);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = certificateFile.name;
      link.click();
      URL.revokeObjectURL(fileURL); // Clean up the object URL
    }
  };

  const handleDelete = (e) => {
    console.log("handleDelete", e);

    setCertificateFile(null); // Remove the uploaded file from state
    setFileUrls((prevState) => ({
      ...prevState,
      certificateFile: "", // Reset only the certificateFile field
    }));
  };

  const handleS3Delete = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // const userId = localStorage.getItem("userId");

    const payload = {
      profile: {
        profilePic: userDetails?.profile?.profilePic,

        coverPhoto: userDetails?.profile?.coverPhoto,

        resume: "",
      },
    };

    try {
      // Make the API call
      const response = await ApiCall(
        "PUT",
        `user/${userId}/photos`,
        payload,
        "application/json"
      );

      // Handle success with 200 status code
      if (response.statusCode === 200) {
        notify("success", "Resume deleted successfully!");
        getUserDetails();
      } else {
        setError("Upload failed. Please try again.");
        notify("error", response.message || "Upload failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      console.error("Upload error:", error);
      setError("Upload failed. Please try again.");
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="profile-layout">
        {showProfileCreation ? (
          <ProfileCreation
            handleBackArrow={handleBackArrow}
            userDetails={userDetails}
            getUserDetails={getUserDetails}
          />
        ) : (
          <>
            {/* First Row: Back Arrow */}
            <div className="back-arrow-row" onClick={handleNavigate}>
              <img
                src={backArrowCircleSvg}
                alt="Back Arrow"
                className="back-arrow-icon"
              />
            </div>
            {/* Second Row: Heading */}
            <div className="heading-row">
              <h3>Create your profile</h3>
            </div>

            {/* <Card className="upload_doc_card mt-4">
            <div className="d-flex justify-content-between align-items-center">
            <div>
                  <img
                    src={uploadIconSvg}
                    alt="Upload Icon"
                    width="40px"
                    height="40px"
                  />
                    <label>
                      Please upload your Resume 
                    </label>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="secondary-btn"  onClick={() => handleUploadClick(certiInputRef)}>Choose</button>
                  </div>
              </div>
            </Card> */}

            <Card className="upload_doc_card mt-4">
              <div className="d-flex justify-content-between align-items-center">
                {certificateFile ? (
                  <div className="file-preview">
                    <div className="file-details d-flex align-items-center">
                      <img
                        src={pdfIcon}
                        alt="PDF Icon"
                        width="40px"
                        height="40px"
                      />
                      <div className="file-info ms-3">
                        <p className="file-title mb-0">{certificateFile.name}</p>
                        <p className="file-meta mb-0">
                          {certificateFile.size} KB ·{" "}
                          {new Date(
                            certificateFile.lastModified
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <div className="file-actions">
                      <button onClick={handleDownload}>
                        <img src={downloadIcon} alt="Download Icon" />
                      </button>
                      <button className="ms-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete();
                        }}
                      >
                        <img src={trashIcon} alt="Delete Icon" />
                      </button>
                    </div>
                  </div>
                ) : userDetails?.profile?.resume ? (
                  <div className="file-preview">
                    <div className="file-details d-flex align-items-center">
                      <img
                        src={pdfIcon}
                        alt="PDF Icon"
                        width="40px"
                        height="40px"
                      />
                      <div className="file-info ms-3">
                        <p className="file-title">
                          {userDetails?.profile?.resume.name}
                        </p>
                        <p className="file-meta">
                          {userDetails?.profile?.resume.size} KB
                        </p>
                      </div>
                    </div>
                    <div className="file-actions">
                      <button
                        onClick={(e) => {
                          if (
                            window.confirm("Do you want to delete this file?")
                          ) {
                            handleS3Delete(e);
                          }
                        }}
                      >
                        <img src={trashIcon} alt="Delete Icon" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      <img
                        src={uploadIconSvg}
                        alt="Upload Icon"
                        width="40px"
                        height="40px"
                      />
                      <label className="ml-3">Please upload your Resume</label>
                    </div>
                  </>
                )}
                <div>
                  <input
                    type="file"
                    accept=".pdf,.docx"
                    style={{ display: "none" }}
                    ref={certiInputRef}
                    onChange={(e) =>
                      handlePhotoChange(
                        e,
                        setCertificateFile,
                        "certificateFile"
                      )
                    }
                  />
                  <button
                    type="button"
                    className="secondary-btn"
                    onClick={() => handleUploadClick(certiInputRef)}
                  >
                    Choose
                  </button>
                </div>
              </div>
            </Card>

            <div className="browse_sec mt-4">
              <Row>
                {/* Profile Photo Upload */}
                <Col sm={6} md={4} lg={3}>
                  <Card className="browse_card">
                    <div
                      className="text-center upload_icon"
                      onClick={() => handleUploadClick(profileInputRef)}
                    >
                      {profilePhoto ? (
                        <img
                          src={URL.createObjectURL(profilePhoto)}
                          alt="Profile Preview"
                          className="preview-image"
                        />
                      ) : userDetails?.profile?.profilePic ? (
                        <div className="position-relative preview_card">
                        <img
                          src={userDetails?.profile?.profilePic}
                          alt="User Details Profile Photo Preview"
                          className="preview-image"
                        />
                        <div className="camera_img">
                          <img src={camera} alt="camera"/>
                          </div>
                        </div>
                      ) : (
                        <div>
                        <div>
                           <img src={uploadIconSvg} alt="Upload Icon" />
                        <h5 className="pt-2">Please Upload Your Photo</h5>
                        </div>
                         <label className="browse-button">
                         Browse
                         <input
                           type="file"
                           accept="image/*"
                           ref={profileInputRef}
                           style={{ display: "none" }}
                           onChange={(e) =>
                             handlePhotoChange(e, setProfilePhoto, "profilePhoto")
                           }
                         />
                       </label>
                       </div>
                     
                      )}
                    </div>

                   
                  </Card>
                </Col>

                {/* Cover Photo Upload */}
                <Col sm={6} md={8} lg={9}>
                  <Card className="browse_card mt-3 mt-sm-0">
                    <div
                      className="text-center upload_icon"
                      onClick={() => handleUploadClick(coverInputRef)}
                    >
                      {coverPhoto ? (
                        <img
                          src={URL.createObjectURL(coverPhoto)}
                          alt="Cover Preview"
                          className="preview-cover"
                        />
                      ) : userDetails?.profile?.coverPhoto ? (
                        <div className="position-relative preview_card">
                        <img
                          src={userDetails?.profile?.coverPhoto}
                          alt="User Details Cover Photo Preview"
                          className="preview-image"
                        />
                        <div className="camera_img">
                          <img src={camera} alt="camera"/>
                          </div>
                        
                        </div>
                      ) : (
                        <div>
                        <div>
                        <img src={uploadIconSvg} alt="Upload Icon" />
                        <h6 className="pt-2">Please Upload Your Cover Photo</h6>
                        </div>
                         <label className="browse-button">
                         Browse
                         <input
                           type="file"
                           accept="image/*"
                           ref={coverInputRef}
                           style={{ display: "none" }}
                           onChange={(e) =>
                             handlePhotoChange(e, setCoverPhoto, "coverPhoto")
                           }
                         />
                       </label>
                        </div>
                      )}
                    </div>

                   
                  </Card>
                </Col>

                {/* <Col sm={12} md={4} lg={3}>
                  <Card className="browse_card">
                    <div className="text-center upload_icon">
                      <img src={uploadIconSvg} alt="Upload Icon" />
                    </div>
                    <h5>Please upload your photo</h5>
                    <label className="browse-button">
                      Browse
                      <input
                        type="file"
                        accept="image/*"
                        ref={profileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handlePhotoChange(e, setProfilePhoto, "profilePhoto")
                        }
                      />
                    </label>
                  </Card>
                </Col> */}
                {/* <Col sm={12} md={8} lg={9}>
                  <div className="uploaded_img">
                    <div className="upload_card">
                      <Card>
                        <div className="text-center upload_icon">
                          <img src={uploadIconSvg} alt="Upload Icon" />
                        </div>
                        <p className="text-center my-3">
                          Please upload your cover image
                        </p>
                        <div className="text-center">
                          <label className="browse-button">
                            Browse
                            <input
                              type="file"
                              accept="image/*"
                              ref={coverInputRef}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handlePhotoChange(
                                  e,
                                  setCoverPhoto,
                                  "coverPhoto"
                                )
                              }
                            />
                          </label>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </div>

            {/* Buttons: Skip and Save & Continue */}

            <div className="buttons-row text-end mt-4">
              <button className="secondary-btn" onClick={handleSkip}>
                Skip
              </button>
              {(certificateFile || profilePhoto || coverPhoto) && (
                <button
                  className="primary-btn ms-3"
                  onClick={(e) => handleSubmit(e, "submit")}
                >
                  Save & Continue
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profile;
