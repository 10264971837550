import React from 'react'
import { Card, Row, Col, Form } from "react-bootstrap";
import listview from "../../../Images/list_view.png";
import gridview from "../../../Images/grid_view.png";
import online from "../../../Images/online_icon.png";
import profilePic from "../../../Images/profile.png"
import reputation from "../../../Images/reputation_score.png";
import aiverify from "../../../Images/ai_verification.png";
import clientrating from "../../../Images/client-rating.png";
import amount from "../../../Images/amount_usd.png";

import "./MyWorks.css";

const MyWorks = () => {
  return (
    <div>
      <div className='find-work-sec'>
     <Row>
      <Col xs={12} sm={12} lg={12}>
        <Card>
          <div>
            <h1>Find your work</h1>
          </div>
          <div className='search_sec my-4'>
            <div className='w-25'>
          <Form.Select aria-label="Default select example">
            <option>Location</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          </div>
          <div className='w-25'>
          <Form.Select aria-label="Default select example">
            <option>Category</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          </div>
          <div className='w-50 d-flex'>
          <Form.Control type="text" placeholder="Search here..." />
          <div>
            <button type="button" className='primary-btn'>Search</button>
          </div>
          </div>
          
          </div>
          <div className='worklist'>
            <ul>
              <li>Web Dev</li>
              <li>Mobile Dev</li>
              <li>Design</li>
              <li>Writing</li>
              <li>Admin Support</li>
            </ul>
          </div>
        </Card>
      </Col>
     </Row>
     </div>
     <div className='mt-4'>
     <Row>
      <Col xs={12} sm={12} md={6} lg={9}>
        <Card className='people_left_card'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
            <h3>Showing 87 of 234 Peoples</h3>
            </div>
            <div className='d-flex align-items-center'>
              
              <img src={gridview} alt="grid"/>
              <img src={listview} alt="list" className='ms-3'/>
              <div className='ms-3'>
              <Form.Select aria-label="Default select example">
                <option>Select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
              </div>
            </div>
          </div>
          <Row>
            <Col sm={12} md={6} lg={6} className='mt-4'>
              <Card className="people_card">
                <Card.Body className='p-0'>
                  <div className='p-4'>
                  <div className='d-flex'>
                  <div className="profile_img">
                  <img src={profilePic} alt="profile" className='prof_pic' />
                  <div className="profile_img_online">
                    <img src={online} alt="profile" />
                  </div>
                </div>
                <div className="ms-2">
                  <h4 className="mb-0">Rakesh Kumar</h4>
                  <label>Freelancer - UI UX Designer</label>
                  <label>India - Posted by : 5 min ago</label>
                </div>
                
                  </div>
                  <p className='pt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                <div className='peoplelist'>
                  <ul>
                    <li>Visual Designer</li>
                    <li>UI UX Designer</li>
                    <li>Figma</li>
                  </ul>
                </div>
                </div>
                <div className='payment_strip'>
                  <label>Payment Accepted <span>: FREL, Crypto, $ Dollar</span></label>
                </div>
                </Card.Body>
                <Card.Footer className='border-0 p-2'>
                <div class="work-grid-container">
                  <div class="grid-item">
                    <img src={reputation} alt="image"/>
                    <label>Reputation Score</label>
                    <h6><span>4.5</span>/5</h6>
                  </div>
                  <div class="grid-item">
                  <img src={aiverify} alt="image"/>
                  <label>AI Verification</label>
                  <h6><span>Verifiled</span></h6>
                  </div>
                  <div class="grid-item">
                  <img src={clientrating} alt="image"/>
                  <label>Client Rating</label>
                  <h6><span>4.5</span>(2547)</h6>
                  </div>
                  <div class="grid-item">
                  <img src={amount} alt="image"/>
                  <label>Amount USD</label>
                  <h6><span>$50</span>/Hour</h6>
                  </div>
                </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} className='mt-4'>
              <Card className="people_card">
                <Card.Body className='p-0'>
                  <div className='p-4'>
                  <div className='d-flex'>
                  <div className="profile_img">
                  <img src={profilePic} alt="profile" className='prof_pic' />
                  <div className="profile_img_online">
                    <img src={online} alt="profile" />
                  </div>
                </div>
                <div className="ms-2">
                  <h4 className="mb-0">Rakesh Kumar</h4>
                  <label>Freelancer - UI UX Designer</label>
                  <label>India - Posted by : 5 min ago</label>
                </div>
                
                  </div>
                  <p className='pt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                <div className='peoplelist'>
                  <ul>
                    <li>Visual Designer</li>
                    <li>UI UX Designer</li>
                    <li>Figma</li>
                  </ul>
                </div>
                </div>
                <div className='payment_strip'>
                  <label>Payment Accepted <span>: FREL, Crypto, $ Dollar</span></label>
                </div>
                </Card.Body>
                <Card.Footer className='border-0 p-2'>
                <div class="work-grid-container">
                  <div class="grid-item">
                    <img src={reputation} alt="image"/>
                    <label>Reputation Score</label>
                    <h6><span>4.5</span>/5</h6>
                  </div>
                  <div class="grid-item">
                  <img src={aiverify} alt="image"/>
                  <label>AI Verification</label>
                  <h6><span>Verifiled</span></h6>
                  </div>
                  <div class="grid-item">
                  <img src={clientrating} alt="image"/>
                  <label>Client Rating</label>
                  <h6><span>4.5</span>(2547)</h6>
                  </div>
                  <div class="grid-item">
                  <img src={amount} alt="image"/>
                  <label>Amount USD</label>
                  <h6><span>$50</span>/Hour</h6>
                  </div>
                </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          
        </Card>
      </Col>
      <Col xs={12} sm={12} md={6} lg={3}>
      <Card className='people_right_card'>

      </Card>
      </Col>
     </Row>
     </div>
    </div>
  )
}

export default MyWorks
