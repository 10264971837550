import { useEffect, useState } from "react";
import MyService from "./Tabs/MyService";
import MyProposals from "./Tabs/MyProposals";
import MyTasks from "./Tabs/MyTasks";
import MyWorkspace from "./Tabs/MyWorkspace";
import MyCalendar from "./Tabs/MyCalendar";
import { Button } from "react-bootstrap";


const FreelancerView = ({counts}) => {
  const [activeTab, setActiveTab] = useState("myService");
  const [getIds, setGetIds] = useState({
    projectId: "",
    clientId: "",
    freelancerId: "",
  });
  
  const renderTabContent = () => {
    switch (activeTab) {
      case "myService":
        return <MyService role="freelancer" counts={counts} />;
      case "myProposals":
        return <MyProposals role="freelancer" />;
      case "myTasks":
        return <MyTasks role="freelancer" setActiveTab={setActiveTab} setGetIds={setGetIds} />;
      case "myWorkspace":
        return <MyWorkspace role="freelancer" getIds={getIds}/>;
      case "myCalendar":
        return <MyCalendar role="freelancer" />;
      default:
        return null;
    }
  };

  return (
    <>
     
      <div className="freela_horizontal_tabs mt-2">
        <div className="tab_lists px-4">
          {[
            "myService",
            "myProposals",
            "myTasks",
            "myWorkspace",
            "myCalendar",
          ].map((type, idx) => (
            <Button
              key={idx}
              onClick={() => setActiveTab(type)}
              variant=""
              className={activeTab === type && "active"}
            >
              {type === "myService"
                ? `My Service  (${
                    (counts.msgCount || 0) +
                    counts.draftCount +
                    counts.finalCount +
                    (counts.savedCount || 0)
                  })`
                : type === "myProposals"
                ? `My Proposals (${counts.bidCount})`
                : type === "myTasks"
                ? "My Tasks"
                : type === "myWorkspace"
                ? "My Workspace"
                : type === "myCalendar"
                ? "My Calendar"
                : ""}
            </Button>
          ))}
        </div>
        <div className="tab_content p-4">{renderTabContent()}</div>
      </div>
    </>
  );
};

export default FreelancerView;
