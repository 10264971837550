import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

export const Breadcrumbs = ({ breadcrumbList }) => {
    return (
        <Nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {breadcrumbList.map((breadcrumb, idx) => (
                    breadcrumb.path ?
                        <Nav.Item as='li' className="breadcrumb-item" key={idx}>
                            <NavLink to={breadcrumb.path} className="text-decoration-none freela-greaseblack-text">{breadcrumb.name}</NavLink>
                        </Nav.Item>
                        :
                        <Nav.Item as='li' className="breadcrumb-item active freela-text-primary" aria-current="page">{breadcrumb.name}</Nav.Item>
                ))}
            </ol>
        </Nav>
    )
}
