import React, { useRef, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import profile from "../../../Images/profile_pic.png";
import online from "../../../Images/online_icon.png";
// import "./ProjectPreview.css";
import playBtn from "../../../Images/play_btn.png";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";

import frel from "../../../Images/payment_icons/frel.png";
import usd from "../../../Images/payment_icons/usd.png";
import inr from "../../../Images/payment_icons/inr.png";
import euro from "../../../Images/payment_icons/euro.png";
import pound from "../../../Images/payment_icons/pound.png";
import bitcoin from "../../../Images/payment_icons/bitcoin.png";
import litecoin from "../../../Images/payment_icons/litecoin.png";
import ethereum from "../../../Images/payment_icons/ethereum.png";
import usdt from "../../../Images/payment_icons/usdt.png";
import PlaceBid from "../MyWorks/PlaceBid";
import Modal from "../../Common/Modal/CustomModal";
import Loader from "../../Common/Loader/Loader";
import pdfIcon from "../../../Images/icons/pdf-icon.svg";
import PaymentModes from "../../Common/PaymentModes";

const ProjectView = ({
  payloadData,
  skillPayloadData,
  handleNext,
  videoSrc,
  userDetails,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { notify } = useToast();
  const [showBidModal, setShowBidModal] = useState(false);
  const paymentData = (skillPayloadData || payloadData)?.acceptedPaymentModes;

  const handlePlayPause = () => {
    console.log("handlePlayPause");

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const userId = localStorage.getItem("userId");

  const handlePost = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: payloadData?.projectName,
        description: payloadData?.description,
        category: payloadData?.category,
        subCategory: payloadData?.subCategory,
        userId: userId,
        tags: payloadData?.searchTagsList,
        skillsNeeded: skillPayloadData?.skillsNeeded,
        instruction: payloadData?.instruction,
        media: [
          {
            coverImage: payloadData?.coverPhoto,
            video: payloadData?.video,
            photo: payloadData?.profilePhoto,
            document: payloadData?.certificateFile,
          },
        ],
        paymentType: {
          type: skillPayloadData?.paymentType?.type,
          duration: skillPayloadData?.paymentType?.duration,
        },
        estimateBudget: {
          min: skillPayloadData?.estimateBudget?.min,
          max: skillPayloadData?.estimateBudget?.max,
        },
        acceptedPaymentModes: skillPayloadData?.acceptedPaymentModes,
        serviceType: skillPayloadData?.serviceType,
        tier: skillPayloadData?.tier,
      };

      // console.log(payload, "project post payload");

      // Make the API call
      const response = await ApiCall(
        "POST",
        "project/createProject",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 201) {
        console.log("successful:", response.data);
        notify("success", "Project post successfully!");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleBid = () => {
    setShowBidModal(true);
  };
  const handleCloseModal = () => {
    setShowBidModal(false);
  };
 
  return (
    <>
      {loading && <Loader />}
      {showBidModal && (
        <Modal isOpen={showBidModal} onClose={handleCloseModal}>
          <PlaceBid
            projectId={payloadData?.projectId}
            clientId={payloadData?.userId}
            freelancerId={userDetails?.userId}
            freelancerName={userDetails?.firstName}
            userId={userId}
          />
        </Modal>
      )}
      <Row className="mt-4">
        <Col sm={12} md={8} lg={8} className="project_preview_left">
          <Card className="about_card">
            <div className="about_heading_sec">
              <h5>{payloadData?.projectName || payloadData?.title}</h5>
            </div>
            <div className="profile_sec mt-3">
              <div className="profile_img">
                <img
                  src={
                    type === "published"
                      ? payloadData?.profilePhoto
                      : userDetails?.profile?.profilePic
                  }
                  alt="profile"
                />
                <div className="profile_img_online">
                  <img src={online} alt="profile" />
                </div>
              </div>
              <div className="ms-2">
                <h6 className="mb-0">
                  {type === "published" || "bid"
                    ? payloadData?.firstName
                    : userDetails?.firstName}
                </h6>
                <label>Freelancer - UI UX Designer</label>
              </div>
            </div>
            <div className="mt-4">
              <h5>About Service</h5>
              <p>{payloadData?.description}</p>
            </div>
            <div className="mt-4">
              <h5>Instruction</h5>
              <p> {payloadData?.instruction}</p>
              <div>
                <h5>Tags</h5>
                <div className="tagList">
                  <ul className="justify-content-start ps-0">
                    {(payloadData?.searchTagsList || payloadData?.tags)?.map(
                      (tag, index) => (
                        <li key={index}>{tag}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <h5>Document</h5>
                </div>
                <div className="file-preview ms-3">
                  <div className="file-details">
                    <img src={pdfIcon} alt="PDF Icon" className="file-icon" />
                    <div className="file-info ms-3">
                      <p className="file-title mb-0">
                        {payloadData?.media[0]?.document?.name}
                      </p>
                      <p className="file-meta mb-0">
                        {payloadData?.media[0]?.document?.size} KB ·{" "}
                        {new Date(
                          payloadData?.media[0]?.document?.lastModified
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="mt-4">
            <Row>
              <Col sm={12}>
                <Card className="image-caurosel p-0">
                  <img
                    src={payloadData?.media[0]?.coverImage}
                    alt=""
                    className="w-100"
                  />
                </Card>
              </Col>
              <Row className="pe-0">
                <Col sm={12} className="pe-0">
                  <Card className="mt-3">
                    <Row className="align-items-center">
                      <Col sm={12} md={8} lg={8}>
                        <h6>Video</h6>
                        <p className="mb-0">
                          {payloadData?.media[0]?.video?.description}
                        </p>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        className="coverphoto-preview"
                      >
                        {/* <img src={payloadData?.profilePhoto} alt="" className="w-100" /> */}
                        <div className="video-preview position-relative">
                          {/* Video Preview */}
                          <video
                            ref={videoRef}
                            // src={payloadData?.video}
                            src={payloadData?.media[0]?.video?.url}
                            className="w-100" // Adjust size as needed, full width of the container
                            onClick={handlePlayPause} // Toggle play/pause on click
                            onEnded={() => setIsPlaying(false)} // Reset play state when video ends
                            style={{ cursor: "pointer" }}
                            controls={false} // Hide default controls
                          />
                          {/* Play/Pause Button */}
                          <button
                            onClick={handlePlayPause}
                            className="play-button position-absolute"
                            style={{
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              background: "transparent",
                              border: "none",
                            }}
                          >
                            <div className="play_btn">
                              <img
                                src={isPlaying ? "pauseIcon" : playBtn}
                                alt={isPlaying ? "Pause" : "Play"}
                              />
                            </div>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row className="pe-0">
                <Col sm={12} className="pe-0">
                  <Card className="mt-3">
                    <Row className="align-items-center">
                      <Col sm={12} md={8} lg={8}>
                        <h6>Photo</h6>
                        <p className="mb-0">
                          {payloadData?.media[0]?.photo?.description}
                        </p>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        className="coverphoto-preview"
                      >
                        <img
                          src={payloadData?.media[0]?.photo?.url}
                          alt=""
                          className="w-100"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Row>
          </div>
        </Col>
        <Col sm={12} md={4} lg={4} className="project_preview_right">
          <Card>
            <div className="d-flex justify-content-between py-3">
              <h5>Reward</h5>
              <h5>
                ${" "}
                {skillPayloadData?.estimateBudget?.max ||
                  payloadData?.estimateBudget?.max}
              </h5>
            </div>
            <div className="d-flex justify-content-between py-3">
              <h5>Paid in</h5>
              <PaymentModes paymentData={paymentData} />
            </div>

            <div className="d-flex justify-content-between py-3">
              <h5>Time to Complete</h5>
              <label>
                {skillPayloadData || payloadData?.paymentType?.duration}{" "}
                {skillPayloadData || payloadData?.paymentType?.type} Delivery
              </label>
            </div>
            <div className="mt-3">
              <h5>Required Skills</h5>
            </div>
            <div className="tagList mt-3">
              <ul className="justify-content-start ps-0">
                {skillPayloadData ||
                  payloadData?.skillsNeeded?.map((tag, index) => (
                    <li key={index}>{tag}</li>
                  ))}
              </ul>
            </div>
          </Card>
          <div>
            <button
              type="button"
              className="primary-btn w-100 mt-4 py-3"
              disabled={type === "published"}
              onClick={type === "bid" ? handleBid : handlePost}
            >
              {type === "bid"
                ? "Bid The Project"
                : type === "published"
                ? "Project published"
                : "Post Project"}
            </button>
            {type !== "bid" && type !== "published" && (
              <button
                type="button"
                className="secondary-btn w-100 mt-3 py-3"
                onClick={() => handleNext(false)}
              >
                Back
              </button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProjectView;
