import React, { useState, useEffect } from "react";
import "./ServicesAndPricing.css";
import Table from "react-bootstrap/Table";
import { Form } from "react-bootstrap";
import info from "../../../Images/icons/information.png";
import edit from "../../../Images/icons/edit_icon.png";
import dollar_circle from "../../../Images/dollar-circle.png";
import ApiCall from "../../../Api/ApiCall";

const ServicesAndPricing = ({ onSave, serviceData, updatePayloadData }) => {
  // console.log(serviceData,"serviceData in sp");

  const [subSubCategoriesList, setSubSubCategoriesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tiers, setTiers] = useState(() => {
    if (serviceData && serviceData.tiers) {
      // Check if serviceData.tiers[0].subSubcategory has true values
      const updatedTiers = serviceData.tiers.map((tier) => {
        if (
          tier.subSubcategory &&
          Object.values(tier.subSubcategory).includes(true)
        ) {
          // If there are true values in subSubcategory, use those values
          return {
            ...tier,
            subSubcategory: Object.fromEntries(
              Object.keys(tier.subSubcategory).map((key) => [key, true])
            ),
          };
        }
        return tier; // Otherwise, return the tier as is
      });
      return updatedTiers; // Return the updated tiers
    } else {
      // Default tiers structure if serviceData is not available
      return [
        {
          modeType: "Independent",
          name: "",
          description: "",
          deliveryTime: 0,
          price: 0,
          revisions: 0,
          initialConcepts: 0,
          subSubcategory: {},
          flag: true,
          title: "For individual freelancers",
        },
        {
          modeType: "Agency",
          name: "",
          description: "",
          deliveryTime: 0,
          price: 0,
          revisions: 0,
          initialConcepts: 0,
          subSubcategory: {},
          flag: false,
        },
        {
          modeType: "Enterprise",
          name: "",
          description: "",
          deliveryTime: 0,
          price: 0,
          revisions: 0,
          initialConcepts: 0,
          subSubcategory: {},
          flag: false,
        },
      ];
    }
  });

  useEffect(() => {
    setTiers((prevTiers) =>
      prevTiers.map((tier) => ({
        ...tier,
        subSubcategory: Object.fromEntries(
          subSubCategoriesList.map((cat) => [cat, false])
        ),
      }))
    );
  }, [subSubCategoriesList]);

  const handleSubcategoryChange = (tierIndex, category) => {
    setTiers((prevTiers) => {
      return prevTiers.map((tier, index) => {
        if (index === tierIndex) {
          return {
            ...tier,
            subSubcategory: {
              ...tier.subSubcategory,
              [category]: !tier.subSubcategory[category],
            },
          };
        }
        return tier;
      });
    });
  };

  // console.log(tiers, "tiers in ServicesAndPricing ");

  const getSubSubCategories = async (categoryId, subcategory) => {
    setLoading(true);
    try {
      const response = await ApiCall(
        "GET",
        `metadata/${categoryId}/${subcategory}`,
        null,
        "application/json"
      );
      if (response.statusCode === 200) {
        setSubSubCategoriesList(response.data[0].values);
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching subcategories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (serviceData.categoryId && serviceData.subCategory) {
      getSubSubCategories(serviceData.categoryId, serviceData.subCategory);
    } else {
      setSubSubCategoriesList([]);
      //   setPayloadData((prevData) => ({ ...prevData, subCategory: "" }));
    }
  }, [serviceData.categoryId, serviceData.subCategory]);

  useEffect(() => {
    updatePayloadData({ tiers });
  }, [tiers]);

  const handleModeSelect = (selectedIndex) => {
    setTiers((prevTiers) =>
      prevTiers.map((tier, index) => ({
        ...tier,
        flag: index === selectedIndex ? !tier.flag : tier.flag, // Toggle only the selected mode
      }))
    );
  };

  return (
    <div className="serices_section">
      <div className="service-tilte">
        <h2>Services and Pricing</h2>
      </div>
      <div className="table_width">
        <Table bordered className="pricing_table">
          <thead>
            <tr>
              <th width="25%">Type of Modes</th>
              {tiers.map((tier, index) => (
                <th key={index} width="25%">
                  <div className="d-flex align-items-center justify-content-center">
                    <Form.Check
                      type="checkbox"
                      className="custom-checkbox me-2"
                      checked={tier.flag}
                      onChange={() => handleModeSelect(index)}
                    />
                    <span title="For individual freelancers">
                      {tier.modeType}
                      <img src={info} className="ms-3" alt="info" />
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={2}></td>
              {tiers.map((tier, index) => (
                <td
                  key={index}
                  style={{
                    opacity: tier.flag ? 1 : 0.5,
                    pointerEvents: tier.flag ? "auto" : "none",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Form.Label>Name Your Package</Form.Label>
                    <img src={edit} alt="edit" />
                  </div>
                  <Form.Control
                    className="border-0"
                    type="text"
                    // placeholder="Enter package name"
                    value={tier.name}
                    onChange={(e) =>
                      setTiers((prev) => {
                        const newTiers = [...prev];
                        newTiers[index].name = e.target.value;
                        return newTiers;
                      })
                    }
                    maxLength={100}
                  />
                  <div className="text-end">
                    <small className="grey_font">
                      {tier.name.length}/100 characters
                    </small>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              {tiers.map((tier, index) => (
                <td
                  key={index}
                  style={{
                    opacity: tier.flag ? 1 : 0.5,
                    pointerEvents: tier.flag ? "auto" : "none",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-start">
                      Describe the details of your offering
                    </Form.Label>
                    <img src={edit} alt="edit" />
                  </div>
                  <Form.Control
                    className="border-0"
                    as="textarea"
                    // placeholder="Enter description"
                    rows={3}
                    value={tier.description}
                    onChange={(e) =>
                      setTiers((prev) => {
                        const newTiers = [...prev];
                        newTiers[index].description = e.target.value;
                        return newTiers;
                      })
                    }
                    maxLength={1000}
                  />
                  <div className="text-end">
                    <small className="grey_font">
                      {tier.description.length}/1000 characters
                    </small>
                  </div>
                </td>
              ))}
            </tr>

            <tr>
              <td>Delivery Time</td>
              {tiers.map((tier, index) => (
                <td
                  key={index}
                  style={{
                    opacity: tier.flag ? 1 : 0.5,
                    pointerEvents: tier.flag ? "auto" : "none",
                  }}
                >
                  <Form.Select
                    value={tier.deliveryTime}
                    onChange={(e) =>
                      setTiers((prev) => {
                        const newTiers = [...prev];
                        newTiers[index].deliveryTime = e.target.value;
                        return newTiers;
                      })
                    }
                  >
                    <option value="Deliver Time ">Deliver Time</option>
                    <option value="7">7 Days</option>
                    <option value="10">10 Days</option>
                    <option value="15">15 Days</option>
                  </Form.Select>
                </td>
              ))}
            </tr>

            <tr>
              <td>Choose Your Price</td>
              {tiers.map((tier, index) => (
                <td
                  key={index}
                  style={{
                    opacity: tier.flag ? 1 : 0.5,
                    pointerEvents: tier.flag ? "auto" : "none",
                  }}
                >
                  <Form.Group
                    className="position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Control type="email" placeholder="Select Price" /> */}
                    <Form.Control
                      placeholder="Select Price"
                      type="number"
                      value={tier.price}
                      onChange={(e) =>
                        setTiers((prev) => {
                          const newTiers = [...prev];
                          newTiers[index].price = e.target.value;
                          return newTiers;
                        })
                      }
                    />
                    <div className="dollar_img">
                      <img src={dollar_circle} alt="dollar" />
                    </div>
                  </Form.Group>
                </td>
              ))}
            </tr>

            <tr>
              <td>Revisions</td>
              {tiers.map((tier, index) => (
                <td
                  key={index}
                  style={{
                    opacity: tier.flag ? 1 : 0.5,
                    pointerEvents: tier.flag ? "auto" : "none",
                  }}
                >
                  <Form.Select
                    value={tier.revisions}
                    onChange={(e) =>
                      setTiers((prev) => {
                        const newTiers = [...prev];
                        newTiers[index].revisions = e.target.value;
                        return newTiers;
                      })
                    }
                  >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Form.Select>
                </td>
              ))}
            </tr>
            <tr>
              <td>Number of initial conepts included</td>
              {tiers.map((tier, index) => (
                <td
                  key={index}
                  style={{
                    opacity: tier.flag ? 1 : 0.5,
                    pointerEvents: tier.flag ? "auto" : "none",
                  }}
                >
                  <Form.Select
                    value={tier.initialConcepts}
                    onChange={(e) =>
                      setTiers((prev) => {
                        const newTiers = [...prev];
                        newTiers[index].initialConcepts = e.target.value;
                        return newTiers;
                      })
                    }
                  >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Form.Select>
                </td>
              ))}
            </tr>

            {subSubCategoriesList.map((category, catIndex) => (
              <tr key={catIndex}>
                <td>{category}</td>
                {tiers.map((tier, tierIndex) => (
                  <td
                    key={tierIndex}
                    style={{
                      opacity: tier.flag ? 1 : 0.5,
                      pointerEvents: tier.flag ? "auto" : "none",
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={tier.subSubcategory[category] || false}
                      onChange={() =>
                        handleSubcategoryChange(tierIndex, category)
                      }
                      className="custom-checkbox justify-content-center"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default ServicesAndPricing;
