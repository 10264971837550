import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const location = useLocation(); // Will work only inside Router

  useEffect(() => {
    // Scroll to top whenever route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);
};

export default useScrollToTop;
