// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
  .tabs-row {
    display: flex;
    /* padding: 10px; */
    border-bottom: 1px solid #EEEAEA;
    justify-content: center;
  }
   
  .tab-content {
    min-height: 200px;
    /* border: 1px solid #ddd; */
    padding: 20px;
    border-radius: 8px;
    /* margin-bottom: 20px; */
  }
  

  .tab-button {
    padding: 10px 5px;
    font-size: 16px;
    /* font-family: var(--font-family); */
    cursor: pointer;
    border: none;
    background: transparent;
    color: #363848;
    margin: 0px 20px;
    outline: none;
}
.tab-button.active {
  border-bottom: 2px solid #f0806c;
  color: #f0806c;
  font-weight: bold;
}
.buttons-row{
margin-right:1rem;
}
@media (max-width:1366px) {
  .tabs-row {
    overflow-x: auto;
    justify-content: flex-start;
  }
}
@media (max-width:991px) {
  .tab-content{
    padding: 20px 10px;
  }
}
@media (max-width:820px) {
  .about_tab_content{
    margin: 0px 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Profile/ProfileCreation/ProfileCreation.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,mBAAmB;IACnB,gCAAgC;IAChC,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,4BAA4B;IAC5B,aAAa;IACb,kBAAkB;IAClB,yBAAyB;EAC3B;;;EAGA;IACE,iBAAiB;IACjB,eAAe;IACf,qCAAqC;IACrC,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,cAAc;IACd,gBAAgB;IAChB,aAAa;AACjB;AACA;EACE,gCAAgC;EAChC,cAAc;EACd,iBAAiB;AACnB;AACA;AACA,iBAAiB;AACjB;AACA;EACE;IACE,gBAAgB;IAChB,2BAA2B;EAC7B;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["  \r\n  .tabs-row {\r\n    display: flex;\r\n    /* padding: 10px; */\r\n    border-bottom: 1px solid #EEEAEA;\r\n    justify-content: center;\r\n  }\r\n   \r\n  .tab-content {\r\n    min-height: 200px;\r\n    /* border: 1px solid #ddd; */\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    /* margin-bottom: 20px; */\r\n  }\r\n  \r\n\r\n  .tab-button {\r\n    padding: 10px 5px;\r\n    font-size: 16px;\r\n    /* font-family: var(--font-family); */\r\n    cursor: pointer;\r\n    border: none;\r\n    background: transparent;\r\n    color: #363848;\r\n    margin: 0px 20px;\r\n    outline: none;\r\n}\r\n.tab-button.active {\r\n  border-bottom: 2px solid #f0806c;\r\n  color: #f0806c;\r\n  font-weight: bold;\r\n}\r\n.buttons-row{\r\nmargin-right:1rem;\r\n}\r\n@media (max-width:1366px) {\r\n  .tabs-row {\r\n    overflow-x: auto;\r\n    justify-content: flex-start;\r\n  }\r\n}\r\n@media (max-width:991px) {\r\n  .tab-content{\r\n    padding: 20px 10px;\r\n  }\r\n}\r\n@media (max-width:820px) {\r\n  .about_tab_content{\r\n    margin: 0px 0rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
