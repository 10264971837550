import React, { useRef, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";

import profile from "../../../Images/profile_pic.png";
import online from "../../../Images/online_icon.png";
import "./ProjectPreview.css";
import playBtn from "../../../Images/play_btn.png";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";

import location from "../../../Images/workspace_icons/map.svg";
import clock from "../../../Images/icons/clock.png";
import { calculateDaysDifference, formatDateToDDMMYYYYC } from "../../Common/utils/utils";
import { useNavigate } from "react-router-dom";

import pdfIcon from "../../../Images/icons/pdf-icon.svg";
import PaymentModes from "../../Common/PaymentModes";

const ProjectPreview = ({
  payloadData,
  skillPayloadData,
  handleNext,
  videoSrc,
  userDetails,
  userId,
  projectId,
  certificateFile,
  projectData,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { notify } = useToast();
  const navigate = useNavigate();

  const paymentData = skillPayloadData?.acceptedPaymentModes[0];

  const handlePlayPause = () => {
    console.log("handlePlayPause");

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // const userId = localStorage.getItem("userId");

  const handlePost = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: payloadData?.projectName,
        description: payloadData?.description,
        category: payloadData?.category,
        subCategory: payloadData?.subCategory,
        userId: userId,
        tags: payloadData?.searchTagsList,
        skillsNeeded: skillPayloadData?.skillsNeeded,
        instruction: payloadData?.instruction,
        media: [
          {
            coverImage: payloadData?.coverPhoto,
            video: {
              url: payloadData?.video,
              description: payloadData?.videoDescription,
            },
            photo: {
              url: payloadData?.profilePhoto,
              description: payloadData?.photoDescription,
            },
            document: payloadData?.certificateFile,
          },
        ],
        paymentType: {
          type: skillPayloadData?.paymentType?.type,
          duration: skillPayloadData?.paymentType?.duration,
        },
        estimateBudget: {
          min: skillPayloadData?.estimateBudget?.min,
          max: skillPayloadData?.estimateBudget?.max,
        },
        projectBidTimings: {
          start: formatDateToDDMMYYYYC(
            skillPayloadData?.projectBidTimings?.start
          ),
          end: formatDateToDDMMYYYYC(skillPayloadData?.projectBidTimings?.end),
        },
        acceptedPaymentModes: skillPayloadData?.acceptedPaymentModes,
        serviceType: skillPayloadData?.serviceType,
        tier: skillPayloadData?.tier,
        status: "final",
        projectStatus: "Open",
        projectId: type === "draft" ? projectData?.projectId : projectId,
      };
      // console.log(payload, "project post payload");

      // Make the API call
      const response = await ApiCall(
        "PUT",
        "project",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log("successful:", response.data);
        notify("success", "Project post successfully!");
        navigate(type === "draft" ? "/find-works" : "/my-works");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <Row className="mt-4">
      <Col sm={12} md={8} lg={8} className="project_preview_left">
        <Card className="about_card">
          <div className="about_heading_sec">
            <h5>{payloadData?.projectName}</h5>
          </div>
          <div className="profile_sec mt-3">
            <div className="profile_img">
              <img src={userDetails?.profile?.profilePic} alt="profile" />
              <div className="profile_img_online">
                <img src={online} alt="profile" />
              </div>
            </div>
            <div className="ms-2">
              <h6 className="mb-0">{userDetails?.firstName}</h6>
              <label>Freelancer - UI UX Designer</label>
              <div>
                <label>
                  <img src={location} alt="image" /> India -
                </label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h5>About {type === "draft" ? "Project" : "Service"}</h5>
            <p>{payloadData?.description}</p>
          </div>
          <div className="mt-4">
            <h5>Instruction</h5>
            <p> {payloadData?.instruction}</p>
            <div>
              <h5>Tags</h5>
              <div className="tagList">
                <ul className="justify-content-start ps-0">
                  {payloadData?.searchTagsList?.map((tag, index) => (
                    <li key={index}>{tag}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <h5>Document</h5>
              </div>
              <div className="file-preview ms-3">
                <div className="file-details">
                  <img src={pdfIcon} alt="PDF Icon" className="file-icon" />
                  <div className="file-info ms-3">
                    <p className="file-title mb-0">{certificateFile?.name}</p>
                    <p className="file-meta mb-0">
                      {certificateFile?.size} KB ·{" "}
                      {new Date(
                        certificateFile?.lastModified
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="mt-4">
          <Row>
            <Col sm={12}>
              <Card className="image-caurosel p-0">
                <img src={payloadData?.coverPhoto} alt="" className="w-100" />
              </Card>
            </Col>
            <Row className="pe-0">
              <Col sm={12} className="pe-0">
                <Card className="mt-3">
                  <Row className="align-items-center">
                    <Col sm={12} md={8} lg={8}>
                      <h6>Video</h6>
                      <p className="mb-0">{payloadData?.videoDescription}</p>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      className="coverphoto-preview"
                    >
                      {/* <img src={payloadData?.profilePhoto} alt="" className="w-100" /> */}
                      <div className="video-preview position-relative">
                        {/* Video Preview */}
                        <video
                          ref={videoRef}
                          // src={payloadData?.video}
                          src={videoSrc}
                          className="w-100" // Adjust size as needed, full width of the container
                          onClick={handlePlayPause} // Toggle play/pause on click
                          onEnded={() => setIsPlaying(false)} // Reset play state when video ends
                          style={{ cursor: "pointer" }}
                          controls={false} // Hide default controls
                        />
                        {/* Play/Pause Button */}
                        <button
                          onClick={handlePlayPause}
                          className="play-button position-absolute"
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <div className="play_btn">
                            <img
                              src={isPlaying ? "pauseIcon" : playBtn}
                              alt={isPlaying ? "Pause" : "Play"}
                            />
                          </div>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row className="pe-0">
              <Col sm={12} className="pe-0">
                <Card className="mt-3">
                  <Row className="align-items-center">
                    <Col sm={12} md={8} lg={8}>
                      <h6>Photo</h6>
                      <p className="mb-0">{payloadData?.photoDescription}</p>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      className="coverphoto-preview"
                    >
                      <img
                        src={payloadData?.profilePhoto}
                        alt=""
                        className="w-100"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Col xs={12} sm={6} md={4} className="mt-4">
              <img
                src={payloadData?.certificateFile}
                alt=""
                className="w-100"
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col sm={12} md={4} lg={4} className="project_preview_right">
        <Card>
          <div className="d-flex justify-content-between py-3">
            <h5>Reward</h5>
            <h5>$ {skillPayloadData.estimateBudget.max}</h5>
          </div>

          <div className="d-flex justify-content-between py-3">
            <h5>Paid in</h5>
            <PaymentModes paymentData={paymentData} />
          </div>

          <div className="d-flex justify-content-between py-3">
            <h5>Time to Complete</h5>
            <label>
              <img src={clock} alt="clock" className="me-2" />
              {skillPayloadData?.paymentType?.duration}{" "}
              {skillPayloadData?.paymentType?.type} Delivery
            </label>
          </div>
          <div className="d-flex justify-content-between py-3">
            <h5>Service Type</h5>
            <label>Independent</label>
          </div>
          <div className="mt-3">
            <h5>Required Skills</h5>
          </div>
          <div className="tagList mt-3">
            <ul className="justify-content-start ps-0">
              {skillPayloadData?.skillsNeeded?.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
            </ul>
          </div>
          <div className="mt-3">
            <h5>
              Biding Time{" "}
              {calculateDaysDifference(
                skillPayloadData?.projectBidTimings?.start,
                skillPayloadData?.projectBidTimings?.end
              )}{" "}
              days
            </h5>
          </div>
        </Card>
        <div className="d-flex align-items-center justify-content-between">
          <Button variant="" className="freela-btn-primary-outline" onClick={() => handleNext(false)}>Back</Button>
          <Button variant="" className="freela-btn-primary" onClick={handlePost}>Post Project</Button>
        </div>
      </Col>
    </Row>
  );
};

export default ProjectPreview;
