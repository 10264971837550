import React from "react";
import "./Register.css";
import logo from "../../Images/logo.png";
import register from "../../Images/register-congrats.png";
import { Row, Col } from 'react-bootstrap';


const RegisterCongrats = ({ openLogin }) => {

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form behavior
        openLogin(); // Call the function to open the login modal
    }
    
    return (
    <div>
        <div>
            <img src={logo} alt="Logo" className="forgot-logo" />
        </div>
        <Row className="middle_content mb-5">
            <Col xs={12} sm={12} md={6} className="login-left-content">
                <img src={register} alt="register" className="w-100 left-img"/>
            </Col> 
            <Col xs={12} sm={12} md={6} className="px-4 text-center congrates-right-content">
                <h1>Congratulations!</h1>
                <p className="mb-0">your account has been successfully created.</p>
                <p>continue your journey.</p>
                <form className="register-form mt-5 pt-0 pt-md-4"  onSubmit={handleSubmit} >
                    <button type="submit" className="primary-btn py-2 w-50">Continue</button>
                </form>
            </Col>
        </Row>
        {/* <div className="auth-container">
            <div className="auth-left">
                <img src={logo} alt="Logo" className="auth-logo" />
                <img src={register} alt="register" className="auth-image" />
            </div>

            <div className="auth-right-congrats">
                <h1>Congratulations!</h1>
                <p>
                    your account has been successfully created.  </p>
                <p>continue your journey.
                </p>

                <form className="register-form"  onSubmit={handleSubmit} >
                    <button type="submit" className="auth-btn">Continue</button>
                </form>


            </div>
        </div> */}
        </div>
    );
};

export default RegisterCongrats;


