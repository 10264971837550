import React, { useState, useRef, useEffect } from "react";
import useCommonNavigate from "../../../../Hooks/useCommonNavigate";
import "./ClientProfile.css";
import backArrowCircleSvg from "../../../../Images/icons/backArrow-circle.svg";
import uploadIconSvg from "../../../../Images/icons/upload.svg";

import ProfileCreation from "../ClientProfileCreation/ClientProfileCreation";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { Card, Row, Col } from "react-bootstrap";
import camera from "../../../../Images/icons/camera.svg";


const ClientProfile = ({ userDetails, getUserDetails }) => {
  
  const [certificateFile, setCertificateFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const profileInputRef = useRef(null);
  const coverInputRef = useRef(null);
  const [showProfileCreation, setShowProfileCreation] = useState(false); // State to toggle ProfileCreation component
  const { commonNavigate } = useCommonNavigate();
  const [error, setError] = useState("");
  const { notify } = useToast();
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState({
    certificateFile: "",
    profilePhoto: "",
    coverPhoto: "",
  });

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userDetails) {
      setFileUrls({
        certificateFile: userDetails.profile?.resume || "",
        profilePhoto: userDetails.profile?.profilePic || "",
        coverPhoto: userDetails.profile?.coverPhoto || "",
      });
    }
  }, [userDetails]);

  const resetValues = () => {
    setCertificateFile(null);
    setProfilePhoto(null);
    setCoverPhoto(null);
  };

  const handleNavigate = () => {
    commonNavigate("/settings");
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0]; // Get the file from input
    if (file) {
      try {
        // Prepare the form data with the uploaded file
        const formData = new FormData();
        formData.append("file", file);

        // Call the API to upload the file
        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // Handle successful file upload response
          setPhoto(file);
          setFileUrls((prevState) => ({
            ...prevState,
            [field]: response.data.s3Url, // Update the correct field dynamically
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handles upload click to trigger file input
  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };



  const handleSkip = () => {
    setShowProfileCreation(true); // Show the ProfileCreation component
  };

  const handleBackArrow = () => {
    setShowProfileCreation(false);
  };

  const handleSubmit = async (e) => {


    e.preventDefault();
    setError("");
    setLoading(true);


    const payload = {
      profile: {
        profilePic: fileUrls.profilePhoto,
        coverPhoto: fileUrls.coverPhoto,
        resume: fileUrls.certificateFile,
      },
    };

    try {
      console.log(payload, "payload ");

      // Make the API call
      const response = await ApiCall(
        "PUT",
        `user/${userId}/photos`,
        payload,
        "application/json"
      );

      // Handle success with 200 status code
      if (response.statusCode === 200) {
        notify("success", "Photos uploaded successfully!");
        resetValues();
        setShowProfileCreation(true);
        getUserDetails();
      } else {
        setError("Upload failed. Please try again.");
        notify("error", response.message || "Upload failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      console.error("Upload error:", error);
      setError("Upload failed. Please try again.");
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

 

  return (
    <>
      {loading && <Loader />}
      <div className="profile-layout">
        {showProfileCreation ? (
          <ProfileCreation
            handleBackArrow={handleBackArrow}
            userDetails={userDetails}
            getUserDetails={getUserDetails}
          />
        ) : (
          <>
            {/* First Row: Back Arrow */}
            <div className="back-arrow-row" onClick={handleNavigate}>
              <img
                src={backArrowCircleSvg}
                alt="Back Arrow"
                className="back-arrow-icon"
              />
            </div>
            {/* Second Row: Heading */}
            <div className="heading-row">
              <h3>Create your profile</h3>
            </div>

            <div className="browse_sec mt-4">
                <Row>
                 {/* Profile Photo Upload */}
                <Col sm={6} md={4} lg={3}>
                  <Card className="browse_card">
                    <div
                      className="text-center upload_icon"
                      onClick={() => handleUploadClick(profileInputRef)}
                    >
                      {profilePhoto ? (
                        <img
                          src={URL.createObjectURL(profilePhoto)}
                          alt="Profile Preview"
                          className="preview-image w-100"
                        />
                      ) : userDetails?.profile?.profilePic ? (
                        <div className="position-relative preview_card">
                        <img
                          src={userDetails?.profile?.profilePic}
                          alt="User Details Profile Photo Preview"
                          className="preview-image w-100"
                        />
                        <div className="camera_img">
                        <img src={camera} alt="camera"/>
                        </div>
                        </div>
                      ) : (
                        <div>
                        <div>
                        <img src={uploadIconSvg} alt="Upload Icon" />
                        <h5 className="pt-2">Please Upload Your Photo</h5>
                        </div>
                        <label className="browse-button">
                      Browse
                      <input
                        type="file"
                        accept="image/*"
                        ref={profileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handlePhotoChange(e, setProfilePhoto, "profilePhoto")
                        }
                      />
                    </label>
                        </div>
                      )}
                    </div>
                   

                   
                  </Card>
                </Col>

                {/* Cover Photo Upload */}
                <Col sm={6} md={8} lg={9}>
                  <Card className="browse_card mt-3 mt-sm-0">
                    <div
                      className="text-center upload_icon"
                      onClick={() => handleUploadClick(coverInputRef)}
                    >
                      {coverPhoto ? (
                        <img
                          src={URL.createObjectURL(coverPhoto)}
                          alt="Cover Preview"
                          className="preview-cover"
                        />
                      ) : userDetails?.profile?.coverPhoto ? (
                        <div className="position-relative preview_card">
                        <img
                          src={userDetails?.profile?.coverPhoto}
                          alt="User Details Cover Photo Preview"
                          className="preview-image w-100"
                        />
                         <div className="camera_img">
                          <img src={camera} alt="camera"/>
                          </div>
                        </div>
                      ) : (
                        <div>
                        <div>
                        <img src={uploadIconSvg} alt="Upload Icon" />
                        <h6 className="pt-2">Please Upload Your Cover Photo</h6>
                        </div>
                        <label className="browse-button">
                        Browse
                        <input
                          type="file"
                          accept="image/*"
                          ref={coverInputRef}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handlePhotoChange(e, setCoverPhoto, "coverPhoto")
                          }
                        />
                      </label>
                      </div>
                      )}
                    </div>                    
                  </Card>
                </Col>
                    </Row>
                  </div>

            {/* Buttons: Skip and Save & Continue */}

            <div className="buttons-row mt-4 text-end">
              <button className="secondary-btn" onClick={handleSkip}>
                Skip
              </button>
              {(certificateFile || profilePhoto || coverPhoto) && (
                <button
                  className="primary-btn"
                  onClick={(e) => handleSubmit(e, "submit")}
                >
                  Save & Continue
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ClientProfile;
