import React, { useState } from "react";
import profilePic from "../../../Images/profile.png";
import coverPhoto from "../../../Images/cover.png";
import { Card, Row, Col, Image, Badge, Nav, Stack, Button, Form } from "react-bootstrap";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";

const ProfilePreview = ({ userDetails, getUserDetails, setUserDetails }) => {
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();


  // console.log(userDetails, "userDetails");

  const handleFollow = async (isFollow) => {
    setLoading(true);
    const userId = localStorage.getItem("userId");

    try {
      const payload = {
        followerId: userId, //Your userId
        followeeId: userDetails?.userId, //User Id of person whom you are following
        isFollow: isFollow,
      };

      const endpoint = "user/follow";

      const response = await ApiCall(
        "POST",
        endpoint,
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200 || 201) {
        console.log("successful:", response.data);
        notify("success", response.message);
        setUserDetails((prevDetails) => {
          const updatedDetails = [...prevDetails];
          if (updatedDetails[0]) {
            updatedDetails[0].isFollow = isFollow;
          }
          return updatedDetails;
        });
      } else {
        notify("error", response.message || "saved failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Card className="section_card p-0 rounded-5">
        <Card.Img src={userDetails?.profile?.coverPhoto || coverPhoto} alt="ProfileBgImg" className="w-100 h-320px rounded-5 rounded-bottom-0 object-fit-cover" />
        <Card.Body>
          <Card as={Card.Body} className="mt-n100 section_card mx-lg-5">
            <Row>
              <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                <Image src={userDetails?.profile?.profilePic || profilePic} className="rounded-4 object-fit-cover" fluid />
              </Col>
              <Col xs={8} sm={9} md={10} lg={10} xl={10}>
                <div className="d-flex flex-md-row flex-column justify-content-between gap-2">
                  <div className="content">
                    <Card.Title className="freela-fs-16px">{userDetails?.firstName} <Badge className="freela-bg-success-200 freela-text-success fw-400 rounded-pill d-inline-block py-1 px-2">Available</Badge></Card.Title>
                    <Card.Subtitle className="freela-fs-14px freela-text-greaseblack">UI UX Designer</Card.Subtitle>
                    <Nav className="flex-wrap gap-2 mt-2">
                      {['KYC Verified', 'AI Verified', 'Freelancing'].map((item, idx) => (
                        <Badge key={idx}
                          className={`${item === 'KYC Verified' ? 'freela-bg-blue-200 freela-text-blue rounded-2 freela-fs-12px fw-400 p-2' :
                            item === 'AI Verified' ? 'freela-bg-cyan-200 freela-text-cyan rounded-2 freela-fs-12px fw-400 p-2' :
                              item === 'Freelancing' ? 'freela-bg-danger-200 freela-text-danger rounded-2 freela-fs-12px fw-400 p-2' : ''
                            }`}
                        >{item}</Badge>
                      ))}
                    </Nav>
                  </div>
                  <Stack direction="horizontal" className="mb-auto">
                    {userDetails?.isFollow ? (
                      <Button
                        variant=""
                        className="freela-btn-primary px-5"
                        onClick={() => handleFollow(false)}
                      >
                        Following
                      </Button>
                    ) : (
                      <Button
                        variant=""
                        className="freela-btn-primary px-5"
                        onClick={() => handleFollow(true)}
                      >
                        Follow
                      </Button>
                    )}
                  </Stack>
                </div>
              </Col>
            </Row>
          </Card>

          <Stack direction="vertical" className="mx-lg-5 mt-3">
            <Row>
              <Col md={8} lg={9} xl={9}>
                <Stack direction="vertical" className="gap-3">
                  <Card as={Card.Body} className="section_card">
                    <Card.Title as="h6" className="fw-400">Bio</Card.Title>
                    <Card.Text>{userDetails?.about[0]?.bio}</Card.Text>
                  </Card>

                  <Card as={Card.Body} className="section_card">
                    <Card.Title as="h6" className="fw-400">Education</Card.Title>
                    {userDetails?.freelancerSettings?.education?.map((row, idx) => (
                      <Row as="dl" key={idx} className="mb-3">
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Degree</dt>
                          <dd className="fw-500">{row.degree}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">University</dt>
                          <dd className="fw-500">{row.University}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Starting from</dt>
                          <dd className="fw-500">{new Date(row.start).toLocaleDateString()}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Ending</dt>
                          <dd className="fw-500">{new Date(row.end).toLocaleDateString()}</dd>
                        </Col>
                      </Row>
                    ))}
                  </Card>

                  <Card as={Card.Body} className="section_card">
                    <Card.Title as="h6" className="fw-400">Experience</Card.Title>
                    {userDetails?.freelancerSettings?.experience?.map((row, idx) => (
                      <Row as="dl" key={idx} className="mb-3">
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Designation</dt>
                          <dd className="fw-500">{row.designation}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Company</dt>
                          <dd className="fw-500">{row.company}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Starting from</dt>
                          <dd className="fw-500">{new Date(row.start).toLocaleDateString()}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Ending</dt>
                          <dd className="fw-500">{new Date(row.end).toLocaleDateString()}</dd>
                        </Col>
                      </Row>
                    ))}
                  </Card>

                  <Card as={Card.Body} className="section_card">
                    <Card.Title as="h6" className="fw-400">Experience</Card.Title>
                    {userDetails?.freelancerSettings?.experience?.map((row, idx) => (
                      <Row as="dl" key={idx} className="mb-3">
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Designation</dt>
                          <dd className="fw-500">{row.designation}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Company</dt>
                          <dd className="fw-500">{row.company}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Starting from</dt>
                          <dd className="fw-500">{new Date(row.start).toLocaleDateString()}</dd>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                          <dt className="freela-text-greaseblack fw-400">Ending</dt>
                          <dd className="fw-500">{new Date(row.end).toLocaleDateString()}</dd>
                        </Col>
                      </Row>
                    ))}
                  </Card>

                  <Card as={Card.Body} className="section_card">
                    <Card.Title as="h6" className="fw-400">Skills &amp; Certifications</Card.Title>
                    <div className="my-3">
                      <Card.Title as="h6" className="fw-400 mb-3">Skills</Card.Title>
                      <Nav className="flex-wrap gap-2 mt-2">
                        {['Figma', 'Mobile Dev', 'Design', 'Writing', 'Admin Support'].map((item, idx) => (
                          <Badge key={idx} className="freela-bg-transparent fw-400 border freela-text-black freela-border-greylight13 freela-fs-12px rounded-pill py-2 px-3">{item}</Badge>
                        ))}
                      </Nav>
                    </div>

                    <div className="my-2">
                      <Card.Title as="h6" className="fw-400 mb-3">Certifications</Card.Title>
                      {userDetails?.freelancerSettings?.courses?.map((row, idx) => (
                        <Row as="dl" key={idx} className="mb-3">
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">Course</dt>
                            <dd className="fw-500">{row.certificateName}</dd>
                          </Col>
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">Company Name</dt>
                            <dd className="fw-500">{row.company}</dd>
                          </Col>
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">From</dt>
                            <dd className="fw-500">{new Date(row.start).toLocaleDateString()}</dd>
                          </Col>
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">To</dt>
                            <dd className="fw-500">{new Date(row.end).toLocaleDateString()}</dd>
                          </Col>
                        </Row>
                      ))}
                    </div>

                    <div className="my-2">
                      <Card.Title as="h6" className="fw-400 mb-3">Certifications</Card.Title>
                      <Row className="gy-3">
                        {userDetails?.freelancerSettings?.courses?.map((row, index) => (
                          <Col sm={6} md={6} lg={4} xl={3} key={index}>
                            <Image src={row.file[0] || "certificate-image.jpg"} alt="Course Certificate" className="certificate-image" />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Card>

                  <Card as={Card.Body} className="section_card">
                    <Card.Title as="h6" className="fw-400">Portfolio</Card.Title>
                    {userDetails?.freelancerSettings?.portfolio?.map((row, idx) => (
                      <>
                        <Row as="dl" key={idx} className="mb-2">
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">Course</dt>
                            <dd className="fw-500">{row.projectName}</dd>
                          </Col>
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">Type</dt>
                            <dd className="fw-500">{row.type}</dd>
                          </Col>
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">Role</dt>
                            <dd className="fw-500">{row.role}</dd>
                          </Col>
                          <Col sm={6} md={6} lg={6} xl={3} xxl={2}>
                            <dt className="freela-text-greaseblack fw-400">Ending</dt>
                            <dd className="fw-500">{new Date(row.end).toLocaleDateString()}</dd>
                          </Col>
                        </Row>

                        <Row className="description my-2">
                          <Col xs={12}>
                            <Card.Title as="h6" className="fw-400">Description</Card.Title>
                            <Card.Text>{row.description}</Card.Text>
                          </Col>
                          <Col sm={6} md={6} lg={4} xl={3}>
                            <Image src={row.fileUpload} alt="project Certificate" className="certificate-image" />
                          </Col>
                        </Row>

                        <Row>
                          <Form.Group as={Col} md={4} lg={4}>
                            <Form.Label>Portfolio Name</Form.Label>
                            <Form.Control value={row.behance} placeholder="Behance" readOnly />
                          </Form.Group>
                          <Form.Group as={Col} md={6} lg={6}>
                            <Form.Label>Portfolio Link</Form.Label>
                            <Form.Control value={row.upwork} placeholder="Behance" readOnly />
                          </Form.Group>
                        </Row>
                      </>
                    ))}
                  </Card>
                </Stack>
              </Col>
              <Col md={4} lg={3} xl={3}>
                <aside className="sticky-top z-1" style={{ top: 80 }}>
                  <div className="d-grid mb-3">
                    <Button variant="" className="freela-btn-primary px-5">Published</Button>
                  </div>
                  <Card as={Card.Body} className="section_card p-3 mb-3">
                    <Card.Title as="h6">Contact Information</Card.Title>
                    <Nav className="gap-1 flex-column">
                      {userDetails?.clientSettings?.weblinks?.map((row, index) => (
                        <Nav.Item className="d-flex align-items-center" key={index}>
                          <div className="icon" style={{ width: 36, height: 36 }}>
                            <svg className="d-inline-block" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="18" cy="18" r="18" fill="#F8F7F4" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9909 11H24.0091C24.237 11 24.4469 11.0944 24.5983 11.2458L24.6016 11.249C24.7529 11.402 24.8473 11.6104 24.8473 11.8382V13.5456H26.7158C27.0706 13.5456 27.3913 13.6904 27.6224 13.9232C27.8551 14.1559 28 14.4782 28 14.8314V20.2546C28 20.6061 27.8551 20.9284 27.6224 21.1611C27.6045 21.179 27.5866 21.1953 27.5671 21.21C27.3392 21.4134 27.0397 21.5387 26.7158 21.5387H24.8473V23.2461C24.8473 23.4772 24.7529 23.6872 24.6016 23.8385C24.4502 23.9899 24.2402 24.0843 24.0091 24.0843H11.9909C11.763 24.0843 11.5531 23.9915 11.4001 23.8385L11.3952 23.8337C11.2438 23.6807 11.1494 23.4723 11.1494 23.2445V21.5387H9.28418C8.93262 21.5387 8.61035 21.3939 8.37598 21.1611C8.14486 20.93 8 20.6094 8 20.2546V14.8314C8 14.4766 8.14486 14.1559 8.37598 13.9232C8.39388 13.9053 8.41178 13.889 8.43132 13.8727C8.65918 13.6693 8.95703 13.5456 9.28418 13.5456H11.151V11.8366C11.1527 11.6055 11.2471 11.3971 11.3984 11.2441C11.4082 11.236 11.418 11.2279 11.4277 11.2197C11.5775 11.083 11.7744 11 11.9909 11ZM11.9111 21.5387V23.2445C11.9111 23.2673 11.9193 23.2868 11.9339 23.3014L11.9355 23.3031C11.9502 23.3177 11.9697 23.3258 11.9925 23.3258H24.0107C24.0319 23.3258 24.0514 23.3177 24.0677 23.3014C24.0824 23.2868 24.0921 23.2673 24.0921 23.2445V21.5387H11.9111Z" fill="#363848" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28418 14.2422H26.7142C27.0381 14.2422 27.3034 14.5107 27.3034 14.8314V20.2546C27.3034 20.5752 27.0348 20.8438 26.7142 20.8438H9.28418C8.96354 20.8438 8.69499 20.5785 8.69499 20.2546V14.8314C8.69499 14.5059 8.96029 14.2422 9.28418 14.2422Z" fill="white" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9258 21.5387H24.0807C24.0889 21.5387 24.0954 21.5452 24.0954 21.5534V23.2445C24.0954 23.2884 24.0596 23.3242 24.0156 23.3242H11.9909C11.9469 23.3242 11.9111 23.2884 11.9111 23.2445V21.5534C11.9111 21.5452 11.9176 21.5387 11.9258 21.5387Z" fill="white" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9219 12.0758C13.2197 12.0758 13.4622 12.3184 13.4622 12.6162C13.4622 12.9141 13.2197 13.1566 12.9219 13.1566C12.624 13.1566 12.3831 12.9141 12.3831 12.6162C12.3831 12.3184 12.624 12.0758 12.9219 12.0758Z" fill="white" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8294 12.0758C15.1273 12.0758 15.3682 12.3184 15.3682 12.6162C15.3682 12.9141 15.1257 13.1566 14.8294 13.1566C14.5316 13.1566 14.2891 12.9141 14.2891 12.6162C14.2891 12.3184 14.5316 12.0758 14.8294 12.0758Z" fill="white" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7386 12.0758C17.0365 12.0758 17.2773 12.3184 17.2773 12.6162C17.2773 12.9141 17.0348 13.1566 16.737 13.1566C16.4391 13.1566 16.1966 12.9141 16.1966 12.6162C16.1966 12.3184 16.4408 12.0758 16.7386 12.0758Z" fill="white" />
                              <path d="M10.1615 15.9186H11.2227L11.6051 17.9385L12.165 15.9186H13.2181L13.7813 17.9417L14.1654 15.9186H15.2217L14.4242 19.5384H13.3271L12.6924 17.2598L12.0609 19.5384H10.9655L10.1615 15.9186ZM15.4691 15.9186H16.5303L16.9128 17.9385L17.4727 15.9186H18.5257L19.0889 17.9417L19.473 15.9186H20.5293L19.7318 19.5384H18.6348L18 17.2598L17.3685 19.5384H16.2731L15.4691 15.9186ZM20.7783 15.9186H21.8395L22.2204 17.9385L22.7803 15.9186H23.8333L24.3965 17.9417L24.7806 15.9186H25.8369L25.0394 19.5384H23.9424L23.3076 17.2598L22.6761 19.5384H21.5807L20.7783 15.9186Z" fill="#363848" />
                            </svg>
                          </div>
                          <span className="text-decoration-underline ms-2 me-1">{row}</span>
                          <i className="bi bi-arrow-up-short freela-fs-24px rotate-45" />
                        </Nav.Item>
                      ))}

                      <Nav.Item className="d-flex align-items-center">
                        <div className="icon" style={{ width: 36, height: 36 }}>
                          <svg className="d-inline-block" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="18" cy="18" r="18" fill="#F8F7F4" />
                            <path d="M26.3337 13.0007C26.3337 12.084 25.5837 11.334 24.667 11.334H11.3337C10.417 11.334 9.66699 12.084 9.66699 13.0007M26.3337 13.0007V23.0007C26.3337 23.9173 25.5837 24.6673 24.667 24.6673H11.3337C10.417 24.6673 9.66699 23.9173 9.66699 23.0007V13.0007M26.3337 13.0007L18.0003 18.834L9.66699 13.0007" stroke="#363848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <span className="ms-2">{userDetails?.email}</span>
                      </Nav.Item>

                      <Nav.Item className="d-flex align-items-center">
                        <div className="icon" style={{ width: 36, height: 36 }}>
                          <svg className="d-inline-block" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="18" cy="18" r="18" fill="#F8F7F4" />
                            <g clip-path="url(#clip0_564_39845)">
                              <path d="M26.3332 22.0994V24.5994C26.3341 24.8315 26.2866 25.0612 26.1936 25.2739C26.1006 25.4865 25.9643 25.6774 25.7933 25.8343C25.6222 25.9912 25.4203 26.1107 25.2005 26.185C24.9806 26.2594 24.7477 26.287 24.5165 26.2661C21.9522 25.9875 19.489 25.1112 17.3249 23.7078C15.3114 22.4283 13.6043 20.7212 12.3249 18.7078C10.9165 16.5338 10.0401 14.0586 9.76653 11.4828C9.7457 11.2523 9.77309 11.0201 9.84695 10.8008C9.9208 10.5815 10.0395 10.38 10.1955 10.2091C10.3515 10.0382 10.5414 9.90169 10.753 9.8082C10.9647 9.71471 11.1935 9.66631 11.4249 9.6661H13.9249C14.3293 9.66212 14.7214 9.80533 15.028 10.069C15.3346 10.3328 15.5349 10.699 15.5915 11.0994C15.697 11.8995 15.8927 12.685 16.1749 13.4411C16.287 13.7394 16.3112 14.0635 16.2448 14.3752C16.1783 14.6868 16.0239 14.9729 15.7999 15.1994L14.7415 16.2578C15.9278 18.3441 17.6552 20.0715 19.7415 21.2578L20.7999 20.1994C21.0264 19.9754 21.3125 19.821 21.6241 19.7545C21.9358 19.688 22.2599 19.7123 22.5582 19.8244C23.3143 20.1066 24.0998 20.3022 24.8999 20.4078C25.3047 20.4649 25.6744 20.6688 25.9386 20.9807C26.2029 21.2926 26.3433 21.6907 26.3332 22.0994Z" stroke="#363848" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_564_39845">
                                <rect width="20" height="20" fill="white" transform="translate(8 8)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span className="ms-2"> + {userDetails?.phoneNumber}</span>
                      </Nav.Item>

                      <Nav.Item className="d-flex align-items-center">
                        <div className="icon" style={{ width: 36, height: 36 }}>
                          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="18" cy="18" r="18" fill="#F8F7F4" />
                            <path d="M25.5 16.334C25.5 22.1673 18 27.1673 18 27.1673C18 27.1673 10.5 22.1673 10.5 16.334C10.5 14.3449 11.2902 12.4372 12.6967 11.0307C14.1032 9.62416 16.0109 8.83398 18 8.83398C19.9891 8.83398 21.8968 9.62416 23.3033 11.0307C24.7098 12.4372 25.5 14.3449 25.5 16.334Z" stroke="#363848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18 18.834C19.3807 18.834 20.5 17.7147 20.5 16.334C20.5 14.9533 19.3807 13.834 18 13.834C16.6193 13.834 15.5 14.9533 15.5 16.334C15.5 17.7147 16.6193 18.834 18 18.834Z" stroke="#363848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <span className="ms-2 text-wrap text-break">1261 Silver Lake Blvd. Suite 22 Los Angeles, CA 90039</span>
                      </Nav.Item>
                    </Nav>

                    <div className="my-3 skills">
                      <Card.Title as="h6">Skills</Card.Title>
                      <Stack direction="vertical" as="ul" className="gap-2 list-unstyled">
                        {userDetails?.freelancerSettings?.skills?.map(
                          (row, index) => (
                            <li key={index}>
                              <span>{row}</span>
                            </li>
                          )
                        )}
                      </Stack>
                    </div>

                    <div className="mb-3 projects_list">
                      <Card.Title as="h6" className="mb-3">Projects</Card.Title>
                      <Stack direction="vertical" as="ul" className="gap-2 list-unstyled">
                        <li className="d-flex justify-content-between">
                          <div className="title">Project Views</div>
                          <div className="projects_count">120</div>
                        </li>
                        <li className="d-flex justify-content-between">
                          <div className="title">Followers</div>
                          <div className="projects_count">0</div>
                        </li>
                        <li className="d-flex justify-content-between">
                          <div className="title">Following</div>
                          <div className="projects_count">0</div>
                        </li>
                      </Stack>
                    </div>

                    <Stack direction="horizontal">
                      <div className="domain_link">www.thefreela.com</div>
                      <Button variant="link" className="p-0 ms-auto freela-text-black">
                        <i className="bi bi-copy freela-fs-16px" />
                      </Button>
                    </Stack>
                  </Card>

                </aside>
              </Col>
            </Row>
          </Stack>
        </Card.Body>
      </Card>
    </>

  );
};

export default ProfilePreview;
