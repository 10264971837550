import React, { useEffect, useState } from "react";
import "./SkillsTab.css";
import addIcon from "../../../../Images/icons/experience-icon.png"; // import your add icon
import closeIcon from "../../../../Images/icons/close-x.svg"; // import your close icon
import {Card, Container} from "react-bootstrap";

const SkillsTab = ({ userDetails, onGetData }) => {
  const [skill, setSkill] = useState(""); // Store current input
  const [skillsList, setSkillsList] = useState([]); // Store list of added skills

  useEffect(() => {
    // Check if userDetails has skills data and set initial state
    if (userDetails?.freelancerSettings?.skills && userDetails?.freelancerSettings?.skills.length > 0) {
      setSkillsList(userDetails?.freelancerSettings?.skills); // Initialize skillsList with userDetails?.freelancerSettings?.skills
    }
  }, [userDetails]);

  useEffect(() => {
    // Pass the skills data to the parent component
    onGetData({ skills: skillsList }); // Wrap in an object
  }, [skillsList]);

  const handleAddSkill = () => {
    if (skill.trim() && !skillsList.includes(skill.trim())) { // Prevent duplicates
      setSkillsList([...skillsList, skill.trim()]); // Add skill to the list
      setSkill(""); // Clear the input field
    }
  };

  const handleDeleteSkill = (index) => {
    const updatedSkills = skillsList.filter((_, i) => i !== index); // Remove skill from list
    setSkillsList(updatedSkills);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && skill.trim()) {
      e.preventDefault(); // Prevent form submission or page refresh
      setSkillsList((prevSkills) => [...prevSkills, skill]);
      setSkill(''); // Clear input after adding skill
    }
  };

  return (
    <Card className="skills-section">
        <div className="d-flex align-items-center heading_section">
          <img src={addIcon} alt="Add Icon" />
          <h2>Add skills here.</h2>
        </div>

      {/* Label for Skills */}
     <div className="skills_container">
      <div className="skills-content">
        <div className="input-group">
          <label htmlFor="skills-input" className="mb-1">
            Skills <span className="text-danger">*</span>
          </label>
        </div>

        {/* Input for adding skills */}
        <div className="skill-input-group">
          <div className="skill_input">
          <input
            type="text"
            id="skills-input"
            placeholder="Enter your skill here"
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            onKeyDown={handleKeyDown} 
          />
          </div>
          <div className="ms-3">
            <button className="secondary-btn" onClick={handleAddSkill}>
              Add
            </button>
          </div>
        </div>
      </div>

      {/* Display list of added skills */}
      <div className="skills-list">
        {skillsList.map((skill, index) => (
          <div key={index} className="skill-item">
            <span>{skill}</span>
            <img
              src={closeIcon}
              alt="Delete Icon"
              className="delete-icon"
              onClick={() => handleDeleteSkill(index)}
            />
          </div>
        ))}
      </div>
      </div>
    </Card>
  );
};

export default SkillsTab;
