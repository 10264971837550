import React, { useEffect, useState } from "react";
import { ReactComponent as ExperienceIcon } from "../../../../Images/freela-icons/experience-icon.svg";
import { Row, Col, Form, Card, Stack, Button } from "react-bootstrap";

const SkillsTab = ({ userDetails, onGetData, onGetErrData }) => {
  const [skill, setSkill] = useState(""); // Store current input
  const [skillsList, setSkillsList] = useState([]); // Store list of added skills
  const [formError, setFormError] = useState("");

  useEffect(() => {
    // Check if userDetails has skills data and set initial state
    if (
      userDetails?.freelancerSettings?.skills &&
      userDetails?.freelancerSettings?.skills.length > 0
    ) {
      setSkillsList(userDetails?.freelancerSettings?.skills); // Initialize skillsList with userDetails?.freelancerSettings?.skills
    }
  }, [userDetails]);

  useEffect(() => {
    // Pass the skills data to the parent component
    onGetData({ skills: skillsList }); // Wrap in an object
  }, [skillsList]);

  const handleAddSkill = () => {
    if (skill.trim() && !skillsList.includes(skill.trim())) {
      // Prevent duplicates
      setSkillsList([...skillsList, skill.trim()]); // Add skill to the list
      setSkill(""); // Clear the input field
    }
  };

  const handleDeleteSkill = (index) => {
    const updatedSkills = skillsList.filter((_, i) => i !== index); // Remove skill from list
    setSkillsList(updatedSkills);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && skill.trim()) {
      e.preventDefault(); // Prevent form submission or page refresh
      setSkillsList((prevSkills) => [...prevSkills, skill]);
      setSkill(""); // Clear input after adding skill
    }
  };

  useEffect(() => {
    handleValidate();
  }, [skillsList, skill]);

  const handleValidate = () => {
    if (skillsList.length === 0) {
      setFormError("Please add at least one skill.");
    } else if (!/^[A-Za-z\s]{2,50}$/.test(skill.trim()) && skill !== "") {
      setFormError("Skill must be between 2 to 50 alphabetic characters");
    } else {
      setFormError("");
    }
  };
  useEffect(() => {
    onGetErrData(formError ? 1 : 0);
  }, [formError, skill]);
  
  return (
    <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
      <Row className="gy-3 mb-4 align-items-center">
        <Col xs={12}>
          <Stack direction="horizontal" className="gap-2">
            <ExperienceIcon width={50} />
            <div className="freela-fs-20 fw-500 freela-text-primary">
              Add Work Experience
            </div>
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col lg={10} xl={9} className="mx-auto">
          <Row className="align-items-end g-2">
            <Form.Group as={Col} xs={9} sm={10} md={10} lg={10} xl={10}>
              <Form.Label>
                Skill <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="skills-input"
                placeholder="Enter your skill here"
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={skillsList.length > 10}
              />
            </Form.Group>
            <Col xs={3} sm={2} md={2} lg={2} xl={2} className="d-grid">
              <Button
                variant=""
                className="freela-btn-primary-outline"
                onClick={handleAddSkill}
                style={{ height: 42 }}
              >
                Add
              </Button>
            </Col>
          </Row>
          {formError && <div className="text-danger mt-2">{formError}</div>}

          {skillsList.length > 0 && (
            <Row className="mt-4">
              <Col>
                <div className="skillset d-flex flex-wrap justify-content-center align-items-center gap-2">
                  {skillsList.map((skill, index) => (
                    <Button
                      key={index}
                      size="sm"
                      className="freela-btn-primary-outline d-flex align-items-center rounded-pill gap-2 py-2 px-3"
                      onClick={() => handleDeleteSkill(index)}
                    >
                      <span>{skill}</span>
                      <i className="bi bi-x rounded-circle p-1" />
                    </Button>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default SkillsTab;
