import React from 'react'
import { Row, Col, Stack, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Step1 = ({ clickToAccept }) => {
    const navigate = useNavigate();

    return (
        <Row className="py-4">
            <Col md={8} lg={7} xl={6} className='mx-auto'>
                <div className="content_one text-center">
                    <div className="h3 fw-500">Welcome</div>
                    <p>Thank you for joining our interview invitation.</p>
                </div>
                <div className="content_two text-center my-5">
                    <div className="h5 fw-500">Accept Your Interview</div>
                    <p>Please confirm your availability to proceed with the interview or choose to Re-schedule.</p>
                </div>
                <Stack direction='horizontal' className='gap-3 justify-content-center'>
                    <Button variant='' className='freela-btn-primary-outline px-5' onClick={() => navigate(-1)}>Back</Button>
                    <Button variant='' className='freela-btn-primary px-5' onClick={clickToAccept}>Accept</Button>
                </Stack>
            </Col>
        </Row>
    )
}

export default Step1