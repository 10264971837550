import React, { useEffect, useState } from "react";
import "./EducationTab.css";
import addIcon from "../../../../Images/icons/Book_light.svg"; // import your icon
import deleteIcon from "../../../../Images/icons/delete-icon.png"; // import delete icon
import { formatDateString, formatForInput ,formatDateToDDMMYYYY} from '../../../Common/utils/utils';

const EducationTab = ({ userDetails, onGetData }) => {

  // console.log(userDetails,"userDetails",userDetails.freelancerSettings.education,"userDetails.freelancerSettings.education");
  

  const [educationRows, setEducationRows] = useState([
    { degree: "", university: "", start: "", end: "" }, // Use "start" and "end" here
  ]);

  useEffect(() => {
    // Check if userDetails has education data and set initial state
    if (userDetails?.freelancerSettings?.education && userDetails.freelancerSettings.education.length > 0) {
      const formattedEducation = userDetails.freelancerSettings.education.map((edu) => ({
        degree: edu.degree,
        university: edu.university,
        start: formatDateString(edu.start), // Change here
        end:  formatDateString(edu.end),     // Change here
      }));
      setEducationRows(formattedEducation);
    }
  }, [userDetails]);


  useEffect(() => {
    // Map to the required structure before passing data to parent component
    const formattedData = educationRows.map((row) => ({
      degree: row.degree,
      university: row.university,
      start:formatDateToDDMMYYYY( row.start), // Change here
      end: formatDateToDDMMYYYY(row.end),     // Change here
    }));
    onGetData({ education: formattedData }); // Wrap in an object
  }, [educationRows]);

  const handleAddRow = () => {
    setEducationRows([...educationRows, { degree: "", university: "", start: "", end: "" }]); // Change here
  };

  const handleDeleteRow = (index) => {
    const updatedRows = educationRows.filter((_, i) => i !== index);
    setEducationRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...educationRows];
    updatedRows[index][field] = value;
    setEducationRows(updatedRows);
  };

  return (
    <div className="education-section">
      {/* First Row: Title and Add Button */}
      <div className="education-header">
        <div className="education-header-left">
          <img src={addIcon} alt="Add Icon" />
          <h2>Add Educational Background</h2>
        </div>
        <div className="education-header-right">
          <button className="add-button" onClick={handleAddRow}>
            Add
          </button>
        </div>
      </div>

      {/* Dynamic Rows for Education Fields */}
      {educationRows.map((row, index) => (
        <div className="responsive-row mt-3" key={index}>
          {/* Degree Field */}
          <div className="input-group">
            <label htmlFor={`degree-${index}`}>Degree <span className="text-danger">*</span></label>
            <input
              type="text"
              id={`degree-${index}`}
              placeholder="Degree"
              value={row.degree}
              onChange={(e) => handleChange(index, "degree", e.target.value)}
              required
            />
          </div>

          {/* University Field */}
          <div className="input-group">
            <label htmlFor={`university-${index}`}>University <span className="text-danger">*</span></label>
            <input
              type="text"
              id={`university-${index}`}
              placeholder="University"
              value={row.university}
              onChange={(e) => handleChange(index, "university", e.target.value)}
              required
            />
          </div>

          {/* Starting from Field */}
          <div className="input-group">
            <label htmlFor={`start-${index}`}>Starting from <span className="text-danger">*</span></label>
            <input
              type="date"
              id={`start-${index}`}
              placeholder="Starting from *"
              value={formatForInput(row.start)} // Change here
              onChange={(e) => handleChange(index, "start", e.target.value)} // Change here
              required
            />
          </div>

          {/* Ending Field */}
          <div className="input-group">
            <label htmlFor={`end-${index}`}>Ending <span className="text-danger">*</span></label>
            <input
              type="date"
              id={`end-${index}`}
              placeholder="Ending *"
              value={formatForInput(row.end)} // Change here
              onChange={(e) => handleChange(index, "end", e.target.value)} // Change here
              required
            />
          </div>

          {/* Delete Button */}
          <button className="delete-button" onClick={() => handleDeleteRow(index)}>
            <img src={deleteIcon} alt="Delete Icon" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default EducationTab;


