import React from 'react'

const MyWorkspace = ({role}) => {
  return (
    <>
    {role === "client" ? (
      <div>My client MyWorkspace</div>
    ) : (
      <div> My freelancer MyWorkspace</div>
    )}
  </>
  )
}

export default MyWorkspace


