import React from 'react'

const MyBids = () => {
  return (
    <div>
      MyBids
    </div>
  )
}

export default MyBids
