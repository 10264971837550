import React, { useState, useRef, useEffect } from "react";
import ApiCall from "../../../../Api/ApiCall";
import Loader from "../../../Common/Loader/Loader";
import { Button, Card, Col, Figure, Form, Row, Stack } from "react-bootstrap";
import { ReactComponent as ExperienceIcon } from "../../../../Images/freela-icons/experience-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../Images/freela-icons/delete-icon.svg";
import { ReactComponent as PdfIcon } from "../../../../Images/freela-icons/pdf-icon.svg";
import { ReactComponent as FileUploadIcon } from "../../../../Images/freela-icons/file-upload-icon.svg";

import {
  formatDateString,
  formatDateToDDMMYYYYC,
  formatForInput,
} from "../../../Common/utils/utils";

const CourseTab = ({ userDetails, onGetData, onGetErrData }) => {
  // Array to store references for each file input
  const [certificationRows, setCertificationRows] = useState([
    {
      certification: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
      certificateFile: "",
    },
  ]);

  const certificationInputRefs = useRef([]); // Array of refs for all rows
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");

  useEffect(() => {
    if (
      userDetails?.freelancerSettings?.courses &&
      userDetails?.freelancerSettings?.courses.length > 0
    ) {
      const extractedFileUrls = userDetails?.freelancerSettings?.courses.map(
        (course) => course.file || []
      );
      setFileUrls(extractedFileUrls);

      const formattedCourses = userDetails?.freelancerSettings?.courses.map(
        (course, index) => {
          return {
            certification: course.certificateName,
            company: course.company,
            start: formatDateString(course.start),
            end: formatDateString(course.end),
            description: course.description,
            certificateFile:
              course.file && course.file.length > 0 ? course.file[0] : "",
          };
        }
      );

      setCertificationRows(formattedCourses);
    }
  }, [userDetails]);

  useEffect(() => {
    const formattedData = certificationRows.map((row, index) => ({
      certificateName: row.certification,
      company: row.company,
      start: formatDateToDDMMYYYYC(row?.start),
      end: formatDateToDDMMYYYYC(row?.end),
      description: row.description,
      file:
        fileUrls[index] !== undefined || fileUrls[index] !== null
          ? fileUrls[index]
          : row.certificateFile || "",
    }));

    onGetData({ courses: formattedData });
  }, [certificationRows, fileUrls]);

  const handleAddRow = () => {
    setCertificationRows((prevRows) => [
      ...prevRows,
      {
        certification: "",
        company: "",
        startDate: "",
        endDate: "",
        description: "",
        certificateFile: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = certificationRows.filter((_, i) => i !== index);
    setCertificationRows(updatedRows);
    certificationInputRefs.current.splice(index, 1);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...certificationRows];
    updatedRows[index][field] = value;
    setCertificationRows(updatedRows);
  };

  const handlePhotoChange = async (e, index) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          const updatedRows = [...certificationRows];
          updatedRows[index].certificateFile = file;
          setCertificationRows(updatedRows);

          setFileUrls((prevUrls) => {
            const updatedUrls = [...prevUrls];
            updatedUrls[index] = response.data.s3Url;
            return updatedUrls;
          });
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadClick = (index) => {
    certificationInputRefs.current[index].click(); // Trigger file input click for a specific row
  };

  const handleDownload = (index) => {
    const fileURL = certificationRows[index].certificateFile;

    if (typeof fileURL === "string" && fileURL) {
      const link = document.createElement("a");
      link.href = fileURL;
      link.target = "_blank";
      link.download = fileURL.split("/").pop(); // Extract the filename from the URL
      link.click();
    } else {
      console.error("No valid file URL available for download");
    }
  };

  const handleDelete = (e, index) => {
    const updatedRows = [...certificationRows];
    updatedRows[index].certificateFile = null;
    setCertificationRows(updatedRows);

    const updatedFileUrls = [...fileUrls];
    updatedFileUrls[index] = "";

    setFileUrls(updatedFileUrls);
  };

  useEffect(() => {
    handleValidate();
  }, [certificationRows]);

  const handleValidate = () => {
    let hasValidEntry = false;
    let newErrors = {};

    certificationRows.forEach((edu, index) => {
      let entryErrors = {};
      if (!edu.certification.trim()) {
        entryErrors.certification = "Certification is required";
      } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.certification.trim())) {
        entryErrors.certification =
          "Certification name must be between 2 to 50 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.company.trim()) {
        entryErrors.company = "Company is required ";
      } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.company.trim())) {
        entryErrors.company =
          "Company must be between 2 to 50 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (edu.startDate === "") {

        entryErrors.startDate = "Start Date is required";
      } else {
        hasValidEntry = true;
      }

      if (edu.endDate === "") {
        entryErrors.endDate = "End Date is required";
      } else {
        hasValidEntry = true;
      }

      if (!edu.certificateFile || !(edu.certificateFile instanceof File)) {
        entryErrors.certificateFile = "File is required";
    } else if (edu.certificateFile.size === 0) {
        entryErrors.certificateFile = "File cannot be empty";
    } else {
        hasValidEntry = true;
    }

      if (Object.keys(entryErrors).length > 0) {
        newErrors[index] = entryErrors;
      }
    });

    if (!hasValidEntry || certificationRows.length === 0) {
      setFormError("Please complete all fields for your certification.");
    } else {
      setFormError("");
    }

    setErrors(newErrors);

    // Return true if there are no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onGetErrData(Object.keys(errors).length || (formError ? 1 : 0));
  }, [errors, formError]);


  return (
    <>
      {loading && <Loader />}

      <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
        <Row className="gy-2 mb-1 align-items-center">
          <Col sm={8} md={8} lg={8} xl={8}>
            <Stack direction="horizontal" className="gap-2">
              <ExperienceIcon width={50} />
              <div className="freela-fs-20 fw-500 freela-text-primary">
                Add Certifications
              </div>
            </Stack>
          </Col>
          <Col sm={4} md={4} lg={4} xl={4} className="text-sm-end">
            <Button
              variant=""
              className="freela-btn-primary-outline"
              onClick={handleAddRow}
            >
              Add
            </Button>
          </Col>
        </Row>
        {formError && <div className="text-danger mt-2">{formError}</div>}

        {certificationRows.map((row, index) => (
          <Row className="align-items-end mt-4 gy-4" key={index}>
            <Col lg={11} xl={11}>
              <Row className="gy-3">
                <Form.Group as={Col} md={6} lg={6} xl={3}>
                  <Form.Label>
                    Certification <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id={`certification-${index}`}
                    value={row.certification}
                    onChange={(e) =>
                      handleChange(index, "certification", e.target.value)
                    }
                    placeholder="Certification"
                    maxLength={50}
                  />
                  {errors[index]?.certification && (
                    <div className="text-danger mt-2">
                      {errors[index].certification}
                    </div>
                  )}
                </Form.Group>

                <Form.Group as={Col} md={6} lg={6} xl={3}>
                  <Form.Label>
                    Company Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id={`company-${index}`}
                    value={row.company}
                    onChange={(e) =>
                      handleChange(index, "company", e.target.value)
                    }
                    placeholder="Company"
                    maxLength={50}
                  />
                  {errors[index]?.company && (
                    <div className="text-danger mt-2">
                      {errors[index].company}
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6} lg={6} xl={3}>
                  <Form.Label>
                    Starting from <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id={`start-${index}`}
                    placeholder="Starting from *"
                    value={formatForInput(row.startDate)} // Change here
                    onChange={(e) =>
                      handleChange(index, "startDate", e.target.value)
                    } // Change here
                    required
                  />
                  {errors[index]?.startDate && (
                    <div className="text-danger mt-2">
                      {errors[index].startDate}
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6} lg={6} xl={3}>
                  <Form.Label>
                    Ending <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id={`end-${index}`}
                    placeholder="Ending *"
                    value={formatForInput(row.end)} // Change here
                    onChange={(e) => handleChange(index, "endDate", e.target.value)} // Change here
                    required
                  />
                  {errors[index]?.endDate && (
                    <div className="text-danger mt-2">
                      {errors[index].endDate}
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Col>

            <Col lg={1} xl={1}>
              <Button
                variant=""
                className="freela-btn-primary btn-rounded"
                onClick={() => handleDeleteRow(index)}
              >
                <DeleteIcon width={20} height={20} />
              </Button>
            </Col>

            <Col xs={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  id={`description-${index}`}
                  value={row.description}
                  onChange={(e) =>
                    handleChange(index, "description", e.target.value)
                  }
                  placeholder="Brief description about your certification"
                  rows={6}
                  maxLength={150}

                />
                <div className="text-end">
                  <Form.Text>{row.description.length}/150 Characters</Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Card className="p-4 rounded-5">
                {row.certificateFile ? (
                  <Stack direction="horizontal">
                    <Card className="freela-greylight9-bg freela-border-greylight10 d-flex p-3 flex-row gap-4 rounded-4 mb-0">
                      <Figure className="d-flex gap-2 mb-0">
                        <PdfIcon width={40} height={40} />
                        <Figure.Caption className="file-fetails">
                          <p className="file-title text-dark mb-0 w-75 text-truncate">
                            {" "}
                            {row.certificateFile.name}
                          </p>
                          <p className="file-meta mb-0">
                            {" "}
                            {row.certificateFile.size} KB -{" "}
                            {new Date(
                              row.certificateFile.lastModified
                            ).toLocaleDateString()}
                          </p>
                        </Figure.Caption>
                      </Figure>
                      <Stack
                        direction="horizontal"
                        className="ms-auto ms-xl-5 gap-2"
                      >
                        <Button
                          variant="link p-0 freela-greaseblack-text"
                          onClick={() => handleDownload(index)}
                        >
                          <i className="bi bi-download" />
                        </Button>
                        <Button
                          variant="link p-0 freela-danger-text"
                          onClick={(e) => {
                            if (
                              window.confirm("Do you want to delete this file?")
                            )
                              handleDelete(e, index);
                          }}
                        >
                          <i className="bi bi-trash3" />
                        </Button>
                      </Stack>
                    </Card>
                    <Button
                      className="freela-btn-primary-outline ms-auto"
                      onClick={() => handleUploadClick(index)}
                    >
                      Choose
                    </Button>
                  </Stack>
                ) : (
                  <Card.Body className="p-0">
                    <Stack direction="horizontal">
                      <div className="d-flex align-items-center gap-2">
                        <FileUploadIcon
                          className="mb-2"
                          width={50}
                          height={50}
                        />
                        <Card.Text className="mb-0 fs-6">
                          Please upload your Certificates
                        </Card.Text>
                      </div>
                      <Button
                        className="freela-btn-primary-outline ms-auto"
                        onClick={() => handleUploadClick(index)}
                      >
                        Choose
                      </Button>
                    </Stack>
                    {errors[index]?.certificateFile && (
                      <div className="text-danger mt-2">
                        {errors[index].certificateFile}
                      </div>
                    )}
                  </Card.Body>
                )}
                <Form.Control
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  ref={(el) => (certificationInputRefs.current[index] = el)}
                  className="d-none"
                  onChange={(e) => handlePhotoChange(e, index)}
                />
              </Card>
            </Col>
          </Row>
        ))}
      </Card>
    </>
  );
};

export default CourseTab;
