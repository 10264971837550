
import { useNavigate } from "react-router-dom";

const useCommonNavigate = () => {
  const navigate = useNavigate();

  const commonNavigate = (path) => {
    navigate(path);
  };

  return { commonNavigate };
};

export default useCommonNavigate;
