import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import FreelancerView from "./FreelancerView";
import ClientView from "./ClientView";
import { useViewContext } from "../../../Context/ViewContext";
import ViewProposal from "./ViewProposal";
import ShowMyBids from "./Tabs/ShowMyBids";
import Publish from "../Service/Publish";
import ProjectView from "../MyWorks/ProjectView";
import Service from "../Service/Service";
import Project from "../Project/Project";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";
import { debounce } from "lodash";
import Loader from "../../Common/Loader/Loader";

const FindWorksContent = () => {
  const [role, setRole] = useState("freelancer");
  const [activeRole, setActiveRole] = useState("freelancer");
  const { view, projectID, serviceData } = useViewContext();
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();
  const [counts, setCounts] = useState({
    msgCount: 0,
    draftCount: 0,
    finalCount: 0,
    bidCount: 0,
    savedCount: 0,
  });

  const handleRoleChange = (selectedRole) => {
    setRole(selectedRole);
    setActiveRole(selectedRole);
  };
  // console.log(view, "view", role, "role");

  const debouncedGetCountDetails = debounce(() => {
    getCountDetails();
  }, 300);

  useEffect(() => {
    if (role) {
      debouncedGetCountDetails();
    }
    return () => debouncedGetCountDetails.cancel();
  }, [role]);

  const getCountDetails = async () => {
    setLoading(true);
    try {
      const endpoint =
        role === "freelancer"
          ? "metadata/count?role=freelancer"
          : "metadata/count?role=client";

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setCounts(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records count Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <section className="section_card h-100 px-0">
        {view === "ShowPublishedService" &&
          (role === "freelancer" ? (
            <ProjectView payloadData={serviceData} type={"published"} />
          ) : (
            <Publish serviceData={serviceData} type={"published"} />
          )  )}

        {view === "ShowDrafts" &&
          (role === "freelancer" ? (
            <Service draftData={serviceData} type={"draft"} />
          ) : (
            <Project projectData={serviceData} type={"draft"} />
          ))}

        {view === "ShowMyBids" && <ShowMyBids projectId={projectID} />}
        {view === "default" && (
          <ButtonGroup
            aria-label="Basic example"
            className="rounded-3 overflow-hidden mx-4"
          >
            <Button
              variant=""
              onClick={() => handleRoleChange("freelancer")}
              className={
                activeRole === "freelancer"
                  ? "freela-btn-primary"
                  : "freela-btn-primary-outline"
              }
            >
              Freelancer
            </Button>
            <Button
              variant=""
              onClick={() => handleRoleChange("client")}
              className={
                activeRole === "client"
                  ? "freela-btn-primary px-5"
                  : "freela-btn-primary-outline px-5"
              }
            >
              Client
            </Button>
          </ButtonGroup>
        )}

        {view === "default" &&
          (role === "freelancer" ? <FreelancerView counts={counts}/> : <ClientView  counts={counts}/>)}
        {view === "project-preview" && projectID && <ViewProposal />}
      </section>
    </>
  );
};

export default FindWorksContent;
