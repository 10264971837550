import React from 'react';
import landing from '../../Images/home_banner_img.png';
import { Card, Row, Col } from "react-bootstrap";
import "./Home.css"

const Home = () => {
  
 
  return (
    <div className='container-style'>
      <Row>
        <Col sm={12} md={6} lg={6}> 
        <div className='card-height'>
        <Card className='coming_soon'>
          <h1>Freelance Work 
          through <span>Freela</span></h1>
        </Card>
        </div>
        </Col>
        <Col sm={12} md={6} lg={6}>
        <img src={landing} alt="Website Coming Soon" className="image-style"/>
        </Col>
      </Row>
      
    </div>
  );
}

export default Home;
