import React, { useState } from "react";
import { Row, Col, Button, Image, Form } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import { registerValidationSchema } from "../Common/Validation/validationSchema";
import ApiCall from "../../Api/ApiCall";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";
import { ReactComponent as UserIcon } from "../../Images/freela-icons/user-icon.svg";
import { ReactComponent as EmailIcon } from "../../Images/freela-icons/email-icon.svg";
import { ReactComponent as EyeIcon } from "../../Images/freela-icons/eye-icon.svg";
import { ReactComponent as EyeOffIcon } from "../../Images/freela-icons/eyeoff-icon.svg";
import { ReactComponent as FreelaLogo } from "../../Images/freela-icons/freela-logo.svg";
import RegisterImg from "../../Images/freela-icons/register-left-img.svg";

const Register = ({ openVerifyModal, openLogin }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { notify } = useToast();

  // Handle form submission
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        firstName: values.fname,
        lastName: values.lname,
        password: values.password,
        confirmPass: values.cpassword,
        phoneNumber: values.phone,
        code: values.code,
        country: values.country,
        consent: values.consent,
        email: values.email,
      };

      const response = await ApiCall(
        "POST",
        "user/register",
        payload,
        "application/json"
      );
      if (response.statusCode == 201) {
        notify("success", "Registration successful!");
        openVerifyModal(response.data.email);
      } else {
        notify(
          "error",
          response.message || "Registration failed. Please try again."
        );
      }
    } catch (error) {
      if (error.response) {
        notify("error", error.response.data.message || "Registration failed. Please try again.");
      } else if (error.request) {
        notify("error", "Network error. Please check your connection.");
      } else {
        // Other unexpected errors
        console.error("Unexpected Error:", error.message);
        notify("error", "Something went wrong. Please try again.");
      }
    
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Row className="register_form">
        {/* Left Section */}
        <Col sm={12} md={12} lg={5} xl={5}>
          <FreelaLogo className="ms-lg-4 mt-lg-2" width={260} />
          <div className="d-flex align-items-center justify-content-center mt-5 pt-lg-4">
            <Image
              src={RegisterImg}
              alt="RegisterImg"
              className="h-400"
              fluid
            />
          </div>
        </Col>

        <Col sm={12} md={12} lg={7} xl={7}>
          <h1 className="fs-4">Registration</h1>
          <p>
            If you already have an account,
            <Button
              variant="link"
              className="py-0 ps-1 text-decoration-none freela-text-primary"
              onClick={openLogin}
            >
              Login here
            </Button>
          </p>

          {/* Register Form using Formik */}
          <Formik
            initialValues={{
              fname: "",
              lname: "",
              email: "",
              phone: "",
              password: "",
              cpassword: "",
              consent: false,
            }}
            validationSchema={registerValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FormikForm className="mt-4">
                <Row className="gy-3">
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      First Name <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Field
                        as={Form.Control}
                        name="fname"
                        type="text"
                        placeholder="Enter your first name"
                      />
                      <UserIcon
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        width={16}
                      />
                    </div>
                    <ErrorMessage
                      name="fname"
                      component="small"
                      className="text-danger"
                    />
                  </Form.Group>

                  {/* Last Name */}
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      Last Name <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Field
                        as={Form.Control}
                        name="lname"
                        type="text"
                        placeholder="Enter your last name"
                      />
                      <UserIcon
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        width={16}
                      />
                    </div>
                    <ErrorMessage
                      name="lname"
                      component="small"
                      className="text-danger"
                    />
                  </Form.Group>

                  {/* Password */}
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Field
                        as={Form.Control}
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                      />
                      <div
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeIcon width={16} />
                        ) : (
                          <EyeOffIcon width={16} />
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="small"
                      className="text-danger"
                    />
                  </Form.Group>

                  {/* Confirm Password */}
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      Confirm Password <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Field
                        as={Form.Control}
                        name="cpassword"
                        placeholder="Confirm Password"
                        type={showCPassword ? "text" : "password"}
                      />
                      <div
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={() => setShowCPassword(!showCPassword)}
                      >
                        {showCPassword ? (
                          <EyeIcon width={16} />
                        ) : (
                          <EyeOffIcon width={16} />
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      name="cpassword"
                      component="small"
                      className="text-danger"
                    />
                  </Form.Group>

                  {/* Phone Number */}
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      Phone Number <span className="text-danger">*</span>
                    </Form.Label>
                    <PhoneInput
                      as={Form.Control}
                      country="in"
                      value={values.phone}
                      onChange={(phone, country) => {
                        setFieldValue("phone", phone);
                        setFieldValue("code", country.dialCode);
                        setFieldValue("country", country.name);
                      }}
                    />
                    <ErrorMessage
                      name="phone"
                      component="small"
                      className="text-danger"
                    />
                  </Form.Group>

                  {/* Email */}
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Field
                        as={Form.Control}
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                      />
                      <EmailIcon
                        className="position-absolute top-50 end-0 translate-middle-y me-3"
                        width={18}
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="small"
                      className="text-danger"
                    />
                  </Form.Group>

                  {/* Consent */}
                  <Col md={12}>
                    <Form.Check type="checkbox" id="condition-checkbox">
                      <Field
                        as={Form.Check.Input}
                        type="checkbox"
                        name="consent"
                        className="me-2"
                      />
                      <Form.Check.Label>
                        {" "}
                        I have read and accept the Terms of Use, Privacy Policy,
                        Terms &amp; Conditions
                      </Form.Check.Label>
                    </Form.Check>
                    <ErrorMessage
                      name="consent"
                      component="small"
                      className="text-danger"
                    />
                  </Col>

                  <Col md={6} className="mt-5 mx-auto">
                    <Form.Group className="d-grid py-4">
                      <Button type="submit" className="freela-btn-primary px-5">
                        Register
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default Register;
