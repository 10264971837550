// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_tab_content{
  margin:0px 11rem;
}
.gender_card{
  border: 1px solid #dfdfdf;
    border-radius: 30px;
    padding: 10px 20px;
    text-align: center;
    background: #fff;
}
.about_tab_content .form-label{
  color:#626262;
  font-size: 14px;
}
.about_tab_content textarea{
  border-radius: 30px;
  color:#626262;
  padding: 20px;
}
.about_tab_content .input-group{
  width: auto;
}
.about_tab_content .input-group input{
  padding: 12px;
  border-radius: 30px;
}
.about_tab_content .about_input_sec{
  display: inline-flex;
}
@media (max-width:1200px) {
  .about_tab_content{
    margin:0px 2rem;
  }
}
@media (max-width:820px) {
  .about_tab_content .about_input_sec{
    display: inline-block;
  }
}
@media (max-width:767px) {
  .about_tab_content{
    margin:0px 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Profile/AboutTab/AboutTab.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,qBAAqB;EACvB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".about_tab_content{\r\n  margin:0px 11rem;\r\n}\r\n.gender_card{\r\n  border: 1px solid #dfdfdf;\r\n    border-radius: 30px;\r\n    padding: 10px 20px;\r\n    text-align: center;\r\n    background: #fff;\r\n}\r\n.about_tab_content .form-label{\r\n  color:#626262;\r\n  font-size: 14px;\r\n}\r\n.about_tab_content textarea{\r\n  border-radius: 30px;\r\n  color:#626262;\r\n  padding: 20px;\r\n}\r\n.about_tab_content .input-group{\r\n  width: auto;\r\n}\r\n.about_tab_content .input-group input{\r\n  padding: 12px;\r\n  border-radius: 30px;\r\n}\r\n.about_tab_content .about_input_sec{\r\n  display: inline-flex;\r\n}\r\n@media (max-width:1200px) {\r\n  .about_tab_content{\r\n    margin:0px 2rem;\r\n  }\r\n}\r\n@media (max-width:820px) {\r\n  .about_tab_content .about_input_sec{\r\n    display: inline-block;\r\n  }\r\n}\r\n@media (max-width:767px) {\r\n  .about_tab_content{\r\n    margin:0px 0rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
