import React, { useState } from "react";
import "./ProfileCreation.css";
import backArrowCircleSvg from "../../../../Images/icons/backArrow-circle.svg";
import About from "../AboutTab/AboutTab";
import Education from "../EducationTab/EducationTab";
import Experience from "../ExperienceTab/ExperienceTab";
import Skills from "../SkillsTab/SkillsTab";
import Courses from "../CourseTab/CourseTab";
import Portfolio from "../Portfolio/PortfolioTab";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";


const KYCCreation = () => <div>KYC Creation Section Content</div>;
const AIInterview = () => <div>AI Interview Section Content</div>;

const ProfileCreation = ({ handleBackArrow, userDetails, getUserDetails }) => {

  const userId = localStorage.getItem("userId");
  const { notify } = useToast();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [aboutData, setAboutData] = useState({});
  const [educationData, setEducationData] = useState({});
  const [expData, setExpData] = useState({});
  const [skillData, setSkillData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [portData, setPortData] = useState({});
  const [kycData, setkycData] = useState({});

  const [loading, setLoading] = useState(false);



  const tabs = [
    { label: "About", component: <About userDetails={userDetails} onGetData={(data) => setAboutData(data)} /> },
    { label: "Education", component: <Education userDetails={userDetails} onGetData={(data) => setEducationData(data)} /> },
    { label: "Experience", component: <Experience userDetails={userDetails} onGetData={(data) => setExpData(data)} /> },
    { label: "Skills", component: <Skills userDetails={userDetails} onGetData={(data) => setSkillData(data)} /> },
    { label: "Courses & Certifications", component: <Courses userDetails={userDetails} onGetData={(data) => setCourseData(data)} /> },
    { label: "Portfolio", component: <Portfolio userDetails={userDetails} onGetData={(data) => setPortData(data)} /> },
    { label: "KYC Creation", component: <KYCCreation /> },
    { label: "AI Interview", component: <AIInterview /> },

  ];

  const apiEndpoints = {
    About: `user/profile/about/${userId}`,
    Education: `user/profile/education/${userId}`,
    Experience: `user/profile/experience/${userId}`,
    Skills: `user/profile/skills/${userId}`,
    "Courses & Certifications": `user/${userId}/courses`,
    Portfolio:`user/${userId}/portfolio`,
    KYCCreation:`user/${userId}/portfolio`,


  };

  const handleSkip = () => {
    if (activeTabIndex < tabs.length - 1) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }

  const handleNextTab = async () => {
    setLoading(true);
    const currentTab = tabs[activeTabIndex].label;
    const endpoint = apiEndpoints[currentTab];

    let payload;

    console.log(currentTab,"currentTab", payload,"payload", endpoint,"endpoint");
    
    let role = null;
    switch (currentTab) {
      case "About":
        payload = aboutData; // Get the data from About
        break;
      case "Education":
        payload = educationData; // Get the data from Education
        break;
      case "Experience":
        payload = expData; // Get the data from Education
        role = 'freelancer';
        break;
      case "Skills":
        payload = skillData; // Get the data from Education
        break;
      case "Courses & Certifications":
        payload = courseData; // Get the data from Education
        break;
      case "Portfolio":
        payload = portData; // Get the data from Education
        break;
        case "KYCCreation":
          payload = kycData; // Get the data from Education
          break;
        

      // Add cases for other tabs
      default:
        payload = {};
    }

    try {

      // console.log(currentTab,"currentTab");
      
      const response = await ApiCall("PUT", endpoint, payload, "application/json", role);
      if (response.statusCode === 200) {

        // On successful API call, move to the next tab
        if (activeTabIndex < tabs.length - 1) {
          notify('success', "details updated successful!");
          setActiveTabIndex(activeTabIndex + 1);
          getUserDetails()
        }
      } else {
        console.error("API call failed:", response.message);
        notify('error', response.data[0].msg)
      }
    } catch (error) {
      console.log(error, "error", activeTabIndex, "activeTabIndex");

      console.error("Error during API call:", error);
      (activeTabIndex === 0)
        ?
        (notify('error', error?.response?.data.data[0]?.msg))
        :
        notify('error', error?.response?.data.errors[0]?.msg)
    }finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handlePrevTab = () => {
    if (activeTabIndex <= tabs.length - 1) {
      setActiveTabIndex(activeTabIndex - 1); // Go to the prev tab
    }
  };


  return (
    <>
    {loading && <Loader />}
      {/* First Row: Back Arrow */}
      <div className="back-arrow-row">
        <img
          src={backArrowCircleSvg}
          alt="Back Arrow"
          className="back-arrow-icon"
          onClick={(activeTabIndex === 0) ? handleBackArrow : handlePrevTab}
        />
      </div>

      {/* Tab Navigation */}
      <div className="tabs-row">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${index === activeTabIndex ? "active" : ""}`}
            onClick={() => setActiveTabIndex(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Render active tab content */}
      <div className="tab-content">{tabs[activeTabIndex].component}</div>

      <div className="text-end buttons-row">
        <button className="secondary-btn" onClick={handleSkip}>
          Skip
        </button>
        <button className="primary-btn ms-3" onClick={handleNextTab}>
          Save & Continue
        </button>
      </div>
    </>
  );
};

export default ProfileCreation;
