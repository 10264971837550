import React, { useState } from "react";
import About from "../AboutTab/AboutTab";
import Education from "../EducationTab/EducationTab";
import Experience from "../ExperienceTab/ExperienceTab";
import Skills from "../SkillsTab/SkillsTab";
import Courses from "../CourseTab/CourseTab";
import Portfolio from "../Portfolio/PortfolioTab";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { ReactComponent as BackArrowIcon } from "../../../../Images/freela-icons/backArrow-circle.svg";
import KYCCreation from "../KYCCreation/KYCCreation";
import AIInterview from "../AIInterview/AIInterview";

const ProfileCreation = ({ handleBackArrow, userDetails, getUserDetails }) => {
  const userId = localStorage.getItem("userId");
  const { notify } = useToast();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [aboutData, setAboutData] = useState({});
  const [educationData, setEducationData] = useState({});
  const [expData, setExpData] = useState({});
  const [skillData, setSkillData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [portData, setPortData] = useState({});
  const [kycData, setkycData] = useState({});

  const [loading, setLoading] = useState(false);
  const [tabErrors, setTabErrors] = useState(0);

  const handleNextTab = async () => {
    setLoading(true);
    const currentTab = tabs[activeTabIndex].label;
    const endpoint = apiEndpoints[currentTab];

    let payload;
    let role = null;

    switch (currentTab) {
      case "About":
        payload = aboutData;
        break;
      case "Education":
        payload = educationData;
        break;
      case "Experience":
        payload = expData;
        role = "freelancer";
        break;
      case "Skills":
        payload = skillData;
        break;
      case "Courses & Certifications":
        payload = courseData;
        break;
      case "Portfolio":
        payload = portData;
        break;
      case "KYCCreation":
        payload = kycData;
        break;
      default:
        payload = {};
    }

    try {
      const response = await ApiCall("PUT", endpoint, payload, "application/json", role);
      if (response.statusCode === 200) {
              if (activeTabIndex < tabs.length - 1) {
          notify("success", "Details updated successfully!");
          setActiveTabIndex(activeTabIndex + 1);
          getUserDetails();
        }
      } else {
        notify("error", response.data[0].msg);
      }
    } catch (error) {
      notify("error", error?.response?.data?.errors[0]?.msg);
    } finally {
      setLoading(false);
    }
};

  const tabs = [
    { label: "About", component: <About userDetails={userDetails} onGetData={(data) => setAboutData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "Education", component: <Education userDetails={userDetails} onGetData={(data) => setEducationData(data)} onGetErrData={(data) => setTabErrors(data)} /> },
    { label: "Experience", component: <Experience userDetails={userDetails} onGetData={(data) => setExpData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "Skills", component: <Skills userDetails={userDetails} onGetData={(data) => setSkillData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "Courses & Certifications", component: <Courses userDetails={userDetails} onGetData={(data) => setCourseData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "Portfolio", component: <Portfolio userDetails={userDetails} onGetData={(data) => setPortData(data)} onGetErrData={(data) => setTabErrors(data)}/> },
    { label: "KYC Creation", component: <KYCCreation /> },
    { label: "AI Interview", component: <AIInterview /> }
  ];

  const apiEndpoints = {
    About: `user/profile/about/${userId}`,
    Education: `user/profile/education/${userId}`,
    Experience: `user/profile/experience/${userId}`,
    Skills: `user/profile/skills/${userId}`,
    "Courses & Certifications": `user/${userId}/courses`,
    Portfolio: `user/${userId}/portfolio`,
    KYCCreation: `user/${userId}/portfolio`,


  };

  const handleSkip = () => {
    if (activeTabIndex < tabs.length - 1) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }

  const handlePrevTab = () => {
    if (activeTabIndex <= tabs.length - 1) {
      setActiveTabIndex(activeTabIndex - 1); // Go to the prev tab
    }
  };



  return (
    <>
      {loading && <Loader />}
      <section className="card profile h-100 rounded-5">
        <Card.Header className="bg-transparent border-0 p-0">
          <Row className="mt-3">
            <Col xs={12} className="mb-2">
              <Button variant="link p-0 mx-3" onClick={(activeTabIndex === 0) ? handleBackArrow : handlePrevTab}><BackArrowIcon width={45} height={45} /></Button>
            </Col>
            <Col xs={12}>
              <div className="freela_horizontal_tabs">
                <div className="tab_lists justify-content-lg-center px-4">
                  {tabs.map((tab, index) => (
                    <Button variant="link px-1"
                      key={index}
                      className={`tab-button ${index === activeTabIndex && "active"}`}
                      onClick={() => setActiveTabIndex(index)}
                    >
                      {tab.label}
                    </Button>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="gy-4">
            <Col xs={12}>
              <div className="tab-content">{tabs[activeTabIndex].component}</div>
            </Col>
            <Col xs={12}>
              <Stack direction="horizontal" className="justify-content-end gap-3">
                {activeTabIndex !== 7 && <Button variant="" className="freela-btn-primary-outline" onClick={handleSkip}>Skip</Button>}
                <Button className="freela-btn-primary" variant="" onClick={handleNextTab} disabled={tabErrors > 0}>Save &amp; Continue</Button>
              </Stack>
            </Col>
          </Row>
        </Card.Body>
      </section>
    </>
  );
};

export default ProfileCreation;
