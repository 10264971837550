import { useState } from "react";
import MyProposals from "./Tabs/MyProposals";
import MyTasks from "./Tabs/MyTasks";
import MyWorkspace from "./Tabs/MyWorkspace";
import MyCalendar from "./Tabs/MyCalendar";
import MyProject from "./Tabs/MyProject";
import { Button } from "react-bootstrap";

const ClientView = ({ counts }) => {
  const [activeTab, setActiveTab] = useState("myProjects");
  const [getIds, setGetIds] = useState({
    projectId: "",
    clientId: "",
    freelancerId: "",
  });

  const renderTabContent = () => {
    switch (activeTab) {
      case "myProjects":
        return <MyProject role="client" counts={counts}/>;
      case "myProposals":
        return <MyProposals role="client" />;
      case "myTasks":
        return <MyTasks role="client" setActiveTab={setActiveTab} setGetIds={setGetIds} />;
      case "myWorkspace":
        return <MyWorkspace role="client" getIds={getIds}/>;
      case "myCalendar":
        return <MyCalendar role="client" />;
      default:
        return null;
    }
  };

  return (
    <div className="freela_horizontal_tabs mt-2">
      <div className="tab_lists px-4">
        {[
          "myProjects",
          "myProposals",
          "myTasks",
          "myWorkspace",
          "myCalendar",
        ].map((type, idx) => (
          <Button
            key={idx}
            onClick={() => setActiveTab(type)}
            variant=""
            className={activeTab === type && "active"}
          >
            {type === "myProjects"
              ? `My Projects  (${
                  (counts.msgCount || 0) +
                  counts.draftCount +
                  counts.finalCount +
                  (counts.savedCount || 0)
                })`
              : type === "myProposals"
              ? `My Proposals (${counts.bidCount})`
              : type === "myTasks"
              ? "My Tasks"
              : type === "myWorkspace"
              ? "My Workspace"
              : type === "myCalendar"
              ? "My Calendar"
              : ""}
          </Button>
        ))}
      </div>
      <div className="tab_content p-4">{renderTabContent()}</div>
    </div>
  );
};

export default ClientView;
