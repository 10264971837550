import React, { useRef, useState } from "react";
import { Row, Col, Card, Stack, Button, Figure, Form } from "react-bootstrap";
import { ReactComponent as AirpalnIcon } from "../../../../Images/freela-icons/airplan-icon.svg";
import { ReactComponent as PdfIcon } from "../../../../Images/freela-icons/pdf-icon.svg";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";

const Dispute = ({ projectId }) => {
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();
  const [formData, setFormData] = useState({
    selectedCategory: "",
    reason: "",
    certificateFile: "", 
  });
  const [certificateFile, setCertificateFile] = useState(null);
  const [error, setError] = useState("");
  const certiInputRef = useRef(null);

  const resetValues =()=>{
    setFormData({
        selectedCategory: "",
        reason: "",
        certificateFile: "", 
      })
      setCertificateFile(null)
      setError("")
  }

  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Dynamically update selectedCategory or reason
    }));
   
  };

  const handleUpdateBid = async () => {
    setLoading(true);

    try {
      const payload = {
        projectId: projectId,
        projectStatus: "Dispute",
        bidStage: "Dispute",
        project_Status_Reason: formData?.reason,
        dispute_Url: formData?.certificateFile,
        dispute_Category: formData?.selectedCategory,
      };
      const response = await ApiCall(
        "POST",
        "project/updateProjectActivity",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        // console.log("successful:", response.data);
        notify("success", "You have successfully raised a dispute");
        resetValues()
      } else {
        notify(
          "error",
          response.message || "Proposal failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    setLoading(true);

    const file = e.target.files[0];
    if (!file) return;

    if (field === "certificateFile") {
      if (file.type !== "application/pdf") {
        setError("Only PDF files are allowed");
        setPhoto(null);
        setLoading(false);
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError("File size must be less than 5MB");
        setPhoto(null);
        setLoading(false);
        return;
      }

      setError("");
    }

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await ApiCall(
        "POST",
        `user/fileUpload`,
        formData,
        "multipart/form-data"
      );

      if (response.statusCode === 200) {
        // Handle successful file upload response
        setPhoto(file);
        setFormData((prevState) => ({
          ...prevState,
          [field]: response.data.s3Url, // Update the correct field dynamically
        }));
        if (!certificateFile) setError("Please upload your resume");
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = () => {
    if (certificateFile) {
      const fileURL = URL.createObjectURL(certificateFile);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = certificateFile.name;
      link.click();
      URL.revokeObjectURL(fileURL); // Clean up the object URL
    }
  };

  const handleDelete = (e) => {
    // console.log("handleDelete", e);

    setCertificateFile(null); // Remove the uploaded file from state
    setFormData((prevState) => ({
      ...prevState,
      certificateFile: "", // Reset only the certificateFile field
    }));
  };
  return (
    <>
      {loading && <Loader />}
      <Row className="px-lg-5 mx-lg-3">
        <Col xs={12}>
          <div className="text-center mb-5">
            <h5>Raise Dispute</h5>
            <p className="freela-text-greaseblack">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Similique, provident officiis alias suscipit
            </p>
          </div>
          <Form>
            <Form.Group className="mb-3" controlId="selectCategory">
              <Form.Label>Select Category</Form.Label>
              <Form.Select
                name="selectedCategory"
                value={formData.selectedCategory}
                onChange={handleChange}
              >
                <option value="">Open this select menu</option>
                <option value="Work Quality Disputes">
                  Work Quality Disputes
                </option>
                <option value="Deadline & Delivery Disputes">
                  Deadline & Delivery Disputes
                </option>
                <option value="Contract & Scope Disputes">
                  Contract & Scope Disputes
                </option>
                <option value="Communication & Professionalism">
                  Communication & Professionalism
                </option>
                <option value="Refund & Cancellation Disputes">
                  Refund & Cancellation Disputes
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                name="reason"
                placeholder="Write a message here"
                rows={5}
                value={formData.reason}
                onChange={handleChange}
              />
            </Form.Group>
            <Stack direction="horizontal" className="gap-3 mb-3">
              <Form.Group className="mb-3">
                <Form.Control
                  type="file"
                  accept=".pdf,.docx"
                  className="d-none"
                  ref={certiInputRef}
                  onChange={(e) =>
                    handlePhotoChange(e, setCertificateFile, "certificateFile")
                  }
                />
                <Button
                  variant=""
                  className="freela-btn-primary-outline btn-outline-rounded"
                  onClick={() => handleUploadClick(certiInputRef)}
                >
                  <AirpalnIcon width={16} />
                </Button>
              </Form.Group>
              {certificateFile && <Card className="freela-bg-greylight9 mb-3 freela-border-greylight10 d-flex p-2 flex-row gap-4 rounded-4">
                <Figure className="d-flex gap-2 mb-0">
                  <PdfIcon width={35} height={35} />
                  <Figure.Caption className="file-fetails">
                    <p className="file-title text-dark mb-0 w-75 text-truncate">
                      {certificateFile?.name}
                    </p>
                    <p className="small mb-0">
                      {" "}
                      {certificateFile?.size} KB -{" "}
                      {new Date(
                        certificateFile?.lastModified
                      ).toLocaleDateString()}
                    </p>
                  </Figure.Caption>
                </Figure>
                <Stack direction="horizontal" className="ms-auto ms-xl-5 gap-2">
                  <Button
                    variant="link p-0 freela-greaseblack-text"
                    onClick={() => handleDownload()}
                  >
                    <i className="bi bi-download" />
                  </Button>
                  <Button
                    variant="link p-0 freela-danger-text"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                  >
                    <i className="bi bi-trash3" />
                  </Button>
                </Stack>
              </Card>}
            </Stack>
            <Form.Group className="text-center mb-3">
              <Button
                variant=""
                className="freela-btn-primary px-5"
                onClick={() => handleUpdateBid()}
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Dispute;
