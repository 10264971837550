import { useState } from "react";

const MyService = ({ role }) => {
  const [activeButton, setActiveButton] = useState("myMessages");

  const renderContent = () => {
    switch (activeButton) {
      case "myMessages":
        return <div>{role} Message Box Content</div>;
      case "published":
        return <div>{role} Published Cards Content</div>;
      case "drafts":
        return <div>{role} Draft Boxes Content</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="button-group">
        <button onClick={() => setActiveButton("myMessages")}>My Messages</button>
        <button onClick={() => setActiveButton("published")}>Published</button>
        <button onClick={() => setActiveButton("drafts")}>Drafts</button>
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default MyService;
