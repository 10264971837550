import React, { useState } from "react";
import FreelancerImgSelect from "../../Images/freela-images/freelancer_img_filled.png";
import FreelancerImg from "../../Images/freela-images/freelancer_img.png";
import ClientImgSelect from "../../Images/freela-images/client_img_filled.png";
import ClientImg from "../../Images/freela-images/client_img.png";
import { ReactComponent as BgBubbles } from '../../Images/freela-icons/modal-bg-bubbles.svg'
import { Row, Col, Image, Button, Stack } from "react-bootstrap";


const ServiceModal = ({ onClose, onRoleSelect }) => {
  const [selectedRole, setSelectedRole] = useState(null); // Store the selected role
  // console.log(selectedRole,"selectedRole");

  const handleContinue = () => {
    if (selectedRole) {
      onRoleSelect(selectedRole); // Send the selected role
    }
    onClose(); // Close the modal
  };

  return (
    <section className="position-relative">
      <BgBubbles className="position-absolute top-0 start-0 w-100" />
      <Row className="gy-3 mt-4 position-relative">
        <Col xs={12} className="text-center px-5">
          <div className="header_modal_content px-5">
            <h5>Post Your Service</h5>
            <p> A mini game competition for the top <span className="freela-text-primary">Freela</span> profile.<br /> Level up and secure more projects!</p>
          </div>
        </Col>
        <Col xs={12}>
          <Stack direction="horizontal" className="gap-1 mx-5 mb-4 justify-content-center">
            <div className="freelancer_content text-center">
              <Button variant="link" onClick={() => setSelectedRole("freelancer")}>
                {selectedRole === "freelancer" ?
                  <Image src={FreelancerImgSelect} width={140} height={140} alt="FreelancerImg" />
                  :
                  <Image src={FreelancerImg} width={130} height={130} alt="FreelancerImg" />
                }
              </Button>
              <h5>Freelancer</h5>
            </div>
            <div className="client_content text-center">
              <Button variant="link" onClick={() => setSelectedRole("client")}>
                {selectedRole === "client" ?
                  <Image src={ClientImgSelect} width={140} height={140} alt="ClientImg" />
                  :
                  <Image src={ClientImg} width={130} height={130} alt="ClientImg" />
                }
              </Button>
              <h5>Client</h5>
            </div>
          </Stack>
        </Col>
        <Col xs={12} className="text-center">
          <Button variant="" className="freela-btn-primary" onClick={handleContinue} disabled={!selectedRole}>
            Continue
          </Button>
        </Col>
      </Row>
    </section>
  );
};
export default ServiceModal;
