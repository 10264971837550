import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Stack } from "react-bootstrap";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import { calculateRemainingDays } from "../../../Common/utils/utils";

const MyTasks = ({ role, setActiveTab, setGetIds }) => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const [inprogressDetails, setInprogressDetails] = useState([]);
  const [completedDetails, setCompletedDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const debouncedGetActiveRoleDetails = debounce(() => {
    getProposalDetailsByStage();
    getInprogressDetails();
    getCompletedDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  // console.log(role, "role in MyTasks");

  const getProposalDetailsByStage = async (stage) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `bid/filterByStatus?freelancerId=${userId}&stage=Accepted`
          : `bid/filterByStatus?clientId=${userId}&stage=Accepted`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const getInprogressDetails = async () => {
    setLoading(true);
    setInprogressDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `project/filterByStatus?assigned_userId=${userId}&projectStatus=InProgress`
          : `project/filterByStatus?userId=${userId}&projectStatus=InProgress`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setInprogressDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const getCompletedDetails = async () => {
    setLoading(true);
    setCompletedDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `project/filterByStatus?assigned_userId=${userId}&projectStatus=Completed`
          : `project/filterByStatus?userId=${userId}&projectStatus=Completed`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setCompletedDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBid = async (projectId) => {
    setLoading(true);

    try {
      const payload = {
        projectId: projectId,
        projectStatus: "InProgress",
        bidStage: "Work-in-Progress",
      };
      const response = await ApiCall(
        "POST",
        "project/updateProjectActivity",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        // console.log("successful:", response.data);
        notify("success", "You have started the project");
        getProposalDetailsByStage();
        getInprogressDetails();
      } else {
        notify(
          "error",
          response.message || "Proposal failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Row className="gy-4">
        <Col lg={4} xl={4}>
          <Card
            as={Card.Body}
            className="rounded-5 h-100 freela-bg-greylight12 freela-border-greylight8"
          >
            <Card.Header className="px-0 pt-0 mb-3 d-flex flex-nowrap align-items-center justify-content-between bg-transparent border-bottom border-2 freela-border-blue">
              <div className="d-flex align-items-center gap-2">
                <span class="badge rounded-circle freela-bg-blue p-1 w-10px h-10px">
                  <span class="visually-hidden">unread messages</span>
                </span>
                <Card.Title as="h6" className="mb-0">
                  Todo
                </Card.Title>
                <span className="count freela-bg-greylight11 p-1 rounded-circle w-25px h-25px d-flex align-items-center justify-content-center">
                  {activeRoleDetails.length}
                </span>
              </div>
              <Button variant="link p-0 border-0 shadow-none">
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    d="M21.5875 3.10547H10.4998C5.68366 3.10547 2.8125 5.97663 2.8125 10.7928V21.8673C2.8125 26.6966 5.68366 29.5678 10.4998 29.5678H21.5743C26.3904 29.5678 29.2616 26.6966 29.2616 21.8805V10.7928C29.2748 5.97663 26.4037 3.10547 21.5875 3.10547Z"
                    fill="#5030E5"
                  />
                  <path
                    d="M21.3361 15.3472H17.0359V11.047C17.0359 10.5045 16.5861 10.0547 16.0436 10.0547C15.5011 10.0547 15.0513 10.5045 15.0513 11.047V15.3472H10.7511C10.2086 15.3472 9.75879 15.797 9.75879 16.3395C9.75879 16.882 10.2086 17.3318 10.7511 17.3318H15.0513V21.632C15.0513 22.1744 15.5011 22.6243 16.0436 22.6243C16.5861 22.6243 17.0359 22.1744 17.0359 21.632V17.3318H21.3361C21.8785 17.3318 22.3284 16.882 22.3284 16.3395C22.3284 15.797 21.8785 15.3472 21.3361 15.3472Z"
                    fill="#5030E5"
                  />
                </svg>
              </Button>
            </Card.Header>
            <Stack direction="vertical" className="gap-3">
              {activeRoleDetails && activeRoleDetails.length > 0 ? (
                activeRoleDetails.map((item, index) => (
                  <Card
                    as={Card.Body}
                    key={index}
                    className="p-3 rounded-4 freela-border-blue-200"
                  >
                    <Card.Header className="p-0 mb-2 d-flex flex-nowrap align-items-center justify-content-between bg-transparent border-0">
                      <Card.Title className="freela-fs-16px text-truncate mb-0">
                        {item.title}
                      </Card.Title>
                      <div className="d-flex gap-1 flex-nowrap align-items-center">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="d-inline-block"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.25 4H9.75C6.56802 4 4.97703 4 3.98851 5.00421C3 6.00841 3 7.62465 3 10.8571V13.1429C3 16.3753 3 17.9916 3.98851 18.9958C4.97703 20 6.56802 20 9.75 20H14.25C17.432 20 19.023 20 20.0115 18.9958C21 17.9916 21 16.3753 21 13.1429V10.8571C21 7.62465 21 6.00841 20.0115 5.00421C19.023 4 17.432 4 14.25 4Z"
                            stroke="#F0806C"
                          />
                          <path
                            d="M7 8H10"
                            stroke="#F0806C"
                            stroke-linecap="round"
                          />
                          <path
                            d="M19 16H17C16.0572 16 15.5858 16 15.2929 15.7071C15 15.4142 15 14.9428 15 14C15 13.0572 15 12.5858 15.2929 12.2929C15.5858 12 16.0572 12 17 12H19C19.9428 12 20.4142 12 20.7071 12.2929C21 12.5858 21 13.0572 21 14C21 14.9428 21 15.4142 20.7071 15.7071C20.4142 16 19.9428 16 19 16Z"
                            stroke="#F0806C"
                          />
                        </svg>
                        <div className="amount freela-text-primary w-25px h-25px d-flex align-items-center justify-content-center">
                          ${item.bidAmount}
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Text className="text_truncate2">
                      {item.message}
                    </Card.Text>
                    <Stack direction="horizontal" className="gap-3 mb-2">
                      <Card.Text className="freela-fs-14px mb-0">
                        Time To Complete
                      </Card.Text>
                      <Card.Text className="freela-fs-14px mb-0 ms-auto">
                        <i className="bi bi-clock" />{" "}
                        {calculateRemainingDays(item.projectDueDate)} Days
                        Delivery
                      </Card.Text>
                    </Stack>
                    <Stack direction="horizontal" className="gap-3">
                      <Button
                        variant="link p-0"
                        className="freela-fs-14px freela-text-primary text-decoration-none mb-0 ms-auto"
                        onClick={() => handleUpdateBid(item.projectId)}
                      >
                        Start Activity
                      </Button>
                    </Stack>
                  </Card>
                ))
              ) : (
                <Card
                  as={Card.Body}
                  className="freela-border-blue-200 rounded-4 text-center justify-content-center"
                >
                  <div className="no-data">No Todos available</div>
                </Card>
              )}
            </Stack>
          </Card>
        </Col>
        <Col lg={4} xl={4}>
          <Card
            as={Card.Body}
            className="rounded-5 h-100 freela-bg-greylight12 freela-border-greylight8"
          >
            <Card.Header className="px-0 pt-0 mb-3 d-flex flex-nowrap align-items-center justify-content-between bg-transparent border-bottom border-2 freela-border-warning">
              <div className="d-flex align-items-center gap-2">
                <span class="badge rounded-circle freela-bg-warning p-1 w-10px h-10px">
                  <span class="visually-hidden">unread messages</span>
                </span>
                <Card.Title as="h6" className="mb-0">
                  In Progress
                </Card.Title>
                <span className="count freela-bg-greylight11 p-1 rounded-circle w-25px h-25px d-flex align-items-center justify-content-center">
                  {inprogressDetails.length}
                </span>
              </div>
            </Card.Header>
            <Stack direction="vertical" className="gap-3">
              {inprogressDetails && inprogressDetails.length > 0 ? (
                inprogressDetails.map((item, index) => (
                  <Card
                    as={Card.Body}
                    key={index}
                    className="p-3 rounded-4 freela-border-warning-200"
                  >
                    <Card.Header className="p-0 mb-2 d-flex flex-nowrap align-items-center justify-content-between bg-transparent border-0">
                      <Card.Title className="freela-fs-16px text-truncate mb-0">
                        {item.title}
                      </Card.Title>
                      <div className="d-flex gap-1 flex-nowrap align-items-center">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="d-inline-block"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.25 4H9.75C6.56802 4 4.97703 4 3.98851 5.00421C3 6.00841 3 7.62465 3 10.8571V13.1429C3 16.3753 3 17.9916 3.98851 18.9958C4.97703 20 6.56802 20 9.75 20H14.25C17.432 20 19.023 20 20.0115 18.9958C21 17.9916 21 16.3753 21 13.1429V10.8571C21 7.62465 21 6.00841 20.0115 5.00421C19.023 4 17.432 4 14.25 4Z"
                            stroke="#F0806C"
                          />
                          <path
                            d="M7 8H10"
                            stroke="#F0806C"
                            stroke-linecap="round"
                          />
                          <path
                            d="M19 16H17C16.0572 16 15.5858 16 15.2929 15.7071C15 15.4142 15 14.9428 15 14C15 13.0572 15 12.5858 15.2929 12.2929C15.5858 12 16.0572 12 17 12H19C19.9428 12 20.4142 12 20.7071 12.2929C21 12.5858 21 13.0572 21 14C21 14.9428 21 15.4142 20.7071 15.7071C20.4142 16 19.9428 16 19 16Z"
                            stroke="#F0806C"
                          />
                        </svg>
                        <div className="amount freela-text-primary">
                          ${item.amountforFreelancer}
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Text className="text_truncate2">
                      {item.description}
                    </Card.Text>
                    <Stack direction="horizontal" className="gap-3 mb-2">
                      <Card.Text className="freela-fs-14px mb-0">
                        Deadline
                      </Card.Text>
                      <Card.Text className="freela-fs-14px mb-0 ms-auto">
                        <i className="bi bi-clock" />{" "}
                        {calculateRemainingDays(item.projectDueDate)} Days
                        Delivery
                      </Card.Text>
                    </Stack>
                    <Stack direction="horizontal" className="gap-3">
                      <div className="icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                            stroke="#626262"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
                            stroke="#626262"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 13H12"
                            stroke="#626262"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 17H16"
                            stroke="#626262"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <Button
                        variant="link p-0"
                        onClick={() => {
                          setActiveTab("myWorkspace");
                          setGetIds({
                            projectId: item.projectId, 
                            clientId: item.userId  ,  
                            freelancerId: item.assigned_userId  
                          });
                        }}
                        className="freela-fs-14px mb-0 ms-auto fw-500"
                      >
                        View Workspace
                      </Button>
                    </Stack>
                  </Card>
                ))
              ) : (
                <Card
                  as={Card.Body}
                  className="freela-border-warning-200 rounded-4 text-center justify-content-center"
                >
                  <div>No In Progress available</div>
                </Card>
              )}
            </Stack>
          </Card>
        </Col>
        <Col lg={4} xl={4}>
          <Card
            as={Card.Body}
            className="rounded-5 h-100 freela-bg-greylight12 freela-border-greylight8"
          >
            <Card.Header className="px-0 pt-0 mb-3 d-flex flex-nowrap align-items-center justify-content-between bg-transparent border-bottom border-2 freela-border-success">
              <div className="d-flex align-items-center gap-2">
                <span class="badge rounded-circle freela-bg-success p-1 w-10px h-10px">
                  <span class="visually-hidden">unread messages</span>
                </span>
                <Card.Title as="h6" className="mb-0">
                  Done
                </Card.Title>
                <span className="count freela-bg-greylight11 p-1 rounded-circle w-25px h-25px d-flex align-items-center justify-content-center">
                  {completedDetails.length}
                </span>
              </div>
            </Card.Header>
            <Stack direction="vertical" className="gap-3">
              {completedDetails && completedDetails.length > 0 ? (
                completedDetails.map((item, index) => (
                  <Card
                    as={Card.Body}
                    className="p-3 rounded-4 freela-border-success-200"
                    key={index}
                  >
                    <Card.Header className="p-0 mb-2 d-flex flex-nowrap align-items-center justify-content-between bg-transparent border-0">
                      <Card.Title className="freela-fs-16px text-truncate mb-0">
                        {item.title}
                      </Card.Title>
                      <div className="d-flex gap-1 flex-nowrap align-items-center">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="d-inline-block"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.25 4H9.75C6.56802 4 4.97703 4 3.98851 5.00421C3 6.00841 3 7.62465 3 10.8571V13.1429C3 16.3753 3 17.9916 3.98851 18.9958C4.97703 20 6.56802 20 9.75 20H14.25C17.432 20 19.023 20 20.0115 18.9958C21 17.9916 21 16.3753 21 13.1429V10.8571C21 7.62465 21 6.00841 20.0115 5.00421C19.023 4 17.432 4 14.25 4Z"
                            stroke="#F0806C"
                          />
                          <path
                            d="M7 8H10"
                            stroke="#F0806C"
                            stroke-linecap="round"
                          />
                          <path
                            d="M19 16H17C16.0572 16 15.5858 16 15.2929 15.7071C15 15.4142 15 14.9428 15 14C15 13.0572 15 12.5858 15.2929 12.2929C15.5858 12 16.0572 12 17 12H19C19.9428 12 20.4142 12 20.7071 12.2929C21 12.5858 21 13.0572 21 14C21 14.9428 21 15.4142 20.7071 15.7071C20.4142 16 19.9428 16 19 16Z"
                            stroke="#F0806C"
                          />
                        </svg>
                        <div className="amount freela-text-primary">
                          ${item.amountforFreelancer}
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Text className="text_truncate2">
                      {item.description}
                    </Card.Text>
                    <Card.Subtitle className="freela-fs-14px freela-text-success fw-500">
                      Completed
                    </Card.Subtitle>
                  </Card>
                ))
              ) : (
                <Card
                  as={Card.Body}
                  className="freela-border-warning-200 rounded-4 text-center justify-content-center"
                >
                  <div className="no-data">No Completed Records available</div>
                </Card>
              )}
            </Stack>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyTasks;
