import React, { useState, useRef, useEffect } from "react";

import closeIcon from "../../../Images/icons/close-x.svg";
import uploadIconSvg from "../../../Images/icons/upload.svg";
import imgUploadIconSvg from "../../../Images/icons/imgUploadIconSvg.svg";

import playBtn from "../../../Images/play_btn.png";

import { Row, Col, Card, Form } from "react-bootstrap";
import "./CreateService.css";
import ApiCall from "../../../Api/ApiCall";

const 
CreateService = ({ onSave, updatePayloadData, serviceData }) => {
  const [payloadData, setPayloadData] = useState({
    serviceName: "",
    description: "",
    categoryId: "",
    category: "",
    subCategory: "",
    searchTags: "",
    searchTagsList: [],
    video: null,
    images: [],
  });
  const [searchTagsList, setSearchTagsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [showUploadCards, setShowUploadCards] = useState([true]);

  // console.log(serviceData, "serviceData  in create service", payloadData,"payloadData in create service");

  useEffect(() => {
    // Initialize payloadData with existing serviceData when the component mounts
    setPayloadData((prevState) => ({
      ...prevState,
      ...serviceData, // Merge existing serviceData into payloadData
    }));
  }, []);

  useEffect(() => {
    setPayloadData((prevState) => ({
      ...prevState,
      searchTagsList: searchTagsList,
    }));
  }, [searchTagsList]);

  useEffect(() => {
    updatePayloadData(payloadData);
  }, [payloadData]);

  const handleAddUploadCard = () => {
    if (showUploadCards.length < 6) {
      setShowUploadCards((prev) => [...prev, true]);
    }
  };

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Create a preview URL
        const previewUrl = URL.createObjectURL(file);

        // Optionally upload the file to the server (using API call)
        const formData = new FormData();
        formData.append("file", file);
        const response = await ApiCall(
          "POST",
          "user/fileUpload",
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          const s3Url = response.data.s3Url;

          setPayloadData((prevData) => ({
            ...prevData,
            images: [...prevData.images, s3Url], // Append the new image URL to the images array
          }));

          // Remove the upload card after successful upload
          setShowUploadCards((prev) => {
            const newCards = [...prev];
            newCards[index] = false;
            return newCards;
          });
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const getCategories = async () => {
    setLoading(true);

    try {
      const response = await ApiCall(
        "GET",
        "metadata/getCategories",
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setCategoriesList(response.data);
      } else {
        console.error("Failed to fetch categories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching categories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getSubCategories = async (categoryId) => {
    setLoading(true);
    try {
      const response = await ApiCall(
        "GET",
        `metadata/${categoryId}/subcategories`,
        null,
        "application/json"
      );
      if (response.statusCode === 200) {
        setSubCategoriesList(response.data);
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching subcategories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payloadData.categoryId) {
      getSubCategories(payloadData.categoryId);
    } else {
      setSubCategoriesList([]);
      setPayloadData((prevData) => ({ ...prevData, subCategory: prevData.subCategory || "" }));
    }
  }, [payloadData.category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayloadData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlesearchTagsChange = (e) => {
    setPayloadData({ ...payloadData, searchTags: e.target.value });
  };

  const handleDeleteSearchTags = (index) => {
    setSearchTagsList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    const trimmedTag = payloadData.searchTags.trim();

    if (e.key === "Enter" && trimmedTag) {
      e.preventDefault();

      if (!searchTagsList.includes(trimmedTag)) {
        setSearchTagsList((prev) => [...prev, trimmedTag]);
      }

      setPayloadData({ ...payloadData, searchTags: "" });
    }
  };

  const handleChooseClick = () => {
    console.log("handleChooseClick");

    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("handleFileChange", file, "file");
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const playURL = URL.createObjectURL(file);
        setVideoSrc(playURL);
        setIsPlaying(false); // Reset play state

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // const playURL = URL.createObjectURL(file);
          // setVideoSrc(playURL);
          // setIsPlaying(false); // Reset play state

          setPayloadData((prevData) => ({
            ...prevData,
            video: response.data.s3Url,
          }));
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false); // Hide loading state after upload attempt
      }
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <div className="service-tilte">
        <div>
        <h2>Create Service</h2>
        </div>
        <div className="row-service">
          <div className="input-group">
            <label htmlFor="serviceName">
              Service Name<sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              id="serviceName"
              name="serviceName"
              value={payloadData?.serviceName}
              onChange={handleChange}
              placeholder="Enter your service name here"
              maxLength="150"
            />
            <div className="character-count text-end">
              {payloadData.serviceName.length}/150 characters
            </div>
          </div>
        </div>
      </div>

      <div className="row-service">
        <div className="input-group">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            value={payloadData?.description}
            onChange={handleChange}
            type="text"
            placeholder="Write something about you"
          ></textarea>
          {/* <ReactQuill
            value={payloadData.description}
            onChange={handleDescriptionChange}
            placeholder="Write something about you"
            modules={{
              toolbar: [
                ["bold", "italic", "underline"],
                [{ align: ["", "center"] }],
                [{ list: "ordered" }],
              ],
            }}
            className="custom-quill-editor"
          /> */}
        </div>
      </div>
      <div className="des-character-count text-end">
        {payloadData.description.length}/5000 characters
      </div>

      <div className="row-service">
        <div className="input-group">
          <label htmlFor="category">Select Category<sup className="text-danger">*</sup></label>
          <div className="select-wrapper">
            {/* <select
              id="category"
              value={payloadData.category}
              onChange={(e) =>
                setPayloadData({ ...payloadData, category: e.target.value })
              }
              required
              disabled={loading}
            > */}

              <Form.Select
              id="category"
              value={payloadData.categoryId}
              onChange={(e) => {
                const selectedCategory = categoriesList.find(
                  (category) => category._id === e.target.value
                );
                setPayloadData({
                  ...payloadData,
                  categoryId: selectedCategory._id,
                  category: selectedCategory.categoryName,
                });
              }}
              required
              disabled={loading}
            >
              <option value="" disabled>
                {loading ? "Loading categories..." : "Select Category"}
              </option>
              {categoriesList.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.categoryName}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="subCategory">Select Sub Category<sup className="text-danger">*</sup></label>
          <div className="select-wrapper">
            <Form.Select
              id="subCategory"
              value={payloadData.subCategory}
              onChange={(e) =>
                setPayloadData({ ...payloadData, subCategory: e.target.value })
              }
              required
              disabled={loading || !payloadData.category}
            >
              <option value="" disabled>
                {loading ? "Loading subcategories..." : "Select Sub Category"}
              </option>
              {subCategoriesList.map((subCategory, index) => (
                <option key={index} value={subCategory}>
                  {subCategory}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>

      <div className="row-service">
        <div className="input-group">
          <label htmlFor="search-tags">
            Search Tags<sup className="text-danger">*</sup>
          </label>

          <div className="tags-input-group">
            <input
              type="text"
              id="search-tags"
              placeholder="Search Tags"
              value={payloadData.searchTags}
              onChange={handlesearchTagsChange}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="tagList">
            <ul>
              {searchTagsList.map((tag, index) => (
                <li key={index}>
                  <span>{tag}</span>
                  <span onClick={() => handleDeleteSearchTags(index)}>
                    <img
                      src={closeIcon}
                      alt="Delete Icon"
                      className="delete-icon"
                    />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="row-service">
        <div className="img-vid">
          <div className="upload-title">
            Upload Video and Images <sup className="text-danger">*</sup>
          </div>

          <div className="instruction">
            Your service must have a video or at least 1 image.
          </div>

          <div className="file-size-note">
            Video file size must be less than 50 MB.
          </div>
        </div>
      </div>

      <div className="uploaded_img">
        <Row>
          <Col xs={12} className="mt-3">
            <div className="upload-video-box video-upload">
              {videoSrc ? (
                <div className="video-preview position-relative">
                  {/* Video Preview */}
                  <video
                    ref={videoRef}
                    src={videoSrc}
                    className="w-100" // Adjust size as needed, full width of the container
                    onClick={handlePlayPause} // Toggle play/pause on click
                    onEnded={() => setIsPlaying(false)} // Reset play state when video ends
                    style={{ cursor: "pointer" }}
                    controls={false} // Hide default controls
                  />
                  {/* Play/Pause Button */}
                  <button
                    onClick={handlePlayPause}
                    className="play-button position-absolute"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    <div className="play_btn">
                      <img
                        src={isPlaying ? "pauseIcon" : playBtn}
                        alt={isPlaying ? "Pause" : "Play"}
                      />
                    </div>
                  </button>
                </div>
              ) : (
                <>
                  <div className="upload-service text-center">
                    <img src={uploadIconSvg} alt="Upload Icon"  />
                  </div>
                  <p className="text-center mb-0">Please upload your Video</p>
                  <button
                    className="choose-button-middle"
                    onClick={handleChooseClick}
                  >
                    Choose
                  </button>
                  {/* Hidden Input for File Selection */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept="video/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {/* Render Uploaded Images */}

          {payloadData.images.map((imageUrl, index) => (
            <Col xs={12} md={4} className="mt-3" key={`uploaded-${index}`}>
              <img src={imageUrl} alt={`uploaded-${index}`} className="w-100  uploaded_image" />
            </Col>
          ))}

          {/* Render Upload Cards */}
          {showUploadCards.map((show, index) =>
            show ? (
              <Col
                sm={12}
                md={6}
                lg={4}
                key={`upload-card-${index}`}
                className="upload-card image-upload mt-3"
              >
                <Card>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    id={`upload-input-${index}`}
                    onChange={(e) => handleImageUpload(e, index)}
                  />
                  <label
                    htmlFor={`upload-input-${index}`}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={imgUploadIconSvg} alt="Upload Icon" />
                    <div className="upload-title">
                      Drop Image Here or Click to Choose
                    </div>
                    <div className="instruction">
                      Upload the front side of your document.
                    </div>
                    <div className="instruction">Supports JPG, PNG, PDF</div>
                    <div className="file-size-note">(Max file size: 20 MB)</div>
                  </label>
                </Card>
              </Col>
            ) : null
          )}

          {/* Plus Card */}

          {payloadData.images.length < 6 && showUploadCards.length < 6 && (
            <Col xs={12} md={6} lg={4} className="mt-3">
              <Card className="pluscard" onClick={handleAddUploadCard}>
                <div className="plusicon">+</div>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default CreateService;
