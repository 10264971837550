import React, { useEffect, useState } from "react";
import "./Service.css";

import CreateServiceImg from "../../../Images/icons/create-service.png";

import ServicesPricingImg from "../../../Images/icons/service-pricing.png";
import ActiveServicesPricingImg from "../../../Images/icons/active-service-pricing.png";

import ExtraServiceImg from "../../../Images/icons/extra-service.png";
import ActiveExtraServiceImg from "../../../Images/icons/active-extra-service.png";

import FAQSImg from "../../../Images/icons/faqs.png";
import ActiveFAQSImg from "../../../Images/icons/active-faqs.png";

import PaymentImg from "../../../Images/icons/payment.png";
import ActivePaymentImg from "../../../Images/icons/active-payment.png";

import PublishImg from "../../../Images/icons/publish-review.png";
import ActivePublishImg from "../../../Images/icons/active-publish-review.png";


import CreateService from "./CreateService";
import ServicesAndPricing from "./ServicesAndPricing";

import ExtraService from "./ExtraService";
import Faqs from "./Faqs";
import Payment from "./Payment";
import Publish from "./Publish";

import Stepper from "react-stepper-horizontal";

const Service = ({ userDetails }) => {
  const [activeStep, setActiveStep] = useState(1);

  const [serviceData, setServiceData] = useState({
    serviceName: "",
    description: "",
    categoryId: "",
    category: "",
    subCategory: "",
    searchTagsList: [],
    video: null,
    images: [],
    tiers: [
      {
        modeType: "Independent",
        name: "",
        description: "",
        deliveryTime: 0,
        price: 0,
        revisions: 0,
        initialConcepts: 0,
        subSubcategory: {},
        flag: true,
        title: "For individual freelancers",
      },
      {
        modeType: "Agency",
        name: "",
        description: "",
        deliveryTime: 0,
        price: 0,
        revisions: 0,
        initialConcepts: 0,
        subSubcategory: {},
        flag: false,
      },
      {
        modeType: "Enterprise",
        name: "",
        description: "",
        deliveryTime: 0,
        price: 0,
        revisions: 0,
        initialConcepts: 0,
        subSubcategory: {},
        flag: false,
      },
    ],
    addons: [],
    faqs: [],
    acceptedPaymentModes: [],
  });

  const updatePayloadData = (updatedData) => {
    // console.log(updatedData, "updatedData");

    setServiceData((prevData) => ({ ...prevData, ...updatedData }));
  };

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    // console.log(activeStep, "activeStep");

    setActiveStep((prevStep) => prevStep - 1);
  };

  // Save serviceData to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("serviceData", JSON.stringify(serviceData));
  }, [serviceData]);

  // Retrieve serviceData from localStorage when the component mounts
  useEffect(() => {
    const savedData = localStorage.getItem("serviceData");
    if (savedData) {
      setServiceData(JSON.parse(savedData));
    }
  }, []);

  const steps = [
    { title: "Create Service", icon: CreateServiceImg,},
    {
      title: "Services & Pricing",
      icon: activeStep >= 2 ? ActiveServicesPricingImg : ServicesPricingImg,
    },
    {
      title: "Extra Service",
      icon: activeStep >= 3 ? ActiveExtraServiceImg : ExtraServiceImg,
    },
    { title: "FAQs", icon: activeStep >= 4 ? ActiveFAQSImg : FAQSImg },
    {
      title: "Payment",
      icon: activeStep >= 5 ? ActivePaymentImg : PaymentImg,
    },
    {
      title: "Publish & Review",
      icon: activeStep >= 6 ? ActivePublishImg : PublishImg,
    },
  ];

  // console.log(serviceData, "serviceData");

  return (
    <div className="service-container">
      {/* Stepper Component */}

      <Stepper
        steps={steps}
        activeStep={activeStep - 1} // Zero-based index for activeStep
        activeColor="trasparent"
        completeColor="trasparent"
        defaultColor="trasparent"
        completeTitleColor="#000"
        activeTitleColor="#000"
        titleFontSize="1rem"
        size={40} // Adjust icon size if needed
        // barStyle="solid"
      />

      {/* Form Content */}
      <div className="form-content">
        {/* Render form sections based on activeStep */}
        {activeStep === 1 && (
          <CreateService
            onSave={handleNextStep}
            updatePayloadData={updatePayloadData}
            serviceData={serviceData}
          />
        )}
        {activeStep === 2 && (
          <ServicesAndPricing
            onSave={handleNextStep}
            serviceData={serviceData}
            updatePayloadData={updatePayloadData}
          />
        )}
        {activeStep === 3 && (
          <ExtraService
            onSave={handleNextStep}
            serviceData={serviceData}
            updatePayloadData={updatePayloadData}
          />
        )}
        {activeStep === 4 && (
          <Faqs onSave={handleNextStep} updatePayloadData={updatePayloadData} />
        )}
        {activeStep === 5 && (
          <Payment
            onSave={handleNextStep}
            updatePayloadData={updatePayloadData}
          />
        )}
        {activeStep === 6 && (
          <Publish
            onSave={handleBackStep}
            serviceData={serviceData}
            userDetails={userDetails}
          />
        )}
      </div>

      {/* Button to go to next step */}
      {activeStep < 6 && (
        <div className="d-flex justify-content-between btn-wrap mt-4">
          <div>
            <button className="secondary-btn" onClick={handleNextStep}>
              Skip
            </button>
          </div>
          <div>
            {activeStep > 1 && (
              <button className="secondary-btn" onClick={handleBackStep}>
                Back
              </button>
            )}
            <button className="primary-btn ms-3" onClick={handleNextStep}>
              Save and Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Service;
