import React, { useState, useRef, useEffect } from "react";
import ApiCall from "../../../../Api/ApiCall";
import Loader from "../../../Common/Loader/Loader";
import { Button, Card, Col, Figure, Form, Row, Stack } from "react-bootstrap";
import { ReactComponent as ExperienceIcon } from "../../../../Images/freela-icons/experience-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../Images/freela-icons/delete-icon.svg";
import { ReactComponent as PdfIcon } from "../../../../Images/freela-icons/pdf-icon.svg";
import { ReactComponent as FileUploadIcon } from "../../../../Images/freela-icons/file-upload-icon.svg";

const PortfolioTab = ({ userDetails, onGetData, onGetErrData }) => {
  const [portfolioRows, setPortfolioRows] = useState([
    {
      project: "",
      type: "",
      role: "",
      description: "",
      portfolioFile: null,
      behance: "",
      upwork: "",
    },
  ]);

  const portfolioInputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");

  useEffect(() => {
    if (
      userDetails?.freelancerSettings?.portfolio &&
      userDetails?.freelancerSettings?.portfolio.length > 0
    ) {
      const extractedFileUrls = userDetails?.freelancerSettings?.portfolio.map(
        (portfolio) => portfolio.fileUpload || []
      );

      // console.log(extractedFileUrls,"extractedFileUrls");

      setFileUrls(extractedFileUrls);

      const formattedPortfolio = userDetails?.freelancerSettings?.portfolio.map(
        (portfolio, index) => {
          return {
            project: portfolio.projectName,
            type: portfolio.type,
            role: portfolio.role,
            description: portfolio.description,
            portfolioFile:
              portfolio.fileUpload && portfolio.fileUpload.length > 0
                ? portfolio.fileUpload
                : "",
            behance: portfolio.behance,
            upwork: portfolio.upwork,
          };
        }
      );

      setPortfolioRows(formattedPortfolio);
    }
  }, [userDetails]);

  useEffect(() => {
    const formattedData = portfolioRows.map((row, index) => ({
      projectName: row.project,
      type: row.type,
      role: row.role,
      description: row.description,

      behance: row.behance,
      upwork: row.upwork,
      fileUpload:
        fileUrls[index] !== undefined || fileUrls[index] !== null
          ? fileUrls[index]
          : row.portfolioFile || "",
    }));

    onGetData({ portfolio: formattedData });
  }, [portfolioRows, fileUrls]);

  const handleAddRow = () => {
    setPortfolioRows((prevRows) => [
      ...prevRows,
      {
        project: "",
        type: "",
        role: "",
        description: "",
        portfolioFile: "",
        behance: "",
        upwork: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = portfolioRows.filter((_, i) => i !== index);
    setPortfolioRows(updatedRows);
    portfolioInputRefs.current.splice(index, 1);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...portfolioRows];
    updatedRows[index][field] = value;
    setPortfolioRows(updatedRows);
  };

  const handlePhotoChange = async (e, index) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          const updatedRows = [...portfolioRows];
          updatedRows[index].portfolioFile = file;
          setPortfolioRows(updatedRows);

          setFileUrls((prevUrls) => {
            const updatedUrls = [...prevUrls];
            updatedUrls[index] = response.data.s3Url;
            return updatedUrls;
          });
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadClick = (index) => {
    portfolioInputRefs.current[index].click(); // Trigger file input click for a specific row
  };

  const handleDownload = (index) => {
    // console.log(portfolioRows,"portfolioRows");

    const fileURL = portfolioRows[index].portfolioFile;
    // console.log(fileURL,"fileURL");
    if (typeof fileURL === "string" && fileURL) {
      const link = document.createElement("a");
      link.href = fileURL;
      link.target = "_blank";
      link.download = fileURL.split("/").pop();
      link.click();
    } else {
      console.error("No file URL available for download");
    }
  };

  const handleDelete = (e, index) => {
    const updatedRows = [...portfolioRows];
    // console.log(updatedRows,"updatedRows");

    updatedRows[index].portfolioFile = "";
    setPortfolioRows(updatedRows);

    const updatedFileUrls = [...fileUrls];
    updatedFileUrls[index] = "";

    setFileUrls(updatedFileUrls);
  };

  useEffect(() => {
    handleValidate();
  }, [portfolioRows]);

  const handleValidate = () => {
    let hasValidEntry = false;
    let newErrors = {};

    portfolioRows.forEach((edu, index) => {
      let entryErrors = {};

      if (!edu.project.trim()) {
        entryErrors.project = "Project is required";
      } else if (!/^[A-Za-z\s]{3,30}$/.test(edu.project.trim())) {
        entryErrors.project =
          "Project must be between 3 to 30 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.type.trim()) {
        entryErrors.type = "Type is required";
      } else if (!/^[A-Za-z\s]{3,30}$/.test(edu.type.trim())) {
        entryErrors.type = "Type must be between 3 to 30 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.role.trim()) {
        entryErrors.role = "Role is required";
      } else if (!/^[A-Za-z\s]{3,30}$/.test(edu.role.trim())) {
        entryErrors.role = "Role must be between 3 to 30 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.description.trim()) {
        entryErrors.description = "description is required ";
      } else if (!/^[A-Za-z\s]{10,500}$/.test(edu.description.trim())) {
        entryErrors.description =
          "description must be between 10 to 500 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.upwork.trim()) {
        entryErrors.upwork = "Portfolio Name is required";
      } else if (!/^[A-Za-z\s]{3,20}$/.test(edu.upwork.trim())) {
        entryErrors.upwork =
          "Portfolio Name must be between 3 to 20 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (edu.behance === "") {
        entryErrors.behance = "Portfolio Link is required";
      } else if (
        !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/\S*)?$/.test(
          edu.behance.trim()
        )
      ) {
        entryErrors.behance =
          "Enter a valid URL (e.g., https://www.example.com)";
      } else {
        hasValidEntry = true;
      }

      if (Object.keys(entryErrors).length > 0) {
        newErrors[index] = entryErrors;
      }
    });

    if (!hasValidEntry || portfolioRows.length === 0) {
      setFormError("Please provide project details and links.");
    } else {
      setFormError("");
    }

    setErrors(newErrors);

    // Return true if there are no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onGetErrData(Object.keys(errors).length || (formError ? 1 : 0));
  }, [errors, formError]);

  return (
    <>
      {loading && <Loader />}
      <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
        <Row className="gy-2 mb-1 align-items-center">
          <Col sm={8} md={8} lg={8} xl={8}>
            <Stack direction="horizontal" className="gap-2">
              <ExperienceIcon width={50} />
              <div className="freela-fs-20 fw-500 freela-text-primary">
                Add Your Portfolio
              </div>
            </Stack>
          </Col>
          <Col sm={4} md={4} lg={4} xl={4} className="text-sm-end">
            <Button
              variant=""
              className="freela-btn-primary-outline"
              onClick={handleAddRow}
            >
              Add
            </Button>
          </Col>
        </Row>
        {formError && <div className="text-danger mt-2">{formError}</div>}

        {portfolioRows.map((row, index) => (
          <Row className="align-items-end mt-4 gy-3" key={index}>
            {index > 0 && (
              <Col xs={12}>
                <div className="border-bottom mt-2 mb-3 border-1 freela-border-greylight8 border-dashed"></div>
              </Col>
            )}
            <Col lg={11} xl={11}>
              <Row className="gy-3">
                <Form.Group as={Col} md={6} lg={6} xl={4}>
                  <Form.Label>
                    Portfolio Title <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id={`project-${index}`}
                    value={row.project}
                    onChange={(e) =>
                      handleChange(index, "project", e.target.value)
                    }
                    placeholder="UI developer"
                  />
                  {errors[index]?.project && (
                    <div className="text-danger mt-2">
                      {errors[index].project}
                    </div>
                  )}
                </Form.Group>

                <Form.Group as={Col} md={6} lg={6} xl={4}>
                  <Form.Label>
                    Portfolio Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id={`type-${index}`}
                    value={row.type}
                    onChange={(e) =>
                      handleChange(index, "type", e.target.value)
                    }
                    placeholder="Web Development"
                  />
                  {errors[index]?.type && (
                    <div className="text-danger mt-2">{errors[index].type}</div>
                  )}
                </Form.Group>

                <Form.Group as={Col} md={6} lg={6} xl={4}>
                  <Form.Label>
                    Portfolio Role <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id={`role-${index}`}
                    value={row.role}
                    onChange={(e) =>
                      handleChange(index, "role", e.target.value)
                    }
                    placeholder="Role"
                  />
                  {errors[index]?.role && (
                    <div className="text-danger mt-2">{errors[index].role}</div>
                  )}
                </Form.Group>
              </Row>
            </Col>

            <Col lg={1} xl={1}>
              <Button
                variant=""
                className="freela-btn-primary btn-rounded"
                onClick={() => handleDeleteRow(index)}
              >
                <DeleteIcon width={20} height={20} />
              </Button>
            </Col>

            <Col xs={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  id={`description-${index}`}
                  value={row.description}
                  onChange={(e) =>
                    handleChange(index, "description", e.target.value)
                  }
                  placeholder="Brief description about your project"
                  rows={6}
                />
                {errors[index]?.description && (
                  <div className="text-danger mt-2">
                    {errors[index].description}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Card className="p-4 rounded-5">
                {row.portfolioFile ? (
                  <Stack direction="horizontal">
                    <Card className="freela-bg-greylight9 freela-border-greylight10 d-flex p-3 flex-row gap-4 rounded-4 mb-0">
                      <Figure className="d-flex gap-2 mb-0">
                        <PdfIcon width={40} height={40} />
                        <Figure.Caption className="file-fetails">
                          <p className="file-title text-dark mb-0 w-75 text-truncate">
                            {" "}
                            {row.portfolioFile.name}
                          </p>
                          <p className="file-meta mb-0">
                            {" "}
                            {row.portfolioFile.size} KB -{" "}
                            {new Date(
                              row.portfolioFile.lastModified
                            ).toLocaleDateString()}
                          </p>
                        </Figure.Caption>
                      </Figure>
                      <Stack
                        direction="horizontal"
                        className="ms-auto ms-xl-5 gap-2"
                      >
                        <Button
                          variant="link p-0 freela-greaseblack-text"
                          onClick={() => handleDownload(index)}
                        >
                          <i className="bi bi-download" />
                        </Button>
                        <Button
                          variant="link p-0 freela-danger-text"
                          onClick={(e) => {
                            if (
                              window.confirm("Do you want to delete this file?")
                            )
                              handleDelete(e, index);
                          }}
                        >
                          <i className="bi bi-trash3" />
                        </Button>
                      </Stack>
                    </Card>
                    <Button
                      className="freela-btn-primary-outline ms-auto"
                      onClick={() => handleUploadClick(index)}
                    >
                      Choose
                    </Button>
                  </Stack>
                ) : (
                  <Card.Body className="p-0">
                    <Stack direction="horizontal">
                      <div className="d-flex align-items-center gap-2">
                        <FileUploadIcon
                          className="mb-2"
                          width={50}
                          height={50}
                        />
                        <Card.Text className="mb-0 fs-6">
                          Please upload your Portfolio Docs
                        </Card.Text>
                      </div>
                      <Button
                        className="freela-btn-primary-outline ms-auto"
                        onClick={() => handleUploadClick(index)}
                      >
                        Choose
                      </Button>
                    </Stack>
                  </Card.Body>
                )}
                <Form.Control
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  ref={(el) => (portfolioInputRefs.current[index] = el)}
                  className="d-none"
                  onChange={(e) => handlePhotoChange(e, index)}
                />
              </Card>
            </Col>

            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-between gap-2 mt-5">
                <h5>Portfolio Links</h5>
                <Button variant="" className="freela-btn-primary-outline">
                  Add
                </Button>
              </div>

              <Row className="gy-3 mt-3">
                <Form.Group
                  as={Col}
                  lg={5}
                  xl={5}
                  controlId="inputPortfolioName"
                >
                  <Form.Label>Portfolio Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Portfolio Name"
                    id={`upwork-${index}`}
                    value={row.upwork}
                    onChange={(e) =>
                      handleChange(index, "upwork", e.target.value)
                    }
                  />
                  {errors[index]?.upwork && (
                    <div className="text-danger mt-2">
                      {errors[index].upwork}
                    </div>
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg={7}
                  xl={7}
                  controlId="inputPortfolioLink"
                >
                  <Form.Label>Portfolio Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="https://yourlink"
                    id={`behance-${index}`}
                    value={row.behance}
                    onChange={(e) =>
                      handleChange(index, "behance", e.target.value)
                    }
                  />
                  {errors[index]?.behance && (
                    <div className="text-danger mt-2">
                      {errors[index].behance}
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Col>
          </Row>
        ))}
      </Card>
    </>
  );
};

export default PortfolioTab;
