import React, { useState } from 'react'
import { Row, Col, Card, Form, Button, Image, Stack, ProgressBar, Spinner } from 'react-bootstrap';
import { ReactComponent as UploadDocIcon } from '../../../../Images/freela-icons/doc-upload.svg'
import CustomModal from '../../../Common/Modal/CustomModal';

const KYCCreation = ({ clickToNextTab }) => {
  const [kycModalShow, setKycModalShow] = useState(false);

  return (
    <>
      <Card as={Card.Body} className="kyc_creation rounded-5 p-4 inner_card">
        <Row>
          <Col md={10} lg={8} xl={7} xxl={6} className='mx-auto'>
            <Stack direction='horizontal' className='justify-content-between list-unstyled' as="ul">
              <li className="level-stage">
                <Button varaint="" size="sm" className="freela-btn-primary rounded-rounded">1</Button>
              </li>
              <li className="freela-stepper-line" />
              <li className="level-stage">
                <Button varaint="" size="sm" className="freela-btn-primary rounded-rounded">2</Button>
              </li>
              <li className="freela-stepper-line" />
              <li className="level-stage">
                <Button varaint="" size="sm" className="freela-btn-primary rounded-rounded">3</Button>
              </li>
            </Stack>
          </Col>
        </Row>
        {/* level1 */}
        <Row className="gy-3 mt-2 mb-4 align-items-center">
          <Col md={10} lg={9} xl={8} xxl={7} className='mx-auto'>
            <div className="top_content mb-4">
              <h5>Upload a proof of your identity</h5>
              <p>Apex Markets Requires a valid government issue ID <br />(Driving licenses, Passport, National ID)</p>
            </div>
            <Form as={Row}>
              <Form.Group as={Col} md={12} lg={6} xl={6} className="mb-4">
                <Form.Label>Your Country <span className='text-danger'>*</span></Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select Country</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md={12} lg={6} xl={6} className="mb-4">
                <Form.Label>Document Type <span className='text-danger'>*</span></Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select Document Type</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>

              {Array.from({ length: 2 }).map((document, idx) => (
                <Col md={12} lg={6} xl={6} key={idx} className="mb-4">
                  <Card className='rounded-5 position-relative overflow-hidden'>

                    {/* upload document file */}
                    <div className="upload_doc text-center p-4">
                      <UploadDocIcon width={60} />
                      <Card.Title as="h6">Front side of your document</Card.Title>
                      <Card.Text>Upload the front side of your document Supports JPG,PNG,PDF</Card.Text>
                      <Card.Text className='small'>(Max file size: 2 MB)</Card.Text>
                      <Button varaint="" className="freela-btn-primary-outline px-4 mt-3">Choose a file</Button>
                      <Form.Control type="file" accept="image/*" className="d-none" />
                    </div>

                    {/* document-preview */}
                    <div className="preview_doc position-relative">
                      <Button variant="" size='sm' className='freela-btn-primary p-2 rounded-pill position-absolute top-0 end-0 mt-3 me-3 z-1'><i className='bi bi-x-lg' /></Button>
                      <Image src="https://www.shutterstock.com/shutterstock/photos/1661857771/display_1500/stock-vector-dummy-aadhar-card-unique-identity-document-for-indian-citizen-issued-by-government-of-india-vector-1661857771.jpg" className="object-fit-cover h-100 w-100" />
                    </div>
                  </Card>
                </Col>
              ))}

              <Form.Group as={Col} xs={12} className="mb-4">
                <Form.Check
                  label="I Confirm that I uploaded valid government - issued photo ID. This ID include my picture, signature, name date of birth, and address"
                  name="group1"
                  type="checkbox"
                  id="termAndConditions"
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        {/* level2 */}
        <Row className="gy-3 mb-4 align-items-center">
          <Col md={10} lg={9} xl={8} xxl={7} className='mx-auto'>
            <div className="top_content">
              <h5 className='fw-400'>Liveliness Detection</h5>
              <p>Apex Markets Requires a valid government issue ID <br />(Driving licenses, Passport, National ID)</p>
            </div>
            <Card className="rounded-5 inner_card position-relative overflow-hidden my-4">
              <Image className='object-fit-cover h-100 w-100' src="https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <div className='object-fit-cover position-absolute top-0 start-0 w-100 h-100 z-1'>
                <svg viewBox="0 0 894 551" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M60 0C26.8629 0 0 26.8629 0 60V490.083C0 523.22 26.8629 550.083 60 550.083H834C867.137 550.083 894 523.22 894 490.083V60C894 26.8629 867.137 0 834 0H60ZM608.327 266.763C608.327 363.555 533.615 488.141 441.453 488.141C349.291 488.141 274.579 363.555 274.579 266.763C274.579 169.971 349.291 82.2813 441.453 82.2813C533.615 82.2813 608.327 169.971 608.327 266.763Z" fill="black" fill-opacity="0.7" />
                </svg>
              </div>
            </Card>

            <Row className='mt-n80 position-relative z-1'>
              <Col xs={10} sm={10} md={10} lg={8} xl={8} className='mx-auto'>
                <Card as={Card.Body} className="rounded-4 p-4 inner_card">
                  <ProgressBar variant='success' label={`${95}%`} animated now={95} />
                  <Stack direction='horizontal' className='my-2'>
                    <Card.Text className='mb-0 text-dark'>Close Your eyes</Card.Text>
                    <Card.Text className="mb-0 ms-auto text-dark">3/3</Card.Text>
                  </Stack>
                  <Stack direction='horizontal'>
                    <Card.Text as="small" className='freela-text-greaseblack'>Waiting...</Card.Text>
                    <Card.Text className="ms-auto">
                      <Spinner animation="border" size='sm' variant="dark" />
                    </Card.Text>
                  </Stack>
                </Card>
              </Col>
            </Row>

            <Stack direction='horizontal' className="gap-3 justify-content-end mt-5">
              <Button varaint="" className='freela-btn-primary-outline'>Reset</Button>
              <Button varaint="" className='freela-btn-primary' onClick={() => setKycModalShow(true)}>Continue</Button>
            </Stack>
          </Col>
        </Row>

      </Card>

      {kycModalShow &&
        <CustomModal isOpen={kycModalShow} onClose={() => setKycModalShow(false)} modalSize="md">
          <Image src={require('../../../../Images/freela-icons/modal-bg-bubbles.svg').default} className='position-absolute top-0 start-0 w-100' />
          <div className="content py-4 mx-4 text-center position-relative z-1">
            <div className="icon mb-3"><i className="bi bi-check-circle-fill text-success freela-fs-60px" /></div>
            <h3 className='mb-2'>Face Captured Successfully</h3>
            <p className='freela-text-greaseblack'>You will be notified when the verification is done</p>
            <Button variant='' className='freela-btn-primary px-5 mt-4'>Proceed</Button>
          </div>
        </CustomModal>
      }
    </>
  )
}

export default KYCCreation