import React, { useState } from "react";
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import ForgotVerifyImg from '../../Images/freela-icons/forgot-verify-img.svg';
import { ReactComponent as FreelaLogo } from '../../Images/freela-icons/freela-logo.svg';
import { ReactComponent as EyeIcon } from '../../Images/freela-icons/eye-icon.svg';
import { ReactComponent as EyeOffIcon } from '../../Images/freela-icons/eyeoff-icon.svg';

import ApiCall from "../../Api/ApiCall";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";

const ForgotVerify = ({ verifyEmail, openLogin }) => {
  const [formData, setFormData] = useState({
    otp: "",
    password: "",
    cpassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { notify } = useToast();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error messages
    setLoading(true);


    try {
      // Prepare payload for Forgot verify
      const payload = {
        email: verifyEmail,
        otp: Number(formData.otp),
        password: formData.password,
        authenticationType: "forgot password",
      };

      console.log(payload, "Forgot verify payload");

      // Make the API call
      const response = await ApiCall("POST", "user/verifycode", payload, 'application/json');
      // Handle success with 200 status code
      if (response.message === "ok") {
        console.log(
          "Forgot verify successful:",
          response.message,
          "response.message",
          response.data
        );
        notify('success', response.message || "Forgot verify successful");
        openLogin();
      } else {
        notify('error', response.message || "Forgot verify failed. Please try again.")
        setError("Forgot verify failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      notify('error', error.response.data.message || "Forgot verify failed. Please try again.")
      console.error("Forgot verify error:", error);
      setError("Forgot verify failed. Please try again.");
    }
    finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };


  const handleResend = async (e) => {

    e.preventDefault();
    setError('');  // Clear previous error messages
    setLoading(true);

    try {
      // Prepare payload for forgot pwd
      const payload = {
        email: verifyEmail,
        authenticationType: "forgot password"

      };

      console.log(payload, "forgot resend pwd payload");


      // Make the API call
      const response = await ApiCall('POST', 'user/forgotpwd', payload);
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        // console.log('forgot pwd successful:', response.message, "response.message", response.data);
        notify('success', response.message);

      }
      else {
        notify('error', response.message)
        setError('forgot pwd failed. Please try again.');
      }

    } catch (error) {
      // Handle API call error
      console.error('forgot pwd error:', error);
      notify('error', error.response.data.message)
      setError('forgot pwd failed. Please try again.');
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (

    <>
      {loading && <Loader />}

      <Row className="login_form align-items-center">
        {/* Left Section */}
        <Col sm={12} md={12} lg={5} xl={5}>
          <FreelaLogo className="ms-lg-4 mt-lg-2" width={260} />
          <div className="d-flex align-items-center justify-content-center my-xl-5 py-xl-4">
            <Image src={ForgotVerifyImg} alt="LoginImg" fluid />
          </div>
        </Col>

        {/* Right Section */}
        <Col sm={12} md={12} lg={7} xl={7}>
          <h1 className="fs-5 mb-0">Please enter OTP to verify your account</h1>
          <p className="text-secondary">An OTP has been sent your email {verifyEmail}</p>

          {/* Register verify Form */}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                id="otp"
                placeholder="Enter OTP"
                value={formData.otp}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-4 text-end">Did not recieve code?
              <Button variant="" className="py-0 ps-1 text-decoration-none freela-text-primary"
                onClick={handleResend}
              >
                Resend OTP
              </Button>
            </Form.Group>

            <div className="my-4">
              <h4>Kindly enter your password to confirm. </h4>
            </div>

            <Row>
              <Col md={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <Button variant="link" className="text-decoration-none position-absolute top-50 end-0 translate-middle-y me-2" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <EyeIcon width={16} /> : <EyeOffIcon width={16} />}
                    </Button>
                  </div>
                </Form.Group>
              </Col>
              <Col md={12} lg={6}>
                <Form.Group className="position-relative">
                  <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      id="cpassword"
                      type={showCPassword ? "text" : "password"}
                      placeholder="Enter confirm password"
                      value={formData.cpassword}
                      onChange={handleChange}
                      required
                    />
                    <Button variant="link" className="text-decoration-none position-absolute top-50 end-0 translate-middle-y" onClick={() => setShowCPassword(!showCPassword)}>
                      {showCPassword ? <EyeIcon width={16} /> : <EyeOffIcon width={16} />}
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group as={Row} className="text-center mt-5">
              <Col md={6} className="mt-2 mx-auto d-grid">
                <Button type="submit" variant="" className="freela-btn-primary px-5">Confirm</Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>

  );
};

export default ForgotVerify;
