import React from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as BgBubbles } from '../../../Images/freela-icons/modal-bg-bubbles.svg'

const ShowModal = ({ handleProfile }) => {
  return (
    <div className="content text-center p-4 position-relative">
      <BgBubbles className="position-absolute top-0 start-0 w-100" />
      <h6>Enhance your Profile</h6>
      <p>
        A mini game competition for the top Freela profile. Level up and secure
        more projects!
      </p>
      <Button
        className="freela-btn-primary px-5 mt-4"
        onClick={handleProfile}
      >
        Continue
      </Button>
    </div>
  );
};

export default ShowModal;
