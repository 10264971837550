import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Image, Button, Form, Stack, Tooltip, OverlayTrigger } from 'react-bootstrap'
import CustomModal from '../../../Common/Modal/CustomModal';
import ChatbotImg from '../../../../Images/freela-images/chatbot-img.jpg';

const Step4 = ({ clickToBack }) => {
    const navigate = useNavigate();

    const [modalShow, setModalShow] = useState(true);
    const [finalInterviewModalShow, setFinalInterviewModalShow] = useState(false);
    const [currentModal, setCurrentModal] = useState(true);
    const ConversationList = [
        {
            id: 1,
            message: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus dolorum impedit vert.",
            chatType: "incoming",
            timeStamp: '10:00 AM | Feb 8, 2025'
        },
        {
            id: 2,
            message: "Maiores quo commodi porro, quas minima natus odio aspernatur fugiat.",
            chatType: "outcoming",
            timeStamp: '10:30 AM | Feb 9, 2025'
        },
        {
            id: 3,
            message: "Quas minima natus odio aspernatur temporibus eos molestiae.",
            chatType: "incoming",
            timeStamp: '12:00 PM | Feb 10, 2025'
        },
        {
            id: 4,
            message: "Solestias beatae possimus perspiciatis, temporibus eos molestiae.",
            chatType: "outcoming",
            timeStamp: '12:01 PM | Feb 10, 2025'
        },
        {
            id: 5,
            message: "Aspernatur temporibus possimus commodi porro, quas minima natus eos.",
            chatType: "outcoming",
            timeStamp: '12:10 PM | Feb 10, 2025'
        }
    ];

    return (
        <>
            <Card className='Ai_interview rounded-5 overflow-hidden'>
                <Row className='g-0'>
                    <Col lg={8} xl={8}>
                        <div className="position-relative">
                            <Image src={ChatbotImg} fluid style={{ transform: 'scale(1.02)' }} />
                            <Card className="human-preview position-absolute end-0 bottom-0 mb-3 me-3 rounded-4 border-0 overflow-hidden">
                                <Image className='object-fit-cover h-100 w-100' src="https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                                <Card.Text className="human-preview-name text-truncate position-absolute start-0 bottom-0 w-100 py-2 px-3 text-white">Narashimaha Swamy</Card.Text>
                            </Card>
                        </div>
                    </Col>
                    <Col lg={4} xl={4} className='conversation'>
                        <div className="conversation_content h-100">
                            <h6 className='conversation_title px-4 py-3 mb-0'>Conversation</h6>
                            <Stack direction='vertical' className="conversation_content_list px-4 pb-3">
                                {ConversationList.map((chat, idx) => (
                                    <Card className={`${chat.chatType}`} key={idx}>
                                        <Card.Body className={`${chat.chatType}_message_content`}>
                                            <Card.Text className="mb-0">{chat.message}</Card.Text>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Card.Text as='small' className='mb-0'>{chat.timeStamp}</Card.Text>
                                        </Card.Footer>
                                    </Card>
                                ))}
                            </Stack>
                        </div>
                    </Col>
                </Row>
            </Card>

            <Stack direction='horizontal' className='justify-content-center gap-2 mt-3 mb-5'>
                <OverlayTrigger overlay={<Tooltip>Voice</Tooltip>}>
                    <Button variant='dark' className='freela-bg-black rounded-pill shadow'><i className="bi bi-mic-fill" /></Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Video</Tooltip>}>
                    <Button variant='dark' className='freela-bg-black rounded-pill shadow'><i className="bi bi-camera-video" /></Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Handup</Tooltip>}>
                    <Button variant='dark' className='freela-bg-black rounded-pill shadow'>
                        <svg width="16" height="21" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.8433 3.99903C17.4582 3.99903 17.093 4.08421 16.7643 4.23567C16.5413 3.0342 15.4861 2.12132 14.221 2.12132C13.8235 2.12132 13.4469 2.21157 13.1101 2.3724C12.8311 1.24482 11.8112 0.40625 10.5986 0.40625C9.38609 0.40625 8.36619 1.24487 8.08717 2.3724C7.7504 2.21162 7.37373 2.12132 6.97631 2.12132C5.54963 2.12132 4.38893 3.28202 4.38893 4.7087V11.0334C4.07177 10.8944 3.72185 10.8167 3.35398 10.8167C1.9273 10.8167 0.766602 11.9774 0.766602 13.4041V18.7249C0.766602 22.4628 3.8076 25.5038 7.54553 25.5038H13.6517C17.3896 25.5038 20.4307 22.4628 20.4307 18.7249V6.58641C20.4307 5.15968 19.27 3.99903 17.8433 3.99903ZM18.8782 18.7249C18.8782 21.6068 16.5336 23.9514 13.6517 23.9514H7.54553C4.66366 23.9514 2.31903 21.6068 2.31903 18.7249V13.4041C2.31903 12.8334 2.78331 12.3691 3.35398 12.3691C3.92465 12.3691 4.38893 12.8334 4.38893 13.4041V17.5439H5.94136V4.7087C5.94136 4.13802 6.40564 3.67375 6.97631 3.67375C7.54698 3.67375 8.01126 4.13802 8.01126 4.7087V12.8866H9.56369V2.99363C9.56369 2.42296 10.028 1.95868 10.5986 1.95868C11.1693 1.95868 11.6336 2.42296 11.6336 2.99363V12.8866H13.186V4.7087C13.186 4.13802 13.6503 3.67375 14.221 3.67375C14.7916 3.67375 15.2559 4.13802 15.2559 4.7087V12.8866H16.8083V6.58636C16.8083 6.01568 17.2726 5.55141 17.8433 5.55141C18.414 5.55141 18.8782 6.01568 18.8782 6.58636V18.7249Z" fill="white" />
                        </svg>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Call End</Tooltip>}>
                    <Button variant='' className='freela-btn-primary rounded-pill shadow' onClick={() => setFinalInterviewModalShow(true)}>
                        <svg width="25" height="16" viewBox="0 0 42 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.86021 14.4327L1.62795 11.2005C1.32667 10.9016 1.09112 10.5431 0.936397 10.148C0.781675 9.75285 0.711185 9.32975 0.729441 8.90579C0.7477 8.48182 0.854299 8.06634 1.04242 7.68596C1.23054 7.30558 1.49603 6.96868 1.82188 6.69685C5.49753 3.74169 9.81511 1.68992 14.4277 0.706389C18.6851 -0.242641 23.0993 -0.242641 27.3567 0.706389C31.9884 1.69629 36.3217 3.7633 40.0057 6.73994C40.3305 7.01096 40.5954 7.34665 40.7834 7.72565C40.9714 8.10464 41.0785 8.51864 41.0977 8.94127C41.117 9.3639 41.048 9.78591 40.8953 10.1804C40.7425 10.575 40.5092 10.9333 40.2104 11.2328L36.9781 14.465C36.4604 14.9931 35.7683 15.3148 35.0309 15.3703C34.2935 15.4258 33.561 15.2113 32.9701 14.7667C31.7993 13.8688 30.5306 13.1061 29.1884 12.4934C28.6578 12.2527 28.2073 11.865 27.8903 11.3761C27.5733 10.8873 27.4031 10.3178 27.3998 9.73517L27.3998 6.99852C23.1687 5.83492 18.7019 5.83492 14.4708 6.99852V9.73517C14.4675 10.3178 14.2973 10.8873 13.9803 11.3761C13.6634 11.865 13.2129 12.2527 12.6823 12.4934C11.34 13.1061 10.0714 13.8688 8.90053 14.7667C8.30332 15.2163 7.56172 15.4306 6.81677 15.369C6.07182 15.3075 5.37549 14.9742 4.86021 14.4327Z" fill="white" />
                        </svg>
                    </Button>
                </OverlayTrigger>
            </Stack>

            {modalShow &&
                <CustomModal isOpen={modalShow} onClose={() => setModalShow(false)} modalSize="md">
                    <Image src={require('../../../../Images/freela-icons/modal-bg-bubbles.svg').default} className='position-absolute top-0 start-0 w-100' />
                    {currentModal === true ?
                        <div className="content py-5 my-4 mx-4 text-center position-relative z-1">
                            <h4 className='mb-2'>Welcome to the Interview room</h4>
                            <p>We will show you around the key sections of this screen to help you start giving practice interview</p>
                            <Button variant="" className="freela-btn-primary mt-4 px-5" onClick={() => setCurrentModal(false)}>Next</Button>
                        </div>
                        :
                        <div className="content py-5 my-3 mx-4 text-center position-relative z-1">
                            <h4 className='mb-2'>Recorded message appears here</h4>
                            <p>Conversation is automatically transcribed here</p>
                            <Form.Group className='my-4'>
                                <Form.Check
                                    inline
                                    label="I understood the process. Don’t show me this again"
                                    name="understoodProcess"
                                    type="checkbox"
                                    id={`inline-checkbox-modal`}
                                />
                            </Form.Group>
                            <Stack direction='horizontal' className='gap-4 pt-4 justify-content-center'>
                                <Button variant='' className='freela-btn-primary-outline px-5' onClick={clickToBack}>Try Again</Button>
                                <Button variant='' className='freela-btn-primary px-5' onClick={() => setModalShow(false)}>Start Insterview</Button>
                            </Stack>
                        </div>
                    }
                </CustomModal>
            }

            {finalInterviewModalShow &&
                <CustomModal isOpen={finalInterviewModalShow} onClose={() => setFinalInterviewModalShow(false)} modalSize="md">
                    <Image src={require('../../../../Images/freela-icons/modal-bg-bubbles.svg').default} className='position-absolute top-0 start-0 w-100' />
                    <div className="content py-5 mx-4 text-center position-relative z-1">
                        <h3 className='mb-2'>Great Job</h3>
                        <p className='freela-text-greaseblack'>Thank you for completing the interview.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque asperiores reprehenderit dolorem, rerum aliquam amet fuga nemo tempora adipisci quae consectetur sunt laudantium ipsam. A quidem ipsa aliquam est cupiditate.</p>
                        <Button variant='' className='freela-btn-primary px-5' onClick={() => navigate('/profile-preview')}>Preview Profile</Button>
                    </div>
                </CustomModal>
            }
        </>

    )
}

export default Step4