import React, { useState } from 'react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const AIInterview = () => {
    const [currentPage, setCurrentPage] = useState('step1');
    const renderContent = () => {
        switch (currentPage) {
            case 'step1':
                return <Step1 clickToAccept={() => setCurrentPage('step2')} />;
            case 'step2':
                return <Step2 clickToBack={() => setCurrentPage('step1')} clickToContinue={() => setCurrentPage('step3')} />;
            case 'step3':
                return <Step3 clickToBack={() => setCurrentPage('step2')} clickToNext={() => setCurrentPage('step4')} />;
            case 'step4':
                return <Step4 clickToBack={() => setCurrentPage('step1')} />;
            default:
                return <Step1 clickToAccept={() => setCurrentPage('step2')} />;
        }
    };
    return (
        <>{renderContent()}</>
    )
}

export default AIInterview