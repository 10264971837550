// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.SideNavWidth{
  width:250px;  
  border-radius:20px;
}
.right_sec_width{
  width: calc(100% - 250px);
}
.right_sec.card{
  border-radius: 30px;
    border: 1px solid #EEEAEA;
    background: #FCFCFC;
    margin: 20px 0px;
    padding: 20px;
    min-height:555px;
}



@media (max-width:767px) {
  .SideNavWidth{
    width:80px;
  }
  .right_sec_width{
    width: calc(100% - 80px);
  }
}
@media (max-width:480px) {
  .upload-service, .upload-service img{
    margin-top:0px !important;
  }
  .upload-card .card, .pluscard{
    padding:10px;
  }
  .upload-video-box{
    height:auto !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Layout/Layout.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;AACpB;;;;AAIA;EACE;IACE,UAAU;EACZ;EACA;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,YAAY;EACd;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["*{\r\n  margin:0;\r\n  padding:0;\r\n  box-sizing: border-box;\r\n}\r\n.SideNavWidth{\r\n  width:250px;  \r\n  border-radius:20px;\r\n}\r\n.right_sec_width{\r\n  width: calc(100% - 250px);\r\n}\r\n.right_sec.card{\r\n  border-radius: 30px;\r\n    border: 1px solid #EEEAEA;\r\n    background: #FCFCFC;\r\n    margin: 20px 0px;\r\n    padding: 20px;\r\n    min-height:555px;\r\n}\r\n\r\n\r\n\r\n@media (max-width:767px) {\r\n  .SideNavWidth{\r\n    width:80px;\r\n  }\r\n  .right_sec_width{\r\n    width: calc(100% - 80px);\r\n  }\r\n}\r\n@media (max-width:480px) {\r\n  .upload-service, .upload-service img{\r\n    margin-top:0px !important;\r\n  }\r\n  .upload-card .card, .pluscard{\r\n    padding:10px;\r\n  }\r\n  .upload-video-box{\r\n    height:auto !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
