// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-button{
  color: #F0806C;
}
.browse_card{padding:0 !important;}
.browse_card, .upload_card, .upload_card .card {
  background: #F9F9F9;
  border-radius: 30px;
}
.browse_card .uploaded_icon img, .browse_card img.preview-image{
  border-radius: 30px;
}
.preview-cover{
  width:100%;
  height: 100%;
}
.file-actions button{
  border: none;
  background: transparent;
}
.file-actions button img{
  width: 20px;
  height:20px;
}
.preview_card .preview-image{
  height: 200px !important;
  object-fit: cover;
}
.preview_card .camera_img{
position: absolute;
top: 15px;
right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Profile/Profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA,aAAa,oBAAoB,CAAC;AAClC;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,wBAAwB;EACxB,iBAAiB;AACnB;AACA;AACA,kBAAkB;AAClB,SAAS;AACT,WAAW;AACX","sourcesContent":[".browse-button{\r\n  color: #F0806C;\r\n}\r\n.browse_card{padding:0 !important;}\r\n.browse_card, .upload_card, .upload_card .card {\r\n  background: #F9F9F9;\r\n  border-radius: 30px;\r\n}\r\n.browse_card .uploaded_icon img, .browse_card img.preview-image{\r\n  border-radius: 30px;\r\n}\r\n.preview-cover{\r\n  width:100%;\r\n  height: 100%;\r\n}\r\n.file-actions button{\r\n  border: none;\r\n  background: transparent;\r\n}\r\n.file-actions button img{\r\n  width: 20px;\r\n  height:20px;\r\n}\r\n.preview_card .preview-image{\r\n  height: 200px !important;\r\n  object-fit: cover;\r\n}\r\n.preview_card .camera_img{\r\nposition: absolute;\r\ntop: 15px;\r\nright: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
