import React, { useState } from "react";
import { Button, Form, Nav, Offcanvas, Stack } from "react-bootstrap";
import ReactRangeSliderInput from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const MyWorkFilterOffcanvas = ({ show, handleClose, handleSearch }) => {
  const experienceLevels = ["Junior", "Mid", "Senior"];
  const [payloadData, setPayloadData] = useState({
    searchSkills: "",
    requiredSkills: [],
    experience: { min: "Junior", max: "Junior" },
    estimateBudget: {
      min: 0,
      max: 0,
    },
    requiredReputations: [],
    paymentModes: [],
    availability: "",
  });

  const handleKeyDown = (e) => {
    const trimmedTag = payloadData.searchSkills.trim();

    if (e.key === "Enter" && trimmedTag) {
      e.preventDefault();

      if (
        !payloadData.requiredSkills.includes(trimmedTag) &&
        payloadData.requiredSkills.length < 5
      ) {
        setPayloadData((prev) => ({
          ...prev,
          requiredSkills: [...prev.requiredSkills, trimmedTag],
          searchSkills: "",
        }));
      }
    }
  };

  const handleSearchTagsChange = (e) => {
    setPayloadData((prev) => ({ ...prev, searchSkills: e.target.value }));
  };

  const handleRangeChange = (values) => {
    setPayloadData((prev) => ({
      ...prev,
      estimateBudget: {
        min: values[0],
        max: values[1],
      },
    }));
  };

  const handleInputChange = (e, type) => {
    const value = e.target.value ? Number(e.target.value) : 0;
    setPayloadData((prev) => ({
      ...prev,
      estimateBudget: {
        ...prev.estimateBudget,
        [type]: value,
      },
    }));
  };

  const toggleReputation = (tier) => {
    setPayloadData((prev) => {
      const isSelected = prev.requiredReputations?.includes(tier);
      const updatedReputations = isSelected
        ? prev.requiredReputations?.filter((item) => item !== tier) // Remove if selected
        : [...prev.requiredReputations, tier]; // Add if not selected

      return { ...prev, requiredReputations: updatedReputations };
    });
  };

  const handleExperienceChange = (values) => {
    setPayloadData((prev) => ({
      ...prev,
      experience: {
        min: experienceLevels[values[0]], // Convert number back to string
        max: experienceLevels[values[1]],
      },
    }));
  };

  const togglePaymentMode = (mode) => {
    setPayloadData((prev) => {
      const prevModes = Array.isArray(prev.paymentModes)
        ? prev.paymentModes
        : [];
      const updatedModes = prevModes.includes(mode)
        ? prevModes.filter((item) => item !== mode) // Remove if selected
        : [...prevModes, mode]; // Add if not selected

      return { ...prev, paymentModes: updatedModes };
    });
  };
  const handleAvailabilityChange = (value) => {
    setPayloadData((prev) => ({
      ...prev,
      availability: value === "Yes",
    }));
  };

  const handleClear = () => {
    setPayloadData({
      searchSkills: "",
      requiredSkills: [],
      experience: { min: "Junior", max: "Junior" },
      estimateBudget: {
        min: 0,
        max: 0,
      },
      requiredReputations: [],
      paymentModes: [],
      availability: "",
    });
    handleClose();
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      className="freela-bg-section rounded-5 rounded-end-0"
      onHide={handleClose}
    >
      <Offcanvas.Header
        className="border-bottom border-0 border-solid freela-border-greylight13"
        closeButton
      >
        <Offcanvas.Title className="py-2 mb-0 fw-600">
          By Filter
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack direction="vertical" className="gap-4">
          <div className="skills">
            <h6 className="freela-text-primary freela-fs-15px fw-400">By Skills</h6>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Search skills here..."
                value={payloadData.searchSkills}
                onChange={handleSearchTagsChange}
                onKeyDown={handleKeyDown}
                disabled={payloadData.requiredSkills.length >= 5}
              />
            </Form.Group>
            <div className="skills-list mt-2">
              <Nav className="flex-row flex-wrap gap-2">
                {payloadData.requiredSkills?.map((item, idx) => (
                  <Button
                    key={idx}
                    variant=""
                    size="sm"
                    className="freela-btn-primary2"
                  >
                    {item}
                  </Button>
                ))}
              </Nav>
            </div>
          </div>
          <div className="amount-range">
            <h6 className="freela-text-primary freela-fs-15px fw-400">By Amount range</h6>
            <ReactRangeSliderInput
              className="freela_range_slider"
              value={[
                payloadData.estimateBudget.min,
                payloadData.estimateBudget.max,
              ]}
              onInput={handleRangeChange}
            />
            <Stack direction="horizontal" className="mt-3">
              <Form.Group className="position-relative" style={{ width: 85 }}>
                <i className="bi bi-currency-dollar freela-text-greaseblack position-absolute top-50 start-0 translate-middle-y ms-2" />
                <Form.Control
                  type="number"
                  placeholder="Min"
                  className="ps-4"
                  value={payloadData.estimateBudget.min}
                  onChange={(e) => handleInputChange(e, "min")}
                />
              </Form.Group>
              <Form.Group
                className="position-relative ms-auto"
                style={{ width: 85 }}
              >
                <i className="bi bi-currency-dollar freela-text-greaseblack position-absolute top-50 start-0 translate-middle-y ms-2" />
                <Form.Control
                  type="number"
                  placeholder="Max"
                  className="ps-4"
                  value={payloadData.estimateBudget.max}
                  onChange={(e) => handleInputChange(e, "max")}
                />
              </Form.Group>
            </Stack>
          </div>

          <div className="amount-range">
            <h6 className="freela-text-primary freela-fs-15px fw-400">Experience range</h6>
            <ReactRangeSliderInput
              className="freela_range_slider"
              value={[
                experienceLevels.indexOf(payloadData.experience?.min), // Convert string to number
                experienceLevels.indexOf(payloadData.experience?.max),
              ]}
              min={0}
              max={2}
              step={1} // Only allows discrete values (0,1,2)
              onInput={handleExperienceChange}
            />
            <p>
              {payloadData.experience.min} - {payloadData.experience.max}
            </p>
          </div>

          <div className="tier_level">
            <h6 className="freela-text-primary freela-fs-15px fw-400">Tier Level</h6>
            <div className="skills-list mt-2">
              <Nav className="flex-row flex-wrap gap-2">
                {[
                  "Beginner",
                  "Emerging",
                  "Elite",
                  "Standard",
                  "Proficient",
                ].map((item, idx) => (
                  <Button
                    key={idx}
                    variant=""
                    size="sm"
                    className={`freela-btn-primary2 ${
                      payloadData.requiredReputations?.includes(item)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleReputation(item)}
                  >
                    {item}
                  </Button>
                ))}
              </Nav>
            </div>
          </div>
          <div className="modeofpayment">
            <h6 className="freela-text-primary freela-fs-15px fw-400">Mode of payment</h6>
            <Form>
              <div className="mb-3">
                {["FREL", "CRYPTO", "DOLLAR"].map((name, idx) => (
                  <Form.Check
                    key={`payment-${idx}`}
                    inline
                    name={`payment-${name}`}
                    type="checkbox"
                    id={`payment-${name}`}
                    label={name}
                    checked={payloadData.paymentModes.includes(name)}
                    onChange={() => togglePaymentMode(name)}
                  />
                ))}
              </div>
            </Form>
          </div>

          <div className="availability">
            <h6 className="freela-text-primary freela-fs-15px fw-400">Availability</h6>
            <Form>
              <div className="mb-3">
                {["Yes", "No"].map((name, idx) => (
                  <Form.Check
                    key={`availability-${idx}`}
                    inline
                    name="availability-group"
                    type="radio"
                    id={`availability-${name}`}
                    label={name}
                    checked={payloadData.availability === (name === "Yes")}
                    onChange={() => handleAvailabilityChange(name)}
                  />
                ))}
              </div>
            </Form>
          </div>
        </Stack>
      </Offcanvas.Body>
      <Offcanvas.Header className="border-top border-0 border-solid freela-border-greylight13 offcanvas-footer d-flex align-items-center justify-content-end gap-2">
        <Button
          variant=""
          className="freela-btn-primary-outline"
          onClick={() => handleClear()}
        >
          Clear
        </Button>
        <Button
          variant=""
          className="freela-btn-primary"
          onClick={() => {
            handleSearch(payloadData);
            handleClose();
          }}
        >
          Apply
        </Button>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default MyWorkFilterOffcanvas;
