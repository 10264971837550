import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Stack,
} from "react-bootstrap";
import { ReactComponent as StatusFlag } from "../../../../Images/freela-icons/status-flag.svg";
import { ReactComponent as SaveItemIcon } from "../../../../Images/freela-icons/save-item.svg";
import { ReactComponent as SaveItemFillIcon } from "../../../../Images/freela-icons/save-item-fill.svg";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import useToast from "../../../../Hooks/useToast";
import ApiCall from "../../../../Api/ApiCall";
import { useNavigate } from "react-router-dom";
import { CompareListModal } from "./CompareListModal";
import CustomModal from "../../../Common/Modal/CustomModal";
import { Breadcrumbs } from "../../../Common/Breadcrumbs/Breadcrumbs";
import { ComparingGrid } from "../../ComparingGrid/ComparingGrid";

const ShowMyBids = ({ projectId }) => {

  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [activeAccordian, setActiveAccordian] = useState(0);
  const [enableGrid, setEnableGrid] = useState(false);
  const [comparingData, setComparingData] = useState([]);
 

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  const handleAccordionClick = (idx) => {
    setActiveAccordian(activeAccordian === idx ? null : idx);
  };

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const response = await ApiCall(
        "GET",
        `bid/GetbidsByProjectId?projectId=${projectId}`,
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBid = async (bidId, stage) => {
    setLoading(true);
    try {
      const payload = {
        bidId: bidId,
        stage: stage,
      };
      const response = await ApiCall("PUT", "bid", payload, "application/json");
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log("successful:", response.data);
        notify("success", "You have successfully updated the bid!");
        navigate("/find-works");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleUpdateMeta = async (itemId, saveKey, isSave, endpoint) => {
    setLoading(true);

    try {
      const payload = {
        itemType: "bids",
        itemId: itemId,
        userId: userId,
        [saveKey]: isSave,
      };

      const response = await ApiCall(
        "POST",
        endpoint,
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200 || 201) {
        console.log("successful:", response.data);
        getActiveRoleDetails();
      } else {
        notify("error", response.message || "saved failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const breadcrumbPath = [
    {
      id: 0,
      name: "Home",
      path: "#",
    },
    {
      id: 0,
      name: "My Bids",
    },
  ];

  return (
    <>
      {loading && <Loader />}

      {enableGrid ? (
        <ComparingGrid
          comparingData={comparingData}
          setEnableGrid={setEnableGrid}
          handleBid={handleBid}
        />
      ) : (
        <div className="show_mybids px-4">
          <Breadcrumbs breadcrumbList={breadcrumbPath} />
          <Row className="gy-4">
            <Col md={9} lg={8} xl={10}>
              {activeRoleDetails &&
                activeRoleDetails.length > 0 &&
                [...new Set(activeRoleDetails.map((item) => item?.title))].map(
                  (uniqueTitle, index) => (
                    <div key={index}>
                      <h5>{uniqueTitle}</h5>
                    </div>
                  )
                )}
            </Col>
            <Col md={3} lg={4} xl={2}>
              <div className="text-md-end">
                <Button
                  variant=""
                  className="freela-btn-primary-outline"
                  onClick={() => setModalShow(true)}
                  disabled={activeRoleDetails?.length < 2 }
                >
                  Compare
                </Button>
              </div>
            </Col>

            <Col xs={12}>
              {activeRoleDetails && activeRoleDetails.length > 0 ? (
                <Accordion
                  defaultActiveKey={activeAccordian}
                  className="freela_accordion"
                >
                  {activeRoleDetails.map((item, idx) => (
                    <Accordion.Item eventKey={idx}>
                      <Accordion.Header
                        onClick={() => handleAccordionClick(idx)}
                      >
                        <Row key={idx} className="align-items-center gy-3">
                          <Col lg={4} xl={4}>
                            <div className="d-flex align-items-center gap-3">
                              <div className="position-relative">
                                <Image
                                  src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                  className="border border-1 p-05 freela-border-primary rounded-circle mb-0"
                                  width={52}
                                  height={52}
                                />
                                <span
                                  class="position-absolute bottom-0 mt-3 start-100 translate-middle badge border border-light rounded-circle bg-success"
                                  style={{ padding: 6 }}
                                >
                                  <span class="visually-hidden">
                                    unread messages
                                  </span>
                                </span>
                              </div>
                              <div className="content">
                                <div className="firstname mb-1">
                                  {item?.firstName}
                                </div>
                                <p className="freela-greaseblack-text text_truncate2 mb-0">
                                  {item?.message}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col sm={3} md={3} lg={3} xl={3}>
                            <div className="submiteddate_content">
                              <div className="submited_date freela-greaseblack-text mb-1">
                                Submitted Date
                              </div>
                              <p className="mb-0">{item?.deliveryDate}</p>
                            </div>
                          </Col>
                          <Col xs={7} sm={7} md={7} lg={3} xl={3}>
                            <div className="bid_content">
                              <div className="bid_amount freela-greaseblack-text mb-1">
                                Bid Amount
                              </div>
                              <p className="mb-0">
                                <span className="freela-text-primary">
                                  ${item?.bidAmount}
                                </span>
                                <span className="freela-greaseblack-text">
                                  /hour
                                </span>
                              </p>
                            </div>
                          </Col>
                          <Col xs={5} sm={2} md={2} lg={2} xl={2}>
                            <Stack
                              direction="horizontal"
                              className="gap-1 ms-lg-auto justify-content-end pe-lg-4"
                            >
                              <div className="status_flag">
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip>Beginner</Tooltip>}
                                >
                                  <StatusFlag
                                    width={25}
                                    className={`flag-beginner`}
                                  />
                                </OverlayTrigger>
                              </div>
                              <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip>Save Item</Tooltip>}
                              >
                                {item.isSavedItem ? (
                                  <Button
                                    variant="link p-0"
                                    onClick={() => {
                                      handleUpdateMeta(
                                        item.bidId,
                                        "isSavedItem",
                                        false,
                                        "metadata/saveItem"
                                      );
                                    }}
                                  >
                                    <SaveItemFillIcon width={25} />
                                  </Button>
                                ) : (
                                  <Button
                                    variant="link p-0"
                                    onClick={() => {
                                      handleUpdateMeta(
                                        item.bidId,
                                        "isSavedItem",
                                        true,
                                        "metadata/saveItem"
                                      );
                                    }}
                                  >
                                    <SaveItemIcon width={25} />
                                  </Button>
                                )}
                              </OverlayTrigger>
                            </Stack>
                          </Col>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="gy-3">
                          <Col md={6} lg={6} xl={7}>
                            <div className="d-flex flex-column flex-sm-row gap-3">
                              <div className="reputation-content text-center">
                                <div className="reputation_score freela-greaseblack-text freela-fs-12">
                                  Reputation Score
                                </div>
                                <div className="score freela-fs-14">
                                  <span className="freela-text-primary fw-600">
                                    85
                                  </span>
                                  <span className="freela-greaseblack-text">
                                    /100
                                  </span>
                                </div>
                              </div>
                              <div className="ai-verification-content text-center">
                                <div className="ai_verification_status freela-greaseblack-text freela-fs-12">
                                  Reputation Score
                                </div>
                                <div className="verification_status freela-text-primary freela-fs-14 fw-600">
                                  Verified
                                </div>
                              </div>
                              <div className="rating-content text-center">
                                <div className="client_rating freela-greaseblack-text freela-fs-12">
                                  Client rating
                                </div>
                                <div className="freela-text-primary freela-fs-14">
                                  <i className="bi bi-star-fill text-warning" />{" "}
                                  4.5 (2547)
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} lg={6} xl={5}>
                            <div className="d-flex flex-column flex-sm-row justify-content-md-end gap-3">
                              <Button
                                size="sm"
                                variant="link"
                                className="freela-text-primary text-decoration-none p-0"
                              >
                                View Proposal
                              </Button>
                              <Button
                                size="sm"
                                className="freela-btn-primary-outline"
                                onClick={() =>
                                  handleBid(item?.bidId, "rejected")
                                }
                              >
                                Reject
                              </Button>
                              <Button
                                size="sm"
                                className="freela-btn-primary"
                                onClick={() =>
                                  handleBid(item?.bidId, "Accepted")
                                }
                              >
                                Accept
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              ) : (
                <div>No Bids available</div>
              )}
            </Col>
          </Row>

          <CustomModal
            isOpen={modalShow}
            modalSize="sm"
            onClose={() => setModalShow(false)}
          >
            <CompareListModal
              activeRoleDetails={activeRoleDetails}
              projectId={projectId}
              setModalShow={setModalShow}
              setEnableGrid={setEnableGrid}
              setComparingData={setComparingData}
            />
          </CustomModal>
        </div>
      )}
    </>
  );
};

export default ShowMyBids;
