import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Nav,
  Stack,
  Image,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";

import { ReactComponent as LocationIcon } from "../../../Images/freela-icons/location-icon.svg";
import { ReactComponent as StatusFlag } from "../../../Images/freela-icons/status-flag.svg";
import { ReactComponent as SaveItemIcon } from "../../../Images/freela-icons/save-item.svg";
import { ReactComponent as SaveItemFillIcon } from "../../../Images/freela-icons/save-item-fill.svg";
import { ReactComponent as HeartIcon } from "../../../Images/freela-icons/heart-icon.svg";
import { ReactComponent as HeartFillIcon } from "../../../Images/freela-icons/heartfill-icon.svg";
import { ReactComponent as ReputationScoreIcon } from "../../../Images/freela-icons/works/reputationscore-icon.svg";
import { ReactComponent as AIVerificationIcon } from "../../../Images/freela-icons/works/AIVerification-icon.svg";
import { ReactComponent as ClientRatingIcon } from "../../../Images/freela-icons/works/clientrating-icon.svg";
import { ReactComponent as DollerIcon } from "../../../Images/freela-icons/works/doller-icon.svg";
import { ReactComponent as KYCIcon } from "../../../Images/freela-icons/works/kyc-icon.svg";
import { ReactComponent as FreelaCurrencyIcon } from "../../../Images/freela-icons/works/f-current-icon.svg";
import { ReactComponent as CCurrencyIcon } from "../../../Images/freela-icons/works/c-currency-icon.svg";
import { ReactComponent as DollerFillIcon } from "../../../Images/freela-icons/works/dollerfill-icon.svg";
import { ReactComponent as WalletIcon } from "../../../Images/freela-icons/works/wallet-icon.svg";
import defaultProfileImg from "../../../Images/freela-images/profile.png";
import { timeAgo } from "../../Common/utils/utils";

const GridView = ({
  activeRoleDetails,
  activeRole,
  handleProfilePreview,
  handleUpdateMeta,
  handleCard,
}) => {
  return activeRoleDetails && activeRoleDetails.length > 0 ? (
    activeRoleDetails?.map((item, idx) => (
      <Col md={6} lg={6} xl={4} key={idx}>
        <Card as={Card.Body} className="single_works rounded-5 h-100">
          <Row className="freela-bg-transparent border-0 g-0">
            <Col xs={9}>
              <Row
                className="g-2 flex-nowrap mb-0 cursor-pointer"
                onClick={() => handleProfilePreview(item?.userId)}
              >
                <Col xs="auto">
                  <div className="freelance_client_img position-relative">
                    {item.profilePhoto && item.profilePhoto !== "" ? (
                      <Image
                        src={item?.profilePhoto}
                        width={45}
                        height={45}
                        roundedCircle
                        alt="profile"
                        className="object-fit-cover"
                      />
                    ) : (
                      <Image
                        src={defaultProfileImg}
                        width={45}
                        height={45}
                        roundedCircle
                        alt="profile"
                        className="object-fit-cover"
                      />
                    )}
                    <span class="position-absolute bottom-0 end-0 badge border border-light rounded-circle bg-success p-1">
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Col>
                <Col xs={9}>
                  <Card.Title className="freela-fs-14px mb-2 freela-text-primary text-truncate">
                    {item?.firstName}
                  </Card.Title>
                  <Card.Subtitle className="freela-fs-12px mb-1 freela-text-greaseblack text-truncate">
                    {item?.role} - {item?.jobTitle}
                  </Card.Subtitle>
                  <Card.Text className="freela-fs-12px mb-0 freela-text-greaseblack ">
                    <LocationIcon className="d-inline-block me-1" />{" "}
                    {item?.country} - Posted by : {timeAgo(item?.createdAt)}
                  </Card.Text>
                </Col>
              </Row>
            </Col>
            <Col xs={3}>
              <Stack
                direction="horizontal"
                className="gap-1 justify-content-end"
              >
                <div className="status_flag">
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Beginner</Tooltip>}
                  >
                    <StatusFlag
                      width={20}
                      height={20}
                      className={`flag-beginner`}
                    />
                  </OverlayTrigger>
                </div>
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Save Item</Tooltip>}
                >
                  {item.isSavedItem ? (
                    <Button
                      variant="link p-0"
                      onClick={() => {
                        handleUpdateMeta(
                          item.serviceId || item.projectId,
                          "isSavedItem",
                          false,
                          "metadata/saveItem"
                        );
                      }}
                    >
                      <SaveItemFillIcon width={20} height={20} />
                    </Button>
                  ) : (
                    <Button
                      variant="link p-0"
                      onClick={() => {
                        handleUpdateMeta(
                          item.serviceId || item.projectId,
                          "isSavedItem",
                          true,
                          "metadata/saveItem"
                        );
                      }}
                    >
                      <SaveItemIcon width={20} height={20} />
                    </Button>
                  )}
                </OverlayTrigger>
                {item.isLiked ? (
                  <Button
                    variant="link p-0"
                    onClick={() => {
                      handleUpdateMeta(
                        item.serviceId || item.projectId,
                        "isLiked",
                        false,
                        "metadata/like-Item"
                      );
                    }}
                  >
                    <HeartFillIcon width={20} height={20} />
                  </Button>
                ) : (
                  <Button
                    variant="link p-0"
                    onClick={() => {
                      handleUpdateMeta(
                        item.serviceId || item.projectId,
                        "isLiked",
                        true,
                        "metadata/like-Item"
                      );
                    }}
                  >
                    <HeartIcon width={20} height={20} />
                  </Button>
                )}
              </Stack>
            </Col>
            <Col xs={12}>
              <div
                className="middle_content mt-3 cursor-pointer"
                onClick={() => handleCard(item.serviceId || item.projectId)}
              >
                <Card.Title as="h5" className="freela-fs-14px mb-0">
                  {item?.title || "No tilte available"}
                </Card.Title>
                {activeRole !== "freelancer" ? (
                  <div className="d-flex align-items-center flex-wrap gap-2 my-3">
                    {item.skills && item.skills.length > 0 ? (
                      item.skills.map((skill, skillIndex) => (
                        <Badge
                          key={skillIndex}
                          bg=""
                          text="dark"
                          className="freela-bg-white rounded-pill freela-fs-12px fw-400 border border-solid freela-border-primary-200"
                        >
                          {skill}
                        </Badge>
                      ))
                    ) : (
                      <div className="freela-fs-16px">No skills listed</div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex align-items-center flex-wrap gap-2 my-3">
                    {item.skillsNeeded && item.skillsNeeded.length > 0 ? (
                      item.skillsNeeded.map((skill, skillIndex) => (
                        <Badge
                          key={skillIndex}
                          bg=""
                          text="dark"
                          className="freela-bg-white rounded-pill freela-fs-12px fw-400 border border-solid freela-border-primary-200"
                        >
                          {skill}
                        </Badge>
                      ))
                    ) : (
                      <div className="freela-fs-16px">No skills listed</div>
                    )}
                  </div>
                )}
                <Stack
                  direction="horizontal"
                  className="gap-2 justify-content-between border-top border-bottom border-1 freela-border-primary-200 py-2 mb-3"
                >
                  <div className="reputaionscore text-center">
                    <ReputationScoreIcon width={16} height={16} />
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      Reputation Score
                    </Card.Text>
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      <span className="freela-text-primary">
                        {item?.reputation}
                      </span>
                      /100
                    </Card.Text>
                  </div>
                  <div className="aiverfication text-center">
                    <AIVerificationIcon width={16} height={16} />
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      AI Verification
                    </Card.Text>
                    <Card.Text className="freela-fs-10px freela-text-primary mb-0">
                      {item?.aiVerify === "true" ? "Verified" : "Not Verified"}
                    </Card.Text>
                  </div>
                  <div className="clientrating text-center">
                    <ClientRatingIcon width={16} height={16} />
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      Client Rating
                    </Card.Text>
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      <span className="freela-text-primary">
                        <i className="bi bi-star-fill" />{" "}
                        {item?.clientRating?.rating}
                      </span>{" "}
                      ({item?.clientRating?.totalUsers})
                    </Card.Text>
                  </div>
                  <div className="Amount text-center">
                    <DollerIcon width={16} height={16} />
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      {activeRole === "freelancer"
                        ? "Project Budget"
                        : "Amount USD"}
                    </Card.Text>
                    <Card.Text className="freela-fs-10px freela-text-greaseblack mb-0">
                      <span className="freela-text-primary">
                        $
                        {activeRole === "freelancer"
                          ? item?.estimateBudget?.min
                          : item?.independentPrice}
                      </span>
                      /hour
                    </Card.Text>
                  </div>
                </Stack>

                <Stack
                  direction="horizontal"
                  className="align-items-center gap-1"
                >
                  <div className="d-flex freela-fs-10px freela-text-greaseblack">
                    <KYCIcon
                      className="d-inline-block me-1"
                      width={16}
                      height={16}
                    />{" "}
                    KYC{" "}
                    <span className="ms-1 freela-text-primary">
                      {item?.kycVerify === "true" ? "Verified" : "Not Verified"}
                    </span>
                  </div>
                  <div className="paymentmethod d-flex align-items-center gap-2 ms-auto">
                    <div className="freela-fs-10px freela-text-greaseblack">
                      Payment Accepted
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      {/* Freela Currency */}
                      {item?.acceptedPaymentModes?.freelcoin && (
                        <OverlayTrigger overlay={<Tooltip>Freela</Tooltip>}>
                          <FreelaCurrencyIcon width={16} height={16} />
                        </OverlayTrigger>
                      )}

                      {/* Crypto Currencies */}
                      {Object.values(
                        item?.acceptedPaymentModes?.other || {}
                      ).includes(true) && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <Nav className="flex-row gap-1">
                                {Object.entries(
                                  item?.acceptedPaymentModes?.other
                                )
                                  .filter(([_, isEnabled]) => isEnabled)
                                  .map(([currency]) => (
                                    <Nav.Item key={currency}>
                                      {currency}
                                    </Nav.Item>
                                  ))}
                              </Nav>
                            </Tooltip>
                          }
                        >
                          <CCurrencyIcon width={16} height={16} />
                        </OverlayTrigger>
                      )}

                      {/* Traditional Currencies */}
                      {Object.values(
                        item?.acceptedPaymentModes?.traditional || {}
                      ).includes(true) && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <Nav className="flex-row gap-1">
                                {Object.entries(
                                  item?.acceptedPaymentModes?.traditional
                                )
                                  .filter(([_, isEnabled]) => isEnabled)
                                  .map(([currency]) => (
                                    <Nav.Item key={currency}>
                                      {currency}
                                    </Nav.Item>
                                  ))}
                              </Nav>
                            </Tooltip>
                          }
                        >
                          <DollerFillIcon width={16} height={16} />
                        </OverlayTrigger>
                      )}

                      {/* Any Mode */}
                      {item?.acceptedPaymentModes?.any && (
                        <OverlayTrigger overlay={<Tooltip>Any Mode</Tooltip>}>
                          <WalletIcon width={16} height={16} />
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </Stack>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    ))
  ) : (
    <Col xs={12}>
      <div className="text-center freela-fs-20px">
        No {activeRole === "freelancer" ? " projects " : " services "} available{" "}
      </div>
    </Col>
  );
};

export default GridView;
