import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ReactComponent as ModalCloseIcon } from '../../../Images/freela-icons/modal-close.svg'

const CustomModal = ({ isOpen, onClose, modalSize, children }) => {
  return (
    <Modal show={isOpen} onHide={onClose} size={modalSize} className="freela-modal" centered>
      <Button variant='link' className='close-icon' onClick={onClose}>
          <ModalCloseIcon width={30} />
      </Button>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;