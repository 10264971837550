import React, { useEffect, useState } from "react";
import { Row, Col, Card, Stack } from "react-bootstrap";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import Form from "react-bootstrap/Form";
import { useViewContext } from "../../../../Context/ViewContext";
import { ReactComponent as USDollerIcon } from "../../../../Images/freela-icons/us-doller-icon.svg";

const MyBids = () => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const { handleShowMyBids } = useViewContext();

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const response = await ApiCall(
        "GET",
        `bid/with-bids?userId=${userId}`,
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const getProposalDetailsByStage = async (stage) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint = `bid/filterByStatus?freelancerId=${userId}&stage=${stage}`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div className="my_proposals">
        <Row className="gy-3 align-items-center">
          <Col lg={9} xl={9} xxl={10}>
            <h3>My Bids</h3>
            <p className="mb-0">Nemo nesciunt dicta, impedit, qui excepturi voluptatum aliquam, magnam esse quaerat suscipit hic veniam fugit aperiam eum voluptate dolorem aspernatur eius deleniti.</p>
          </Col>
          <Col lg={3} xl={3} xxl={2}>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                const stage = e.target.value;
                if (stage !== "Select") {
                  getProposalDetailsByStage(stage);
                } else {
                  getActiveRoleDetails();
                }
              }}
            >
              <option>Select</option>
              <option value="Accepted">Accepted</option>
              <option value="InProgress">Inprogress</option>
              <option value="Rejected">Rejected</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="mt-3">
          {activeRoleDetails && activeRoleDetails.length > 0 ? (
            activeRoleDetails.map((item, index) => (
              <Col md={6} lg={6} xl={4} key={index}>
                <Card as={Card.Body} className="single_proposal rounded-5 h-100" onClick={() => { handleShowMyBids(item.projectId) }}>
                  <div className="my-2">
                    <Card.Title className="mb-1 freela-fs-18px text-truncate">{item.title}</Card.Title>
                    <Card.Text className="mb-0 freelance_client__description freela-text-greaseblack">{item.description}</Card.Text>
                  </div>
                  <Stack direction="horizontal" className="gap-2 justify-content-between text-center mt-2">
                    <div className="paid lh-1 freela-text-primary">Paid in <br /> US Dollar <USDollerIcon /></div>
                    <div className="total_bids">
                      <Card.Text className="freela-text-greaseblack mb-0">Total Bids</Card.Text>
                      <Card.Text className="freela-text-primary mb-0">{item.total_bids}</Card.Text>
                    </div>
                    <div className="avg_bid_value">
                      <Card.Text className="freela-text-greaseblack mb-0">Avg Bid Value</Card.Text>
                      <Card.Text className="freela-text-primary mb-0">{item.avg_bid}</Card.Text>
                    </div>
                  </Stack>
                </Card>
              </Col>
            )))
            :
            <Col xs={12}>No Bids available</Col>
          }
        </Row>
      </div>
    </>
  );
};

export default MyBids;
