import { useState } from "react";
import MyService from "./Tabs/MyService";
import MyProposals from "./Tabs/MyProposals";
import MyTasks from "./Tabs/MyTasks";
import MyWorkspace from "./Tabs/MyWorkspace";
import MyCalendar from "./Tabs/MyCalendar";

const FreelancerView = () => {
  const [activeTab, setActiveTab] = useState("myService");

  const renderTabContent = () => {
    switch (activeTab) {
      case "myService":
        return <MyService role="freelancer" />;
      case "myProposals":
        return <MyProposals />;
      case "myTasks":
        return <MyTasks />;
      case "myWorkspace":
        return <MyWorkspace />;
      case "myCalendar":
        return <MyCalendar />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tabs">
        <button onClick={() => setActiveTab("myService")}>My Service</button>
        <button onClick={() => setActiveTab("myProposals")}>My Proposals</button>
        <button onClick={() => setActiveTab("myTasks")}>My Tasks</button>
        <button onClick={() => setActiveTab("myWorkspace")}>My Workspace</button>
        <button onClick={() => setActiveTab("myCalendar")}>My Calendar</button>
      </div>
      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
};

export default FreelancerView;
