import React, { useState } from 'react';
import "./Register.css";
import logo from "../../Images/logo.png";
import register from "../../Images/register-verify.png";
import ApiCall from '../../Api/ApiCall';
import useToast from "../../Hooks/useToast";
import Loader from '../Common/Loader/Loader';
import { Row, Col } from 'react-bootstrap';

const RegisterVerify = ({ regEmail, openCongratsModal }) => {
    const [formData, setFormData] = useState({
        otp: '',
    });
    const [error, setError] = useState('');
    const { notify } = useToast();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');  // Clear previous error messages
        setLoading(true);


        try {
            // Prepare payload for registration
            const payload = {
                email: regEmail,
                // otp: formData.otp,
                otp: Number(formData.otp),
                authenticationType: "Registration"
            };

            console.log(payload, "register verify payload");


            // Make the API call
            const response = await ApiCall('POST', 'user/verifycode', payload, 'application/json');
            // Handle success with 200 status code

            if (response.statusCode === 200) {
                console.log('Registration verify successful:', response.message, "response.message", response.data);
                notify('success', "Registration verify successful!");
                openCongratsModal(); // Call the parent function to open RegisterCongrats modal

            }
            else {
                notify('error', response.message || "Registration failed. Please try again.")
                setError('Registration failed. Please try again.');
            }

        } catch (error) {
            // Handle API call error
            console.error('Registration error:', error);
            notify('error', error.response.data.message || "Registration failed. Please try again.")
            setError('Registration failed. Please try again.');
        }
        finally {
            // Set loading to false once the request is complete
            setLoading(false);
          }
    };

    const handleResend = async (e) => {
        e.preventDefault();
        setError('');  // Clear previous error messages
        setLoading(true);

        try {
            // Prepare payload for registration
            const payload = {
                email: regEmail,
                authenticationType: "Registration"
            };

            console.log(payload, "register resend payload");


            // Make the API call
            const response = await ApiCall('POST', 'user/resendcode', payload, 'application/json');
            // Handle success with 200 status code

            if (response.statusCode === 200) {
                console.log('Registration verify successful:', response.message, "response.message", response.data);
                notify('success', "Registration code resent successful!");
               
            }
            else {
                notify('error', response.message || "Registration code failed. Please try again.")
                setError('Registration failed. Please try again.');
            }

        } catch (error) {
            // Handle API call error
            console.error('Registration error:', error);
            notify('error', error || "Registration code failed. Please try again.")
            setError('Registration code failed. Please try again.');
        }
        finally {
            // Set loading to false once the request is complete
            setLoading(false);
          }
    };


    return (

        <> 
      {loading && <Loader />}
      <Row>
      <Col xs={12} sm={12} md={5} className="login-left-content">
        <img src={logo} alt="Logo" className="logo" />
        <img src={register} alt="login" className="w-100 left-img" />
      </Col>      
       

            {/* Right Section */}
            <Col xs={12} sm={12} md={7} className="mt-0 mt-md-5 pt-4 ">
                <h3>Please enter OTP to verify your account</h3>
                <p>
                    An OTP has been sent your email {regEmail}
                </p>

                {/* Register verify Form */}
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <div className="input-wrapper">
                            <input type="text" id="otp" placeholder="Enter OTP" value={formData.otp} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="auth-options">
                        <span className="checkbox-text">Did not recieve code?</span>

                        <a onClick={handleResend} className="forgot-password">
                            Resend OTP
                        </a>
                    </div>
                    <div className="text-center my-5">
                        <button type="submit" className="primary-btn py-2 w-50">
                        Verify
                        </button>
                    </div>
                </form>


            </Col>
        </Row>
        </>
    );
};

export default RegisterVerify;


