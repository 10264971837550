import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css"; // Import the stylesheet
import { ReactComponent as FindWorksIcon } from "../../../Images/icons/search-normal.svg";
import { ReactComponent as DashboardIcon } from "../../../Images/icons/dashboard.svg";
import { ReactComponent as MyBidsIcon } from "../../../Images/icons/bids.svg";
import { ReactComponent as MyWorksIcon } from "../../../Images/icons/myWork.svg";
import { ReactComponent as MyWalletIcon } from "../../../Images/icons/empty-wallet.svg";
import { ReactComponent as MyTasksIcon } from "../../../Images/icons/task-square.svg";
import { ReactComponent as MyReferralsIcon } from "../../../Images/icons/referral.svg";
import { ReactComponent as MyDisputesIcon } from "../../../Images/icons/dispute.svg";
import { ReactComponent as SettingsIcon } from "../../../Images/icons/setting-3.svg";
import { ReactComponent as SubscriptionIcon } from "../../../Images/icons/subscri.svg";
import left_arrow from "../../../Images/icons/left_arrow.png";
import {Card} from "react-bootstrap"



const Sidebar = () => {
  return (
    
    <Card className="sidebar-card">
      <Card.Header>
      <img src={left_arrow} alt="left" />
      </Card.Header>
      <Card.Body>
      <div className="sidebar">
      {/* Section 1 */}
      <div className="sidenav-content">
        <NavLink to="/find-works" className="sidenav-link">
        <FindWorksIcon />
          <span>Find Works</span>
        </NavLink>
        <NavLink to="/dashboard" className="sidenav-link">
          <DashboardIcon />
          <span>Dashboard</span>
        </NavLink>
        {/* <NavLink to="/my-bids" className="sidenav-link">
          <MyBidsIcon />
          <span>My Bids</span>
        </NavLink> */}
        <NavLink to="/my-works" className="sidenav-link">
          <MyWorksIcon />
          <span>My Works</span>
        </NavLink>
        <NavLink to="/my-wallet" className="sidenav-link">
          <MyWalletIcon />
          <span>My Wallet</span>
        </NavLink>
        {/* <NavLink to="/my-tasks" className="sidenav-link">
          <MyTasksIcon />
          <span>My Tasks</span>
        </NavLink> */}
        <NavLink to="/my-referrals" className="sidenav-link">
          <MyReferralsIcon />
          <span>My Referrals</span>
        </NavLink>
        <NavLink to="/my-disputes" className="sidenav-link">
          <MyDisputesIcon />
          <span>My Disputes</span>
        </NavLink>
      </div>
      </div>
      </Card.Body>
      <Card.Footer>
{/* Section 2 */}
<div className="sidenav-content">
        <NavLink to="/settings" className="sidenav-link">
          <SettingsIcon />
          <span>Settings</span>
        </NavLink>
        <NavLink to="/subscription" className="sidenav-link">
          <SubscriptionIcon />
          <span>Subscription</span>
        </NavLink>
      </div>
    
      </Card.Footer>
    </Card>

    

      
  );
};

export default Sidebar;
