import React, { useState } from "react";
import freelancer from "../../Images/freelancer_img.png";
import client from "../../Images/client_img.png";
import { Modal } from "react-bootstrap";
import close from "../../Images/modal_close.png";
import "../Common/Modal/Modal.css";


const ServiceModal = ({ isOpen, onClose, onRoleSelect }) => {
  const [selectedRole, setSelectedRole] = useState(null); // Store the selected role

  console.log(selectedRole,"selectedRole");
  

  const handleContinue = () => {
    if (selectedRole) {
      onRoleSelect(selectedRole); // Send the selected role
    }
    onClose(); // Close the modal
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered className="service-modal">
      <Modal.Header className="border-0 py-0">
        <img src={close} alt="close" onClick={onClose} />
      </Modal.Header>
      <Modal.Body className="text-center p-4">
        <div>
          <h5>Post Your Service</h5>
          <p>
            A mini game competition for the top <span>Freela</span> profile.
            Level up and secure more projects!
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <div
            onClick={() => setSelectedRole("freelancer")}
            className={selectedRole === "freelancer" ? "selected" : ""}
          >
           
            <div class="freelancer-image-container"></div>
           
            {/* {selectedRole === "freelancer"? <img src={freelancerSelected} alt="freelancer" /> : <img src={freelancer} alt="freelancer" />} */}
            <h5>Freelancer</h5>
          </div>
          <div
            onClick={() => setSelectedRole("client")}
            className={selectedRole === "client" ? "selected" : ""}
          >
            <div class="client-image-container"></div>
            <h5>Client</h5>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="primary-btn"
            onClick={handleContinue}
            disabled={!selectedRole}
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ServiceModal;
