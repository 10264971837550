import React, { useEffect, useRef, useState } from "react";
import ProjectSkills from "./ProjectSkills";
import Form from "react-bootstrap/Form";
import { ReactComponent as UploadIcon } from "../../../Images/freela-icons/file-upload-icon.svg";
import { ReactComponent as PdfIcon } from "../../../Images/freela-icons/pdf-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../Images/freela-icons/upload-file-download.svg";

import { Row, Col, Card, Button, Nav, Image, Ratio, Figure, Stack } from "react-bootstrap";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";

const Project = ({ userDetails, projectData, type }) => {
  const [showProSkills, SetShowProSkills] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [payloadData, setPayloadData] = useState({
    projectName: "",
    description: "",
    categoryId: "",
    category: "",
    subCategory: "",
    searchTags: "",
    searchTagsList: [],
    video: null,
    videoDescription: "",
    photoDescription: "",
    instruction: "",
    images: [],
    certificateFile: "",
    profilePhoto: "",
    coverPhoto: "",
  });
  const [searchTagsList, setSearchTagsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [certificateFile, setCertificateFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const certiInputRef = useRef(null);
  const profileInputRef = useRef(null);
  const coverInputRef = useRef(null);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");

  const handleProSkillNext = (boolean) => {
    SetShowProSkills(boolean);
    boolean && handlePost();
  };

  const handlePost = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: payloadData?.projectName,
        description: payloadData?.description,
        category: payloadData?.category,
        subCategory: payloadData?.subCategory,
        userId: userId,
        tags: payloadData?.searchTagsList,
        skillsNeeded: [],
        instruction: payloadData?.instruction,
        media: [
          {
            coverImage: payloadData?.coverPhoto,
            video: {
              url: payloadData?.video,
              description: payloadData?.videoDescription,
            },
            photo: {
              url: payloadData?.profilePhoto,
              description: payloadData?.photoDescription,
            },
            document: payloadData?.certificateFile,
          },
        ],
        paymentType: {
          type: "Hours",
          duration: 0,
        },
        estimateBudget: {
          min: 0,
          max: 0,
        },
        projectBidTimings: {
          start: "1991-01-01",
          end: "1991-01-01",
        },
        acceptedPaymentModes: {},
        serviceType: [],
        tier: [],
        status: "draft",
        projectStatus: "Open",
        ...((type === "draft" || projectId) && {
          projectId: projectData?.projectId || projectId,
        }),
      };

      // Make the API call
      const response = await ApiCall(
        type === "draft" || projectId ? "PUT" : "POST",
        type === "draft" || projectId ? "project" : "project/createProject",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === (type === "draft" || projectId ? 200 : 201)) {
        setProjectId(response.data.projectId);
        notify("success", "Project post successfully!");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const getCategories = async () => {
    setLoading(true);

    try {
      const response = await ApiCall(
        "GET",
        "metadata/getCategories",
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setCategoriesList(response.data);
      } else {
        console.error("Failed to fetch categories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching categories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getSubCategories = async (categoryId) => {
    setLoading(true);
    try {
      const response = await ApiCall(
        "GET",
        `metadata/${categoryId}/subcategories`,
        null,
        "application/json"
      );
      if (response.statusCode === 200) {
        setSubCategoriesList(response.data);
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error(
        "Error fetching subcategories:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payloadData.categoryId) {
      getSubCategories(payloadData.categoryId);
    } else {
      setSubCategoriesList([]);
      setPayloadData((prevData) => ({ ...prevData, subCategory: "" }));
    }
  }, [payloadData.category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayloadData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlesearchTagsChange = (e) => {
    setPayloadData({ ...payloadData, searchTags: e.target.value });
  };

  const handleDeleteSearchTags = (index) => {
    setSearchTagsList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    const trimmedTag = payloadData.searchTags.trim();

    if (e.key === "Enter" && trimmedTag) {
      e.preventDefault();

      if (!searchTagsList.includes(trimmedTag)) {
        setSearchTagsList((prev) => [...prev, trimmedTag]);
      }

      setPayloadData({ ...payloadData, searchTags: "" });
    }
  };

  useEffect(() => {
    setPayloadData((prev) => ({ ...prev, searchTagsList }));
  }, [searchTagsList]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("handleFileChange", file, "file");
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const playURL = URL.createObjectURL(file);
        setVideoSrc(playURL);
        setIsPlaying(false); // Reset play state

        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          setPayloadData((prevData) => ({
            ...prevData,
            video: response.data.s3Url,
          }));
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false); // Hide loading state after upload attempt
      }
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleChooseClick = () => {
    console.log("handleChooseClick");

    fileInputRef.current.click();
  };

  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0]; // Get the file from input
    if (file) {
      setPhoto(file);
      try {
        // Prepare the form data with the uploaded file
        const formData = new FormData();
        formData.append("file", file);

        // Call the API to upload the file
        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
          // Handle successful file upload response
          // setPhoto(file);
          setPayloadData((prevState) => ({
            ...prevState,
            [field]: response.data.s3Url, // Update the correct field dynamically
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!showProSkills ?
        <section className="section_card create_project px-md-5">
          <Row as={Form} className="mx-md-3">
            <Col xs={12}><div className="fs-4 fw-500 section-title mb-3">Create Project</div></Col>
            <Form.Group as={Col} xs={12} className="mb-3" controlId="projectName">
              <Form.Label>Project Name<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                name="projectName"
                value={payloadData?.projectName}
                onChange={handleChange}
                maxLength="150"
              />
              <div className="character-count text-end">
                {payloadData.projectName.length}/150 characters
              </div>
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3" controlId="ProjectDescription">
              <Form.Label>Project Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="write something about project"
                name="description"
                value={payloadData?.description}
                onChange={handleChange}
              />
              <div className="character-count text-end">
                {payloadData.description.length}/5000 Characters
              </div>
            </Form.Group>

            <Form.Group as={Col} sm={12} md={6} lg={6} xl={6} className="mb-3">
              <Form.Label> Select Category<span className="text-danger">*</span></Form.Label>
              <Form.Select
                id="category"
                value={payloadData.categoryId}
                onChange={(e) => {
                  const selectedCategory = categoriesList.find(
                    (category) => category._id === e.target.value
                  );
                  setPayloadData({
                    ...payloadData,
                    categoryId: selectedCategory._id,
                    category: selectedCategory.categoryName,
                  });
                }}
                required
                disabled={loading}
              >
                <option value="" disabled>
                  {loading ? "Loading categories..." : "Select Category"}
                </option>
                {categoriesList.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.categoryName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} sm={12} md={6} lg={6} xl={6} className="mb-3">
              <Form.Label> Select Sub Category<span className="text-danger">*</span></Form.Label>
              <Form.Select
                id="subCategory"
                value={payloadData.subCategory}
                onChange={(e) =>
                  setPayloadData({
                    ...payloadData,
                    subCategory: e.target.value,
                  })
                }
                required
                disabled={loading || !payloadData.category}
              >
                <option value="" disabled>
                  {loading
                    ? "Loading subcategories..."
                    : "Select Sub Category"}
                </option>
                {subCategoriesList.map((subCategory, index) => (
                  <option key={index} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-4" controlId="SearchTags">
              <Form.Label>Search Tags</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search Tags"
                value={payloadData.searchTags}
                onChange={handlesearchTagsChange}
                onKeyDown={handleKeyDown}
              />
            </Form.Group>

            {searchTagsList.length > 0 &&
              <Col xs={12} className="mb-3">
                <Nav className="flex-row flex-wrap justify-content-center gap-2">
                  {searchTagsList.map((tag, index) => (
                    <Button key={index} className="freela-btn-primary2" size="sm">
                      <span className="me-1">{tag}</span>
                      <i className="bi bi-x d-inline-block" onClick={() => handleDeleteSearchTags(index)}>
                      </i>
                    </Button>
                  ))}
                </Nav>
              </Col>
            }

            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label> Upload Cover and Images <span className="text-danger">*</span></Form.Label>
              <div className="form-text">Your service must have a video or at least 1 image.</div>
              <div className="form-text">Video file size must be less than 50 MB.</div>
              {coverPhoto ?
                <div className="preview_img mt-3">
                  <Image className="object-fit-cover rounded-5 w-100" height={320} src={URL.createObjectURL(coverPhoto)} alt="Cover Preview" />
                </div>
                :
                <Card as={Card.Body} className="rounded-5 py-5 mt-3">
                  <div className="text-center">
                    <UploadIcon width={60} height={60} />
                    <Card.Text className="my-3">Please upload your cover image</Card.Text>
                    <Button className="freela-btn-primary-outline px-5" onClick={() => handleUploadClick(coverInputRef)}>
                      Choose
                    </Button>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      ref={coverInputRef}
                      className="d-none"
                      onChange={(e) => handlePhotoChange(e, setCoverPhoto, "coverPhoto")}
                    />
                  </div>
                </Card>
              }
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3">
              <div className="freela-fs-18px">Instruction</div>
              <Form.Group className="mt-2">
                <Form.Label> Give links or special instructions for your Freelancers to follow</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Describe instructions here" name="instruction" value={payloadData?.instruction} onChange={handleChange} />
                <div className="character-count text-end">
                  {payloadData.instruction.length}/1000 characters
                </div>
              </Form.Group>
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3">
              <div className="freela-fs-18px">Upload Video</div>
              <Form.Label>Add a video that explains your project, or just to say hello on your listing.</Form.Label>
            </Form.Group>

            <Col xs={12} className="mb-3">
              <Row className="gy-3">
                <Col md={4} lg={4} xl={3}>
                  <Card className="rounded-5">
                    {videoSrc ?
                      <Ratio aspectRatio="16x9" className="positin-relative">
                        <video
                          ref={videoRef}
                          src={videoSrc}
                          className="w-100"
                          onClick={handlePlayPause}
                          onEnded={() => setIsPlaying(false)}
                          style={{ cursor: "pointer" }}
                          controls={false} />
                        <Button onClick={handlePlayPause} variant="link p-0" className="position-absolute top-50 start-50 translate-middle">
                          <i className={`bi bi-${isPlaying ? 'play' : 'pause'}`} />
                        </Button>
                      </Ratio>
                      :
                      <div className="text-center my-4">
                        <UploadIcon width={50} height={50} />
                        <Card.Text className="my-2 freela-fs-14px">Please upload <br /> your Video</Card.Text>
                        <Button variant="link p-0" className="freela-text-primary text-decoration-none" onClick={handleChooseClick}>
                          Browser
                        </Button>
                        <Form.Control type="file" ref={fileInputRef} accept="video/*" className="d-none" onChange={handleFileChange} />
                      </div>
                    }
                  </Card>
                </Col>
                <Col md={8} lg={8} xl={9}>
                  <Form.Control as="textarea" rows={8} placeholder="Describe here" name="videoDescription" value={payloadData?.videoDescription} onChange={handleChange} />
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="mb-3">
              <div className="freela-fs-18px">Photo</div>
              <p>Add a photo that explains your project, or just to say hello
                on your listing.</p>
              <Row className="gy-3">
                <Col md={4} lg={4} xl={3}>
                  <Card as={Card.Body} className="rounded-5 p-4">
                    {profilePhoto ?
                      <Image
                        src={URL.createObjectURL(profilePhoto)}
                        alt="Profile Preview"
                        className="preview-image"
                      />
                      :
                      <div className="text-center">
                        <UploadIcon width={50} height={50} />
                        <Card.Text className="my-2 freela-fs-14px">Please upload <br />your photo</Card.Text>
                        <Button variant="link p-0 mt-2" className="text-decoration-none freela-text-primary" onClick={() => handleUploadClick(profileInputRef)}>Browse</Button>
                        <Form.Control type="file" accept=".png,.jpeg,.jpg" ref={profileInputRef} className="d-none" onChange={(e) => handlePhotoChange(e, setProfilePhoto, "profilePhoto")} />
                      </div>
                    }
                  </Card>
                </Col>
                <Col md={8} lg={8} xl={9}>
                  <Form.Group>
                    <Form.Control as="textarea" rows={8} placeholder="Describe here" name="photoDescription" value={payloadData?.photoDescription} onChange={handleChange} />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="mb-4">
              <div className="freela-fs-18px">Upload Document</div>
              <Card as={Card.Body} className="rounded-5 p-4">
                {certificateFile ?
                  <Stack direction="horizontal">
                    <Figure className="d-flex gap-2 mb-0">
                      <PdfIcon width={50} height={50} />
                      <Figure.Caption className="file-fetails">
                        <Card.Text className="file-title text-dark mb-0 w-75 text-truncate">
                          {certificateFile.name}
                        </Card.Text>
                        <Card.Text className="file-meta mb-0">
                          {certificateFile.size} KB ·{" "}
                          {new Date(
                            certificateFile.lastModified
                          ).toLocaleDateString()}
                        </Card.Text>
                      </Figure.Caption>
                    </Figure>
                    {/* <Stack direction="horizontal" className="ms-auto gap-2">
                      <Button variant="link p-0">
                        <DownloadIcon />
                      </Button>
                      <Button
                        variant="link p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          // handleDelete();
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Stack> */}
                  </Stack>
                  :
                  <div className="d-flex gap-2 flex-md-row flex-column align-items-center justify-md-content-center">
                    <div className="d-flex gap-2 align-items-center">
                      <UploadIcon width={50} height={50} />
                      <Card.Text className="mb-0">Please upload your documents (Doc , Pdf..etc)</Card.Text>
                    </div>
                    <Button variant="" className="ms-md-auto freela-btn-primary-outline"
                      onClick={() => handleUploadClick(certiInputRef)}
                    >
                      Choose
                    </Button>
                    <Form.Control
                      type="file"
                      accept=".pdf,.docx"
                      className="d-none"
                      ref={certiInputRef}
                      onChange={(e) =>
                        handlePhotoChange(
                          e,
                          setCertificateFile,
                          "certificateFile"
                        )
                      }
                    />
                  </div>
                }
              </Card>
            </Col>

            <Form.Group className="text-end">
              <Button
                className="freela-btn-primary"
                onClick={() => handleProSkillNext(true)}
              >
                Save and Continue
              </Button>
            </Form.Group>
          </Row>
        </section>
        :
        <ProjectSkills
          payloadData={payloadData}
          setPayloadData={setPayloadData}
          handleBack={() => handleProSkillNext(false)}
          videoSrc={videoSrc}
          userDetails={userDetails}
          userId={userId}
          projectId={projectId}
          certificateFile={certificateFile}
          projectData={projectData}
          type={type}
        />
      }
    </>
  );
};

export default Project;
