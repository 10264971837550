import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { Home } from "./Components/LandingPage/Home";
import { About } from "./Components/LandingPage/About";
import { Blog } from "./Components/LandingPage/Blog";
import { LoginNavbar } from "./Components/Common/Navbar/LoginNavbar";
import { useAuth } from "../src/Context/AuthContext";
import { MainLayout } from "./MainLayout";

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [firstTimeLogin, setFirstTimeLogin] = useState(true);
  const { isLogin } = useAuth(); // Assuming useAuth provides login status


  // console.log(isLogin,"isLogin");

  return (
    <Router>
      <ToastContainer />
      {
        isLogin ?
          <MainLayout firstTimeLogin={firstTimeLogin} />
          :
          <>
            <LoginNavbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<Blog />} />
            </Routes>
          </>
      }
    </Router>
  );
};

export default App;
