// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse_card .uploaded_icon img, .browse_card img.preview-image{
    width: 100%;
    height:100%;
}
.browse_card .upload_icon{
    height:100%;
}
.browse_card h5{
    font-size: 20px !important;
    font-weight:500;
}
.browse_card h6{
    font-size: 16px;
    font-weight:500 !important;
}
@media (max-width:1024px){
    .browse_card h5{
        font-size: 16px !important;
    }
}
@media (max-width:540px) {
    .client-tabs-row{
        overflow: auto;
        justify-content: start !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ClientProfile/ClientProfile/ClientProfile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,0BAA0B;IAC1B,eAAe;AACnB;AACA;IACI,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI;QACI,0BAA0B;IAC9B;AACJ;AACA;IACI;QACI,cAAc;QACd,iCAAiC;IACrC;AACJ","sourcesContent":[".browse_card .uploaded_icon img, .browse_card img.preview-image{\r\n    width: 100%;\r\n    height:100%;\r\n}\r\n.browse_card .upload_icon{\r\n    height:100%;\r\n}\r\n.browse_card h5{\r\n    font-size: 20px !important;\r\n    font-weight:500;\r\n}\r\n.browse_card h6{\r\n    font-size: 16px;\r\n    font-weight:500 !important;\r\n}\r\n@media (max-width:1024px){\r\n    .browse_card h5{\r\n        font-size: 16px !important;\r\n    }\r\n}\r\n@media (max-width:540px) {\r\n    .client-tabs-row{\r\n        overflow: auto;\r\n        justify-content: start !important;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
