import React, { useState } from "react";
import useCommonNavigate from '../../../Hooks/useCommonNavigate';
import { ReactComponent as SettingClickArrow } from "../../../Images/freela-icons/setting-click-arrow.svg"
import { Row, Col, Card, Figure, Button, Image } from "react-bootstrap";

export const WelcomeLayout = ({ role, setRole }) => {
    const { commonNavigate } = useCommonNavigate(); // Get the common navigate function

    const handleNavigate = (option) => {
        setRole(option);
        commonNavigate("/profile");
    };

    const [isHovered, setIsHovered] = useState({ freelancer: false, client: false });

    const handleMouseEnter = (option) => {
        setIsHovered((prev) => ({ ...prev, [option]: true }));
    };

    const handleMouseLeave = (option) => {
        setIsHovered((prev) => ({ ...prev, [option]: false }));
    };

    // console.log(isHovered.client, "isHovered.client", isHovered.freelancer, "isHovered.freelancer");
    const settingsList = [{
        id: 1,
        event: 'freelancer',
        figureImg: require('../../../Images/freela-icons/settings-freelancer.svg').default,
        figureTitle: "I’m a Freelancer",
        figureDescription: "Ready to showcase my skills, win projects, and work on my own terms!",
    },
    {
        id: 2,
        event: 'client',
        figureImg: require('../../../Images/freela-icons/settings-client.svg').default,
        figureTitle: "I’m a Client",
        figureDescription: "Looking to hire amazing talent and get my project done, hassle-free!",
    }]
    return (
        <section className="settings">
            <Row>
                <Col className="mx-auto">
                    <Card as={Card.Body} className="rounded-5 mx-lg-4 py-5">
                        <div className="text-center mb-4">
                            <h2 className="freela-text-primary">Welcome to Freela!</h2>
                            <p>✨ Your journey to freedom starts here! ✨ <br />
                                What brings you to Freela today?</p>
                        </div>
                        <Row className="justify-content-center gap-4">
                            {settingsList.map((list, idx) =>
                                <Col sm={6} md={5} lg={5} xl={4} xxl={4} key={idx}>
                                    <Figure className="setting-single"
                                        onClick={() => handleNavigate(list.event)}
                                        onMouseEnter={() => handleMouseEnter(list.event)}
                                        onMouseLeave={() => handleMouseLeave(list.event)}
                                    >
                                        <Button variant="link" className="position-absolute click-arrow top-0 start-100 translate-middle">
                                            <SettingClickArrow width={75} height={75} />
                                        </Button>
                                        <Image src={require('../../../Images/freela-icons/setting-item-bg.svg').default} className="bg-shape" fluid alt="bg-shape" />
                                        <div className="figure-content position-absolute w-100 top-50 start-50 translate-middle">
                                            <Figure.Image src={list.figureImg} className="mb-3" fluid alt="img" />
                                            <Figure.Caption className="px-3">
                                                <h5 className="freela-text-primary">{list.figureTitle}</h5>
                                                <p className="mb-3">{list.figureDescription}</p>
                                            </Figure.Caption>
                                        </div>
                                    </Figure>
                                </Col>
                            )}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}
