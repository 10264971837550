import { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Loader = () => {
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#F0806C");

  useEffect(() => {
    // Add or remove the blur class from the body based on loading state
    if (loading) {
      document.body.classList.add("blurred");
    } else {
      document.body.classList.remove("blurred");
    }

    // Cleanup function to remove the blur class when the component unmounts
    return () => {
      document.body.classList.remove("blurred");
    };
  }, [loading]);

  return (
    <>
      {loading &&
        <div className="loader-overlay position-fixed w-100 h-100 start-0 top-0 z-2 d-flex align-items-center justify-content-center">
          <BeatLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      }
    </>
  );
};

export default Loader;
