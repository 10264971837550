import React, { useState } from "react";
import "./Register.css";
import logo from "../../Images/logo.png";
import register from "../../Images/register-verify.png";
import eye from "../../Images/icons/Eye.png";
import eyeOff from "../../Images/icons/Eyeoff.png";
import ApiCall from "../../Api/ApiCall";
import useToast from "../../Hooks/useToast";
import Loader from "../Common/Loader/Loader";
import { Row, Col } from 'react-bootstrap';

const ForgotVerify = ({ verifyEmail, openLogin }) => {
  const [formData, setFormData] = useState({
    otp: "",
    password: "",
    cpassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const { notify } = useToast();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error messages
    setLoading(true);


    try {
      // Prepare payload for Forgot verify
      const payload = {
        email: verifyEmail,
        otp:  Number(formData.otp),
        password: formData.password,
        authenticationType: "forgot password",
      };

      console.log(payload, "Forgot verify payload");

      // Make the API call
      const response = await ApiCall("POST", "user/verifycode", payload, 'application/json');
      // Handle success with 200 status code
      if (response.message === "ok") {
        console.log(
          "Forgot verify successful:",
          response.message,
          "response.message",
          response.data
        );
        notify('success', response.message||"Forgot verify successful");
        openLogin();
      } else {
        notify('error', response.message || "Forgot verify failed. Please try again.")
        setError("Forgot verify failed. Please try again.");
      }
    } catch (error) {
      // Handle API call error
      notify('error', error.response.data.message || "Forgot verify failed. Please try again.")
      console.error("Forgot verify error:", error);
      setError("Forgot verify failed. Please try again.");
    }
    finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };


  const handleResend = async (e) => {
    
    e.preventDefault();
    setError('');  // Clear previous error messages
    setLoading(true);

    try {
      // Prepare payload for forgot pwd
      const payload = {
        email: verifyEmail,
        authenticationType:"forgot password"

      };

      console.log(payload, "forgot resend pwd payload");


      // Make the API call
      const response = await ApiCall('POST', 'user/forgotpwd', payload);
      // Handle success with 200 status code
      if (response.statusCode === 200)  {
        // console.log('forgot pwd successful:', response.message, "response.message", response.data);
        notify('success',  response.message);
       
      }
      else {
        notify('error', response.message)
        setError('forgot pwd failed. Please try again.');
      }

    } catch (error) {
      // Handle API call error
      console.error('forgot pwd error:', error);
      notify('error', error.response.data.message )
      setError('forgot pwd failed. Please try again.');
    }finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (

    <>
      {loading && <Loader />}
      <div>
        <div>
      <img src={logo} alt="Logo" className="forgot-logo" />
      </div>
    <Row className="middle_content">
      <Col xs={12} sm={12} md={5} className="login-left-content">
        <img src={register} alt="login" className="w-100 left-img" />
      </Col>      

      {/* Right Section */}
      <Col xs={12} sm={12} md={7} className="px-4">
        <h3>Please enter OTP to verify your account</h3>
        <p>An OTP has been sent your email {verifyEmail}</p>

        {/* Register verify Form */}
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input
              type="text"
              id="otp"
              placeholder="Enter OTP"
              value={formData.otp}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth-options">
            <span className="checkbox-text">Did not recieve code?</span>

            <a onClick={handleResend} className="forgot-password">
              Resend OTP
            </a>
          </div>
          <div className="mt-4">
          <h5>Kindly enter your password to confirm. </h5>
          </div>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <label htmlFor="password">
                Password <span className="required-asterisk">*</span>
              </label>
              <div className="input-wrapper position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <img
                  src={showPassword ? eye : eyeOff}
                  alt="Eye Icon"
                  className="input-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <label htmlFor="cpassword">
                Confirm Password <span className="required-asterisk">*</span>
              </label>
              <div className="input-wrapper position-relative">
                <input
                  type={showCPassword ? "text" : "password"}
                  id="cpassword"
                  placeholder="Confirm your password"
                  value={formData.cpassword}
                  onChange={handleChange}
                  required
                />
                <img
                  src={showCPassword ? eye : eyeOff}
                  alt="Eye Icon"
                  className="input-icon"
                  onClick={() => setShowCPassword(!showCPassword)}
                />
              </div>
            </Col>
          </Row>
          <div className="text-center my-4">
          <button type="submit" className="primary-btn py-2 w-50">
          Verify
          </button>
          </div>
         
        </form>
      </Col>
    </Row>
    </div>
    </>
    
  );
};

export default ForgotVerify;
