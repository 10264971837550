import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Button } from "react-bootstrap";

import { ReactComponent as GoogleIcon } from "../../Images/freela-icons/google-icon.svg";

const GoogleLoginButton = ({handleSocial}) => {
  const handleLogin = (response) => {
    // console.log(" Login Response:", response);
    
    handleSocial("GOOGLE",response?.credential )
  };

  
  return (
    <GoogleLogin
      onSuccess={handleLogin}
      onError={() => console.error("Login Failed")}
      render={(renderProps) => (
        <Button
          variant="link"
          className="freela-text-primary text-decoration-none"
          onClick={renderProps.onClick} // Trigger the login on click
          disabled={renderProps.disabled} // Disable button if login is in progress
        >
          <GoogleIcon />
        </Button>
      )}
    />
  );
};

export default GoogleLoginButton;
