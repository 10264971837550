import { useState } from "react";
import FreelancerView from "./FreelancerView";
import ClientView from "./ClientView";

const FindWorks = () => {
  const [role, setRole] = useState("freelancer");

  return (
    <div className="find-works">
      {/* Row 1: Role Selection */}
      <div className="role-selection">
        <button onClick={() => setRole("freelancer")}>Freelancer</button>
        <button onClick={() => setRole("client")}>Client</button>
      </div>

      {/* Row 2: Tabs */}
      {role === "freelancer" ? <FreelancerView /> : <ClientView />}
    </div>
  );
};

export default FindWorks;
