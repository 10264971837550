// import React from 'react';
// import './Modal.css';
// import { Modal, Button } from 'react-bootstrap';

// const Modal = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay" onClick={onClose}>
//       <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//         <button className="modal-close-btn" onClick={onClose}>
//           &times;
//         </button>
//         {children}
//       </div>
//     </div>
//   );
// };

// export default Modal;


import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import close from "../../../Images/modal_close.png";
import './Modal.css';

const CustomModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered className='custom-modal'>
      <Modal.Header className='border-0 py-0'>
       <img src={close} alt="close" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      
    </Modal>
  );
};

export default CustomModal;