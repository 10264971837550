import React, { useState, useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import frel from "../../../Images/payment_icons/frel.png";
import usd from "../../../Images/payment_icons/usd.png";
import inr from "../../../Images/payment_icons/inr.png";
import euro from "../../../Images/payment_icons/euro.png";
import pound from "../../../Images/payment_icons/pound.png";
import bitcoin from "../../../Images/payment_icons/bitcoin.png";
import litecoin from "../../../Images/payment_icons/litecoin.png";
import ethereum from "../../../Images/payment_icons/ethereum.png";
import usdt from "../../../Images/payment_icons/usdt.png";
import "./Payment.css";

const Payment = ({ onSave, updatePayloadData }) => {
  const [acceptedPaymentModes, setAcceptedPaymentModes] = useState({
    freelcoin: true,
    traditional: { usd: false, inr: false, euro: false, pound: false },
    other: { bitcoin: false, litecoin: false, ethereum: false, usdt: false },
    any: false,
  });

  const [selectedMode, setSelectedMode] = useState("frel");
  // console.log(selectedMode,"selectedMode");

  const handleRadioChange = (mode) => {
    console.log(mode, "mode");

    setSelectedMode(mode);

    if (mode === "frel" || mode === "any") {
      setAcceptedPaymentModes((prevState) => ({
        ...prevState,
        freelcoin: mode === "frel",
        any: mode === "any",
        traditional: { usd: false, inr: false, euro: false, pound: false },
        other: {
          bitcoin: false,
          litecoin: false,
          ethereum: false,
          usdt: false,
        },
      }));
    } else if (mode === "traditional") {
      setAcceptedPaymentModes((prevState) => ({
        ...prevState,
        freelcoin: false,
        any: false,
        other: {
          bitcoin: false,
          litecoin: false,
          ethereum: false,
          usdt: false,
        },
      }));
    }
    else {
      setAcceptedPaymentModes((prevState) => ({
        ...prevState,
        freelcoin: false,
        any: false,
        traditional: { usd: false, inr: false, euro: false, pound: false },
      }));
    }
  };

  const isDisabled = (mode) => selectedMode && selectedMode !== mode;

  const handleCheckboxChange = (category, subMode) => {
    setAcceptedPaymentModes((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [subMode]: !prevState[category][subMode],
      },
    }));
  };

  // console.log(acceptedPaymentModes, "acceptedPaymentModes");

  useEffect(() => {
    updatePayloadData({ acceptedPaymentModes: [acceptedPaymentModes] });
  }, [acceptedPaymentModes]);

  return (
    <div>
      <div className="service-tilte">
        <h2>Payment</h2>
      </div>
      <Card className="p-4 payment_card">
        <div>
          <h2>Payment Mode</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ultrices curabitur lobortis
            pharetra id nisi. Eu justo justo econsequat nisl..Lorem ipsum dolor
            sit amet consectetur. Ultrices.
          </p>
          <div>
            <h5>Choose payment mode</h5>
          </div>
        </div>
        {/* Frel Coin */}
        <Form.Check
          inline
          className="mt-4"
          label="Frel Coin"
          name="group1"
          type="radio"
          defaultChecked={selectedMode === "frel"}
          onChange={() => handleRadioChange("frel")}
        />
        <div
          className={`d-flex align-items-center payment_mode ml-40 ${
            isDisabled("frel") ? "disabled" : ""
          }`}
        >
          <img src={frel} alt="frel" />
          <div className="ms-3">
            <p>Frel</p>
            <small>No transaction fees</small>
          </div>
        </div>
        {/* Traditional Payment Mode */}
        <div className="mt-4">
          <Form.Check
            inline
            label="Traditional Payment mode"
            name="group1"
            type="radio"
            onChange={() => handleRadioChange("traditional")}
          />
          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              type="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("traditional")}
              checked={acceptedPaymentModes.traditional.usd}
              onChange={() => handleCheckboxChange("traditional", "usd")}
            />
            <Form.Label>
              <img src={usd} alt="usd" className="me-3" />
              USD
            </Form.Label>
          </div>

          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              ltype="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("traditional")}
              checked={acceptedPaymentModes.traditional.inr}
              onChange={() => handleCheckboxChange("traditional", "inr")}
            />
            <Form.Label>
              <img src={inr} alt="inr" className="me-3"></img>INR
            </Form.Label>
          </div>

          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              ltype="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("traditional")}
              checked={acceptedPaymentModes.traditional.euro}
              onChange={() => handleCheckboxChange("traditional", "euro")}
            />
            <Form.Label>
              <img src={euro} alt="euro" className="me-3"></img>Euro
            </Form.Label>
          </div>
          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              ltype="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("traditional")}
              checked={acceptedPaymentModes.traditional.pound}
              onChange={() => handleCheckboxChange("traditional", "pound")}
            />
            <Form.Label>
              <img src={pound} alt="pound" className="me-3"></img>British Pound
            </Form.Label>
          </div>
        </div>
        {/* Other Payment Option */}
        <div className="mt-4">
          <Form.Check
            inline
            label="Other Payment Option"
            name="group1"
            type="radio"
            onChange={() => handleRadioChange("other")}
          />
          <p className="ml-40 grey_font">Cryptocurrencies</p>
          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              type="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("other")}
              checked={acceptedPaymentModes.other.bitcoin}
              onChange={() => handleCheckboxChange("other", "bitcoin")}
            />
            <Form.Label>
              <img src={bitcoin} alt="bitcoin" className="me-3" />
              Bitcoin
            </Form.Label>
          </div>
          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              ltype="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("other")}
              checked={acceptedPaymentModes.other.litecoin}
              onChange={() => handleCheckboxChange("other", "litecoin")}
            />
            <Form.Label>
              <img src={litecoin} alt="inr" className="me-3"></img>Litecoin
            </Form.Label>
          </div>
          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              ltype="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("other")}
              checked={acceptedPaymentModes.other.ethereum}
              onChange={() => handleCheckboxChange("other", "ethereum")}
            />
            <Form.Label>
              <img src={ethereum} alt="euro" className="me-3"></img>Ethereum
            </Form.Label>
          </div>
          <div className="d-flex align-items-center ml-40">
            <Form.Check
              inline
              ltype="checkbox"
              className="custom-checkbox"
              disabled={isDisabled("other")}
              checked={acceptedPaymentModes.other.usdt}
              onChange={() => handleCheckboxChange("other", "usdt")}
            />
            <Form.Label>
              <img src={usdt} alt="pound" className="me-3"></img>USDT (TRC-20)
            </Form.Label>
          </div>
        </div>
        {/* Any mode of payment */}
        <div className="mt-4">
          <Form.Check
            inline
            label="Check here for Any mode of payment"
            name="group1"
            type="radio"
            onChange={() => handleRadioChange("any")}
          />
        </div>
      </Card>
    </div>
  );
};
export default Payment;
