import React from "react";
import { Button, Card, Nav, Navbar, Offcanvas } from "react-bootstrap"
import { NavLink } from "react-router-dom";
import { ReactComponent as FindWorksIcon } from "../../../Images/freela-icons/asidenav-icons/search-normal.svg";
import { ReactComponent as DashboardIcon } from "../../../Images/freela-icons/asidenav-icons/dashboard.svg";
import { ReactComponent as MyBidsIcon } from "../../../Images/freela-icons/asidenav-icons/bids.svg";
import { ReactComponent as MyWorksIcon } from "../../../Images/freela-icons/asidenav-icons/my-works.svg";
import { ReactComponent as MyWalletIcon } from "../../../Images/freela-icons/asidenav-icons/empty-wallet.svg";
import { ReactComponent as MyTasksIcon } from "../../../Images/freela-icons/asidenav-icons/task-square.svg";
import { ReactComponent as MyReferralsIcon } from "../../../Images/freela-icons/asidenav-icons/referral.svg";
import { ReactComponent as MyDisputesIcon } from "../../../Images/freela-icons/asidenav-icons/dispute.svg";
import { ReactComponent as SettingsIcon } from "../../../Images/freela-icons/asidenav-icons/settings.svg";
import { ReactComponent as SubscriptionIcon } from "../../../Images/freela-icons/asidenav-icons/subscription.svg";
import { ReactComponent as AsideToggleIcon } from "../../../Images/freela-icons/aside-toggle.svg";
import { ReactComponent as FreelaLogo } from '../../../Images/freela-icons/freela-logo.svg';

const NavList = [
  {
    id: 1,
    icon: <FindWorksIcon />,
    name: "Find Works",
    url: "/find-works"
  },
  {
    id: 2,
    icon: <DashboardIcon />,
    name: "Dashboard",
    url: "/dashboard"
  },
  {
    id: 3,
    icon: <MyWorksIcon />,
    name: "My Works",
    url: "/my-works"
  },
  {
    id: 4,
    icon: <MyWalletIcon />,
    name: "My Wallet",
    url: "/my-wallet"
  },
  {
    id: 5,
    icon: <MyReferralsIcon />,
    name: "My Referrals",
    url: "/my-referrals"
  },
  {
    id: 6,
    icon: <MyDisputesIcon />,
    name: "My Disputes",
    url: "/my-disputes"
  },
  {
    id: 7,
    icon: <SettingsIcon />,
    name: "Settings",
    url: "/settings"
  },
  {
    id: 8,
    icon: <SubscriptionIcon />,
    name: "Subscription",
    url: "/subscription"
  }
]

export const Asidebar = ({ handleToggle, offcanvasShow, offcanvasClose }) => {
  return (
    <aside className="freela-aside">
      <Offcanvas show={offcanvasShow} onHide={offcanvasClose} responsive="xl">
        <Offcanvas.Header closeButton>
          <Navbar.Brand as={NavLink} to="/" className="outline-none">
            <FreelaLogo width={225} height={52} />
          </Navbar.Brand>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <Card className="aside-content">
            <Card.Header className="text-end d-none d-xl-block w-100 px-2">
              <Button variant="link" className="text-dark p-0" onClick={handleToggle}>
                <AsideToggleIcon width={30} height={30} />
              </Button>
            </Card.Header>
            <Card.Body className="p-2">
              <Nav className="flex-column mb-5 pb-5">
                {NavList.slice(0, 6).map((list, idx) =>
                  <Nav.Link key={idx} as={NavLink} to={list.url} onClick={offcanvasClose}>
                    <span className="icon d-inline-block">{list.icon}</span>
                    <span className="nav-name ms-2">{list.name}</span>
                  </Nav.Link>
                )}
              </Nav>
            </Card.Body>
            <Card.Footer className="p-2">
              <Nav className="flex-column">
                {NavList.slice(6, 8).map((list, idx) =>
                  <Nav.Link key={idx} as={NavLink} to={list.url} onClick={offcanvasClose}>
                    <span className="icon d-inline-block">{list.icon}</span>
                    <span className="nav-name ms-2">{list.name}</span>
                  </Nav.Link>
                )}
              </Nav>
            </Card.Footer>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </aside>
  );
};