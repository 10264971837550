// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../Styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form .react-tel-input .flag-dropdown{
  background-color: transparent;
  border: 0px solid #cacaca;
  border-radius: 30px 0px 0px 30px;
  border-right: 0;
}
.login-form .phone-inputbox {
  border: 1px solid #ccc;
  border-radius: 30px;
}
.congrates-right-content h1{
  color:  #F0806C;
  font-weight: 600;
  margin-bottom: 10px;
}
.congrates-right-content p{
  color:  #989898;
}`, "",{"version":3,"sources":["webpack://./src/Components/Register/Register.css"],"names":[],"mappings":"AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,gCAAgC;EAChC,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB","sourcesContent":["@import \"../../Styles.css\";\r\n\r\n.login-form .react-tel-input .flag-dropdown{\r\n  background-color: transparent;\r\n  border: 0px solid #cacaca;\r\n  border-radius: 30px 0px 0px 30px;\r\n  border-right: 0;\r\n}\r\n.login-form .phone-inputbox {\r\n  border: 1px solid #ccc;\r\n  border-radius: 30px;\r\n}\r\n.congrates-right-content h1{\r\n  color:  #F0806C;\r\n  font-weight: 600;\r\n  margin-bottom: 10px;\r\n}\r\n.congrates-right-content p{\r\n  color:  #989898;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
