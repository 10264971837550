import React from 'react'
import { Row, Col, Stack, Button, Form } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as UserIcon } from "../../../../Images/freela-icons/user-icon.svg";
import { ReactComponent as EmailIcon } from "../../../../Images/freela-icons/email-icon.svg";

const Step2 = ({ clickToBack, clickToContinue }) => {
    return (
        <Row className="py-4">
            <Col md={8} lg={8} xl={7} className='mx-auto'>
                <div className="content_one text-center">
                    <div className="h4 fw-500">You're just steps away from your dream job.</div>
                    <p>Mention the necessary details below to proceed!!</p>
                </div>
                <div className="content_two text-center my-5">
                    <div className="h5 fw-500">Fill the Basic Details</div>
                </div>
                <Form className='mx-lg-5 px-lg-5'>
                    <Form.Group className="mb-3" controlId="inputFirstName">
                        <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                        <div className="position-relative">
                            <Form.Control type="text" placeholder="Enter your first name" />
                            <UserIcon className="position-absolute top-50 end-0 translate-middle-y me-3" width={16} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inputEmailId">
                        <Form.Label>Email ID <span className="text-danger">*</span></Form.Label>
                        <div className="position-relative">
                            <Form.Control type="email" placeholder="Enter your email id" />
                            <EmailIcon className="position-absolute top-50 end-0 translate-middle-y me-3" width={18} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="inputPhoneNo">
                        <Form.Label>Phone no <span className="text-danger">*</span></Form.Label>
                        <PhoneInput as={Form.Control} country="in" value=""
                        //   onChange={(phone, country) => {
                        //     setFieldValue("phone", phone);
                        //     setFieldValue("code", country.dialCode);
                        //     setFieldValue("country", country.name);
                        //   }}
                        />
                    </Form.Group>
                    <Stack direction='horizontal' className='gap-4 pt-4 justify-content-center'>
                        <Button variant='' className='freela-btn-primary-outline px-5' onClick={clickToBack}>Cancel</Button>
                        <Button variant='' className='freela-btn-primary px-5' onClick={clickToContinue}>Continue</Button>
                    </Stack>
                </Form>
            </Col>
        </Row>
    )
}

export default Step2