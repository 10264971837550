import React, { useEffect, useState } from "react";
import {
  formatDateString,
  formatForInput,
  formatDateToDDMMYYYY,
} from "../../../Common/utils/utils";
import { ReactComponent as BookIcon } from "../../../../Images/freela-icons/book-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../Images/freela-icons/delete-icon.svg";
import { Row, Col, Card, Stack, Form, Button } from "react-bootstrap";

const EducationTab = ({ userDetails, onGetData, onGetErrData }) => {
  const [educationRows, setEducationRows] = useState([
    { degree: "", university: "", start: "", end: "" }, // Use "start" and "end" here
  ]);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");

  useEffect(() => {
    if (
      userDetails?.freelancerSettings?.education &&
      userDetails.freelancerSettings.education.length > 0
    ) {
      const formattedEducation = userDetails.freelancerSettings.education.map(
        (edu) => ({
          degree: edu.degree,
          university: edu.university,
          start: formatDateString(edu.start), // Change here
          end: formatDateString(edu.end), // Change here
        })
      );
      setEducationRows(formattedEducation);
    }
  }, [userDetails]);

  useEffect(() => {
    // Map to the required structure before passing data to parent component
    const formattedData = educationRows.map((row) => ({
      degree: row.degree,
      university: row.university,
      start: formatDateToDDMMYYYY(row.start), // Change here
      end: formatDateToDDMMYYYY(row.end), // Change here
    }));
    onGetData({ education: formattedData }); // Wrap in an object
  }, [educationRows]);

  const handleAddRow = () => {
    setEducationRows([
      ...educationRows,
      { degree: "", university: "", start: "", end: "" },
    ]); // Change here
  };

  const handleDeleteRow = (index) => {
    const updatedRows = educationRows.filter((_, i) => i !== index);
    setEducationRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...educationRows];
    updatedRows[index][field] = value;
    setEducationRows(updatedRows);
  };

  useEffect(() => {
    handleValidate();
  }, [educationRows]);

  const handleValidate = () => {
    let hasValidEntry = false;
    let newErrors = {};

    educationRows.forEach((edu, index) => {
      let entryErrors = {};

      if (!edu.degree.trim()) {
        entryErrors.degree = "Degree is required";
      } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.degree.trim())) {
        entryErrors.degree =
          "Degree must be between 2 to 50 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (!edu.university.trim()) {
        entryErrors.university = "University is required ";
      } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.university.trim())) {
        entryErrors.university =
          "University must be between 2 to 50 alphabetic characters";
      } else {
        hasValidEntry = true;
      }

      if (edu.start === "") {
        entryErrors.start = "Start Date is required";
      } else {
        hasValidEntry = true;
      }

      if (edu.end === "") {
        entryErrors.end = "End Date is required";
      } else {
        hasValidEntry = true;
      }

      if (Object.keys(entryErrors).length > 0) {
        newErrors[index] = entryErrors;
      }
    });

    if (!hasValidEntry || educationRows.length === 0) {
      setFormError("Please add at least one degree and university.");
    } else {
      setFormError(""); // Clear error if there's a valid entry
    }

    setErrors(newErrors);

    // Return true if there are no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onGetErrData(Object.keys(errors).length || (formError ? 1 : 0));
  }, [errors, formError]);

  return (
    <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
      <Row className="gy-3 mb-4 align-items-center">
        <Col md={6} lg={8} xl={8}>
          <Stack direction="horizontal" className="gap-2">
            <BookIcon width={50} />
            <div className="freela-fs-20 fw-500 freela-text-primary">
              Add Education
            </div>
          </Stack>
        </Col>
        <Col md={6} lg={4} xl={4}>
          <Stack
            direction="horizontal"
            className="gap-2 justify-content-md-end"
          >
            <Form.Check
              inline
              label="Currently Pursuing"
              name="CurrentlyPursuing"
              type="checkbox"
              id="CurrentlyPursuing-checkbox"
            />
            <Button
              variant=""
              className="freela-btn-primary-outline"
              onClick={handleAddRow}
            >
              Add
            </Button>
          </Stack>
        </Col>
      </Row>
      {formError && <div className="text-danger mt-2">{formError}</div>}
      {educationRows.map((row, index) => (
        <Row className="align-items-end mt-3">
          <Col lg={11} xl={11}>
            <Row className="gy-3">
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  Degree <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id={`degree-${index}`}
                  placeholder="Degree"
                  value={row.degree}
                  onChange={(e) =>
                    handleChange(index, "degree", e.target.value)
                  }
                  maxLength={50}
                  required
                />
                {errors[index]?.degree && (
                  <div className="text-danger mt-2">{errors[index].degree}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  University <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id={`university-${index}`}
                  placeholder="University"
                  value={row.university}
                  onChange={(e) =>
                    handleChange(index, "university", e.target.value)
                  }
                  maxLength={50}

                  required
                />
                {errors[index]?.university && (
                  <div className="text-danger mt-2">
                    {errors[index].university}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  From <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  id={`start-${index}`}
                  placeholder="Starting from *"
                  value={formatForInput(row.start)} // Change here
                  onChange={(e) => handleChange(index, "start", e.target.value)} // Change here
                  required
                />
                {errors[index]?.start && (
                  <div className="text-danger mt-2">{errors[index].start}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>
                  To <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  id={`end-${index}`}
                  placeholder="Ending *"
                  value={formatForInput(row.end)} // Change here
                  onChange={(e) => handleChange(index, "end", e.target.value)} // Change here
                  required
                />
                {errors[index]?.end && (
                  <div className="text-danger mt-2">{errors[index].end}</div>
                )}
              </Form.Group>
            </Row>
          </Col>

          <Col lg={1} xl={1}>
            <Button
              variant=""
              className="freela-btn-primary btn-rounded"
              onClick={() => handleDeleteRow(index)}
            >
              <DeleteIcon width={20} height={20} />
            </Button>
          </Col>
        </Row>
      ))}
    </Card>
  );
};

export default EducationTab;
