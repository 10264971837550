import React, { useEffect, useState } from "react";
import { Row, Col, Card, Stack, Button, Form } from "react-bootstrap"
import { ReactComponent as BookIcon } from '../../../../Images/freela-icons/book-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../Images/freela-icons/delete-icon.svg';
import { formatDateString, formatForInput, formatDateToDDMMYYYY } from "../../../Common/utils/utils";

const ExperienceTab = ({ userDetails, onGetData, onGetErrData }) => {
  const [experienceRows, setExperienceRows] = useState([
    { designation: "", company: "", start: "", end: "", weblinks: "" },
  ]);

    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState("");


  useEffect(() => {
    if (userDetails?.clientSettings) {
      const experienceData = userDetails.clientSettings.experience || [];
      const weblinkData = userDetails.clientSettings.weblinks || [];

      // Combine experience and weblinks into a single array of objects
      const combinedData = experienceData.map((exp, index) => ({
        designation: exp.designation || "",
        company: exp.company || "",
        start: formatDateString(exp.start) || "",
        end: formatDateString(exp.end) || "",
        weblinks: weblinkData[index] || "", // Use the corresponding weblink if available
      }));

      setExperienceRows(combinedData);
    }
  }, [userDetails]);


  useEffect(() => {

    const formattedExperience = experienceRows
      .filter((row) => row.designation || row.company) // Filter out entries that are only weblinks
      .map((row) => ({
        designation: row.designation,
        company: row.company,
        start: formatDateToDDMMYYYY(row.start),
        end: formatDateToDDMMYYYY(row.end),
      }));

    const formattedWeblinks = experienceRows
      .filter((row) => row.weblinks)
      .map((row) => row.weblinks);

    onGetData({
      experience: formattedExperience,
      weblinks: formattedWeblinks,
    });
  }, [experienceRows]);

  const handleAddRow = () => {
    setExperienceRows([
      ...experienceRows,
      { designation: "", company: "", start: "", end: "" },
    ]);
  };


  const handleDeleteRow = (index) => {
    const updatedRows = experienceRows.filter((_, i) => i !== index);
    setExperienceRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...experienceRows];
    updatedRows[index][field] = value;
    setExperienceRows(updatedRows);
  };

  useEffect(() => {
      handleValidate();
    }, [experienceRows]);
  
    const handleValidate = () => {
      let hasValidEntry = false;
      let newErrors = {};
  
      experienceRows.forEach((edu, index) => {
        let entryErrors = {};
  
        if (!edu.designation.trim()) {
          entryErrors.designation = "Designation is required";
        } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.designation.trim())) {
          entryErrors.designation =
            "Designation must be between 2 to 50 alphabetic characters";
        } else {
          hasValidEntry = true;
        }
  
        if (!edu.company.trim()) {
          entryErrors.company = "Company is required ";
        } else if (!/^[A-Za-z\s]{2,50}$/.test(edu.company.trim())) {
          entryErrors.company =
            "Company must be between 2 to 50 alphabetic characters";
        } else {
          hasValidEntry = true;
        }
  
        if (edu.start === "") {
          entryErrors.start = "Start Date is required";
        } else {
          hasValidEntry = true;
        }
  
        if (edu.end === "") {
          entryErrors.end = "End Date is required";
        } else {
          hasValidEntry = true;
        }

        if (edu.weblinks === "") {
          entryErrors.weblinks = "Website Link is required";
        } else if (
          !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/\S*)?$/.test(
            edu.weblinks.trim()
          )
        ) {
          entryErrors.weblinks =
            "Enter a valid URL (e.g., https://www.example.com)";
        } else {
          hasValidEntry = true;
        }
  
        if (Object.keys(entryErrors).length > 0) {
          newErrors[index] = entryErrors;
        }
      });
  
      if (!hasValidEntry || experienceRows.length === 0) {
        setFormError("Please add your employment details.");
      } else {
        setFormError("");
      }
  
      setErrors(newErrors);
  
      // Return true if there are no errors, otherwise false
      return Object.keys(newErrors).length === 0;
    };
  
    useEffect(() => {
      onGetErrData(Object.keys(errors).length || (formError ? 1 : 0));
    }, [errors, formError]);

       
  return (
    <Card as={Card.Body} className="rounded-5 pb-4 px-4 inner_card">
      <Row className="gy-3 mb-4 align-items-center">
        <Col md={6} lg={8} xl={8}>
          <Stack direction="horizontal" className="gap-2">
            <BookIcon width={50} />
            <div className="freela-fs-20 fw-500 freela-text-primary">Add Work Experience</div>
          </Stack>
        </Col>
        <Col md={6} lg={4} xl={4} className="text-md-end">
          <Button variant="" className="freela-btn-primary-outline" onClick={handleAddRow}>Add</Button>
        </Col>
      </Row>
      {formError && <div className="text-danger mt-2">{formError}</div>}

      {experienceRows.map((row, index) => (
        <Row className="align-items-end mt-3" key={index}>
          <Col lg={11} xl={11}>
            <Row className="gy-3">
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>Designation <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  id={`designation-${index}`}
                  placeholder="Designation"
                  value={row.designation}
                  onChange={(e) =>
                    handleChange(index, "designation", e.target.value)
                  }
                  required
                />
                 {errors[index]?.designation && (
                  <div className="text-danger mt-2">
                    {errors[index].designation}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  id={`company-${index}`}
                  placeholder="Company Name"
                  value={row.company}
                  onChange={(e) => handleChange(index, "company", e.target.value)}
                  required
                />
                {errors[index]?.company && (
                  <div className="text-danger mt-2">
                    {errors[index].company}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>Start Date <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="date"
                  id={`start-${index}`}
                  placeholder="Starting from *"
                  value={formatForInput(row.start)}
                  onChange={(e) => handleChange(index, "start", e.target.value)}
                  required
                />
                 {errors[index]?.start && (
                  <div className="text-danger mt-2">{errors[index].start}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} lg={6} xl={3}>
                <Form.Label>Ending Date <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="date"
                  id={`end-${index}`}
                  placeholder="Ending *"
                  value={formatForInput(row.end)}
                  onChange={(e) => handleChange(index, "end", e.target.value)}
                  required
                />
                {errors[index]?.end && (
                  <div className="text-danger mt-2">{errors[index].end}</div>
                )}
              </Form.Group>
            </Row>
          </Col>
          <Col lg={1} xl={1}>
            <Button variant="" className="freela-btn-primary btn-rounded" onClick={() => handleDeleteRow(index)}>
              <DeleteIcon width={20} height={20} />
            </Button>
          </Col>
        </Row>
      ))}

      <Row className="gy-3 align-items-end mt-5">
        <Col xs={12}>
          <Stack direction="horizontal" className="gap-2">
            <BookIcon width={50} />
            <div className="freela-fs-20 fw-500 freela-text-primary">Add website links</div>
          </Stack>
        </Col>

        {experienceRows.map((row, index) => (
          <Col xs={12} key={index}>
            <Stack direction="horizontal" className="gap-2 align-items-end">
              <Form.Group className="w-100">
                <Form.Label>Website link <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  id={`weblinks-${index}`}
                  placeholder="Enter website link here"
                  value={row.weblinks}
                  onChange={(e) =>
                    handleChange(index, "weblinks", e.target.value)
                  }
                  required
                />
                {errors[index]?.weblinks && (
                    <div className="text-danger mt-2">
                      {errors[index].weblinks}
                    </div>
                  )}
              </Form.Group>
              <Button variant="link p-0" onClick={() => handleDeleteRow(index)} style={{ height: 52 }}>
                <DeleteIcon width={42} />
              </Button>
            </Stack>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default ExperienceTab;
