import React, { useState, useEffect } from "react";
import { Row, Col, Card, Image, Stack, Button } from "react-bootstrap";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import { debounce } from "lodash";
import Loader from "../../../Common/Loader/Loader";
import { useViewContext } from "../../../../Context/ViewContext";
import { ReactComponent as EditIcon } from "../../../../Images/freela-icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../Images/freela-icons/delete-icon.svg";
import { ReactComponent as USDollerIcon } from "../../../../Images/freela-icons/us-doller-icon.svg";
import { ReactComponent as ProfileVerifyIcon } from "../../../../Images/freela-icons/verify-shield-icon.svg";
import DefaultProfileImg from "../../../../Images/freela-images/profile.png";

const PublishedMessages = ({ role }) => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const { handleShowPublishedService } = useViewContext();

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    if (role) {
      debouncedGetActiveRoleDetails();
    }
    return () => debouncedGetActiveRoleDetails.cancel();
  }, [role]);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `service/user?userId=${userId}&status=final`
          : `project/user?userId=${userId}&status=final`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  // console.log(activeRoleDetails, "activeRoleDetails");

  const handleGetDetails = async (id) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer" ? `service/${id}` : `project/${id}`;
      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        handleShowPublishedService(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      if (error.status === 404) {
        notify("success", "No Records Found");
      } else {
        notify("error", error.message || "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    console.log(id, "id");
    setLoading(true);
    try {
      const endpoint =
        role === "freelancer" ? `service/${id}` : `project/${id}`;

      console.log(endpoint, "endpoint");

      const response = await ApiCall(
        "DELETE",
        endpoint,
        {},
        "application/json"
      );
      console.log(response, "response");

      if (response.statusCode === 200 || response.statusCode === 201) {
        getActiveRoleDetails();
      } else {
        notify("error", response.message || "Failed to delete proposal");
      }
    } catch (error) {
      console.error("Delete error:", error.response?.data || error.message);
      notify("error", error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Row className="gy-4 my_proposals">
        <Col xs={12}>
          <h3>Published</h3>
          <p className="mb-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi saepe quisquam sed nemo suscipit et vel officia distinctio, mollitia aut eaque atque dolor veritatis maxime! Iusto reprehenderit quibusdam nobis nam.</p>
        </Col>

        {activeRoleDetails && activeRoleDetails.length > 0 ? (
          activeRoleDetails.map((item, index) => (
            <Col md={6} lg={6} xl={4} key={index}>
              <Card as={Card.Body} className="single_proposal rounded-5 h-100">
                <Row className="align-items-center g-0">
                  <Col xs={role === "freelancer" ? 9 : 12}>
                    <Row className="align-items-center g-2 flex-nowrap mb-0">
                      <Col xs="auto">
                        <div className="freelance_client_img position-relative">
                          <Image src={item?.profilePhoto ? item.profilePhoto : DefaultProfileImg} width={45} height={45} roundedCircle alt="profile" className="p-05 object-fit-cover border-1 border-solid freela-border-primary" />
                          <ProfileVerifyIcon width={14} height={14} className="position-absolute bottom-0 end-0" />
                        </div>
                      </Col>
                      <Col>
                        <h1 className="freela-fs-16px mb-0 freela-text-black freela-w-70 text-truncate">{item.firstName}</h1>
                        <p className="freela-fs-12px m-0 text-truncate">United States</p>
                      </Col>
                    </Row>
                  </Col>
                  {role === "freelancer" &&
                    <Col xs={3}>
                      <Stack direction="horizontal" className="justify-content-end gap-1">
                        <Button varaint="" className="freela-btn-primary btn-rounded btn-xs">
                          <EditIcon width={14} height={14} />
                        </Button>
                        <Button varaint="" className="freela-btn-primary btn-rounded btn-xs"
                          onClick={() => handleDelete(role === "freelancer" ? item.serviceId : item.projectId)}
                        >
                          <DeleteIcon width={14} height={14} />
                        </Button>
                      </Stack>
                    </Col>
                  }
                </Row>
                <div className="content cursor-pointer my-2"
                  onClick={() => handleGetDetails(role === "freelancer" ? item.serviceId : item.projectId)}
                >
                  <Card.Title className="mb-1 freela-fs-18px text-truncate">{item.title}</Card.Title>
                  <div className="rating small freela-text-warning">
                    <i className="bi bi-star-fill" /> 4.5 <span className="ms-1 freela-text-greaseblack">(120)</span>
                  </div>
                  <Card.Text className="mb-0 freelance_client__description freela-text-greaseblack text_truncate2">{item.description}</Card.Text>
                </div>
                <Stack direction="horizontal">
                  <div className="paid lh-1">Paid in <br /> US Dollar <USDollerIcon /></div>
                  {item?.estimateBudget &&
                    <div className="ms-auto">
                      <div className="project_budget">Project Budget</div>
                      <div className="freela-text-primary fw-500">
                        {role === "freelancer" ?
                          <>{item?.estimateBudget?.min}/hour</>
                          :
                          <>${item?.estimateBudget?.min} - ${item?.estimateBudget?.max}/hour</>
                        }
                      </div>
                    </div>}
                </Stack>
              </Card>
            </Col>
          ))
        ) :
          <Col xs={12}>No Published services available</Col>
        }
      </Row>
    </>
  );
};

export default PublishedMessages;
